(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

            .state('api2', {
                parent: 'entity',
                url: '/api2',
                data: {
                    authorities: ["ROLE_PLATFROM_API_SETTING"],
                    pageTitle: 'global.menu.entities.api'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/key-url-setting/settingDetail.html',
                        controller:'APIDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PlatformWechatSetting', function($stateParams, PlatformWechatSetting) {
                        return PlatformWechatSetting.byPlatFrom().$promise;
                    }]
                }
            })

            .state('api2-edit', {
                parent: 'api2',
                url: '/{id}/edit',
                data: {
                    authorities: ["ROLE_PLATFROM_API_SETTING"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/key-url-setting/settingDetail-dialog.html',
                        controller: 'PlatformWechatSettingDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['PlatformWechatSetting', '$stateParams', function(PlatformWechatSetting, $stateParams) {
                        return PlatformWechatSetting.byPlatFrom().$promise;
                    }]
                }
            })

        }

})();
