(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LeagueController', LeagueController);

    LeagueController.$inject = ['$state', 'StoreApplies', 'ParseLinks', 'MessageService','$scope', 'Store', '$stateParams'];

    function LeagueController($state, StoreApplies, ParseLinks, MessageService, $scope, Store, $stateParams) {

        var vm = this;

        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = "";
        $scope.batchArr = [];

        loadAll();

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.leagueList,function (data,i) {
                if($scope.selectAll){
                    data.select = true;
                    $scope.batchArr.push(data.id);
                    $scope.selectOne = true;
                }else{
                    data.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (data) {
            data.select = !data.select;
            // 选中
            if(data.select){
                $scope.batchArr.push(data.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(data.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.leagueList,function (item,i) {
                if(!item.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.leagueList.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }
        function loadAll () {
            var _items = [];
            if(vm.searchQuery){
                _items.push({key: "name", op: "like", value: vm.searchQuery})
            }
            Store.getStoreForJoin({
                items: _items,
                id: $stateParams.storeId,
                sort: ["id,desc"]
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.leagueList = data;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        function search () {
            loadAll()
        }

        $scope.unbind = function (data) {
            MessageService.confirm("确定解绑下级商户？",function(){
                Store.signOut({id: data.id}, function (res) {
                    MessageService.success("解绑成功！");
                    loadAll()
                }, function (error) {
                })
            },function(){},true)
        }
        $scope.batchSignOut = function () {
            if($scope.batchArr.length == 0){
                MessageService.error("请选择解绑的商户");
                return
            }
            MessageService.confirm("确定批量解绑所有商户？",function(){
                Store.batchSignOut({ids: $scope.batchArr},function () {
                    MessageService.success("解绑成功");
                    loadAll();
                },function () {
                    MessageService.error("解绑失败");
                })
            },function(){},true);
        }
    }
})();
