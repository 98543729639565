(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PricingController', PricingController);

    PricingController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Category', 'PaginationUtil', 'PlatformWechatSetting'];

    function PricingController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, MessageService, $uibModalInstance, Category, PaginationUtil, PlatformWechatSetting) {
        var vm = this;
        vm.pricing = angular.copy(entity.data);
        console.log(entity)
        vm.type = entity.type;
        vm.save = save;
        vm.cancel = cancel;
        vm.products = [];
        vm.pricingStrategy = [
            {name: '标准定价', id: 'Standard'},
            {name: '阶梯定价', id: 'Ladder'},
            // {name: '梯度定价', id: 'Grads'},
        ];
        vm.conditions = [
            {name: '数量', id: 'Quantity'},
            // {name: '价格', id: 'Price'}
        ];
        vm.priceTypes = [
            {name: '单价', id: 'Price'},
            {name: '一口价', id: 'Fixed'}
        ];
        if(entity.type == 'add'){
            vm.pricing.priceRule = 'Standard';
            vm.pricing.priceStandard = 'Quantity';
            vm.pricing.priceLines = [];
            vm.pricing.itemValueLines = [];
        }
        //最小数值
        PlatformWechatSetting.byPlatFrom({},function (res) {
            console.log(res)
            vm.priceMinQuantity = res.priceMinQuantity || 0;
        })

        //可销售的商品
        Product.get({id: vm.pricing.productId},function(data){
            for(var i=0; i<data.itemValues.length; i++){
                if(!data.itemValues[i].cancelled){
                    for(var j=0; j<data.itemValues[i].itemValueLines.length; j++){
                        vm.products.push(data.itemValues[i].itemValueLines[j])
                    }
                }
            }
            if (entity.type == 'edit' || entity.type == 'look'){
                for(var i=0; i<vm.pricing.itemValueLines.length; i++){
                    for(var j=0; j<vm.products.length; j++){
                        if(vm.pricing.itemValueLines[i].id === vm.products[j].id){
                            vm.products[j].selected = true
                        }
                    }
                }
            }
        })
        $scope.productsCheckbox = function (data) {
            vm.pricing.itemValueLines = [];
            data.selected = !data.selected ? true : false;
            for(var i=0; i<vm.products.length; i++){
                if(vm.products[i].selected){
                    vm.pricing.itemValueLines.push(vm.products[i])
                }
            }
        }
        //定价策略
        $scope.pricingStrategyChange = function(){
            if(vm.pricing.priceRule == 'Standard'){
                for(var i = vm.pricing.priceLines.length-1;i >= 0 ;i--){
                    if(!vm.pricing.priceLines[i].id){
                        vm.pricing.priceLines.splice(i, 1);
                    }else {
                        vm.pricing.priceLines[i].cancelled = true;
                    }
                }
            }
            if(vm.pricing.priceRule == 'Ladder' || vm.pricing.priceRule == 'Grads'){
                vm.pricing.price = "";
            }
        }
        //添加价格
        $scope.addPriceLines = function () {
            var _obj = {
                startQuantity: '',
                endQuantity: '',
                priceType: '',
                price: ''
            }
            vm.pricing.priceLines.push(_obj)
        }
        //确定
        function save() {
            if(vm.pricing.itemValueLines.length == 0){
                MessageService.error("请选择可销售商品");
                return
            }

            vm.pricing.itemValueLines.sort(function(a, b){
                return a.lowestPrice - b.lowestPrice;
            })

            if(vm.pricing.priceRule == 'Standard' && !vm.pricing.price){
                MessageService.error("请输入大于0的价格");
                return
            }

            var lowestPrice = vm.pricing.itemValueLines[0].lowestPrice;
            if(vm.pricing.priceRule == 'Standard' && vm.pricing.price<lowestPrice){
                MessageService.error("价格不能小于最低成交价 ￥ "+lowestPrice);
                return false;
            }

            if(vm.pricing.priceRule == 'Ladder' || vm.pricing.priceRule == 'Grads'){
                if(vm.pricing.priceLines.length == 0){
                    MessageService.error("请输入价格");
                    return
                }
                if(!isPrice()){
                    return;
                }
            }

            $uibModalInstance.close(vm.pricing);
        }
        //取消
        function cancel() {
            $uibModalInstance.close();
        }

        //删除阶梯价格
        $scope.deletePriceLines = function (data, index) {
            if(!data.id){
                vm.pricing.priceLines.splice(index, 1)
            }else {
                data.cancelled = true;
            }
        }
        //判断阶梯价格
        function isPrice() {
            var _priceLines = [];
            var _priceLinesArr = angular.copy(vm.pricing.priceLines);
            for(var i=0; i<_priceLinesArr.length; i++){
                if(!_priceLinesArr[i].cancelled){
                    _priceLines.push(_priceLinesArr[i]);
                }
            }
            for(var i=0; i<_priceLines.length; i++){
                if(!_priceLines.cancelled){
                    var _isI;
                    if(!_isI && _priceLines[i].startQuantity < vm.priceMinQuantity){
                        MessageService.error("开始数量必须大于" + vm.priceMinQuantity);
                        return false
                    }else {
                        _isI = true
                    }
                    if(_priceLines[i].endQuantity <= _priceLines[i].startQuantity){
                        MessageService.error("结束数量不能小于或者等于开始数量");
                        return false
                    }
                    if(!_priceLines[i].priceType){
                        MessageService.error("请选择价格类型");
                        return false
                    }
                    if(!_priceLines[i].price){
                        MessageService.error("请输入价格");
                        return false
                    }
                    var lowestPrice = vm.pricing.itemValueLines[0].lowestPrice;
                    if(_priceLines[i].price < lowestPrice){
                        MessageService.error("价格不能小于最低成交价 ￥ "+lowestPrice);
                        return false
                    }
                }
            }
            // _priceLines.sort(function(a,b){
            //     var _a = Number(a.startQuantity) + Number(a.endQuantity);
            //     var _b = Number(b.startQuantity) + Number(b.endQuantity);
            //     if(_a<_b){
            //         return -1;
            //     }else if(_a>_b){
            //         return 1;
            //     }
            //     return 0;
            // });
            for(var i=0; i<_priceLines.length; i++){
                if(!_priceLines.cancelled){
                    var _index = i + Number(1);
                    if(_index < _priceLines.length){
                        var _thisEnd = Number(_priceLines[i].endQuantity);
                        var _nextStart = Number(_priceLines[_index].startQuantity);
                        if(_thisEnd == _nextStart){
                            MessageService.error("不能重复" + _thisEnd);
                            return false
                        }
                        if(_thisEnd > _nextStart){
                            MessageService.error("不能重复" + _thisEnd + "和" + _nextStart + "之间的数值");
                            return false
                        }
                        if((_thisEnd + Number(1)) < _nextStart){
                            MessageService.error("阶梯价格缺少" + _thisEnd + "和" + _nextStart + "之间的数值");
                            return false
                        }
                    }
                }

            }

            for(var i=vm.pricing.priceLines.length-1; i>= 0; i--){
                if(!vm.pricing.priceLines[i].cancelled){vm.pricing.priceLines.splice(i, 1);}
            }
            for(var j=0; j<_priceLines.length; j++){
                vm.pricing.priceLines.push(_priceLines[j])
            }
            return true
        }

        vm.priceRuleStatus = priceRuleStatus;
        function priceRuleStatus(target) {
            switch (target) {
                case "Standard":
                    return "标准定价";
                case "Ladder":
                    return "阶梯定价";
                case "Grads":
                    return "梯度定价";
                default:
                    return "";
            }
        }
        vm.conditionsStatus = conditionsStatus;
        function conditionsStatus(target) {
            switch (target) {
                case "Quantity":
                    return "数量";
                default:
                    return "";
            }
        }
        vm.priceTypeStatus = priceTypeStatus;
        function priceTypeStatus(target) {
            switch (target) {
                case "Price":
                    return "单价";
                case "name":
                    return "一口价";
                default:
                    return "";
            }
        }
    }
})();
