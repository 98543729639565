(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

            .state('message-temp', {
                parent: 'entity',
                url: '/message-temp',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.api'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/message-temp/messageTemp.html',
                        controller:'MessageTempController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'MessageTemp', function($stateParams, MessageTemp) {
                        return MessageTemp.get().$promise;
                    }]
                }
            })

            .state('message-temp-edit', {
                parent: 'message-temp',
                url: '/edit',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/message-temp/messageTemp-dialog.html',
                        controller: 'MessageTempDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['MessageTemp', '$stateParams', function(MessageTemp, $stateParams) {
                        return MessageTemp.get().$promise;
                    }]
                }
            })

        }

})();
