(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('SecretKeyDialogController', SecretKeyDialogController);

    SecretKeyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'SecretKey', 'MessageService'];

    function SecretKeyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, SecretKey, MessageService) {
        var vm = this;
        vm.storeList = [
            {name:'公众号', value:'WECHAT'},
            {name:'微信小程序', value: 'MINI'},
            {name:'网页端', value: 'WEB'},
            {name:'支付宝小程序', value: 'ALI'},
            {name:'字节跳动小程序', value: 'BYTEDANCE'},
            {name:'京东小程序', value: 'JD'}
        ];

        vm.data = entity;
        vm.clear = clear;
        vm.save = save;
        $q.all([vm.data.$promise]).then(function(){
             console.log(vm.data)
            if(vm.data.id){
                var arr = vm.data.storePort.split(';');
                angular.forEach(arr,function (item) {
                    angular.forEach(vm.storeList,function (list) {
                        if(list.value==item){
                            list.selected = true
                        }
                    })
                })

                if(vm.data.branch){
                    vm.data.branch = "true";
                }else{
                    vm.data.branch = "false";
                }
            }
        })

        $scope.generateKey = function(){
            SecretKey.generateKey(function (res) {
                vm.data.key = res.key;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.validate = function(){
            var ports = []
            angular.forEach(vm.storeList,function (s) {
                if(s.selected){
                    ports.push(s.value)
                }
            })
            vm.data.storePort = ports.join(';');
            if(vm.data.storePort == null || vm.data.storePort == ""){
                MessageService.error("请选择商户版本");
                return false;
            }

            if(vm.data.key == null || vm.data.key==""){
                MessageService.error("请生成密钥");
                return false;
            }
            if(vm.data.validYears == null || vm.data.validYears==""){
                MessageService.error("请输入商户有效日期");
                return false;
            }
            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;

            if (vm.data.id != null) {
                SecretKey.update(vm.data, onSaveSuccess, onSaveError);
            } else {
                SecretKey.save(vm.data, onSaveSuccess, onSaveError);
            }
        };

        function onSaveSuccess (result) {
            MessageService.success("密钥保存成功");
            $scope.$emit('editorApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        function onSaveError () {
            MessageService.error("密钥保存失败");
            vm.isSaving = false;
        };
    }
})();
