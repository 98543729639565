(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('AfterSaleController', AfterSaleController);

    AfterSaleController.$inject = ['$scope', '$q', '$state', 'Principal', 'AfterSale', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', 'Product'];

    function AfterSaleController ($scope, $q, $state, Principal, AfterSale, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, Product) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.productId = "";
        var s = localStorage.getItem('status');
        localStorage.removeItem('status');
        vm.products = Product.getAllByCondition2({items:[{key:"prototype",op:"=",value:"Original"}]})||[];

        vm.isAuthenticated = Principal.isAuthenticated;

        vm.jump = jump;

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if(s){
                vm.status = s;
            }else{
                vm.status = null;
            }
            loadAll();
        })

        $scope.delete = function(banner){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Banner.delete({id: banner.id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        $scope.byStatus =function(status){
            vm.status = status;
            loadAll();
        };

        $scope.byTime = function(time){
            vm.time = time;
            loadAll();
        };
        $scope.productChange = function(){
            loadAll();
        };

        function loadAll () {
            var items=[{key:"salesOrder.cancelled",op:"!=",value:"true"},{key:"salesOrder.orderLines.orderArticle.prototype",op:"=",value:"Store"}];
            if (vm.time != null) {
                if (vm.time == 'today') {
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()))});
                }else if(vm.time == 'week'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-7*24*60*60*1000-1))});
                }else if(vm.time == 'weeks'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-14*24*60*60*1000-1))});
                }else if(vm.time == 'moth'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-30*24*60*60*1000-1))});
                }
            };
            if (vm.status !=null) {
                items.push({key:"statue",op:"in",value:vm.status});
            };
            if (vm.productId !=null) {
                items.push({key:"salesOrder.orderLines.orderArticle.protoProductId",op:"=",value:vm.productId});
            };

            if (vm.searchQuery) {
                items.push({key:"salesOrder.number,describe,problem,number",op:"like",value:vm.searchQuery}),
                AfterSale.byCondition({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: ["lastModifiedDate,desc"]
                }, onSuccess, onError);
            } else {
                AfterSale.byCondition({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: ["lastModifiedDate,desc"]
                }, onSuccess, onError);
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.allPagesCount = Math.ceil(vm.totalItems/20);
                vm.queryCount = vm.totalItems;
                vm.afterSales = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("获取数据失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.currentSearch = searchQuery;
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.currentSearch = null;
            vm.productId = null;
            loadAll();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }
    }
})();
