(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BannerDialogController', BannerDialogController);

    BannerDialogController.$inject = ['$timeout', '$q', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Banner', 'MessageService', 'Qinius', 'UidService'];

    function BannerDialogController ($timeout, $q, $scope, $stateParams, $uibModalInstance, entity, Banner, MessageService, Qinius, UidService) {
        var vm = this;

        vm.banner = entity;
        vm.clear = clear;
        vm.save = save;
        vm.status = vm.banner.status;
        vm.saleableCheck = saleableCheck;
        vm.storeId = $stateParams.storeId;

        vm.tokens = Qinius.get(); 
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.tokens.$promise,vm.banner.$promise]).then(function(){ 
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/'; 
        })

        //模板背景图更改
        window.updateImgFile = function ($event) {  
            $timeout(function () {
                var file = $event.target.files[0]; 
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型 
                    };
                    reader.onerror = function (error) {     //  出错 
                    };
                }; 
                getBase64(file, function (result) { 
                }); 
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false); 
                formData.append('key', "banner-"+ UidService.get());
                formData.append('token', vm.token); 
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        console.log(data);
                        vm.banner.img = data.key; 
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        }; 


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function saleableCheck(num) {
            console.log(num);
            if (num == 1) {
                vm.status = false;
            } else {
                vm.status = true;
            }
        }

        $scope.validate = function(){
            if(vm.banner.number == null || vm.banner.number==""){
                MessageService.error("请输入排序编号");
                return false;
            }
            if (vm.banner.img == null || vm.banner.img=="") {
                MessageService.error("请上传展示图片");
                return false;
            };
            return true;
        }

        function save () {
            vm.banner.status = vm.status;
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.banner.id !== null) {
                Banner.update(vm.banner, onSaveSuccess, onSaveError);
            } else {
                vm.banner.storeId =vm.storeId;
                Banner.createByAgent(vm.banner, onSaveSuccess, onSaveError);
            }
        } 

        $scope.removeFile = function(file) {
            vm.banner.img = null; 
        } 

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:bannerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        } 
    }
})();
