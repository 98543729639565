(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayBatch3Controller', PayBatch3Controller);

    PayBatch3Controller.$inject = ['$state', '$scope', 'PayBatch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService'];

    function PayBatch3Controller($state, $scope, PayBatch, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        $scope.batchArr = [];

        loadAll();

        
        function loadAll () {
            var items = []
            if (pagingParams.search) {
                items.push({key:"storeName,productName,supplier,number",op:"like",value:pagingParams.search});
            }  
            PayBatch.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
             
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.allPagesCount = Math.ceil(vm.totalItems/20);
                vm.queryCount = vm.totalItems;
                vm.payBatchs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据加载失败");
            }
        }
 

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        //通过
        $scope.approve = function (id) {
            MessageService.confirm("确认通过该请求", function () {
                PayBatch.approve({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        }
        //拒绝
        $scope.reject = function (id) {
            MessageService.confirm("确认拒绝该请求", function () {
                PayBatch.reject({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        }
        //提交
        $scope.submit = function (id) {
            MessageService.confirm("确认提交该信息", function () {
                PayBatch.submit({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        }

    }
})();
