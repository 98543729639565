(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayBatchDialogController', PayBatchDialogController);

    PayBatchDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PayBatch', 'Store', 'Product', 'MessageService', '$q',
        'ItemValueLinePackageViewC', 'ProductPurchase', 'ItemValueLinePackage'];

    function PayBatchDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PayBatch, Store, Product, MessageService, $q,
                                       ItemValueLinePackageViewC, ProductPurchase, ItemValueLinePackage) {
        var vm = this;

        vm.payBatch = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.save2 = save2;// 已分配 编辑保存
        vm.store = Store.getStore({id : $stateParams.storeId});
        vm.payBathAll = PayBatch.getAllByCondition({items: [{key: "storeId", op: "=", value: $stateParams.storeId}]})||[];
        vm.purchases = ProductPurchase.getAllByCondition2({items: [{key: "status", op: "=", value: "Completed"},{key: "storeId", op: "=", value: $stateParams.storeId}]})||[];

        $q.all([vm.payBatch.$promise, vm.store.$promise, vm.payBathAll.$promise, vm.purchases.$promise, vm.store.$promise]).then(function(){
            vm.payBatch.storeId = vm.store.id;
            vm.payBatch.storeName = vm.store.name;

            if(vm.payBatch.purchaseType=='COMMODITYPURCHASE'){
                ItemValueLinePackage.getPackage({
                    purchaseId:vm.payBatch.productPurchaseId,
                    itemValueLineId:vm.payBatch.itemValueLineId,
                },function (data) {
                    vm.payBatch.packageId = data.id;
                    vm.maxStockQuantity = data.stockQuantity;
                })
            }else if(vm.payBatch.purchaseType=='GIFTPURCHASE'){
                ItemValueLinePackage.getPackage({
                    purchaseId:vm.payBatch.productPurchaseId
                },function (data) {
                    vm.payBatch.packageId = data.id;
                    vm.maxStockQuantity = data.stockQuantity;
                })
            }

            vm.productPurchaseLines = [];
            for (var i = vm.purchases.length - 1; i >= 0; i--) {
                var p = vm.purchases[i];
                if (p.cancelled) {
                    continue;
                }
                if (p.id == vm.payBatch.productPurchaseId) {
                    vm.productPurchaseLines = angular.copy(p.productPurchaseLines)
                    formatting();
                    vm.itemValueLines = [];
                    for (var j = vm.products.length - 1; j >= 0; j--) {
                        var product = vm.products[j];
                        if (product.cancelled) {
                            continue;
                        }
                        if (product.productId == vm.payBatch.productId) {
                            vm.itemValueLines = angular.copy(product.itemValueLines);
                            break;
                        }
                    }
                    break;
                }
            }
        })

        $scope.rangeChange = function(){
            vm.payBatch.productId = null;
            vm.payBatch.productName = null;
        }

        $scope.purchaseChange = function(){
            if (vm.payBatch.productPurchaseId == null || vm.purchases == null || vm.purchases.length == 0) {
                vm.payBatch.productPurchaseId == null;
                return;
            }
            vm.productPurchaseLines = [];
            for (var i = vm.purchases.length - 1; i >= 0; i--) {
                var p = vm.purchases[i];
                if (p.cancelled) {
                    continue;
                }
                if (p.id == vm.payBatch.productPurchaseId) {
                    vm.productPurchaseLines = angular.copy(p.productPurchaseLines)
                    vm.payBatch.purchaseType = p.purchaseType;
                    formatting();
                    vm.maxStockQuantity = null;
                    vm.payBatch.itemValueLineId = null;
                    vm.payBatch.productId = null;
                    vm.itemValueLines = [];
                    if(vm.payBatch.purchaseType=='GIFTPURCHASE'){
                        ItemValueLinePackage.getPackage({
                            purchaseId:vm.payBatch.productPurchaseId
                        },function (data) {
                            vm.payBatch.packageId = data.id;
                            vm.maxStockQuantity = data.stockQuantity;
                            // vm.payBatch.packageName = data.productPurchaseName;
                        })
                    }
                    break;
                }
            }
        }

        function formatting() {
            vm.products = []
            for(var i=0; i<vm.productPurchaseLines.length; i++){
                if (vm.productPurchaseLines[i].cancelled) {
                    continue;
                }
                var _itemValueLineProductId = vm.productPurchaseLines[i].itemValueLineProductId;
                var _itemValueLineProductName = vm.productPurchaseLines[i].itemValueLineProductName;
                var _product = {
                    productId: _itemValueLineProductId,
                    productName: _itemValueLineProductName,
                    itemValueLines:  []
                }
                var _isResult = vm.products.some(function (item, index) {
                    if(item.productId==_itemValueLineProductId){
                        vm.products[index].itemValueLines.push(vm.productPurchaseLines[i])
                        return true
                    }
                })
                if(!_isResult){
                    _product.itemValueLines.push(vm.productPurchaseLines[i])
                    vm.products.push(_product)
                }
            }
        }

        $scope.productChange = function(){
            vm.payBatch.productName = null;
            if (vm.payBatch.productId == null || vm.products == null || vm.products.length == 0) {
                vm.payBatch.productId == null;
                return;
            }
            vm.itemValueLines = [];
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var p = vm.products[i];
                if (p.cancelled) {
                    continue;
                }
                if (p.productId == vm.payBatch.productId) {
                    vm.payBatch.productName = p.productName;
                    vm.itemValueLines = angular.copy(p.itemValueLines);
                    break;
                }
            }
            console.log(vm.payBatch.productId)
        }

        $scope.skuChange = function () {
            ItemValueLinePackage.getPackage({
                purchaseId:vm.payBatch.productPurchaseId,
                itemValueLineId:vm.payBatch.itemValueLineId,
            },function (data) {
                vm.payBatch.packageId = data.id;
                vm.maxStockQuantity = data.stockQuantity;
            })
        }

        $scope.generateCode = function (e) {
            fn(e);
            if(vm.store.unique==null){
                MessageService.error("请先设置商户标识");
                return
            }
            var nowYear = new Date().getFullYear();
            var nowMouth = new Date().getMonth()+1;
            var nowDay = new Date().getDate();
            if(nowMouth<10){
                nowMouth=0+''+nowMouth;
            }
            if(nowDay<10){
                nowDay=0+''+nowDay;
            }

            var uniqueTimeStr = vm.store.unique+""+nowYear+""+nowMouth+""+nowDay;
            var random = Math.floor(Math.random()*9000) + 1000;
            confirmNumber(uniqueTimeStr,random);

        }
        function confirmNumber(uniqueTimeStr,random) {
            var str = uniqueTimeStr+""+random;
            if(!vm.payBathAll.length){
                vm.payBatch.number = str;
                return
            }
            angular.forEach(vm.payBathAll,function (p,i) {
                if(p.number.indexOf(str)>=0){
                    random = Math.floor(Math.random()*9000) + 1000;
                    confirmNumber(uniqueTimeStr,random);
                    return
                }
                if(i==vm.payBathAll.length-1){
                    vm.payBatch.number = str;
                    return
                }
            })
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
        $scope.validate = function() {
            if (vm.payBatch.periodOfValidity.getTime() < new Date().getTime()) {
                MessageService.error("支付码有效期不能是当天或之前的日期");
                return false;
            }

            if (vm.payBatch.range == "Product" && ( vm.payBatch.productId == null || vm.payBatch.productId == "")) {
                MessageService.error("请选择产品");
                return false;
            }
            if ( vm.payBatch.periodOfValidity == null || vm.payBatch.periodOfValidity == "") {
                MessageService.error("请输入有效时间");
                return false;
            }

            if (vm.payBatch.number == null || vm.payBatch.number == "") {
                MessageService.error('请输入批次号');
                return false;
            }

            if (vm.payBatch.quantity == null || vm.payBatch.quantity == "") {
                MessageService.error('请输入将要生成支付码数量');
                return false;
            }

            if (vm.payBatch.quantity > vm.maxStockQuantity) {
                MessageService.error('支付码数量不能大于库存数量');
                return false;
            }

            if (/^([0-9]+)$/.test(vm.payBatch.startNumber.substring(1)) && /[a-zA-Z]+/.test(vm.payBatch.startNumber.substring(0,1))) {
            }else{
                MessageService.error("输入的起始卡号不符合规则");
                return false;
            }
            if (vm.payBatch.startNumber.length>15) {
                MessageService.error("起始卡号不能超过15个字符");
                return false;
            }
            return true;
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }



        function save () {
            if(!$scope.validate()){
                return;
            }
            PayBatch.getAllByCondition({
                items: [{key:"number", op:"=", value:vm.payBatch.number}]
            }, function(data){
                if (vm.payBatch.id !== null) {
                    if ((data.length ==1 && data[0].id != vm.payBatch.id) || data.length>1) {
                        MessageService.error("批次号重复，请重新设定批次号");
                    }else{
                        vm.isSaving = true;
                        PayBatch.update(vm.payBatch, onSaveSuccess, onSaveError);
                    }
                } else {
                    if (data.length >0) {
                        MessageService.error("批次号重复，请重新设定批次号");
                    }else{
                        vm.isSaving = true;
                        PayBatch.save(vm.payBatch, onSaveSuccess, onSaveError);
                    }

                }
            }, function(){
                MessageService.error("检查批次号失败，请稍后再试");
            });


        }

        function save2 () {
            vm.isSaving = true;
            PayBatch.update2(vm.payBatch, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:payBatchUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('添加成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            $uibModalInstance.dismiss('cancel');
            MessageService.error("系统请求超时，请稍后刷新系统，确认是否添加成功");
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
