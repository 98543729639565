(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('OrderAgreementController', OrderAgreementController);

    OrderAgreementController.$inject = ['$scope', '$q', '$stateParams', 'MessageService', 'Store', 'PlatformWechatSetting', 'Qinius', '$timeout', 'UidService', '$state'];

    function OrderAgreementController($scope, $q, $stateParams, MessageService, Store, PlatformWechatSetting, Qinius, $timeout, UidService, $state) {
        var vm = this;
        vm.save= save;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.platformSetting = PlatformWechatSetting.byPlatFrom();
        $q.all([vm.domains.$promise, vm.platformSetting.$promise, vm.tokens.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            $("#orderTerms").html(vm.platformSetting.orderTerms);
            messageEditor();
        })
        function messageEditor() {
            var E = window.wangEditor;
            var editor = new E('#messageEditor')
            editor.customConfig.onchange = function (html) {
                vm.platformSetting.orderTerms = html;
            };
            editor.customConfig.uploadImgServer = 'https://upload.qiniup.com/';
            editor.customConfig.uploadImgParams = {
                key: UidService.get(),
                token: vm.token,
                unique_names: false
            };
            editor.customConfig.uploadFileName = 'file';
            editor.customConfig.uploadImgHooks = {
                before: function (xhr, editor, files) {
                    editor.config.uploadImgParams.key= UidService.get();
                },
                customInsert: function (insertImg, result, editor) {
                    var url = $scope.domain + result.key;
                    insertImg(url);
                }
            };
            editor.customConfig.customAlert = function (info) {
                MessageService.error(info)
            };
            editor.create();
            editor.txt.html(vm.platformSetting.orderTerms);

        }

        function save(){
            console.log(vm.platformSetting)
            PlatformWechatSetting.update(vm.platformSetting, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $state.go('orderAgreement');
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
        }

    }
})();
