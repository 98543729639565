(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BaseBannerDialogController', BaseBannerDialogController);

    BaseBannerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'BaseBanner', '$q', 'Qinius', 'UidService', 'MessageService'];

    function BaseBannerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, BaseBanner, $q, Qinius, UidService, MessageService) {
        var vm = this;

        vm.baseBanner = entity;
        vm.clear = clear;
        vm.save = save;

        vm.tokens = Qinius.get(); 
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.tokens.$promise,vm.baseBanner.$promise]).then(function(){ 
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/'; 
        })

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function(){
            if(vm.baseBanner.seq == null || vm.baseBanner.seq==""){
                MessageService.error("请输入排序编号");
                return false;
            }
            if (vm.baseBanner.img == null || vm.baseBanner.img=="") {
                MessageService.error("请上传展示图片");
                return false;
            };
            return true;
        }


        //模板背景图更改
        window.updateImgFile = function ($event) {  
            $timeout(function () {
                var file = $event.target.files[0]; 
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false); 
                formData.append('key', "banner-"+ UidService.get());
                formData.append('token', vm.token); 
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.baseBanner.img = data.key; 
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        }; 

        $scope.removeFile = function(file) {
            vm.baseBanner.img = null; 
        } 

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.baseBanner.id !== null) {
                BaseBanner.update(vm.baseBanner, onSaveSuccess, onSaveError);
            } else {
                BaseBanner.save(vm.baseBanner, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:baseBannerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
