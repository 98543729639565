(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MessageTempController', MessageTempController);

    MessageTempController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'entity', 'MessageTemp', 'MessageService'];

    function MessageTempController($state, $scope, $rootScope, $stateParams, entity, MessageTemp, MessageService) {
        var vm = this;
        vm.tempList = entity.message;

        $scope.getLabel = function(target){
            if (!target) {
                return;
            }
            switch(target){
                case "auditPassTemp":
                    return "商户审核通过";
                case "auditRejectTemp":
                    return "商户未通过审核";
                case "storeRegisterTemp":
                    return "商户入驻提醒审核";
                case "storeValidityTemp":
                    return "商户授权到期提醒";
                case "verifyCodeTemp":
                    return "验证码";
                case "orderMsgTemp":
                    return "订单短信通知";
                case "storeAuthTemp":
                    return "商户授权提醒";
                case "balanceTemp":
                    return "余额不足";
                case "balanceWarnTemp":
                    return "余额预警";
                case "newProductTemp":
                    return "平台商品新增通知";
                case "storeStorageTemp":
                    return "商户存储到期提醒";
                case "customerStorageTemp":
                    return "客户存储到期提醒";
                case "customerStorageTemp":
                    return "客户存储到期提醒";
                case "cleanStorageTemp":
                    return "计划清理客户存储通知";
                case "cleanedStorageTemp":
                    return "客户存储清理通知";
                case "sendCouponTemp":
                    return "发放优惠券";
                default :
                return"";
            }
        }
    }
})();
