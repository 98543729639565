(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PasswordActivityDialogController', PasswordActivityDialogController);

    PasswordActivityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'PasswordActivity', 'Product', 'Brand'];

    function PasswordActivityDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, PasswordActivity, Product, Brand) {
        var vm = this;

        vm.passwordActivity = entity;
        vm.clear = clear;
        vm.save = save;
        vm.products = Product.getAllByCondition2({items: [{key: "storeId", op: "=", value: $stateParams.storeId}]});

        vm.brands = Brand.getAllByCondition({items: [{key: "enabled", op: "=", value: "true"},{key: "storeId", op: "=", value: $stateParams.storeId}]});

        $q.all([vm.passwordActivity.$promise, vm.brands.$promise, vm.products.$promise]);


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.passwordActivity.storeId = $stateParams.storeId
            console.log(vm.passwordActivity);
            vm.isSaving = true;
            if (vm.passwordActivity.id !== null) {
                PasswordActivity.update(vm.passwordActivity, onSaveSuccess, onSaveError);
            } else {
                PasswordActivity.createByAgent(vm.passwordActivity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:passwordActivityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
