(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

            .state('platform-byte-dances', {
                parent: 'entity',
                url: '/platform-byte-dances',
                data: {
                    authorities: ['ROLE_PLATFROM_SETTING'],
                    pageTitle: 'platformApp.platformByteDance.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/platform-byte-dances/platform-byte-dances.html',
                        controller: 'PlatformByteDancesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformByteDances');
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PlatformByteDance', function($stateParams, PlatformByteDance) {
                        return PlatformByteDance.getOne().$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'platform-byte-dances',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('platform-byte-dances.edit', {
                parent: 'platform-byte-dances',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_PLATFROM_SETTING']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/platform-byte-dances/platform-byte-dances-dialog.html',
                        controller: 'PlatformByteDancesDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformByteDances');
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['PlatformByteDance', '$stateParams', function(PlatformByteDance, $stateParams) {
                        return PlatformByteDance.getOne().$promise;
                    }]
                }
            });
    }

})();
