(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RechargeSettingDetailController', RechargeSettingDetailController);

    RechargeSettingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 
    'RechargeSetting', '$uibModalInstance'];

    function RechargeSettingDetailController($scope, $rootScope, $stateParams, previousState, entity, 
        RechargeSetting, $uibModalInstance) {
        var vm = this;

        vm.rechargeSetting = entity;
        vm.previousState = previousState.name;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('platformApp:rechargeSettingUpdate', function(event, result) {
            vm.rechargeSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
