(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TimerSettingDetailController', TimerSettingDetailController);

    TimerSettingDetailController.$inject = ['$state', '$scope', '$rootScope', '$stateParams',  'entity', 'MessageService', 'OrderSetting'];

    function TimerSettingDetailController($state, $scope, $rootScope, $stateParams, entity, MessageService, OrderSetting) {
        var vm = this;
        vm.timer = entity;
        vm.back = back;
        vm.save = save;

        var unsubscribe = $rootScope.$on('platformApp:platformWechatSettingUpdate', function(event, result) {
            vm.timer = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function save(){
            vm.timer.platformFlag = true;
            vm.timer.storeId = 0;
            OrderSetting.update(vm.timer,onSaveSuccess,onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            MessageService.success('操作成功');
            $state.go("timer-setting-detail", {reload: true});
        }


        function back() { 
            $state.go("timer-setting-detail", {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error('操作失败');
        }

          
    }
})();
