(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreDialogController', StoreDialogController);

    StoreDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', 'Qinius', 'OrderSetting', '$stateParams',
    'StoreApplies', '$uibModal','UidService', 'Product', 'StoreStorage'];

    function StoreDialogController($timeout, $scope, $q, $state, MessageService, entity, Store, Qinius, OrderSetting, $stateParams,
     StoreApplies, $uibModal,UidService, Product, StoreStorage) {
        var vm = this;

        vm.store = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        //代运营 保存
        vm.save2 = save2;
        vm.types = [
                    {key: "公众号", value: "WECHAT", selected: false},
                    {key: "微信小程序", value: "MINI", selected: false},
                    {key: "网页端", value: "WEB", selected: false},
                    {key: "支付宝", value: "ALI", selected: false},
                    {key: "字节跳动", value: "BYTEDANCE", selected: false},
                    {key: "京东", value: "JD", selected: false}
                    ]
        vm.navigateRules = [
            {key: "h5", value: "h5", selected: false},
            {key: "小程序", value: "mini", selected: false}
        ];
        vm.qualityOptions = [
            { name: '中', value: 'LOW', description: '品质普通' },
            { name: '高', value: 'MEDIUM', description: '品质清晰' },
            { name: '超清', value: 'HIGH', description: '品质高清' }
        ];



        vm.clear2 = clear2;
        if ($stateParams.storeId) {
            vm.storeOrderSetting = OrderSetting.getStoreOrderSetting({storeId: $stateParams.storeId});
        }

        $scope.automaticDeduction = function(flag){
            vm.store.automaticDeduction = flag;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.storeTypeSeleted = function (t) {
            t.selected = !t.selected;
        }

        $scope.typeSeleted = function (t) {
            if(t.key == "h5"){
                vm.navigateRules[0].selected = !t.selected;
                vm.navigateRules[1].selected = !vm.navigateRules[0].selected;
            }else {
                vm.navigateRules[1].selected = !t.selected;
                vm.navigateRules[0].selected = !vm.navigateRules[1].selected;
            }
        }


        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.store.$promise]).then(function () {
            StoreStorage.byStoreId({storeId:vm.store.id}, function(data){
                vm.storeStorage = data;
                vm.initSize = vm.storeStorage.initSize;//TB
                vm.buySize = vm.storeStorage.buySize;//TB
            }, function(data){
            })
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            if (vm.store.storeType2) {
                for (var i = vm.types.length - 1; i >= 0; i--) {
                    var t = vm.types[i];
                    if (vm.store.storeType2.indexOf(t.value) >= 0) {
                        t.selected = true;
                    }
                }
            }
            if (vm.store.navigateRule) {
                for (var i = vm.navigateRules.length - 1; i >= 0; i--) {
                    var t = vm.navigateRules[i];
                    if (vm.store.navigateRule.indexOf(t.value) >= 0) {
                        t.selected = true;
                    }
                }
            }
            Product.getAllByCondition2({
                items: [
                    {key: "prototype", op: "=", value: 'Store'},
                    {key: "cancelled", op: "=", value: 'false'},
                    {key: "storeId", op: "=", value: vm.store.id}],
                sort: ["id,desc"]
            }, function (res) {
                vm.products = res;
            }, function (error) {

            });
        });

        $scope.deletePic = function () {
            vm.store.logo = null;
        }

        $scope.deletePic2 = function () {
            vm.store.miniIndex = null;
        }

        $scope.parentBox = function () {
            if(!vm.store.bigStore){
                Store.getStoreForJoin({id: vm.store.id}, function (res) {
                    if(res.length > 0){
                        vm.store.bigStore = true;
                        MessageService.error("请先解绑所有的子商户！");
                    }
                },function (error) {
                    vm.store.bigStore = true;
                    MessageService.error("关闭失败，请稍后再试！");
                })
            }
        }

        //加入商户
        $scope.join = function () {
            Store.getStore({id : vm.store.id}, function (res) {
                if(!res.parentId){
                    StoreApplies.getAllByCondition({
                        items: [{key: "storeId", op: "=", value: vm.store.id},
                            {key: "applyType", op: "=", value: "JOIN"},
                            {key: "status", op: "=", value: "Created"}]
                    }, function (res) {
                        if(res.length > 0){
                            Store.getStore({id : res[0].parentStoreId}, function (item) {
                                MessageService.success("【" + item.name + "】" + "商户正在审核中，不可重复提交！");
                            })
                        }else {
                            $uibModal.open({
                                templateUrl: 'app/entities/store/modal/join.html',
                                controller: 'JoinController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                resolve: {
                                    entity: [function() {
                                        return vm.store
                                    }]
                                }
                            });
                        }
                    })
                }else {
                    MessageService.success("已加盟，不可重复加盟！");
                }
            })
        }
        //解绑
        $scope.unbind = function () {
            Store.getStore({id : vm.store.id}, function (res) {
                if(!res.parentId){
                    MessageService.success("已解绑商户，不可重复解绑！");
                }else {
                    StoreApplies.getAllByCondition({
                        items: [{key: "parentStoreId", op: "=", value: vm.store.parentId},
                            {key: "storeId", op: "=", value: vm.store.id},
                            {key: "applyType", op: "=", value: "SIGNOUT"},
                            {key: "status", op: "=", value: "Created"}]
                    }, function (res) {
                        if(res.length > 0){
                            MessageService.success("解绑申请已提交，不可重复提交！");
                        }else {
                            MessageService.confirm("确定解绑上级商户？",function(){
                                var _data = {
                                    storeName: vm.store.name,
                                    status: vm.store.status,
                                    applyType: 'SIGNOUT',
                                    parentStoreId: vm.store.parentId,
                                    storeId: vm.store.id
                                }
                                StoreApplies.createByAnge(_data, function (res) {
                                    MessageService.success("解绑申请已提交！");
                                }, function (error) {
                                })
                            },function(){},true)
                        }
                    })
                }
            })
        }
        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "store-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.store.logo = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload2(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "store-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.store.miniIndex = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        window.uploadPic2 = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload2(files[0]);
            }
        }

        $scope.activityQueue = function () {
            $uibModal.open({
                templateUrl: 'app/entities/store/modal/activity-queue.html',
                controller: 'ActivityQueueController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return vm.products || []
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    vm.products = angular.copy(backData);
                }
            });
        }

        function clear() {
            $state.go("store-detail", {id: vm.store.id}, {reload: true})
        }

        function clear2() {
            $state.go("store-detail2", {storeId: vm.store.id}, {reload: true});
        }

        $scope.valadata = function () {
            if (vm.store.name == null || vm.store.name == "") {
                MessageService.error("录入商户名称");
                return false;
            }
            // if (vm.store.contact == null || vm.store.contact == "") {
            //     MessageService.error("录入商户联系人");
            //     return false;
            // }
            if (vm.store.phone == null || vm.store.phone == "") {
                MessageService.error("录入商户联系电话");
                return false;
            }
            if (vm.store.unique == null || vm.store.unique == "") {
                MessageService.error("请输入商户标识");
                return false;
            }
            if (vm.store.numberRule == null || vm.store.numberRule == "") {
                MessageService.error("请输入商户单号前缀");
                return false;
            }
            if (vm.store.numberRule.length !=4) {
                MessageService.error("请输入4位长度单据前缀规则")
                return;
            }
            if (/^[0-9a-zA-Z]{5}$/.test(vm.store.unique)) {

            } else {
                MessageService.error("请输入由字母或数字组成的5位数编码");
                return false;
            }
            if (vm.store.miniIndexQuantity != null && vm.store.miniIndexQuantity < 0) {
                MessageService.error("小程序首页起始数量不能小于零")
                return;
            }


            return true;
        }

        function save() {
            if (!$scope.valadata()) {
                return false;
            }
            if(!vm.store.bigStore){
                vm.store.parentId = null;
                vm.store.parentName = null;
            }

            vm.store.storeType2 = "";
            for (var i = vm.types.length - 1; i >= 0; i--) {
                var t = vm.types[i];
                if (t.selected) {
                    vm.store.storeType2 += t.value + ";"
                }
            }
            vm.store.navigateRule = "";
            for (var i = vm.navigateRules.length - 1; i >= 0; i--) {
                var t = vm.navigateRules[i];
                if (t.selected) {
                    vm.store.navigateRule = t.value;
                }
            }
            var productIds = [];
            vm.products.forEach(function (res) {
                var _product = {
                    productId: res.id,
                    justifyQueue: res.justifyQueue
                }
                productIds.push(_product)
            })
            Product.setQueue({productIds:productIds});
            Store.getAllByCondition({
                items:[{key:"unique", op:"=", value:vm.store.unique}]}, function (data) {
                if (vm.store.id !== null) {
                    if ((data.length ==1 && data[0].id != vm.store.id) || data.length>1) {
                        MessageService.error("商户标识号重复，请重新设定标识号");
                    }else{
                        vm.isSaving = true;
                        Store.update(vm.store, onSaveSuccess, onSaveError);
                    }
                } else {
                    if (data.length >0) {
                        MessageService.error("商户标识号重复，请重新设定标识号");
                    }else{
                        vm.isSaving = true;
                        Store.save(vm.store, onSaveSuccess, onSaveError);
                    }

                }
            })
        }

        function save2() {

            vm.isSaving = true;
            if (vm.store.id !== null) {
                Store.update(vm.store, onSaveSuccess2, onSaveError);
            } else {
                Store.save(vm.store, onSaveSuccess2, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
            $state.go("store-detail", {id: vm.store.id}, {reload: true})
        }

        function onSaveSuccess2(result) {
            $scope.$emit('platformApp:storeUpdate', result);
            vm.isSaving = false;
            vm.storeOrderSetting.storeId = $stateParams.storeId;
            vm.storeOrderSetting.platformFlag = false;
            OrderSetting.updateStoreTimerSetting(vm.storeOrderSetting, function (data) {
                MessageService.success("保存成功！");
            }, function (data) {
                MessageService.error("延时信息保存失败！");
            })
            $state.go("store-detail2", {storeId: vm.store.id}, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }

        vm.datePickerOpenStatus.activationDate = false;
        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
