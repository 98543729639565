(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('Store', Store)
        .factory('StoreStorage', StoreStorage)
        .factory('StoreApplies', StoreApplies);

    Store.$inject = ['$resource', 'DateUtils'];
    StoreStorage.$inject = ['$resource', 'DateUtils'];
    StoreApplies.$inject = ['$resource', 'DateUtils'];

    function Store ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/stores/:path/:id';

        return $resource(resourceUrl, {}, {
            "findByHasAliSmallAll":{
                method: 'GET',
                params: {
                    path: 'findByHasAliSmallAll'
                },
                isArray: true
            },
            
            "activation2":{
                method: 'GET',
                params: {
                    path: 'activation2'
                }
            },
            "findByHasSmallAll":{
                method: 'GET',
                params: {
                    path: 'findByHasSmallAll'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getStore":{
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.activationDate = DateUtils.convertLocalDateFromServer(data.activationDate);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                },
                params: {
                    path: 'getStore'
                }
            },
            "updateCustom":{
                method: 'GET',
                params: {
                    path: 'updateCustom'
                }
            },
            "setStoreNumber":{
                method:"GET",
                params:{
                    path:"setStoreNumber"
                },
            },
            "sendAuthMsg":{
                method:"GET",
                params:{
                    path:"sendAuthMsg"
                },
            },
            "approve":{
                method: 'GET',
                params: {
                    path: 'approve'
                },
            },
            "bokeh":{
                method: 'GET',
                params: {
                    path: 'bokeh'
                },
            },
            "updateSkin":{
                method: 'GET',
                params: {
                    path: 'updateSkin'
                },
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.activationDate = DateUtils.convertLocalDateFromServer(data.activationDate);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.activationDate = DateUtils.convertLocalDateToServer(copy.activationDate);
                    copy.periodOfValidity = DateUtils.convertLocalDateToServer(copy.periodOfValidity);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.activationDate = DateUtils.convertLocalDateToServer(copy.activationDate);
                    copy.periodOfValidity = DateUtils.convertLocalDateToServer(copy.periodOfValidity);
                    return angular.toJson(copy);
                }
            },
            'getNum': {
                method: 'GET',
                params: {
                    path: "getNum"
                }
            },
            'getNum4Time': {
                method: 'GET',
                params: {
                    path: "getNum4Time"
                }
            },
            'updatePeriodOfValidity': {
                method: 'GET',
                params: {
                    path: "updatePeriodOfValidity"
                }
            },
            'closeStore': {
                method: 'GET',
                params: {
                    path: "closeStore"
                }
            },
            'getStoreForAgent': {
                method: 'GET',
                params: {
                    path: "getStoreForAgent"
                },
                isArray: true
            },
            'checkStoreNumber': {
                method: 'GET',
                params: {
                    path: "checkStoreNumber"
                }
            },
            'getStoreForJoin': {
                method: 'GET',
                params: {
                    path: "getStoreForJoin"
                },
                isArray: true
            },
            'signOut': {
                method: 'GET',
                params: {
                    path: "signOut"
                }
            },
            'batchSignOut': {
                method: 'GET',
                params:{
                    path:"batchSignOut"
                },
            },
            'updateFreeSpace': {
                method: 'GET',
                params:{
                    path:"updateFreeSpace"
                },
            },
        });
    }
    function StoreStorage ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-storages/:path/:id';

        return $resource(resourceUrl, {}, {
            "byStoreId":{
                method: 'GET',
                params: {
                    path: 'byStoreId'
                }
            },
            "updateInitSize":{
                method: 'GET',
                params: {
                    path: 'updateInitSize'
                }
            }
        });
    }

    function StoreApplies ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-applies/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition":{
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method: 'POST',
                params: {
                    path: 'getAllByCondition2'
                }
            },
            'save': {method: 'POST'},
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'approve': {
                method: 'GET',
                params: {
                    path: 'approve'
                }
            },
            'reject': {
                method: 'GET',
                params: {
                    path: 'reject'
                }
            },
            'createByAnge': {
                method: 'POST',
                params: {
                    path: 'createByAnge'
                }
            },
            'batchApprove': {
                method: 'GET',
                params: {
                    path: 'batchApprove'
                }
            },
            'batchReject': {
                method: 'GET',
                params: {
                    path: 'batchReject'
                }
            },
        });
    }
})();
