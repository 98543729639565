(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('VisitorTickets', VisitorTickets);

    VisitorTickets.$inject = ['$resource', 'DateUtils'];

    function VisitorTickets ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/visitor-tickets/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'treat': {//处理
                method: 'GET',
                params: {
                    path: 'treat'
                },
                isArray: false
            },
            "export":{//导出
                method:"POST",
                params:{
                    path:"export"
                },
            },
        });
    }
})();
