(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('DataHelperController', DataHelperController);

    DataHelperController.$inject = ['$scope', '$state', '$q', 'MessageService'];

    function DataHelperController ($scope, $state, $q, MessageService) {
        var vm = this;

        vm.imgs=['case-1.png','case-2.png','case-3.png','case-4.png']
    }
})();
