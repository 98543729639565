(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('password-marketing', {
            parent: 'entity',
            url: '/password-marketing?type',
            data: {
                authorities: ['ROLE_PLATFROM_COMMAND_MARKETING'],
                pageTitle: 'platformApp.passwordMarketing.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/password-marketing/password-marketings.html',
                    controller: 'PasswordMarketingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                type: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('passwordMarketing');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['PasswordMarketing', '$stateParams', function(PasswordMarketing, $stateParams) {
                    return PasswordMarketing.getOne({type:$stateParams.type}).$promise;
                }]
            }
        })

        .state('password-marketing.edit', {
            parent: 'password-marketing',
            url: '/edit',
            data: {
                authorities: ['ROLE_PLATFROM_COMMAND_MARKETING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/password-marketing/password-marketing-dialog.html',
                    controller: 'PasswordMarketingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PasswordMarketing', function(PasswordMarketing) {
                            return PasswordMarketing.getOne({type:$stateParams.type}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('password-marketing', null, { reload: 'password-marketing' });
                }, function() {
                    $state.go('^');
                });
            }]

        })
         ;
    }

})();
