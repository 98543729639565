(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

            .state('platform-wechat-setting', {
                parent: 'entity',
                url: '/platform-wechat-setting',
                data: {
                    authorities: ['ROLE_PLATFROM_SETTING'],
                    pageTitle: 'platformApp.platformWechatSetting.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/platform-wechat-setting/platform-wechat-setting-detail.html',
                        controller: 'PlatformWechatSettingDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PlatformWechatSetting', function($stateParams, PlatformWechatSetting) {
                        return PlatformWechatSetting.byPlatFrom().$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'platform-wechat-setting',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('timer-setting-detail', {
                parent: 'entity',
                url: '/timer-setting-detail',
                data: {
                    authorities: ['ROLE_PLATFROM_CHECKING_SETTING'],
                    pageTitle: 'global.menu.entities.timerSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/platform-wechat-setting/timer-detail.html',
                        controller: 'TimerSettingDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrderSetting', function($stateParams, OrderSetting) {
                        return OrderSetting.getOrderSetting({platformFlag:"1"}).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'platform-wechat-setting',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('timer-setting-detail.edit', {
                parent: 'entity',
                url: '/timer-setting-detail-edit',
                data: {
                    authorities: ['ROLE_PLATFROM_CHECKING_SETTING']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/platform-wechat-setting/timer-detail-dialog.html',
                        controller: 'TimerSettingDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['OrderSetting', '$stateParams', function(OrderSetting, $stateParams) {
                        return OrderSetting.getOrderSetting({platformFlag:"1"}).$promise;
                    }]
                }
            })

            .state('platform-small-app-setting', {
                parent: 'entity',
                url: '/platform-small-app-setting',
                data: {
                    authorities: ['ROLE_PLATFROM_MINI_SETTING'],
                    pageTitle: 'platformApp.platformWechatSetting.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/platform-wechat-setting/platform-small-app-setting-detail.html',
                        controller: 'PlatformSmallAppSettingDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'WechatAuth2', function($stateParams, WechatAuth2) {
                        return WechatAuth2.getTemplateList().$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'platform-wechat-setting',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('platform-small-app-setting2', {
                parent: 'entity',
                url: '/platform-small-app-setting2',
                data: {
                    authorities: ['ROLE_PLATFROM_MINI_SETTING'],
                    pageTitle: 'platformApp.platformWechatSetting.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/platform-wechat-setting/platform-small-app-setting-detail2.html',
                        controller: 'PlatformSmallAppSettingDetail2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'WechatAuth2', function($stateParams, WechatAuth2) {
                        return {};
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'platform-wechat-setting',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('platform-wechat-setting.edit', {
                parent: 'platform-wechat-setting',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_PLATFROM_SETTING']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/platform-wechat-setting/platform-wechat-setting-dialog.html',
                        controller: 'PlatformWechatSettingDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('platformWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['PlatformWechatSetting', '$stateParams', function(PlatformWechatSetting, $stateParams) {
                        return PlatformWechatSetting.byPlatFrom().$promise;
                    }]
                }
            })

            .state('platform-abutment-edit', {
                parent: 'platform-wechat-setting',
                url: '/{id}/abutment',
                data: {
                    authorities: ['ROLE_PLATFROM_SETTING']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/platform-wechat-setting/platform-abutment-edit.html',
                        controller: 'PlatformAbutmentEditController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PlatformAbutment', function(PlatformAbutment) {
                                return PlatformAbutment.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('platform-wechat-setting', null, {
                            reload: 'platform-wechat-setting'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })

            .state('platform-abutment-new', {
                parent: 'platform-wechat-setting',
                url: '/abutment',
                data: {
                    authorities: ['ROLE_PLATFROM_SETTING']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/platform-wechat-setting/platform-abutment-edit.html',
                        controller: 'PlatformAbutmentEditController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PlatformAbutment', function(PlatformAbutment) {
                                return null;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('platform-wechat-setting', null, {
                            reload: 'platform-wechat-setting'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
