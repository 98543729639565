(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PriceListDialogController', PriceListDialogController);

    PriceListDialogController.$inject = ['$timeout', '$scope', '$stateParams',  '$q', 'entity', 'PriceList', 'Store', 'Product', '$uibModal',
    'UidService', 'MessageService', '$state' ];

    function PriceListDialogController ($timeout, $scope, $stateParams,   $q, entity, PriceList, Store, Product, $uibModal,
     UidService, MessageService, $state ) {
        var vm = this;

        vm.priceList = entity;
        vm.clear = clear;
        vm.save = save;
        vm.storeId = $stateParams.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.valueLines = [];
        vm.product = null;
        vm.products = Product.getAllByCondition2({items:[{key: "prototype", op: "in", value: "Store;StoreSelf"},{key:"storeId", op:"=", value:$stateParams.storeId}]});
        $q.all([vm.priceList.$promise, vm.products.$promise]).then(function() {
            var str = '';
            angular.forEach(vm.priceList.storePriceLines,function (line) {
                if (!line.cancelled) {
                    line.skuArr = [];
                    if(line.sku){
                        line.skuArr = line.sku.split(',');
                    }
                    str+=line.skuNumber+',';
                }

            })

             if (vm.priceList.productId) {
                Product.get({id:vm.priceList.productId},function(data){
                    vm.product = data;
                    for (var i = data.itemValues.length - 1; i >= 0; i--) {
                        var itemValue = data.itemValues[i];
                        if (itemValue.cancelled) {
                            continue;
                        }
                        if (itemValue.itemValueLines!=null && itemValue.itemValueLines.length>0) {
                            for (var j = itemValue.itemValueLines.length - 1; j >= 0; j--) {
                                var valueLine =  itemValue.itemValueLines[j];
                                if (valueLine.cancelled) {
                                    continue;
                                }
                                for(var z= 0;z<vm.priceList.storePriceLines.length;z++){
                                    var l = vm.priceList.storePriceLines[z];
                                    if(l.itemValueLineId==valueLine.id){
                                        l.lowestPrice = valueLine.lowestPrice;
                                    }
                                }
                                var skuArrs = [];
                                if(valueLine.sku){
                                    skuArrs = valueLine.sku.split(',');
                                }
                                var storePriceLine = {
                                    uuid:UidService.get(),
                                    priceObjectValues:[],
                                    added: true,
                                    skuArr:skuArrs,
                                    sku:valueLine.sku,
                                    lowestPrice:valueLine.lowestPrice,
                                    itemValueLineId:valueLine.id,
                                    skuNumber:valueLine.skuNumber,
                                };
                                if(str.indexOf(valueLine.skuNumber)==-1){
                                    vm.valueLines.push(storePriceLine)
                                }
                            }
                        }
                    }
                },function(data){

                })
             }
        });

        $scope.updateCondition = function (line) {
            createModal('app/entities/price-list/price-list-dialog-condition.html', 'PriceListDialogConditionController', line).result.then(
                function (data) {
                    for (var i = vm.priceList.storePriceLines.length - 1; i >= 0; i--) {
                        var priceLine = vm.priceList.storePriceLines[i];
                        if (data.uuid == priceLine.uuid) {
                            priceLine = data;
                            break;
                        }
                    }
                });
        }


        var createModal = function (url, controller, line) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:line,
                }
            });
        };

        $scope.productChange = function(){
            if (!vm.priceList.id) {
                vm.priceList.storePriceLines = [];
                Product.get({id:vm.priceList.productId},function(data){
                    vm.product = data;
                    for (var i = data.itemValues.length - 1; i >= 0; i--) {
                        var itemValue = data.itemValues[i];
                        if (itemValue.cancelled) {
                            continue;
                        }
                        if (itemValue.itemValueLines!=null && itemValue.itemValueLines.length>0) {
                            for (var j = itemValue.itemValueLines.length - 1; j >= 0; j--) {
                                var valueLine =  itemValue.itemValueLines[j];
                                if (valueLine.cancelled) {
                                    continue;
                                }
                                var skuArrs = [];
                                if(valueLine.sku){
                                    skuArrs = valueLine.sku.split(',');
                                }
                                var storePriceLine = {
                                    uuid:UidService.get(),
                                    priceObjectValues:[],
                                    added: true,
                                    skuArr:skuArrs,
                                    sku:valueLine.sku,
                                    lowestPrice:valueLine.lowestPrice,
                                    itemValueLineId:valueLine.id,
                                    skuNumber:valueLine.skuNumber,
                                };
                                vm.priceList.storePriceLines.push(storePriceLine);
                            }
                        }
                    }
                },function(data){

                })
            }

        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.addPriceLine = function(){
            if (!vm.priceList.productId) {
                MessageService.error("请先选择商品");
                return;
            }
            if(!vm.valueLines.length){
                MessageService.error("没有可添加的属性！");
                return
            }

            if(!vm.valueLines.length){
                MessageService.error("没有可添加的属性！");
                return
            }
            if(vm.valueLines[0].deleted){
                angular.forEach(vm.priceList.storePriceLines,function (line) {
                    if(line.id==vm.valueLines[0].id){
                        line.deleted = false;
                    }
                })
            }else{
                vm.priceList.storePriceLines.push(vm.valueLines[0]);
            }

            vm.valueLines.shift();
        }

        $scope.deleteLine = function(line){
            if (line.added) {
                var ind = vm.priceList.storePriceLines.indexOf(line);
                if (ind >= 0) {
                    vm.priceList.storePriceLines.splice(ind, 1);
                }
            }else{
                line.deleted = true;
            }
            vm.valueLines.push(line);
        }

        $scope.valedate = function(){
            if (!vm.priceList.name) {
                MessageService.error("请输入名称");
                return false;
            }
            if (!vm.priceList.seq) {
                MessageService.error("请输入优先级");
                return false;
            }
            if (!vm.priceList.productId) {
                MessageService.error("请输入优先级");
                return false;
            }
            if (vm.priceList.storePriceLines == null || vm.priceList.storePriceLines.length == 0) {
                MessageService.error("请配置有效价目规则");
                return false;
            }
            for (var i = vm.priceList.storePriceLines.length - 1; i >= 0; i--) {
                var line = vm.priceList.storePriceLines[i];
                if (line.deleted || line.cancelled) {
                    continue;
                }
                if (line.priceRule == "Standard" && !line.price) {
                    MessageService.error("请为标准价目行输入价格");
                    return false;
                }

                if (!line.standard == "Standard") {
                    MessageService.error("请选择基于");
                    return false;
                }

                if ((line.priceRule == "Ladder" || line.priceRule == "Grads") && (line.priceConditions == null || line.priceConditions.length == 0)) {
                    MessageService.error("请为阶梯价目行或者梯度价目行设置规则");
                    return false;
                }
                if (line.lowestPrice!=null&&(line.priceConditions == null || line.priceConditions.length == 0)&&line.price < line.lowestPrice) {
                    MessageService.error("价格不能小于最低成交价")
                    return false;
                }
            }
            return true;
        }

        function clear () {
            $state.go("price-list", {storeId:vm.storeId});
        }

        function save () {
            if (!$scope.valedate()) {
                return;
            }
            vm.isSaving = true;
            if (vm.priceList.id !== null) {
                PriceList.update(vm.priceList, onSaveSuccess, onSaveError);
            } else {
                vm.priceList.storeId = vm.storeId;
                PriceList.createByAgent(vm.priceList, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:priceListUpdate', result);
            $state.go("price-list", {storeId:vm.storeId})
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
