(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('Article', Article)
        .factory('ProductXmls', ProductXmls)
        .factory('ProductXmls1', ProductXmls1);

    Article.$inject = ['$resource'];
    ProductXmls.$inject = ['$resource'];
    ProductXmls1.$inject = ['$resource'];

    function Article ($resource) {
        var resourceUrl =  'service/' + 'api/articles/:path/:id';
        return $resource(resourceUrl, {}, {
            "invalidByProductXmlId":{
                method:"GET",
                params:{
                    path:"invalidByProductXmlId"
                }
            }
        });

    }

    function ProductXmls ($resource) {
        var resourceUrl =  'manager/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
            "showPic":{
                method:"POST",
                params:{
                    path:"showPic"
                },
            },
            "approve":{
                method:"GET",
                params:{
                    path:"approve"
                }
            },
             "batchApprove":{
                method:"GET",
                params:{
                    path:"batchApprove"
                }
            },
            "batchEdit":{
                method:"GET",
                params:{
                    path:"batchEdit"
                }
            },
            "batchDelete":{
                method:"GET",
                params:{
                    path:"batchDelete"
                }
            },
            "themeClassify":{
                method:"GET",
                params:{
                    path:"saveTheme"
                }
            },
            'update': { method:'PUT' },
            "updateProductXml":{
                method:"GET",
                params:{
                    path:"updateSourceModel",
                },

            },
            "findOne":{
                method:"GET",
                params:{
                    path:"findOne"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
    function ProductXmls1 ($resource) {
        var resourceUrl =  'fileserves/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            "upgradeProductXml":{
                method:"GET",
                params:{
                    path:"upgradeProductXml"
                }
            }
        });

    }
})();
