(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformSmallAppSettingDetailController', PlatformSmallAppSettingDetailController);

    PlatformSmallAppSettingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState',
    'entity', 'PlatformWechatSetting', 'WechatAuth2', 'MessageService', "Store", 'Upload', 'ListService'];

    function PlatformSmallAppSettingDetailController($scope, $rootScope, $stateParams, previousState,
        entity, PlatformWechatSetting, WechatAuth2, MessageService, Store, Upload, ListService) {
        var vm = this;
 
        $scope.storeShow = false;
        $scope.versionShow = false;

        vm.templates = entity.template_list;
        vm.stores = Store.findByHasSmallAll();
        $scope.batchArr = [];
        // $scope.selectStore = function(store){
        //     if (store == null) {
        //         return;
        //     }
        //     if (store.id == vm.storeId) {
        //         vm.storeId = null;
        //     }else{
        //         vm.storeId = store.id;
        //     }
        // }

        window.updateFile = function ($event) {
            vm.file = $event.target.files[0];
            $(".choose-file").val(vm.file.name);
        };

        $scope.selectVersion = function(temp){
            if (temp == null) {
                return;
            }
            if (temp.template_id == vm.template_id) {
                vm.template_id = null;
                vm.user_version= null;
                vm.user_desc = null;
            }else{
                vm.template_id = temp.template_id;
                vm.user_version= temp.user_version;
                vm.user_desc = temp.user_desc;
            }
        }


        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.stores,function (store,i) {
                if($scope.selectAll){
                    store.select = true;
                    $scope.batchArr.push(store.id);
                    $scope.selectOne = true;
                }else{
                    $scope.batchArr = [];
                    store.select = false;
                    $scope.selectOne = false;
                }
            })
        }


        $scope.chooseOne = function (store) {
            store.select = !store.select;
            // 选中
            if(store.select){
                $scope.batchArr.push(store.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(store.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.stores,function (store,i) {
                if(!store.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.stores.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        } 
        

        $scope.getStatus = function(status){
            switch(status){
                case "Upload":
                    return "待提交";
                case "Commit":
                    return "已提交";
                case "Release":
                    return "已发布";
                case "Fail":
                    return "审核失败"
                default:
                    return "";
            }
        }

        $scope.upload = function() {
            if ( $scope.batchArr.length == 0) {
                MessageService.error("请先选择想要上传代码的商户");
                return;
            }
            if (vm.template_id == null) {
                MessageService.error("请选择想要上传代码小程序的商铺或者版本");
                return;
            }
            MessageService.confirm("确认要上传该版本代码？", function() {
                angular.forEach(vm.stores, function(store){
                    if (!store.select) {
                        store.message="";
                    }else{
                        Upload.upload({
                            type: 'POST',
                            url: 'manager/wx/small-app/commit', //上传路径
                            data: {
                                storeId: store.id,
                                template_id: vm.template_id,
                                user_version: vm.user_version,
                                user_desc: vm.user_desc,
                                extFile: vm.file,
                                type: vm.type
                            }
                        }).then(function(res){
                            if (res.errcode == "0") {
                                store.message = "操作成功， 小程序等待提交审核";
                            } else {
                                store.message = "操作失败，错误编号：" + data.errcode;
                            }
                        }, function(){
                            store.message = "操作失败";
                        });
                    }
                })
            },function(){
            });
        }

        $scope.submit = function() {
            if ( $scope.batchArr.length == 0) {
                MessageService.error("请先选择想要提交的商户");
                return;
            }
            if (vm.template_id == null) {
                MessageService.error("请选择想要提交小程序的商铺或者版本");
                return;
            }
            MessageService.confirm("确认要提交该版本代码？", function() {
                angular.forEach(vm.stores, function(store){
                    if (!store.select) {
                        store.message="";
                    }else{
                        WechatAuth2.submit_audit({storeId: store.id}, function(data) {
                            if (data.errcode == "0") {
                                store.message="提交成功，小程序进入审核阶段";
                            }else if (data.errcode == "-1") {
                                store.message="提交失败，系统错误"+":"+data.errmsg;
                            } else  {
                                store.message=data.errcode+":"+data.errmsg;
                            }
                        }, function() { })
                    }
                })
            },function(){
            });
        }

        $scope.recall = function() {
            if ( $scope.batchArr.length == 0) {
                MessageService.error("请先选择想要撤回的商户");
                return;
            }
            MessageService.confirm("确认要撤回正在审核的掉程序么？", function() {
                angular.forEach(vm.stores, function(store){
                    if (!store.select) {
                        store.message="";
                    }else{
                        WechatAuth2.undocodeaudit({storeId: store.id}, function(data) {
                            if (data.errcode == "0") {
                                store.message="撤回成功";
                            }else if (data.errcode == "-1") {
                                store.message="撤回失败，系统错误";
                            }else if (data.errcode == "87013") {
                                store.message="撤回失败，撤回次数达到上限（每天一次，每个月 10 次）";
                            }else  {
                                store.message=data.errcode+":"+data.errmsg;
                            }
                        }, function() { })
                    }
                })
            },function(){
            });
        }

        $scope.search = function(){
            if ( $scope.batchArr.length == 0) {
                MessageService.error("请先选择想要查询的商户");
                return;
            }
            angular.forEach(vm.stores, function(store){
                if (!store.select) {
                    store.message="";
                }else{
                    WechatAuth2.get_latest_auditstatus({storeId:store.id},function(data){
                        if(data.errcode == "0"){
                            if (data.status == "0") {
                                store.message="审核成功";
                            }
                            if (data.status == "1") {
                                store.message="审核失败:"+ data.reason;
                            }
                            if (data.status == "2") {
                                store.message="审核中";
                            }
                        }else{
                            store.message="审核异常，"+ data.errcode+":"+data.errmsg;
                        }
                    },function(){}); 
                }
            });
        }

        $scope.release = function(){
            if ( $scope.batchArr.length == 0) {
                MessageService.error("请选择想要发布小程序的商铺");
                return;
            }
            MessageService.confirm("确认要发布已选择的小程序么？", function() {
                angular.forEach(vm.stores, function(store){
                    if (!store.select) {
                        store.message="";
                    }else{
                        WechatAuth2.release({storeId: store.id}, function(data) {
                            if(data.errcode == "0" && data.status == "0"){
                                store.message="发布成功";
                            }
                            if (data.status == "-1") {
                                store.message="系统繁忙";
                            }
                            if (data.status == "85019") {
                                store.message="没有审核版本";
                            }
                        }, function() {});
                    }
                })
            },function(){});
        }


        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('platformApp:platformWechatSettingUpdate', function(event, result) {
            vm.platformWechatSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.appDomains = null;
        $scope.getSmallAppDomain = function () {
             if( $scope.batchArr.length != 1){
                MessageService.error("请选择一个想要查询的商铺");
                return
            }
            vm.appDomains = WechatAuth2.getDomain({storeId:$scope.batchArr[0]});
        }

        $scope.setPrivacySetting = function () {
            if( $scope.batchArr.length == 0){
                MessageService.error("请选择想要设置的商铺");
                return
            }

            if (vm.contactPhone == null) {
                MessageService.error("信息收集方的手机号");
                return
            }
            if (vm.privacies == null || vm.privacies.length == 0) {
                MessageService.error("请先设置隐私指引");

            }
            MessageService.confirm("确认要设置已选择的小程序？", function() {
                vm.privacies.push({privacy_key:"phone", privacy_text:vm.contactPhone});
                angular.forEach(vm.stores, function(store){
                    if (!store.select) {
                        store.message="";
                    }else{
                        vm.privacies.push({privacy_key:"storeId", privacy_text:store.id});
                        WechatAuth2.setPrivacySetting(vm.privacies, function(res){
                            if (res.errcode == 0) {
                                store.message = "设置成功";
                            }else{
                                store.message = "设置失败："+res.errmsg;
                            }
                        });
                    }
                    
                })
            },function(){});
        }

        $scope.getPrivacySetting = function () {
            if( $scope.batchArr.length != 1){
                MessageService.error("请选择一个想要查询的商铺");
                return
            }
            WechatAuth2.getPrivacySetting({storeId:$scope.batchArr[0]}, function(data){
                if (data == null|| data.privacy_desc == null || data.privacy_desc.privacy_desc_list == null) {
                    MessageService.error("获取隐私配置失败");
                    return;
                }
                vm.privacies = data.privacy_desc.privacy_desc_list;
                if (data.setting_list!=null && data.setting_list.length>0) {
                    for (var i = data.setting_list.length - 1; i >= 0; i--) {
                        var setting= data.setting_list[i];
                        var result =  ListService.inList(setting, vm.privacies, ["privacy_key"]);
                        if (result!= null) {
                            result.privacy_text = setting.privacy_text
                        }
                    }
                }
            }, function(){
                MessageService.error("获取隐私配置失败");
            });
        }
    }
})();
