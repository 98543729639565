(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BannerDetailController', BannerDetailController);

    BannerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Banner', '$uibModalInstance', 'Qinius', '$q'];

    function BannerDetailController($scope, $rootScope, $stateParams, entity, Banner, $uibModalInstance, Qinius, $q) {
        var vm = this;

        vm.banner = entity;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.domains = Qinius.getDomain(); 
        $q.all([vm.domains.$promise]).then(function(){ 
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';   
        })  


        var unsubscribe = $rootScope.$on('platformApp:bannerUpdate', function(event, result) {
            vm.banner = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
