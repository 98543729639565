
(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MultimediaGroupAddController', MultimediaGroupAddController);

    MultimediaGroupAddController.$inject = ['$q', '$state', 'MessageService', 'entity', '$uibModalInstance', 'MultimediaGroup'];

    function MultimediaGroupAddController($q, $state, MessageService, entity, $uibModalInstance, MultimediaGroup) {
        var vm = this;

        vm.multimediaGroup = entity;
        vm.isSaving = false;

        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            MultimediaGroup.save(vm.multimediaGroup, function (result) {
                $uibModalInstance.close(result);
                MessageService.success("操作完成");
            }, function (){
                vm.isSaving = false;
                MessageService.error("操作失败");
            })
        }
    }
})();
