(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CalendarSettingController', CalendarSettingController);

    CalendarSettingController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'MessageService', 'PlatformWechatSetting',
     '$timeout', '$state', "SystemSetting"];

    function CalendarSettingController($scope, $q, $rootScope, $stateParams, MessageService, PlatformWechatSetting, 
     $timeout, $state, SystemSetting) {
        var vm = this;
        vm.save= save;
        vm.calendarSettingList = SystemSetting.getAll();
        vm.calendarServiceHost = {key:"calendarServiceHost"};
        vm.calendarAppSecret = {key:"calendarAppSecret"};
        vm.calendarAppId = {key:"calendarAppId"};
        vm.calendarStartOfWeek = {key:"calendarStartOfWeek"};
        $q.all([  vm.calendarSettingList.$promise ]).then(function(){
            for (var i = vm.calendarSettingList.length - 1; i >= 0; i--) {
                var setting = vm.calendarSettingList[i];
                if (setting.key == 'calendarAppId') {
                    vm.calendarAppId = setting;
                    continue;
                }
                if (setting.key == 'calendarAppSecret') {
                    vm.calendarAppSecret = setting;
                    continue;
                }
                if (setting.key == 'calendarServiceHost') {
                    vm.calendarServiceHost = setting; 
                    continue;
                }
                if (setting.key == 'calendarStartOfWeek') {
                    vm.calendarStartOfWeek = setting; 
                    continue;
                }
            }
        })

        
        function save(){
            if (!vm.calendarAppId.value) {
                MessageService.error("请录入appId");
                return ;
            }

            if (!vm.calendarAppSecret.value) {
                MessageService.error("请录入secret");
                return ;
            }
            if (!vm.calendarServiceHost.value) {
                MessageService.error("请录入域名");
                return ;
            }
            if (!vm.calendarStartOfWeek.value) {
                MessageService.error("请选择周开始日");
                return ;
            }
            var list = [];
            list.push(vm.calendarAppId);
            list.push(vm.calendarAppSecret);
            list.push(vm.calendarServiceHost);
            list.push(vm.calendarStartOfWeek);
            SystemSetting.update(list, onSaveSuccess, onSaveError);
        }
         
        function onSaveSuccess (result) {
            MessageService.success("保存成功");
        }
        
        function onSaveError () {
            MessageService.error("保存失败");
        }

    }
})();
