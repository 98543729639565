(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RechargeLevelPageController', RechargeLevelPageController);

    RechargeLevelPageController.$inject = ['$state', '$scope', 'MessageService', 'entity', 'RechargeLevel', '$q'];

    function RechargeLevelPageController($state, $scope, MessageService, entity, RechargeLevel, $q) {

        var vm = this;

        vm.clear = clear;
        vm.rechargeLevel = entity;
        $q.all([vm.rechargeLevel.$promise]).then(function(){
            if (!!vm.rechargeLevel.level) {
                vm.rechargeLevel.level = Number(vm.rechargeLevel.level);
            }
        })
        vm.save = save;

        function clear() {
            $state.go("recharge-level");
        }

        $scope.validata = function(){
            if (vm.rechargeLevel.name == null || vm.rechargeLevel.name == "") {
                MessageService.error("请输入名称");
                return false;
            }

            if (vm.rechargeLevel.level == null || vm.rechargeLevel.level == "") {
                MessageService.error("请输入商户等级");
                return false;
            }

            if (vm.rechargeLevel.number == null || vm.rechargeLevel.number == "") {
                MessageService.error("请输入累计充值金额");
                return false;
            }

            if (vm.rechargeLevel.discount == null || vm.rechargeLevel.discount == "") {
                MessageService.error("请输入有效的折扣");
                return false;
            } 

            return true;
        }

        function save() {
            if (!$scope.validata()) {
                return;
            }
            console.log(vm.rechargeLevel);
            RechargeLevel.save(vm.rechargeLevel, function(data){
                MessageService.success("操作成功");
                $state.go("recharge-level");
            })
        }
        
    }
})();
