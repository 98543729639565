(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoragePriceDialogController', StoragePriceDialogController);

    StoragePriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'StoragePrice', 'Store', '$state', 'MessageService', '$uibModal'
    ];

    function StoragePriceDialogController($timeout, $scope, $stateParams, $q, entity, StoragePrice, Store, $state, MessageService, $uibModal) {
        var vm = this;
        vm.storagePrice = entity;
        vm.stores = [];
        vm.storesAll = { name: '全部', selected: false };
        vm.isStores = true;
        vm.save = save;
        vm.clear = clear;
        if (vm.storagePrice.storagePriceLines) {
            for (var i = vm.storagePrice.storagePriceLines.length - 1; i >= 0; i--) {
                var price = vm.storagePrice.storagePriceLines[i];
                price.space = price.space / 1000 /1000 / 1000 ;
            }
        }


        Store.getAllByCondition({
            items: [{ key: "status", op: "=", value: "Approved" }]
        }, function(res) {
            for (var i = 0; i < res.length; i++) {
                if (!res[i].cancelled && res[i].authorityType) {
                    if (res[i].authorityType.indexOf("PRODUCT") != -1) {
                        vm.stores.push(res[i])
                    }
                }
            }
            if (entity.id) {
                if (vm.storagePrice.base) {
                    vm.storesAll.selected = true;
                    vm.isStores = false;
                } else {
                    for (var i = 0; i < vm.storagePrice.stores.length; i++) {
                        for (var j = 0; j < vm.stores.length; j++) {
                            if (vm.storagePrice.stores[i].id === vm.stores[j].id) {
                                vm.stores[j].selected = true;
                            }
                        }
                    }
                }
            }
        })


        //选择使用商家
        $scope.storesCheckbox = function(data) {
            vm.storagePrice.stores = [];
            data.selected = !data.selected ? true : false;
            for (var i = 0; i < vm.stores.length; i++) {
                if (vm.stores[i].selected) {
                    vm.storagePrice.stores.push(vm.stores[i])
                }
            }
            if (vm.storagePrice.stores.length > 0) {
                vm.storagePrice.base = false;
            }
        };
        $scope.storesAllCheckbox = function() {
            vm.storesAll.selected = !vm.storesAll.selected ? true : false;
            if (!vm.storesAll.selected) {
                vm.isStores = true;
                vm.storagePrice.base = false;
            } else {
                for (var j = 0; j < vm.stores.length; j++) {
                    vm.stores[j].selected = false;
                }
                vm.isStores = false;
                vm.storagePrice.stores = [];
                vm.storagePrice.base = true;
            }
        };

        //添加价格
        $scope.addPriceLines = function() {
            var _obj = {
                space: '',
                originalPrice:'',
                price: '',
                periodOfValidity: ''
            }
            vm.storagePrice.storagePriceLines.push(_obj)
        }

        //新增定价
        $scope.addPricing = function(data, index) {
            $uibModal.open({
                templateUrl: 'app/entities/flow-price/modal/pricing.html',
                controller: 'Pricing1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            data: data
                        };
                    }]
                }
            }).result.then(function(item) {
                if (item) {
                    vm.storagePrice.storagePriceLines[index] = item;
                }
            });
        }

        function save() {
            if (!vm.storagePrice.name) {
                MessageService.error("名称不能为空！");
                return
            }
            if (!vm.storagePrice.base) {
                if (vm.storagePrice.stores.length == 0) {
                    MessageService.error("适用商家不能为空！");
                    return
                }
            }
            if (!vm.storagePrice.seq) {
                MessageService.error("优先级不能为空！");
                return
            }
            if (vm.storagePrice.storagePriceLines) {
                for (var i = vm.storagePrice.storagePriceLines.length - 1; i >= 0; i--) {
                    var price = vm.storagePrice.storagePriceLines[i];
                    if (!price.cancelled) {
                        if (!price.price) {
                            MessageService.error("请输入价格");
                            return false
                        }
                    }
                    price.space = price.space * 1000 * 1000 * 1000;
                }
            }
            if (!vm.storagePrice.id) {
                StoragePrice.save(vm.storagePrice, function(res) {
                    $state.go("storage-price");
                    MessageService.success("保存成功");
                })
            } else {
                StoragePrice.update(vm.storagePrice, function(res) {
                    $state.go("storage-price");
                    MessageService.success("编辑成功");
                })
            }
        }

        function clear() {
            $state.go("storage-price");
        }

        $scope.deletePriceLines = function (data, index) {
            if(!data.id){
                vm.storagePrice.storagePriceLines.splice(index, 1)
            }else {
                data.cancelled = true;
            }
        }
    }
})();
