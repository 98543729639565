(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('RegionTemp', RegionTemp);

    RegionTemp.$inject = ['$resource'];

    function RegionTemp ($resource) {
        var resourceUrl =  'manager/' + 'api/region-temps/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "byCondition":{
                method:"POST",
                isArray: true,
                params:{
                    path:"byCondition"
                }
            },
            "getAllByCondition":{
                method:"POST",
                isArray: true,
                params:{
                    path:"getAllByCondition"
                }
            },
            "createByAgent":{
                method:"POST",
                params:{
                    path:"createByAgent"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
