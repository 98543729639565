(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RegionTempDialogController', RegionTempDialogController);

    RegionTempDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RegionTemp', "MessageService"];

    function RegionTempDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, RegionTemp, MessageService) {
        var vm = this;
        vm.storeId = $stateParams.storeId;
        vm.regionTemp = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validata = function(){
            if (vm.regionTemp.name == null || vm.regionTemp.name == "") {
                MessageService.error("请输入姓名");
                return false;
            }
            return true;
        }

        function save () {
            if (!$scope.validata()) {
                return;
            }
            vm.isSaving = true;
            if (vm.regionTemp.id !== null) {
                RegionTemp.update(vm.regionTemp, onSaveSuccess, onSaveError);
            } else {
                vm.regionTemp.storeId = vm.storeId;
                RegionTemp.createByAgent(vm.regionTemp, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:regionTempUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
