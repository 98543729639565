(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformDouyinDialogController', PlatformDouyinDialogController);

    PlatformDouyinDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'MessageService', 'entity', 'PlatformDouDians', '$state'];

    function PlatformDouyinDialogController ($timeout, $scope, $stateParams, MessageService, entity, PlatformDouDians, $state) {
        var vm = this;

        vm.platformDouYin = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go("platform-douyin");
        }

        function save () {
            vm.isSaving = true;
            if (vm.platformDouYin.id !== null) {
                PlatformDouDians.update(vm.platformDouYin, onSaveSuccess, onSaveError);
            } else {
                PlatformDouDians.save(vm.platformDouYin, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:platformDouYinUpdate', result);
            MessageService.success("保存成功");
            $state.go("platform-douyin", null, { reload: 'platform-douyin' });
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }
    }
})();
