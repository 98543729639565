(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayCodeDialogImportantController', PayCodeDialogImportantController);

    PayCodeDialogImportantController.$inject = ['$scope', '$q', 'entity', 'PayCode', '$uibModalInstance', 'MessageService', 'Upload', 'Store', 'Product', 'PayBatch', 'DateUtils'];

    function PayCodeDialogImportantController ($scope, $q,  entity, PayCode, $uibModalInstance, MessageService, Upload, Store, Product, PayBatch, DateUtils) {
        var vm = this;
        vm.payCode = entity;
        vm.clear = clear;
        vm.save = save;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.products = [];
        vm.file = null;
        vm.fileName = null;

        vm.paybatchs = PayBatch.getAllByCondition({items:[{key:"used", op:"=", value:"false"},{key:"status", op:"=", value:"Approved"}]});
        vm.paybatchs2 = PayBatch.getAllByCondition({items:[{key:"used", op:"=", value:"true"}]});
        vm.stores = Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]});

        $q.all([vm.stores.$promise, vm.paybatchs.$promise]).then(function(){

        })

        $scope.payBatchChange = function(){
            if (vm.payCode.payBatchId == null) {
                vm.payCode= {};
            }else{
                var p = $scope.findPayBatchById(vm.payCode.payBatchId, vm.paybatchs);
                if (p == null) {
                    return;
                }
                vm.payCode.range = p.range ;
                vm.payCode.productId = p.productId ;
                vm.payCode.productName = p.productName ;
                vm.payCode.periodOfValidity = DateUtils.convertLocalDateFromServer(p.periodOfValidity) ;
                vm.payCode.storeName = p.storeName ;
                vm.payCode.storeId = p.storeId ;
                vm.payCode.batch = p.number ;
                vm.payCode.quantity = p.quantity;
                vm.payCode.supplier = p.supplier ;
                vm.payCode.payBatchId = p.id;
                vm.payCode.number = p.startNumber;
                $scope.storeChange();
            }
        }

        $scope.findPayBatchById = function(id, datas){
            if (id == null || datas == null || datas.length == 0) {
                return null;
            }
            for (var i = datas.length - 1; i >= 0; i--) {
                var p = datas[i];
                if (p.id  == id) {
                    return p;
                }
            }
            return null;
        }

        $scope.storeChange = function(){
            vm.products = [];
            vm.payCode.storeName = null;
            if (vm.payCode.storeId == null) {
                return;
            }

            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i]
                if (s.id == vm.payCode.storeId) {
                    vm.payCode.storeName  = s.name;
                    break;
                }
            }
            Product.getAllByCondition2({items:[{key:"prototype",value:"Store",op:"="},{key:"storeId",value:vm.payCode.storeId ,op:"="}]},function(data){
                vm.products = data;
            })
        }


        $scope.export = function(){
            var p = $scope.findPayBatchById(vm.payCode.payBatchId, vm.paybatchs2);
            vm.payCode.batch = p.number;
            vm.payCode.storeId = p.storeId;
            var params = "storeId="+vm.payCode.storeId;
            if (vm.payCode.storeId == null) {
                MessageService.error("请先选择商家");
                return;
            }
            if (vm.payCode.batch !=null && vm.payCode.batch != "") {
                params = params+ "&batch="+vm.payCode.batch;
            }
            window.open("service/api/pay-codes/exportDate?"+params);
        }

        $scope.rangeChange = function(){
            vm.payCode.productId = null;
            vm.payCode.productName = null;
        }

        $scope.productChange = function(){
            vm.payCode.productName = null;
            // vm.payCode.periodOfValidity = null;
            if (vm.payCode.productId == null || vm.products == null || vm.products.length == 0) {
                vm.payCode.productId == null;
                return;
            }
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var p = vm.products[i]
                if (p.id == vm.payCode.productId) {
                    vm.payCode.productName = p.name;
                    // vm.payCode.periodOfValidity = p.periodOfValidity;
                    break;
                }
            }
        }

        $scope.import = function(){
            if (vm.payCode.storeId == null) {
                MessageService.error("请选择商家后再重新导入");
                return ;
            }
            if (vm.payCode.range == 'Product' && vm.payCode.productId == null) {
                MessageService.error("请选择产品后再重新导入");
                return;
            }
            if (vm.payCode.batch == null || vm.payCode.batch == "") {
                MessageService.error("请输入批次号后再重新导入");
                return ;
            }

            if (vm.payCode.supplier == null || vm.payCode.supplier == "") {
                MessageService.error("请输入卡片供应商后再重新导入");
                return ;
            }

            var data = {
                storeId:vm.payCode.storeId,
                storeName:vm.payCode.storeName,
                batch:vm.payCode.batch,
                supplier:vm.payCode.supplier,
                file: vm.file,
                name:vm.fileName,
                payBatchId:vm.payCode.payBatchId,
                range:vm.payCode.range,
                number:vm.payCode.number,
                periodOfValidity: DateUtils.convertLocalDateToServer(vm.payCode.periodOfValidity)
            };
            if (vm.payCode.productId) {
                data.productId= vm.payCode.productId;
                data.productName= vm.payCode.productName;
            }

            Upload.upload({
                type: 'POST',
                url: 'service/api/pay-codes/importData', //上传路径
                data: data
            }).then(function successCallback(res) {
                if(res.status && res.status == 200){
                    MessageService.success(res.data.message);
                    // $uibModalInstance.close();
                }
            }, function errorCallback(response) {
                // $uibModalInstance.close();
                MessageService.error("文件导入，连接超时， 请稍后刷新页面确认是否成功");
            });
        }

        $scope.uploadFile = function(fileWatched) {
            vm.file = fileWatched;
            vm.fileName = fileWatched.name;
        };

        $scope.importCancelled = function(){
            var data = {
                file: vm.file,
                name:vm.fileName,
            };

            Upload.upload({
                type: 'POST',
                url: 'service/api/pay-codes/importCancelledData', //上传路径
                data: data
            }).then(function successCallback(res) {
                if(res.status && res.status == 200){
                    MessageService.success(res.data.message);
                    // $uibModalInstance.close();
                }
            }, function errorCallback(response) {
                // $uibModalInstance.close();
                MessageService.error("文件导入，连接超时， 请稍后刷新页面确认是否成功");
            });
        }


        $scope.validate = function() {
            if (vm.payCode.periodOfValidity.getTime() < new Date().getTime()) {
                MessageService.error("支付码有效期不能是当天或之前的日期");
                return false;
            }
            if (vm.payCode.range == null || vm.payCode.range == "") {
                MessageService.error("请选择使用范围");
                return false;
            }
            if (vm.payCode.range == "Product" && ( vm.payCode.productId == null || vm.payCode.productId == "")) {
                MessageService.error("请选择产品");
                return false;
            }
            if ( vm.payCode.periodOfValidity == null || vm.payCode.periodOfValidity == "") {
                MessageService.error("请输入有效时间");
                return false;
            }
            if (vm.payCode.type == null || vm.payCode.type == "") {
                MessageService.error("请选择类型");
                return false;
            };
            if (vm.payCode.quantity == null || vm.payCode.quantity == '') {
                MessageService.error('请输入数量');
                return false;
            }
            if (vm.payCode.number == null || vm.payCode.number == "") {
                MessageService.error('请输入起始卡号');
                return false;
            }
            if (vm.payCode.scalar == "Multiple" && vm.payCode.payCodeQuantity == null || vm.payCode.payCodeQuantity == "" ) {
                MessageService.error("请输入支付码支付数量");
                return false;
            }

            if (/^([0-9]+)$/.test(vm.payCode.number.substring(1)) && /[a-zA-Z]+/.test(vm.payCode.number.substring(0,1))) {
                // return true;
            }else{
                MessageService.error("输入的起始卡号不符合规则");
                return false;
            }
            if (vm.payBatch.startNumber.length>15) {
                MessageService.error("起始卡号不能超过15个字符");
                return false;
            }
            return true;
        };

        function save() {

            if(!$scope.validate()){
                return;
            }
            if (vm.payCode.scalar == 'Single') {
                vm.payCode.payCodeQuantity = 1;
            }
            vm.isSaving = true;
            PayCode.batchCreate({
                scalar: vm.payCode.scalar,
                payCodeQuantity: vm.payCode.payCodeQuantity,
                quantity:vm.payCode.quantity,
                productId:vm.payCode.productId,
                productName:vm.payCode.productName,
                storeId:vm.payCode.storeId,
                storeName:vm.payCode.storeName,
                range:vm.payCode.range,
                batch:vm.payCode.batch,
                supplier:vm.payCode.supplier,
                periodOfValidity:vm.payCode.periodOfValidity,
                number:vm.payCode.number,
                payBatchId:vm.payCode.payBatchId
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:payCodeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('添加成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            // $uibModalInstance.dismiss('cancel');
            MessageService.error("系统请求超时，请稍后刷新系统，确认是否添加成功");
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
