(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('PayOrder', PayOrder);

    PayOrder.$inject = ['$resource'];

    function PayOrder ($resource) {
        var resourceUrl =  'manager/' + 'api/pay-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            
            'getAllByCondition2': {
                params:{
                    path:"getAllByCondition2"
                },
                method: 'POST', 
                isArray: true
            },
            'byCondition2': {
                params:{
                    path:"byCondition2"
                },
                method: 'POST', 
                isArray: true
            },
            "createPayOrder": { 
                params:{
                    path:"createPayOrder"
                },
                method: 'GET'
            },
            "createPayOrder2": { 
                params:{
                    path:"createPayOrder2"
                },
                method: 'POST'
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
