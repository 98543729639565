(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LicenseKeyDialogController', LicenseKeyDialogController);

    LicenseKeyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LicenseKey', 'MessageService'];

    function LicenseKeyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LicenseKey, MessageService) {
        var vm = this;

        vm.licenseKey = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.inputFlag = true;
        vm.check = check;
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function check() {
            vm.inputFlag = false;
            if (vm.licenseKey.utilityTime == null || vm.licenseKey.utilityTime < 0 || vm.licenseKey.utilityTime % 1 != 0) {
                // MessageService.error("请输入有效期限");
                vm.inputFlag = true;
            };
            if (vm.licenseKey.periodOfTime == null || vm.licenseKey.periodOfTime < 0 || vm.licenseKey.periodOfTime % 1 != 0) {
                // MessageService.error("请输入有效的有效期");
                vm.inputFlag = true;
            };
            if (vm.licenseKey.quantity == null || vm.licenseKey.quantity < 0 || vm.licenseKey.quantity % 1 != 0) {
                // MessageService.error("请输入有效数量");
                vm.inputFlag = true;
            };
        }

        function save () {
            vm.isSaving = true;
            LicenseKey.create({utilityTime:vm.licenseKey.utilityTime, quantity:vm.licenseKey.quantity,period:vm.licenseKey.periodOfTime}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:licenseKeyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('添加成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("添加失败,输入数据可能非法！");
        }

        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
