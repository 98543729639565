(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('MessageTemp', MessageTemp);

    MessageTemp.$inject = ['$resource'];

    function MessageTemp ($resource) {
        var resourceUrl =  'manager/' + 'api/message-templete-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            "getByTypes":{
                method:"GEt",
                params:{
                    path:"getByTypes"
                },
            },
            "batchUpdate": {
                method: "POST",
                params: {
                    path: "batchUpdate"
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
