(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('FontManagement', FontManagement);

    FontManagement.$inject = ['$resource'];

    function FontManagement ($resource) {
        var resourceUrl =  'manager/' + 'api/font-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAll1": {
                method: "POST",
                params: {
                    path: "getAll1"
                },
                isArray: true
            },
            "byCondition1":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "getCss": {
                method: "GET",
                params: {
                    path: "getCss"
                }
            },
            "upload": {
                method: "POST",
                params: {
                    path: "upload"
                }
            },
            "defaultShow":{
                method:"GET",
                params:{
                    path:"defaultShow"
                }
            },
            "getAll": {
                method: "POST",
                params: {
                    path: "getAll"
                },
                isArray: true
            },
            "updateType": {
                method: "GET",
                params: {
                    path: "updateType"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
