(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('RegionMySuffixDialogController', RegionMySuffixDialogController);

    RegionMySuffixDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'entity', 'Region', 'Province', 'MessageService', '$stateParams'];

    function RegionMySuffixDialogController($timeout, $scope, $q, $state, entity, Region, Province, MessageService, $stateParams) {
        var vm = this;

        vm.region = entity;
        vm.cancel = cancel;
        vm.save = save;
        vm.tempId = $stateParams.tempId;
        vm.storeId = $stateParams.storeId;
        if (vm.tempId == null || !vm.storeId) {
            return;
        }

        if (vm.region.id == null) {
            vm.region.id = 0;
        }
        vm.provinces = Province.other({id: vm.region.id, tempId:vm.tempId});

        $scope.selectedAll = false;
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.areas = [{
            area: '',
            province: [],
            selected: false
        }];
        function getArea(provincese, flag) {
            if (provincese == undefined) {
                return;
            }
            var arr = provincese.reduce(function (pre, current, index) {
                pre[current.area] = pre[current.area] || [];
                pre[current.area].push(current);
                pre[current.area].selected = false;
                return pre
            }, {});
            if (!flag) {
                var t = 0;
                for (var area in arr) {
                    vm.areas[t] = {
                        area: area,
                        province: arr[area],
                        selected: false
                    };
                    t++;
                }
            }

        }

        function cancel() {
            $state.go("regionmySuffix");
        }

        $q.all([vm.region.$promise, vm.provinces.$promise]).then(function () {
            angular.forEach(vm.region.provinces, function (province) {
                vm.provinces.push(province);
            });
            getArea(vm.provinces, 0);
            getArea(vm.region.provinces, 1);
            if (vm.region.provinces != null && vm.region.provinces.length > 0) {
                for (var i = vm.region.provinces.length - 1; i >= 0; i--) {
                    vm.region.provinces[i].selected = true;
                }
            }

            angular.forEach(vm.areas, function (area, i) {
                isSelectAll(area);
            })
        });

        function isSelectAll(area) {
            var t = 0;
            angular.forEach(area.province, function (province, i) {
                if (!province.selected) {
                    return
                }
                t++;
                if (t == area.province.length) {
                    area.selected = true
                }
            })
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.isSelected = function (obj, flag, area) {
            obj.selected = !obj.selected;
            if (flag) {
                angular.forEach(obj.province, function (province, i) {
                    province.selected = obj.selected;
                })
            } else {
                $scope.arr = [];
                angular.forEach(area.province, function (province, i) {
                    if (province.selected) {
                        $scope.arr.push(province);
                    }

                });
                if ($scope.arr.length == area.province.length) {
                    area.selected = true;
                } else {
                    area.selected = false;
                }
            }
        };

        $scope.isSelectedAll = function () {
            $scope.selectedAll = !$scope.selectedAll;
            if ($scope.selectedAll) {
            }
        };

        $scope.toPost = function () {
            var postData = {};
            postData.provinces = [];
            postData.id = vm.region.id;
            postData.name = vm.region.name;
            postData.regionTempId = vm.tempId;
            postData.freightFree = vm.region.freightFree;
            postData.freight = vm.region.freight;
            if (vm.region.id != null && vm.region.provinces != null) {
                for (var i = vm.region.provinces.length - 1; i >= 0; i--) {
                    var p1 = vm.region.provinces[i];
                    if (p1.selected) {
                        postData.provinces.push(p1);
                    }
                }
            }
            for (var i = vm.provinces.length - 1; i >= 0; i--) {
                var p2 = vm.provinces[i];
                if (p2.selected) {
                    postData.provinces.push(p2);
                }
            }
            return postData;
        };

        $scope.validate = function (data) {
            if (data.name == null || data.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            if (data.freight == null || data.freight == ""&&data.freight!=0) {
                MessageService.error("请输入运费");
                return false;
            }
            if (data.provinces == null || data.provinces.length == 0) {
                MessageService.error("请勾选区域");
                return false;
            }
            return true;
        };


        function save() {
            var data = $scope.toPost();
            if (!$scope.validate(data)) {
                return;
            }
            vm.isSaving = true;
            if (vm.region.id !== null && vm.region.id != 0) {
                Region.update(data, onSaveSuccess, onSaveError);
            } else {
                if (data.id == 0) {
                    data.id = null;
                }
                data.storeId = vm.storeId;
                Region.createByAgent(data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            $state.go("regionmySuffix");
            MessageService.success("信息更新成功");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
