(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StorePaymentSettingDetailController', StorePaymentSettingDetailController);

    StorePaymentSettingDetailController.$inject = ['$scope', '$q', '$state', '$rootScope', '$stateParams', 'entity', 'Store',
    'StoreWechatSetting', 'StoreSmallAppWechatSetting', 'StorePaymentSetting'];

    function StorePaymentSettingDetailController($scope, $q, $state, $rootScope, $stateParams, entity, Store,
        StoreWechatSetting, StoreSmallAppWechatSetting, StorePaymentSetting) {
        var vm = this;

        vm.store = entity;
        vm.clear2 = clear2;
        vm.storePaymentSetting = StorePaymentSetting.getCurrent({storeId:$stateParams.storeId});
        function clear2 (){
            $state.go("store2");
        }

        $q.all([vm.store.$promise]).then(function(){
             if (vm.store.payType) {
                if (vm.store.payType.indexOf("paycode")>= 0 ) {
                    vm.store.paycode = true;
                }
                if (vm.store.payType.indexOf("wechat")>= 0 ) {
                    vm.store.wechat = true;
                }
                if (vm.store.payType.indexOf("underLine")>= 0 ) {
                    vm.store.underLine = true;
                }
            }
            if (vm.store.storeType2) {
                if (vm.store.storeType2.indexOf("WECHAT" )>=0) {
                    vm.store.storeTypeWechat = true;
                }
                if (vm.store.storeType2.indexOf("MINI" )>=0) {
                    vm.store.storeTypeMini = true;
                }
                if (vm.store.storeType2.indexOf("THIRD" )>=0) {
                    vm.store.storeTypeThird = true;
                }
            }

            vm.miniSetting = StoreSmallAppWechatSetting.getEntityByStoreId({storeId:vm.store.id});
            vm.wechatSetting = StoreWechatSetting.getByStoreId({storeId:vm.store.id});
            $q.all([vm.miniSetting.$promise , vm.wechatSetting.$promise]).then(function(){
                vm.store.wechatStore = vm.wechatSetting.weChatMchid;
                vm.store.wechatKey = vm.wechatSetting.weChatKey;
                vm.store.wechatStore2 = vm.miniSetting.weChatMchid;
                vm.store.wechatKey2 = vm.miniSetting.weChatKey;
            });

        });


        var unsubscribe = $rootScope.$on('platformApp:storeUpdate', function(event, result) {
            vm.store = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
