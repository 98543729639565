(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RoleController', RoleController);

    RoleController.$inject = ['$scope', '$state', 'Role', 'ParseLinks', 'pagingParams', 'paginationConstants', 'MessageService'];

    function RoleController ($scope, $state, Role, ParseLinks, pagingParams, paginationConstants, MessageService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.jump = jump;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;


        loadAll();
        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Role.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true)
        };


        function loadAll () {
            var items=[{key:"type",op:"=",value:"PLATFORM"},{key:"storeId",op:"=",value:null},{key:"cancelled",op:"=",value:false}];
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search});
            };

            Role.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.roles = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据加载失败");
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.batchArr = [];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.roles,function (role,i) {
                if($scope.selectAll){
                    role.select = true;
                    $scope.batchArr.push(role.id);
                    $scope.selectOne = true;
                }else{
                    role.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (role) {
            role.select = !role.select;
            // 选中
            if(role.select){
                $scope.batchArr.push(role.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(role.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.roles,function (role,i) {
                if(!role.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.roles.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Role.deleteBatch({ids: $scope.batchArr},function () {
                    MessageService.success("删除成功");
                    $scope.selectOne = false;
                    $scope.selectAll = false;
                    loadAll();
                },function () {
                    MessageService.error("删除失败");
                })
            },function(){},true)
        }
    }
})();
