(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FontManagementController', FontManagementController);

    FontManagementController.$inject = ['$scope', '$state', 'FontManagement', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 
    '$localStorage', 'Qinius', '$q'];

    function FontManagementController ($scope, $state, FontManagement, ParseLinks, paginationConstants, pagingParams, MessageService,
     $localStorage, Qinius, $q) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.account = $localStorage.user;

        loadAll();

        vm.domains = Qinius.getDomain(); 
        $q.all([ vm.domains.$promise]).then(function(){
            vm.domainQ = 'http://' + vm.domains.domains[0] + '/';
        })


        $scope.defaultShow = function(id){
            MessageService.confirm("确认要设置为模板中默认展示？",function(){
                FontManagement.defaultShow({id: id},function () {
                    loadAll();
                });
            },function(){});
        }

        $scope.delete = function(id){
            MessageService.confirm("确认要删除该数据？",function(){
                FontManagement.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };

        function loadAll () {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            if (pagingParams.search) {
                FontManagement.byCondition({
                    items:[{key:"family",value:pagingParams.search, op:"like"}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                FontManagement.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, 
                onSuccess, onError);

            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.fontManagements = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                console.log(2);
                MessageService.error("字体获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
    }
})();
