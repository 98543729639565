(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('Banner', Banner);

    Banner.$inject = ['$resource'];

    function Banner ($resource) {
        var resourceUrl =  'manager/' + 'api/banners/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            }, 
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "createByAgent":{
               method: 'POST',
                params: {
                    path: 'createByAgent'
                } 
            },
            "deleteBatch":{
                method:"GET",
                params:{
                    path:"deleteBatch"
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
