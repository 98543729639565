
(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FeedbackWwwController', FeedbackWwwController);

    FeedbackWwwController.$inject = ['$scope', '$uibModal', 'Principal','$state', 'MessageService', 'ParseLinks', 'paginationConstants', 'pagingParams', 'VisitorTickets','$http'];

    function FeedbackWwwController($scope, $uibModal, Principal,$state, MessageService, ParseLinks, paginationConstants, pagingParams, VisitorTickets,$http) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.status = pagingParams.status;
        vm.time = pagingParams.time;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.feedbckList = [];
        loadAll();
        function loadAll () {
            var items=[];
            // if (vm.status !=null) {
            //     items.push({key:"status",op:"=",value:vm.status});
            // }
            // if (pagingParams.search) {
            //     items.push({key:"title", op:"like", value:pagingParams.search});
            // }
            VisitorTickets.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.feedbckList = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("反馈获取失败");
            }
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                time:vm.time,
                status:vm.status
            });
        };

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
        $scope.getStatusName = function (status) {
            if(status === "Untreated"){
                return "未处理";
            }else if(status === "Treated"){
                return "已处理";
            }
            return "";
        }
        $scope.exportExcel = function () {
            $http({
                url: 'service/api/visitor-tickets/export',
                data:{},
                method: "POST",
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/vnd.ms-excel"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "反馈.xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function() {
                MessageService.error("网络异常")
            });
        }

        /**
         * 查看详情
         * @param e 反馈信息
         */
        $scope.showDetail = function (e) {
            $uibModal.open({
                templateUrl: 'app/entities/feedback/feedback-www-detail.html',
                controller: 'FeedbackWwwDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity:function () {
                        return angular.copy(e)
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('feedback');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
            });
        }
        /**
         * 显示处理弹窗
         * @param id 反馈id
         */
        $scope.showDispose = function (id) {
            console.log(id);
            $uibModal.open({
                templateUrl: 'app/entities/feedback/feedback-www-dispose.html',
                controller: 'FeedbackWwwDisposeController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity:function () {
                        return {
                            id: id
                        }
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('feedback');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                $state.reload()
            });
        }
    }
})();
