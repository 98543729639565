(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig2);

    stateConfig2.$inject = ['$stateProvider'];

    function stateConfig2($stateProvider) {
        $stateProvider
            .state('storage-space', {
                parent: 'storage-price',
                url: '/storage-space?page&sort&search',
                data: {
                    authorities: ['ROLE_PLATFROM_COMMODITY_PRICE'],
                    pageTitle: 'global.menu.entities.storagePrice'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/storage-space/storage-space.html',
                        controller: 'StorageSpaceController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('storagePrice');
                        return $translate.refresh();
                    }]
                }
            })
            .state('storage-space-settle', {
                parent: 'storage-price',
                url: '/{id}/storage-space-settle',
                data: {
                    authorities: ['ROLE_PLATFROM_COMMODITY_PRICE'],
                    pageTitle: 'global.menu.entities.storagePrice'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/storage-space/storage-space-settle.html',
                        controller: 'StorageSpaceSettleController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    
                }
            })
    }
})();