(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformALiSettingDialogController', PlatformALiSettingDialogController);

    PlatformALiSettingDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'MessageService', 'entity', 'PlatformALiSetting', '$state'];

    function PlatformALiSettingDialogController ($timeout, $scope, $stateParams, MessageService, entity, PlatformALiSetting, $state) {
        var vm = this;

        vm.platformALiSetting = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go("platform-a-li-setting");
        }

        function save () {
            vm.isSaving = true;
            if (vm.platformALiSetting.id !== null) {
                PlatformALiSetting.update(vm.platformALiSetting, onSaveSuccess, onSaveError);
            } else {
                PlatformALiSetting.save(vm.platformALiSetting, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:platformALiSettingUpdate', result);
            MessageService.success("保存成功");
            $state.go("platform-a-li-setting", null, { reload: 'platform-wechat-setting' });
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.expiresTime = false;
        vm.datePickerOpenStatus.reExpiresTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
