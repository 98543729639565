(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreThemeStyleDialogController', StoreThemeStyleDialogController);

    StoreThemeStyleDialogController.$inject = ['$scope' ,'$uibModalInstance'];

    function StoreThemeStyleDialogController($scope ,$uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.imgs = [];

        var flag= localStorage.getItem('flag');
        // console.log(flag);
        if(flag == 'one'){
            vm.imgs =['content/images/homeStyleOne@2x.png','content/images/categoryStyleOne@2x.png' , 'content/images/productStyleOne.png',
            'content/images/productDetailStyleOne.png','content/images/cartStyleOne@2x.png','content/images/myStyleOne@2x.png'];
        }else if(flag == 'two'){
            vm.imgs =['content/images/homeStyleTwo.png','content/images/categoryStyleTwo.png', 'content/images/productStyleTwo.png',
            'content/images/productDetailStyleTwo.png', 'content/images/cartStyleTwo.png','content/images/myStyleTwo.png'];
        }else if(flag == 'three'){
            vm.imgs =['content/images/homeStyleThree.png','content/images/categoryStyleThree.png','content/images/cartStyleThree.png','content/images/myStyleThree.png'];
        }
        var init=0;
        var num=vm.imgs.length;
        $scope.prev =function(){
            if(init>0){
                init--;
            }else{
                init=0;
            }
            ani();
        };
        $scope.next = function(){
            if(init<(num-1)){
                init++;
            }else{
                init=num-1;
            }
            ani();
        };
        var imgWidth =248;
        function ani(){
            angular.element('#banner').animate({left:'-' + (init*imgWidth)},300);
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
