(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('Pricing2Controller', Pricing2Controller);

    Pricing2Controller.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Category', 'PaginationUtil', 'PlatformWechatSetting'];

    function Pricing2Controller ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, MessageService, $uibModalInstance, Category, PaginationUtil, PlatformWechatSetting) {
        var vm = this;
        vm.pricing = angular.copy(entity.data);
        vm.type = entity.type;
        vm.save = save;
        vm.cancel = cancel;
        vm.pricingStrategy = [
            {name: '标准定价', id: 'Standard'},
            {name: '梯度定价', id: 'Grads'},
        ];
        vm.conditions = [
            {name: '数量', id: 'Quantity'},
            // {name: '价格', id: 'Price'}
        ];
        //最小数值
        PlatformWechatSetting.byPlatFrom({},function (res) {
            vm.priceMinQuantity = res.priceMinQuantity || 0;
        })
        //添加价格
        $scope.addPriceLines = function () {
            var _obj = {
                startQuantity: '',
                endQuantity: '',
                priceType: 'Price',
                price: ''
            }
            vm.pricing.priceLines.push(_obj)
        }

        $q.all([vm.pricing.$promise ]).then(function(){
            vm.pricing.priceLines.sort(function (a,b) {
                return a.id - b.id
            });
        });
        //确定
        function save() {
            if(vm.pricing.priceRule == 'Standard' && !vm.pricing.price){
                MessageService.error("请输入大于0的价格");
                return
            }
            if(vm.pricing.priceRule == 'Ladder' || vm.pricing.priceRule == 'Grads'){
                if(vm.pricing.priceLines.length == 0){
                    MessageService.error("请输入价格");
                    return
                }
                if(!isPrice()){
                    return;
                }
            }

            $uibModalInstance.close(vm.pricing);
        }
        //取消
        function cancel() {
            $uibModalInstance.close();
        }

        //删除阶梯价格
        $scope.deletePriceLines = function (data, index) {
            // if(!data.id){
                vm.pricing.priceLines.splice(index, 1)
            // }else {
            //     data.cancelled = true;
            // }
        }
        //判断阶梯价格
        function isPrice() {
            var _priceLines = [];
            var _priceLinesArr = angular.copy(vm.pricing.priceLines);
            var _startQuantitys = [];
            for(var i=0; i<_priceLinesArr.length; i++){
                if(!_priceLinesArr[i].cancelled){
                    _priceLines.push(_priceLinesArr[i]);
                }
            }
            for(var i=0; i<_priceLines.length; i++){
                if(!_priceLines.cancelled){
                    var _isI;
                    if(!_isI && _priceLines[i].startQuantity < vm.priceMinQuantity){
                        MessageService.error("开始数量必须大于" + vm.priceMinQuantity);
                        return false
                    }else {
                        _isI = true
                    }
                    if(_priceLines[i].endQuantity <= _priceLines[i].startQuantity){
                        MessageService.error("结束数量不能小于或者等于开始数量");
                        return false
                    }
                    if(!_priceLines[i].price){
                        MessageService.error("请输入价格");
                        return false
                    }
                    _startQuantitys.push(_priceLines[i].startQuantity)
                }
            }
            var minValue=Math.min.apply(Math,_startQuantitys);
            if(minValue < _startQuantitys[0]){
                MessageService.error("开始数量必须是最小数");
                return
            }
            for(var i=0; i<_priceLines.length; i++){
                if(!_priceLines.cancelled){
                    var _index = i + Number(1);
                    if(_index < _priceLines.length){
                        var _thisEnd = Number(_priceLines[i].endQuantity);
                        var _nextStart = Number(_priceLines[_index].startQuantity);
                        if(_thisEnd == _nextStart){
                            MessageService.error("不能重复" + _thisEnd);
                            return false
                        }
                        if(_thisEnd > _nextStart){
                            MessageService.error("不能重复" + _thisEnd + "和" + _nextStart + "之间的数值");
                            return false
                        }
                        if((_thisEnd + Number(1)) < _nextStart){
                            MessageService.error("阶梯价格缺少" + _thisEnd + "和" + _nextStart + "之间的数值");
                            return false
                        }
                    }
                }

            }

            for(var i=vm.pricing.priceLines.length-1; i>= 0; i--){
                if(!vm.pricing.priceLines[i].cancelled){vm.pricing.priceLines.splice(i, 1);}
            }
            for(var j=0; j<_priceLines.length; j++){
                vm.pricing.priceLines.push(_priceLines[j])
            }
            return true
        }

        vm.priceRuleStatus = priceRuleStatus;
        function priceRuleStatus(target) {
            switch (target) {
                case "Standard":
                    return "标准定价";
                case "Ladder":
                    return "阶梯定价";
                case "Grads":
                    return "梯度定价";
                default:
                    return "";
            }
        }
    }
})();
