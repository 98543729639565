(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('flow-price', {
                parent: 'entity',
                url: '/flow-price?page&sort&search',
                data: {
                    authorities: ['ROLE_PLATFROM_COMMODITY_PRICE'],
                    pageTitle: 'global.menu.entities.flowPrices'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/flow-price/flow-prices.html',
                        controller: 'FlowPriceController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodityPrice');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('flow-price-detail', {
                parent: 'flow-price',
                url: '/flow-price/{id}',
                data: {
                    authorities: ["ROLE_PLATFROM_COMMODITY_PRICE"],
                    pageTitle: 'global.menu.entities.flowPrices'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/flow-price/flow-price-detail.html',
                        controller: 'FlowPriceDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodityPrice');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['Tariffs', '$stateParams',
                        function(Tariffs, $stateParams) {
                            return Tariffs.get({
                                id: $stateParams.id
                            }).$promise;
                        }
                    ]
                }
            })
            .state('flow-price.new', {
                parent: 'flow-price',
                url: '/new',
                data: {
                    authorities: ["ROLE_PLATFROM_COMMODITY_PRICE"],
                    pageTitle: 'global.menu.entities.flowPrices'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/flow-price/flow-price-dialog.html',
                        controller: 'FlowPriceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodityPrice');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            cancelled: null,
                            seq: null,
                            id: null,
                            priceLines: [],
                            tariffType:'Editor',
                            itemValueLines:[]
                        };
                    }
                }

            })
            .state('flow-price.edit', {
                parent: 'flow-price',
                url: '/{id}/edit',
                data: {
                    authorities: ["ROLE_PLATFROM_COMMODITY_PRICE"],
                    pageTitle: 'global.menu.entities.flowPrices'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/flow-price/flow-price-dialog.html',
                        controller: 'FlowPriceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodityPrice');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['Tariffs', '$stateParams',
                        function(Tariffs, $stateParams) {
                            return Tariffs.get({
                                id: $stateParams.id
                            }).$promise;
                        }
                    ]
                }
            });
    }

})();
