(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('PayCode', PayCode);

    PayCode.$inject = ['$resource', 'DateUtils'];

    function PayCode ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/pay-codes/:path/:code';

        return $resource(resourceUrl, {}, {
            'batchCreate': {
                method: 'POST',
                params: {
                    path: 'batchCreate'
                }
            },
            'batchDelete': {
                method: 'GET',
                params: {
                    path: 'batchDelete'
                }
            },
            "payCodeAnalysis":{
               method: 'GET',
                params: {
                    path: 'payCodeAnalysis'
                } 
            },
            "payCodeDataByBatch":{
               method: 'GET',
                params: {
                    path: 'payCodeDataByBatch'
                } 
            },
            
            'activateByBatch': {
                method: 'GET',
                params: {
                    path: 'activateByBatch'
                }
            },
            'allByTimeSlot2': {
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot2'
                },
                isArray: true
            },
            'getAllByTimeSlot3':{
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot3'
                }
            },
            "payCodeAnalysisDownload":{
                method: 'GET',
                params: {
                    path: 'payCodeAnalysisDownload'
                }
            },
            'getBuyDateByYesterDay': {
                method: 'GET',
                params: {
                    path: 'getBuyDateByYesterDay'
                }
            },
            'unbind': {
                method: 'GET',
                params: {
                    path: 'unbind'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
