(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PriceListController', PriceListController);

    PriceListController.$inject = ['$state', 'PriceList', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', '$scope', '$stateParams'];

    function PriceListController($state, PriceList, ParseLinks, MessageService, paginationConstants, pagingParams, $scope, $stateParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.storeId = $stateParams.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        $scope.selectAll = false;

        loadAll();

        function loadAll () {
            var items = [{key:"storeId", op:"=", value:vm.storeId}];
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search});
            }  
            PriceList.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.prices = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("定价获取失败");
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                PriceList.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true);
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                storeId:vm.storeId,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0  ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.prices,function (price,i) {
                if($scope.selectAll){
                    price.select = true;
                    $scope.batchArr.push(price.id);
                    $scope.selectOne = true;
                }else{
                    $scope.batchArr = [];
                    price.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (price) {
            price.select = !price.select;
            // 选中
            if(price.select){
                $scope.batchArr.push(price.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(price.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.prices,function (price,i) {
                if(!price.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.prices.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        } 

        $scope.deleteBatch = function () {
            MessageService.confirm("确认要删除选中的定价？",function(){
                PriceList.deleteBatch({ids:$scope.batchArr},function(data){
                    MessageService.success("删除成功");
                    loadAll();
                },function(){
                MessageService.error("删除失败");
                }) 
            },function(){});
        }
    }
})();
