(function() {
	'use strict';
	angular
		.module('platformApp')
		.factory('XlsxService', XlsxService);

	XlsxService.$inject = [];

	function XlsxService() {
		var service = {
			xlsxOut: xlsxOut
		}
		return service;
	}

	function xlsxOut(datas, sheetName, fileName) {

		const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
		const wb = {
			SheetNames: [sheetName],
			Sheets: {},
			Props: {}
		};
		wb.Sheets[sheetName] = XLSX.utils.json_to_sheet(datas); 
		saveAs(new Blob([s2ab(XLSX.write(wb, wopts))], { type: "application/octet-stream" }), fileName + '.' + (wopts.bookType=="biff2"?"xls":wopts.bookType));
	}

	function s2ab(s) {
        if (typeof ArrayBuffer !== 'undefined') {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        } else {
            var buf = new Array(s.length);
            for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
    }

	 function saveAs(obj, fileName) {//当然可以自定义简单的下载文件实现方式 
            var tmpa = document.createElement("a");
            tmpa.download = fileName || "下载";
            tmpa.href = URL.createObjectURL(obj); //绑定a标签
            tmpa.click(); //模拟点击实现下载
            setTimeout(function () { //延时释放
                URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
            }, 100);
        }

})();