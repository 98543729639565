(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CheckDeliveryDialogController', CheckDeliveryDialogController);

    CheckDeliveryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CheckDelivery', 'Upload', 'MessageService'];

    function CheckDeliveryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CheckDelivery, Upload, MessageService) {
        var vm = this;

        vm.checkDelivery = entity;
        vm.clear = clear;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.uploadFile = function(fileWatched) {
            vm.file = fileWatched;
            vm.fileName = fileWatched.name;
             
        };

        $scope.import = function(){
            Upload.upload({
                type: 'POST',
                url: 'service/api/check-deliveries/importData', //上传路径
                data: {
                    file: vm.file,
                    name:vm.fileName
                }
            }).then(function successCallback(res) {
                if(res.status && res.status == 200){
                    MessageService.success("导入成功");
                    $uibModalInstance.close();
                }
            }, function errorCallback(response) {
                $uibModalInstance.close();
                MessageService.error("文件导入，连接超时， 请稍后刷新页面确认是否成功");
            });
        }
    }
})();
