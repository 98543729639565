(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('StorageSpaceSettleController', StorageSpaceSettleController)
    StorageSpaceSettleController.$inject = ['$state', '$stateParams', 'StorageSpace', 'StorageSpace3', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', '$scope'];

    function StorageSpaceSettleController($state, $stateParams, StorageSpace, StorageSpace3, ParseLinks, MessageService, paginationConstants, pagingParams, $scope) {

        var vm = this;
        vm.storageStoreId = $stateParams.id;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        $scope.selectAll = false;
        vm.storeId = vm.storageStoreId
        loadAll();

        function loadAll() {
            if (pagingParams.search) {
                StorageSpace3.byCondition({
                    items: [{ key: "storeId", op: "=", value: vm.storeId }],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                StorageSpace3.byCondition({
                    items: [{ key: "storeId", op: "=", value: vm.storeId }],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.prices = res;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("查询失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.prices, function (price, i) {
                if ($scope.selectAll) {
                    price.select = true;
                    $scope.batchArr.push(price.id);
                    $scope.selectOne = true;
                } else {
                    $scope.batchArr = [];
                    price.select = false;
                    $scope.selectOne = false;
                }
            })
        }
    }
})();