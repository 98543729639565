(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', '$scope', '$q', 'Auth', 'Principal', 'ProfileService', 'LoginService', "$uibModal", "Account", "$localStorage",
    "$sessionStorage", '$stateParams','Store', 'PlatformWechatSetting', 'Qinius'];

    function NavbarController($state, $scope, $q, Auth, Principal, ProfileService, LoginService, $uibModal, Account, $localStorage,
     $sessionStorage, $stateParams,Store, PlatformWechatSetting, Qinius) {
        var vm = this;
        var pageName = null;
        var arr = null;

        vm.isNavbarCollapsed = true;
        vm.account = $localStorage.user;
        console.log(vm.account)
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.platformSetting = PlatformWechatSetting.byPlatFrom();
        vm.domains = Qinius.getDomain();
        $q.all([vm.platformSetting.$promise, vm.domains.$promise]).then(function(){
            vm.menulogo = vm.platformSetting.menuLogo;
            // 测试
            // vm.menulogo = 'logo-7a0d1ad9-d702-4fcd-99ed-419d2166d0e9';
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.shrink = shrink;
        vm.sidebar2Shrik = sidebar2Shrik;
        vm.passwords = passwords;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.listNavBar = listNavBar;
        vm.lmenuClick = lmenuClick;
        vm.url = $state.current.name;

        var $logo = $(".navbar-logo");
        var $bar1 = $(".sidebar1-btn");
        var $side1 = $(".sidebar1");
        var $bar2 = $(".sidebar2-btn");
        var $side2 = $(".sidebar2");
        var isHide = false;
        var $navbar = $(".navbar-wrap");

        setTimeout(function() {
            if (window.location.href.indexOf('homeClear') !=-1) {
                if ($side1[0] && $side1[0].children[1]) {
                    var childs = $side1[0].children[1];
                    for (var i = 0; i< childs.childElementCount; i++) {
                        var li = childs.children[i];
                        if (li.attributes["style"] && li.attributes["style"].value == "display: none;") {
                            continue;
                        }
                        console.log(li.attributes["id"])
                        if (li.attributes["id"]) {
                            console.log(li.attributes["id"].nodeValue)
                            lmenuClick(li.attributes["id"].nodeValue)
                            return;
                        }
                    }
                }
            }
        }, 100);


        $scope.storeDetail2 = function(){
            vm.url = "store-detail2";
            $state.go("store-detail2");
        };

        $scope.choose = function(index){
            $scope.index2 = index;
        };
        Store.getAllByCondition({items:[{key:"activated", op : "=", value:"true"}]},function(data){
            vm.stores = data;
        });

        $scope.storeBanner = function(){
            vm.url = "store-banner";
            $state.go("store-banner");
        }
        $scope.storeWechatSetting = function(){
            vm.url = "store-store-wechat-setting";
            $state.go("store-store-wechat-setting");
        }

        $scope.storeThird= function(){
            vm.url = "store-third";
            $state.go("store-third-list");
        }

        $scope.storePaymentSetting = function(){
            vm.url = "store-payment-setting";
            $state.go("store-payment-setting");
        }

        $scope.storeStyle = function(){
            vm.url = "store-theme-style";
            $state.go("store-theme-style");
        }

        $scope.priceList = function(){
            vm.url = "price-list";
            $state.go("price-list");
        }

        $scope.storeSmallProgram =  function(){
            vm.url = "store-store-small-app-setting";
            $state.go("store-store-small-app-setting");
        }

        $scope.storeProduct = function(){
            vm.url = "store-product";
            $state.go("store-product");
        }

        $scope.agentBack = function(){
            sessionStorage.setItem("lmenu", 'a_agent');
            $state.go("store2");
            vm.lmenu = 'a_agent';
        }

        $scope.marketingBack = function(){
            sessionStorage.setItem("lmenu", 'a_agent_management');
            $state.go("store-marketing", {storeId: $state.params.storeId});
            vm.lmenu = 'a_agent_management';
            vm.url = "store-marketing";
        }

        $scope.storeMarketing = function(){
            vm.url = "store-marketing";
            $state.go("store-marketing");
        }

        $scope.payBatch = function(){
            vm.url = "pay-batch";
            $state.go("store-payBatch2");
        }

        $scope.payCode = function(){
            vm.url = "pay-code";
            $state.go("store-payCode");
        }

        $scope.regionTemp = function(){
            vm.url = "region-temp";
            $state.go("region-temp");
        }
        $scope.brand = function(){
            vm.url = "brand";
            $state.go("brand");
        }
         $scope.passwordActivity = function(){
            vm.url = "password-activity";
            $state.go("password-activity");
        }
        $scope.command = function(){
            vm.url = "command";
            $state.go("command");
        }
        $scope.productOrder = function(){
            vm.url = "product-order";
            $state.go("product-order");
        }
        $scope.league = function(){
            vm.url = "league";
            $state.go("league");
        }




        // 菜单刷新后定位
        vm.lmenu = sessionStorage.getItem("lmenu") == null ? 'a_home' : sessionStorage.getItem("lmenu");
        // console.log(vm.lmenu);
        // if (vm.lmenu == "null" || !!vm.lmenu || vm.lmenu == 'a_home') {
        //     sessionStorage.setItem("lmenu", "a_home");
        //     lmenuClick('a_home');
        //     $state.go('home-page');
        // }

        function lmenuClick(a_id) {
            vm.lmenu = a_id;
            sessionStorage.setItem("lmenu", a_id);

            setTimeout(function() {
                console.log($side2[0].children[0])
                if ($side2[0] && $side2[0].children[0]) {
                    var childs = $side2[0].children[0];
                    for (var i = 0; i< childs.childElementCount; i++) {
                        var li = childs.children[i];
                        if (li.attributes["style"] && li.attributes["style"].value == "display: none;") {
                            continue;
                        }

                        if (li.attributes["ui-sref"]) {
                            console.log(li.attributes["ui-sref"].value)
                            $state.go(li.attributes["ui-sref"].value);
                            return;
                        }
                    }
                }
            }, 100);
        }

        // 初次进入，默认首页
        vm.home_flag = true;
        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            sessionStorage.setItem("lmenu", "a_home");
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        var createModal = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: true,
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }]
                }
            });
        };

        window.showSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left2');
                return;
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left');
        };

        window.hideSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
                return;
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left');
        };

        //侧边栏隐藏
        $(".wrap").addClass("wrap-mar220");
        vm.show_logo = true;
        function shrink() {
            if (vm.lmenu == 'a_home') {
                return;
            }
            $navbar.toggleClass("sidebar-collapse");
            $side2.addClass('transition');
            if (isHide) {
                if ($navbar.hasClass("sidebar-collapse")) {
                    $bar1.removeClass('btn1-shrik1').addClass("btn1-shrik2");
                    $navbar.toggleClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
                    $(".wrap").removeClass("wrap-mar130").removeClass("wrap-mar160").toggleClass("wrap-mar60");
                } else {
                    $bar1.removeClass('btn1-shrik2').addClass("btn1-shrik1");
                    $(".wrap").removeClass("wrap-mar160").removeClass("wrap-mar60").toggleClass("wrap-mar130");
                    $navbar.toggleClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                }
            } else {$bar1.toggleClass('btn1-shrik1').toggleClass("btn1-shrik2");
                if ($(".wrap").hasClass("wrap-mar160")) {
                    $(".wrap").removeClass("wrap-mar160").toggleClass("wrap-mar220");
                } else {
                    $(".wrap").removeClass("wrap-mar220").toggleClass("wrap-mar160");
                }
            }

            $logo.addClass('transition');
            $logo.toggleClass("navbar-logo3");
            if ($logo.hasClass("navbar-logo3")) {
                vm.show_logo = false;
            } else {
                vm.show_logo = true;
            }
            return false;
        }

        function sidebar2Shrik() {
            if(!($(".sidebar2-shrink").hasClass('shidebar2-shrink-left')||$(".sidebar2-shrink").hasClass('shidebar2-shrink-left2'))){
                return
            }

            // 首页特殊处理
            // if (vm.lmenu == 'a_home') {
            //     // 一级菜单缩进
            //     if (vm.home_page_shrink2) {
            //         isHide = !isHide;
            //     }
            //     if (vm.home_page_shrink) {
            //         if ($("#home-page").hasClass("wrap-mar-0-190")) {
            //             $("#home-page").removeClass("wrap-mar-0-190");
            //         } else {
            //             $("#home-page").addClass("wrap-mar-0-190");
            //         }
            //     } else {
            //         if ($("#home-page").hasClass("wrap-mar-0-110")) {
            //             $("#home-page").removeClass("wrap-mar-0-110");
            //         } else {
            //             $("#home-page").addClass("wrap-mar-0-110");
            //         }
            //     }
            // }

            $side2.removeClass('transition');
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
            isHide = !isHide;
            if(isHide){
                $(".sidebar2").addClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik1').addClass("btn2-shrik2");
                vm.home_page_shrink2 = true;
            }else {
                $(".sidebar2").removeClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik2').addClass("btn2-shrik1");
                vm.home_page_shrink2 = false;
            }

            // console.log(isHide);
            if ($navbar.hasClass("sidebar-collapse")) {
                // 一级缩进
                if (isHide) { // 二级缩进
                    $(".wrap").removeClass("wrap-mar160").removeClass("wrap-mar220").addClass("wrap-mar60");
                } else {
                    $(".wrap").toggleClass("wrap-mar160").toggleClass("wrap-mar60");
                }
                $navbar.removeClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
            } else {
                // 一级展开
                if ($(".wrap").hasClass("wrap-mar130")) {
                    $(".wrap").removeClass("wrap-mar130").toggleClass("wrap-mar220");
                } else {
                    $(".wrap").removeClass("wrap-mar220").toggleClass("wrap-mar130");
                }
                $navbar.removeClass("sidebar-collapse2").removeClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
            }
        }

        $scope.resetPassword = function () {
            createModal('app/account/password/password.html', 'PasswordController').result.then(function (data) {});
        };

        var m1 = $localStorage.menu1;
        var m2 = $localStorage.menu2;
        $(".titleNavbar").eq(m1).find($(".navbar-title")).css({background: '#313840'});
        $(".titleNavbar").eq(m1).find($(".menu1")).show().siblings().find($(".menu1")).hide();

        $(".titleNavbar").eq(m1).find($(".menu1")).find($(".menu2")).eq(m2).css({
            background: '#464c54',
            outline: 'none'
        }).find($(".menu2-text")).css({color: '#fff'});

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function passwords() {
            delete $localStorage.pageName;
            pageName = window.location.hash;
            arr = pageName.split('#')[1].substring(1);
            $localStorage.pageName = arr;
        }

        // 首页没有缩进
        function listNavBar(num1, num2) {
            if (num2 == -1) {
                vm.home_flag = true;
            } else {
                vm.home_flag = false;
            }
        }
        $scope.goModele = function () {
            var url = vm.platformSetting.productXmlUrl + '/platform/#/homeFirst?key=' + vm.platformSetting.productXmlKey + '&userId=' + vm.account.id + '&scope=' + vm.account.scope + '&phone=' + vm.account.phone + '&uuid=' + vm.account.uuid;
            window.open(url);
        }
    }
})();
