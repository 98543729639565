(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreThemeController', StoreThemeController);

    StoreThemeController.$inject = ['$scope', '$state', 'MessageService','$q','Store', '$stateParams'];

    function StoreThemeController ($scope, $state, MessageService, $q, Store, $stateParams) {
        var vm = this;
        vm.save = save;
        vm.store = Store.getStore({id:$stateParams.storeId});
        vm.selectStyle ='';

        $q.all([vm.store.$promise]).then(function(){
            if(vm.store.skin == '' || vm.store.skin == null || vm.store.skin == undefined){
                vm.selectStyle = 'one';
            }else{
                vm.selectStyle =vm.store.skin;
            }
        });

        $scope.themeSelectd = function(str){
            vm.selectStyle = str;
        };
        function save () {
            Store.updateSkin({id:$stateParams.storeId, skin:vm.selectStyle }, onSuccess, onError);
        }
        function onSuccess () {
            vm.isSaving = false;
            MessageService.success('保存成功！');
        }

        function onError () {
            vm.isSaving = false;
            MessageService.success('保存失败！')
        }

        $scope.preview =function(data){
            localStorage.setItem('flag',data);
            $state.go('store-theme-style-detail');
        };


    }
})();
