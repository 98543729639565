(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MessageNotificationShareController', MessageNotificationShareController);

    MessageNotificationShareController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'MessageService', '$uibModal',
    'Store', 'MessageNotification', 'ListService'];

    function MessageNotificationShareController($scope, $q, $rootScope, $stateParams, $uibModalInstance, entity, MessageService, $uibModal,
        Store, MessageNotification, ListService) {
        var vm = this;

        vm.product = entity;
        vm.clear = clear;
        vm.save = save;
        vm.stores = Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]});
        vm.messages = MessageNotification.getAllByCondition({items:[{key:"protoId",op:"=",value:$stateParams.id},{key: "type", op: "=", value: "Platform"}
            ]});
        $q.all([vm.stores.$promise, vm.messages]).then(function(){
            if (vm.stores && vm.stores.length) {
                for (var i = vm.stores.length - 1; i >= 0; i--) {
                    var store = vm.stores[i];
                    var result = ListService.inList2(store, vm.messages, ["id"], ["storeId"]);
                    if (result !=null) {
                        store.selected = true;
                    }
                }
            }
        })

        $scope.selectedStore = function (store) {
            store.selected = !store.selected;
        }

        function save(){
            var ids = [];
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var store = vm.stores[i];
                if (store.selected) {
                    ids.push(store.id);
                }
            }
            MessageNotification.shareMessage({id:$stateParams.id, storeIds:ids}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:messageNotificationUpdate', result);
            $uibModalInstance.close(result);
            MessageService.success("保存成功");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('platformApp:messageNotificationUpdate', function(event, result) {
            vm.product = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
