(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('GiveNoticeDialogController', GiveNoticeDialogController);

    GiveNoticeDialogController.$inject = ['$state', 'ProductPurchase', 'ParseLinks',  'paginationConstants', 'MessageService'
    ,'$scope', 'VersionDescriptions', 'entity','Qinius', '$q', 'UidService'];

    function GiveNoticeDialogController($state, ProductPurchase, ParseLinks,   paginationConstants, MessageService,
        $scope, VersionDescriptions, entity,Qinius,$q, UidService) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.data = entity || {};
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        angular.element("#content").html(vm.data.content);

        $q.all([vm.domains.$promise, vm.tokens.$promise,vm.data.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            messageEditor()
        });
        function messageEditor() {
            var E = window.wangEditor;
            var editor = new E('#messageEditor')
            editor.customConfig.onchange = function (html) {
                vm.data.content = html;
            };
            editor.customConfig.uploadImgServer = 'https://upload.qiniup.com/';
            editor.customConfig.uploadImgParams = {
                key: UidService.get(),
                token: vm.token,
                unique_names: false
            };
            editor.customConfig.uploadFileName = 'file';
            editor.customConfig.uploadImgHooks = {
                before: function (xhr, editor, files) {
                    editor.config.uploadImgParams.key= UidService.get();
                },
                customInsert: function (insertImg, result, editor) {
                    var url = $scope.domain + result.key;
                    insertImg(url);
                }
            };
            editor.customConfig.customAlert = function (info) {
                MessageService.error(info)
            };
            editor.create();
            editor.txt.html(vm.data.content);

        }
        function save() {
            if(!vm.data.seq){
                MessageService.error("请输入通知排序");
                return
            }
            if(!vm.data.title){
                MessageService.error("请输入通知标题");
                return
            }
            if(!vm.data.content){
                MessageService.error("请输入通知内容");
                return
            }
            if(vm.data.id){
                VersionDescriptions.update(vm.data, function (res) {
                    MessageService.success("编辑通知成功");
                    $state.go("giveNotice")
                }, function () {
                    MessageService.error("编辑通知失败，请稍后重试！");
                })
            }else {
                vm.data.enable = true;
                VersionDescriptions.save(vm.data, function (res) {
                    MessageService.success("新建通知成功");
                    $state.go("giveNotice")
                }, function () {
                    MessageService.error("新建通知失败，请稍后重试！");
                })
            }
        }
        function clear() {
            $state.go("giveNotice")
        }

    }
})();
