(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('CustomerWallets', CustomerWallets)
        .factory('OrderSetting', OrderSetting);

    CustomerWallets.$inject = ['$resource'];
    OrderSetting.$inject = ['$resource', 'DateUtils'];

    function CustomerWallets($resource) {
        var resourceUrl = 'manager/' + 'api/customer-wallets/:path/:id';
        return $resource(resourceUrl, {}, {
            "guideToWallet":{
                method: 'GET',
                params:{
                    path:"guideToWallet"
                }
            }
        });
    }

    function OrderSetting($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/timer-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'update': {
                method: 'PUT'
            },
            "updateStoreTimerSetting":{
                method: 'PUT',
                params:{
                    path:"updateStoreTimerSetting"
                }
            },
            "getStoreOrderSetting":{
                method: 'GET',
                params:{
                    path:"getStoreOrderSetting"
                }
            },
            "getOrderSetting":{
               method: 'GET',
                params:{
                    path:"getOrderSetting"
                } 
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'getOrderSetting'
                }
            }
        });
    }
})();