(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FilterPricingModalController', FilterPricingModalController);

    FilterPricingModalController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Category', 'PaginationUtil', 'PlatformWechatSetting'];

    function FilterPricingModalController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, MessageService, $uibModalInstance, Category, PaginationUtil, PlatformWechatSetting) {
        var vm = this;
        vm.pricing = angular.copy(entity.data);
        vm.save = save;
        vm.cancel = cancel;
        vm.pricingStrategy = [
            {name: '按次收费', id: 'TIME'},
        ];
         
        //添加价格
        $scope.addPriceLines = function () {
            var _obj = {
                times: '',
                price: '',
            }
            vm.pricing.filterPriceRules.push(_obj)
        }
        
        //确定
        function save() {
            if (vm.pricing.filterPriceRules && vm.pricing.filterPriceRules.length>0) {
                for (var i = vm.pricing.filterPriceRules.length - 1; i >= 0; i--) {
                    var rule = vm.pricing.filterPriceRules[i];
                    if (rule.times==null || rule.times=="" ) {
                        MessageService.error("请录入次数");
                        return;
                    }
                    if (rule.price==null || rule.price=="" ) {
                        MessageService.error("请录入价格");
                        return;
                    }
                }
            }
            $uibModalInstance.close(vm.pricing);
        }
        //取消
        function cancel() {
            $uibModalInstance.close();
        }

        //删除阶梯价格
        $scope.deletePriceLines = function (data, index) {
            if(!data.id){
                vm.pricing.filterPriceRules.splice(index, 1)
            }else {
                data.cancelled = true;
            }
        }
    }
})();
