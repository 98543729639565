(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PasswordActivityDetailController', PasswordActivityDetailController);

    PasswordActivityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PasswordActivity', 'Product', 'Brand'];

    function PasswordActivityDetailController($scope, $rootScope, $stateParams, previousState, entity, PasswordActivity, Product, Brand) {
        var vm = this;

        vm.passwordActivity = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('backApp:passwordActivityUpdate', function(event, result) {
            vm.passwordActivity = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
