(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayBatchController', PayBatchController);

    PayBatchController.$inject = ['$state', 'PayBatch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', '$scope'];

    function PayBatchController($state, PayBatch, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, $scope) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.tempArr = [];
        $scope.selectAll = false;
        $scope.batchArr = [];
        $scope.selectOne = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.payBatchs,function (payBatch,i) {
                if($scope.selectAll){
                    payBatch.select = true;
                    $scope.batchArr.push(payBatch.id);
                    $scope.selectOne = true;
                }else{
                    $scope.batchArr = [];
                    payBatch.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (payBatch) {
            payBatch.select = !payBatch.select;
            // 选中
            if(payBatch.select){
                $scope.batchArr.push(payBatch.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(payBatch.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.payBatchs,function (payBatch,i) {
                if(!payBatch.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.payBatchs.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("确认要删除选中的批次号？",function(){
                PayBatch.deleteBatch({ids:$scope.batchArr},function(data){
                    MessageService.success("删除成功");
                    loadAll();
                },function(){
                MessageService.error("删除失败");
                })
            },function(){});
        }
        // $scope.submit = function (id) {
        //     MessageService.confirm("确认提交该信息", function () {
        //         PayBatch.submit({id: id}, function () {
        //             loadAll();
        //         });
        //     }, function () {
        //     });
        // }


        $scope.approve = function (id) {
            MessageService.confirm("确认提交审核？", function () {
                PayBatch.approve({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        }


        // $scope.reject = function (id) {
        //     MessageService.confirm("确认拒绝该请求", function () {
        //         PayBatch.reject({id: id}, function () {
        //             loadAll();
        //         });
        //     }, function () {
        //     });
        // }



        $scope.delete = function (id) {
            MessageService.confirm("entity.action.deleteConfirm", function () {
                PayBatch.delete({id: id}, function () {
                    loadAll();
                });
            }, function () {
            }, true);
        }


        loadAll();

        function loadAll () {
            var items = [{key:"used", op:"=", value:'false'}]
            if (pagingParams.search) {
                items.push({key:"storeName,productName,supplier,number,startNumber",op:"like",value:pagingParams.search});
            }
            PayBatch.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.allPagesCount = Math.ceil(vm.totalItems/20);
                vm.queryCount = vm.totalItems;
                vm.payBatchs = data;
                angular.forEach(vm.payBatchs,function (batch) {
                    if(batch.productName){
                        batch.productNameList = batch.productName.split(";");
                    }
                    if(batch.productSku){
                        batch.productSkuList = batch.productSku.split(";");
                    }
                })
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据加载失败");
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;

            if(flag){
                flag = false;
                $(".paybatch-proName"+index).addClass('text-decoration-underline');
                $(".paybatch-tip"+index).show().css({left:e.clientX || 0,top:e.clientY || 0});
            }

        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
    }
})();
