(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MessagePriceDialogController', MessagePriceDialogController);

    MessagePriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MessagePrice', 'Store'];

    function MessagePriceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MessagePrice, Store) {
        var vm = this;

        vm.messagePrice = entity;
        vm.clear = clear;
        vm.save = save;
        vm.stores = [];
        vm.storesAll = {name: '全部', selected: false};
        vm.isStores = true;
        Store.getAllByCondition({
            items: [{key: "status", op: "=", value: "Approved"}]
        }, function (res) {
            for(var i=0; i<res.length; i++){
                if(!res[i].cancelled){
                    vm.stores.push(res[i])
                }
            }
            if(entity.id){
                if(vm.messagePrice.base){
                    vm.storesAll.selected = true;
                    vm.isStores = false;
                }else {
                    for(var i=0; i<vm.messagePrice.stores.length; i++){
                        for(var j=0; j<vm.stores.length; j++){
                            if(vm.messagePrice.stores[i].id === vm.stores[j].id){
                                vm.stores[j].selected = true;
                            }
                        }
                    }
                }
            }
        })

        //选择使用商家
        $scope.storesCheckbox = function(data){
            vm.messagePrice.stores = [];
            data.selected = !data.selected ? true : false;
            for(var i=0; i<vm.stores.length; i++){
                if(vm.stores[i].selected){
                    vm.messagePrice.stores.push(vm.stores[i])
                }
            }
            if(vm.messagePrice.stores.length > 0){
                vm.messagePrice.base = false;
            }
        };

        $scope.storesAllCheckbox = function(){
            vm.storesAll.selected = !vm.storesAll.selected ? true : false;
            if(!vm.storesAll.selected){
                vm.isStores = true;
                vm.messagePrice.base = false;
            }else {
                for(var j=0; j<vm.stores.length; j++){
                    vm.stores[j].selected = false;
                }
                vm.isStores = false;
                vm.messagePrice.stores = [];
                vm.messagePrice.base = true;
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.messagePrice.id !== null) {
                MessagePrice.update(vm.messagePrice, onSaveSuccess, onSaveError);
            } else {
                MessagePrice.save(vm.messagePrice, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:messagePriceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
