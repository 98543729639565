(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal', '$state', '$uibModalInstance' ,'$timeout'];

    function PasswordController (Auth, Principal, $state, $uibModalInstance, $timeout) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                var user = {
                    name:vm.account.name,
                    password:vm.password
                };
                vm.doNotMatch = null;
                Auth.changePassword(user).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                    $timeout(function(){
                        $uibModalInstance.close();
                        $state.go('home');
                    },1000);

                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();
