(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('StoragePrice', StoragePrice);

    StoragePrice.$inject = ['$resource'];

    function StoragePrice ($resource) {
        var resourceUrl =  'manager/' + 'api/storage-prices/:path/:id';

        return $resource(resourceUrl, {}, {
            'enable': {
                method: 'GET',
                params: {
                    path: 'enable'
                }
            },
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                },
                isArray: true,
            },
            "getAllByCondition1":{
                method:"POST",
                params:{
                    path:"getAllByCondition1"
                },
                isArray: true,
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true,
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true,
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': { method:'PUT' },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            },
        });
    }
})();
