(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('ThemeClassifyController', ThemeClassifyController);

    ThemeClassifyController.$inject = ['$state', '$scope', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', 'ThemeClassify', '$uibModal'];

    function ThemeClassifyController($state, $scope, ParseLinks, MessageService, paginationConstants, pagingParams, ThemeClassify, $uibModal) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.jump = jump;
        vm.clear = clear;
        vm.search = search;
        vm.classType = pagingParams.classType || 'XML';
        vm.classList = [
            {valur: '模板分类', type: 'XML'},
            {valur: '背景分类', type: 'BG'},
            {valur: '蒙版分类', type: 'MASK'},
            {valur: '素材分类', type: 'CLIPART'},
            {valur: '文字模板分类', type: 'FONT'},
            {valur: '边框分类', type: 'BORDER'}
        ];

        loadAll();
        $scope.delete = function (id) {
            MessageService.confirm("确认要删除该数据么？", function () {
                ThemeClassify.del({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        };

        $scope.changeStatue = function (id) {
            ThemeClassify.changeStatue({id: id}, function () {
                loadAll();
            });
        }

        function loadAll() {
            if (pagingParams.search) {
                ThemeClassify.byCondition({
                    items: [
                        {key: "name", op: "like", value: pagingParams.search},
                        {key: "storeId", op: "=", value: "null"},
                        {key: "type", op: "=", value: vm.classType}
                     ],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ThemeClassify.byCondition({
                    items: [
                        {key: "storeId", op: "=", value: "null"},
                        {key: "type", op: "=", value: vm.classType}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.themeClassifyList = data;
                console.log(data);
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("数据加载出错");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $(".navbar").show();
        $(".wrap").show().css({'margin': 'margin: 48px 0 0 130px;'});

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                classType: vm.classType
            });
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            console.log(vm.page);
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }


        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.addClass = function () {

            $uibModal.open({
                templateUrl: 'app/entities/theme-classify/theme-classify-detail.html',
                controller: 'ThemeClassifyDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'add',
                            classIfyType: vm.classType
                        };
                    }]
                }
            }).result.then(function(item) {
                if(item){
                    loadAll()
                }
            });
        }
        $scope.editClass = function (data) {

            $uibModal.open({
                templateUrl: 'app/entities/theme-classify/theme-classify-detail.html',
                controller: 'ThemeClassifyDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'edit',
                            data: data
                        };
                    }]
                }
            }).result.then(function(item) {
                if(item){
                    loadAll()
                }
            });
        }
        $scope.classCb = function (item) {
            vm.classType = item.type;
            vm.transition();
            loadAll()
        }
    }
})();
