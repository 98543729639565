(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('CommandController', CommandController);

    CommandController.$inject = ['$state', 'Command', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$stateParams', 'Qinius', '$scope', '$q', 'MessageService', 'DateUtils'];

    function CommandController($state, Command, ParseLinks, AlertService, paginationConstants, pagingParams, $stateParams, Qinius, $scope, $q, MessageService, DateUtils) {

        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.storeId = $stateParams.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.domains = Qinius.getDomain();
        var acId = $stateParams.acId;
        $scope.filterCon = $stateParams.filterCon||'all';

        $q.all([vm.domains.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })


        function loadAll() {
            vm.items = [{key: "passwordActivityId", op: "=", value: acId}, {key:"storeId", op:"=", value: vm.storeId}];
            if (pagingParams.search) {
                vm.items.push({key: "word", op: "like", value: pagingParams.search});
            }
            $(".command-nav").removeClass('nav-active')
            if ($scope.filterCon == 'all') {
                Command.byCondition({
                    items: vm.items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
                $(".command-nav-l").addClass('nav-active');
                $(".command-nav-c").css({'border-left': 'none'});
                $(".command-nav-r").css({'border-left': '1px solid #d9d9d9'});
            }

            if ($scope.filterCon == '1') {
                Command.byCondition3({
                    items: vm.items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
                $(".command-nav-c").addClass('nav-active');
                $(".command-nav-c").css({'border-left': '1px solid #FF5001'});
                $(".command-nav-r").css({'border-left': 'none'});
            }

            if ($scope.filterCon == '0') {
                Command.byCondition2({
                    items: vm.items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

                $(".command-nav-r").addClass('nav-active');
                $(".command-nav-c").css({'border-left': '1px solid #d9d9d9'});
                $(".command-nav-r").css({'border-left': '1px solid #FF5001'});
            }
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.commands = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                acId: acId,
                filterCon: $scope.filterCon,
                storeId:vm.storeId
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.showCondition = function (attr) {
            $scope.filterCon = attr;
            vm.page = 1;
            transition();
        }

        $scope.delete = function (id) {
            MessageService.confirm("确认要作废该信息吗", function () {
                Command.delete({id: id}, function () {
                    loadAll();
                });
            }, function () {
            }, true);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.commands, function (command, i) {
                if ($scope.selectAll) {
                    command.select = true;
                    $scope.batchArr.push(command.id);
                    $scope.selectOne = true;
                } else {
                    command.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (command) {
            command.select = !command.select;
            // 选中
            if (command.select) {
                $scope.batchArr.push(command.id);
            } else {
                // 未选中
                var ind = $scope.batchArr.indexOf(command.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.commands, function (command, i) {
                if (!command.select) {
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if (i == vm.commands.length - 1 && flag) {
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if ($scope.batchArr.length) {
                $scope.selectOne = true;
            } else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("entity.action.deleteConfirm", function () {
                Command.deleteBatch({ids: $scope.batchArr}, function () {
                    MessageService.success("作废成功");
                    $scope.selectOne = false;
                    $scope.selectAll = false;
                    vm.commands = [];
                    loadAll();
                }, function () {
                    MessageService.error("作废失败");
                })
            }, function () {
            }, true);
        }
    }
})();
