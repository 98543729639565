(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformSmallAppSettingDetail2Controller', PlatformSmallAppSettingDetail2Controller);

    PlatformSmallAppSettingDetail2Controller.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState',
    'entity', 'PlatformWechatSetting', 'WechatAuth2', 'MessageService', "Store", 'Upload', 'StoreAliSettings'];

    function PlatformSmallAppSettingDetail2Controller($scope, $rootScope, $stateParams, previousState,
        entity, PlatformWechatSetting, WechatAuth2, MessageService, Store, Upload, StoreAliSettings) {
        var vm = this;

        vm.templates = entity.template_list;
        $scope.storeShow = false;
        $scope.versionShow = false;
 
        vm.stores = Store.findByHasAliSmallAll(); 

        $scope.selectStore = function(store){
            if (store == null) {
                return;
            }
            if (store.id == vm.storeId) {
                vm.storeId = null;
            }else{
                vm.storeId = store.id;
            }
        }

        window.updateFile = function ($event) {
            vm.file = $event.target.files[0];
            $(".choose-file").val(vm.file.name);
        };

        
        $scope.upload = function(){
            if (vm.storeId == null || vm.storeId =="") {
                MessageService.error("请选择想要上传小程序的商铺");
                return;
            }
            if (vm.templateId == null|| vm.templateId =="") {
                MessageService.error("请录入想要上传小程序的模板id");
                return;
            }
            if (vm.appVersion == null|| vm.appVersion =="") {
                MessageService.error("请录入想要上传小程序的小程序版本");
                return;
            }
            if (vm.templateVersion == null|| vm.templateVersion =="") {
                MessageService.error("请录入想要上传小程序的模板版本");
                return;
            }
            MessageService.confirm("确认要提交该版本代码？", function(){
                Upload.upload({
                    type: 'POST',
                    url: 'manager/api/store-ali-settings/miniVersionUpload', //上传路径
                    data: {
                        storeId: vm.storeId,
                        temId: vm.templateId,
                        appVersion: vm.appVersion,
                        temVersion: vm.templateVersion,
                        extFile: vm.file,
                        type:vm.type
                    }
                }).then(function successCallback(data) {
                    if (data.data.success && data.data.code == "10000") {
                        MessageService.success("提交成功， 小程序进入审核阶段");
                    }else{
                        MessageService.error("提交失败，错误编号："+ data.data.errorCode+",错误信息："+data.data.subMsg);
                    }
                }, function errorCallback(response) {
                   MessageService.error("提交失败");
                });
                }, function(){
            });
        };

        $scope.auditedCancel = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要删除的商铺");
                return;
            }
            StoreAliSettings.auditedCancel({storeId:vm.storeId},function(data){
                if (data.success && data.code == "10000") {
                    MessageService.success("小程序删除功");
                }else{
                    MessageService.error("小程序删除失败，错误编号："+ data.errorCode+","+data.msg);
                }  
                 
            },function(){});
               
        }

        $scope.submit = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要提交的商铺");
                return;
            }
            StoreAliSettings.miniVersionAuditApply({storeId:vm.storeId},function(data){
                if (data.success && data.code == "10000") {
                    MessageService.success("小程序提交成功");
                }else{
                    MessageService.error("小程序提交失败，错误编号："+ data.errorCode+","+data.msg);
                }  
                 
            },function(){});
               
        }

        $scope.grayRelease = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要灰度发布的商铺");
                return;
            }

            if (vm.grayStrategy == null || vm.grayStrategy == "") {
                MessageService.error("请选怎灰度值");
                return;
            }
            StoreAliSettings.miniVersionGrayOnline({storeId:vm.storeId, grayStrategy: vm.grayStrategy},function(data){
                if (data.success && data.code == "10000") {
                    MessageService.success("小程序灰度发布成功");
                }else{
                    MessageService.error("小程序灰度发布失败，错误编号："+ data.data.errorCode+","+data.data.msg);
                }  
                 
            },function(){});
               
        }

        $scope.grayCancel = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要灰度结束的商铺");
                return;
            }
            StoreAliSettings.grayCancel({storeId:vm.storeId},function(data){
                if (data.success && data.code == "10000") {
                    MessageService.success("小程序灰度结束成功");
                }else{
                    MessageService.error("小程序灰度结束失败，错误编号："+ data.data.errorCode+","+data.data.msg);
                }  
                 
            },function(){});
               
        }
        

        $scope.searchSubmit = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要查询审核的商铺");
                return;
            }
            StoreAliSettings.detailQuery({storeId:vm.storeId},function(data){
                if (data.success && data.code == "10000") {
                    if (data.status =='INIT') {
                         MessageService.success("开发中");
                    }
                    if (data.status =='AUDITING') {
                         MessageService.success("审核中");
                    }
                    if (data.status =='WAIT_RELEASE') {
                         MessageService.success("审核通过");
                    }
                    if (data.status =='AUDIT_REJECT') {
                         MessageService.success("审核驳回");
                    }
                    if (data.status =='RELEASE') {
                         MessageService.success("已上架");
                    }
                    if (data.status =='GRAY') {
                         MessageService.success("灰度中");
                    }
                    if (data.status =='OFFLINE') {
                         MessageService.success("下架");
                    }
                }else{
                    MessageService.error("小程序构建失败，错误编号："+ data.data.errcode+","+data.data.msg);
                }  
                 
            },function(){});
               
        }

        $scope.searchBuild = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要查询小程序构建状态的商铺");
                return;
            }
            StoreAliSettings.buildQuery({storeId:vm.storeId},function(data){
                if (data.success && data.code == "10000") {
                    MessageService.success("小程序构建成功");
                }else{
                    MessageService.error("小程序构建失败，错误编号："+ data.errcode+","+data.msg);
                }  
                 
            },function(){});
        }

        $scope.release = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要发布小程序的商铺");
                return;
            }
            StoreAliSettings.miniVersionOnline({storeId:vm.storeId},function(data){
                if(data.code == "10000"){
                    MessageService.success("发布成功");
                }else{
                    MessageService.error("小程序发布失败,失败原因："+data.subMsg); 
                }
                 
            },function(){});
        }


        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('platformApp:platformWechatSettingUpdate', function(event, result) {
            vm.platformWechatSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.appDomains = null;
        $scope.getSmallAppDomain = function () {
            if(vm.storeId==null){
                MessageService.error("请选择想要查询的商铺");
                return
            }
            vm.appDomains = WechatAuth2.getDomain({storeId:vm.storeId});
        }
    }
})();
