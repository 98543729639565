(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RechargeSettingDialogController', RechargeSettingDialogController);

    RechargeSettingDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RechargeSetting', 'MessageService'];

    function RechargeSettingDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, RechargeSetting, MessageService) {
        var vm = this;

        vm.rechargeSetting = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.validate = function(){
            if(vm.rechargeSetting.number == null || vm.rechargeSetting.number == ""){
                MessageService.error("请输入充值时间");
                return false;
            }
            if(vm.rechargeSetting.price == null || vm.rechargeSetting.price == ""){
                MessageService.error("请输入充值金额");
                return false;
            }
            return true;
        };

        function save () {
            if(!$scope.validate()){
                return
            }
            vm.isSaving = true;
            vm.rechargeSetting.cancelled = false;
            if (vm.rechargeSetting.id !== null) {
                RechargeSetting.update(vm.rechargeSetting, onSaveSuccess, onSaveError);
            } else {
                RechargeSetting.save(vm.rechargeSetting, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:rechargeSettingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
