(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('InvoiceShowController', InvoiceShowController);

    InvoiceShowController.$inject = ['$q', '$state','$scope',  'entity', 'MessageService', 'Invoice'];

    function InvoiceShowController($q, $state, $scope,   entity, MessageService, Invoice) {

        var vm = this;
        vm.invoice = entity;
        vm.clear = clear;
        $q.all([  vm.invoice.$promise]).then(function(){

        });
        function clear () {
            $state.go("invoice");
        }
    }
})();
