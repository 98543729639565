(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StorePaymentSettingDialogController', StorePaymentSettingDialogController);

    StorePaymentSettingDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'StoreWechatSetting',
    'StoreSmallAppWechatSetting', 'StorePaymentSetting'];

    function StorePaymentSettingDialogController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams, StoreWechatSetting,
        StoreSmallAppWechatSetting, StorePaymentSetting) {
        var vm = this;

        vm.store = entity;
        //代运营 保存
        vm.save2 = save2;
        vm.clear2 = clear2;
        vm.wechatSetting = null;
        vm.miniSetting = null;
        vm.storePaymentSetting = StorePaymentSetting.getCurrent({storeId:$stateParams.storeId});
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        $q.all([vm.store.$promise]).then(function(){
            if (vm.store.payType) {
                if (vm.store.payType.indexOf("paycode")>= 0 ) {
                    vm.store.paycode = true;
                }
                if (vm.store.payType.indexOf("wechat")>= 0 ) {
                    vm.store.wechat = true;
                }
                if (vm.store.payType.indexOf("underLine")>= 0 ) {
                    vm.store.underLine = true;
                }
            }
            if (vm.store.storeType2) {
                if (vm.store.storeType2.indexOf("WECHAT" )>=0) {
                    vm.store.storeTypeWechat = true;
                }
                if (vm.store.storeType2.indexOf("MINI" )>=0) {
                    vm.store.storeTypeMini = true;
                }
                if (vm.store.storeType2.indexOf("THIRD" )>=0) {
                    vm.store.storeTypeThird = true;
                }
            }

            vm.miniSetting = StoreSmallAppWechatSetting.getEntityByStoreId({storeId:vm.store.id});
            vm.wechatSetting = StoreWechatSetting.getByStoreId({storeId:vm.store.id});
            $q.all([vm.miniSetting.$promise , vm.wechatSetting.$promise]).then(function(){
                vm.store.wechatStore = vm.wechatSetting.weChatMchid;
                vm.store.wechatKey = vm.wechatSetting.weChatKey;
                vm.store.wechatStore2 = vm.miniSetting.weChatMchid;
                vm.store.wechatKey2 = vm.miniSetting.weChatKey;
            });

        });


        function clear2 (){
            $state.go("store-payment-setting", {storeId:vm.store.id});
        }


        function save2 () {
            vm.store.payType = "";
            if (vm.store.paycode) {
                vm.store.payType += "paycode;"
            }
            if (vm.store.underLine) {
                vm.store.payType += "underLine;"
            }
            if (vm.store.wechat) {
                vm.store.payType += "wechat;"
            }

            vm.isSaving = true;
            if (vm.store.storeTypeWechat) {
                vm.wechatSetting.weChatMchid = vm.store.wechatStore;
                vm.wechatSetting.weChatKey = vm.store.wechatKey;
                StoreWechatSetting.update(vm.wechatSetting, function(data){

                }, function(data){
                    MessageService.error("公众号保存微信商户失败！");
                })
            }

            if (vm.store.storeTypeMini) {
                vm.miniSetting.weChatMchid = vm.store.wechatStore2;
                vm.miniSetting.weChatKey = vm.store.wechatKey2;
                StoreSmallAppWechatSetting.update(vm.miniSetting, function(data){
                }, function(data){
                    MessageService.error("小程序保存微信商户失败！");
                })
            }

            if (vm.storePaymentSetting.storeId == null) {
                vm.storePaymentSetting.storeId = $stateParams.storeId;
            }
            StorePaymentSetting.createByAgent(vm.storePaymentSetting , function(data){
                MessageService.success("web端支付信息保存成功");
            },function(){
                MessageService.success("web端支付信息保存失败");
            })

            Store.update(vm.store , onSaveSuccess2, onSaveError);
        }

        function onSaveSuccess2 (result) {
            $scope.$emit('platformApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");

            $state.go("store-payment-setting", {storeId: vm.store.id});
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }
    }
})();
