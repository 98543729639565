(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PaycodeAnalysisController', PaycodeAnalysisController);

    PaycodeAnalysisController.$inject = ['$scope', '$state', 'ParseLinks', 'MessageService', 'PayCode', 'Store', 'PayBatch'];

    function PaycodeAnalysisController ($scope, $state, ParseLinks, MessageService, PayCode, Store, PayBatch) {
        var vm = this; 
        vm.downLoadFile = downLoadFile;  

        vm.paybatchs2 = PayBatch.getAllByCondition({items:[{key:"used", op:"=", value:"true"}]});

        $scope.payBatchChange = function(){
            if (vm.payCode.payBatchId == null) {
                vm.batchs = null;
                vm.storeId = null;
            }else{
                if (vm.paybatchs2!= null && vm.paybatchs2.length!=0) {
                    for (var i = vm.paybatchs2.length - 1; i >= 0; i--) {
                        var p = vm.paybatchs2[i];
                        if (p.id == vm.payCode.payBatchId) {
                            vm.storeId = p.storeId;
                            vm.batchs = p.number;
                            return;
                        }
                    }
                }
            }
        }

        $scope.search = function(){
            // if(vm.storeId== null ||  vm.storeId == ""){
            //     MessageService.error("请选择商户");
            //     return;
            // }

            if(vm.batchs== null ||  vm.batchs == ""){
                MessageService.error("请选择批次号");
                return;
            }
            var batchArr = vm.batchs.split(";")
            PayCode.payCodeAnalysis({storeId:vm.storeId, batches:vm.batchs},function(data){
                vm.totalCount = data.totalCount;
                vm.totalActivatedCount = data.totalActivatedCount;
                vm.totalUsedCount = data.totalUsedCount;
                vm.result = [];
                for (var i = batchArr.length - 1; i >= 0; i--) {
                    var b = batchArr[i];
                    var value = {
                        batch:b,
                        totalCount:data[b].totalCount,
                        totalActivatedCount: data[b].totalActivatedCount,
                        totalUsedCount:data[b].totalUsedCount
                    }
                    vm.result.push(value);
                }
            },function(){
                MessageService.error("获取数据失败");
            })
        }
 
 
        function downLoadFile(){ 
            if(vm.storeId== null ||  vm.storeId == ""){
                MessageService.error("请选择商户");
                return;
            }

            if(vm.batchs== null ||  vm.batchs == ""){
                MessageService.error("请录入批次号");
                return;
            }
            window.open("service/api/pay-codes/payCodeAnalysisDownload?storeId=" + vm.storeId + "&batches="+ vm.batchs);
        }
  
    }
})();
