(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('LocationProvice', LocationProvice)
        .factory('LocationCity', LocationCity)
        .factory('LocationDistrict', LocationDistrict)
        .factory('ExpressLocation', ExpressLocation);

    LocationProvice.$inject = ['$resource']

    LocationCity.$inject = ['$resource']

    LocationDistrict.$inject = ['$resource']

    ExpressLocation.$inject = ['$resource', 'DateUtils'];

    function LocationProvice($resource) {
        var resourceUrl = 'manager/' + 'api/location-provinces/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                },
            },
            "other4Region":{
                method:"GET",
                params:{
                    path:"other4Region"
                },
            },
            "other4Store":{
                method:"GET",
                params:{
                    path:"other4Store"
                },
                isArray: true
            }
        });
    }

    function LocationCity($resource) {
        var resourceUrl = 'manager/' + 'api/location-cities/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByProvince":{
                method:"GET",
                params:{
                    path:"findByProvince"
                },
                isArray: true
            },
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                },
            }
        });
    }

    function LocationDistrict($resource) {
        var resourceUrl = 'manager/' + 'api/location-districts/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByCities":{
                method:"GET",
                params:{
                    path:"findByCities"
                },
                isArray: true
            },
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                },
            }

        });
    }

    function ExpressLocation($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/expressLocation/:path/:id';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'getOne': {
                method: 'GET',
                params: {
                    path: 'getExpressLocation'
                }
            },
            'getLocation': {
                method: 'GET',
                params: {
                    path: "all"
                },
            },
            'expressCode': {
                method: 'POST',
                params: {
                    path: 'code'
                }
            },
            'del': {
                method: 'DELETE'
            },
            'changeStatus': {
                method: 'GET',
                params: {
                    path: 'changeStatus'
                }
            }

        });
    }
})();