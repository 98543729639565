(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FeedbackDetailController', FeedbackDetailController);

    FeedbackDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Feedback'];

    function FeedbackDetailController($scope, $rootScope, $stateParams, $uibModalInstance, entity, Feedback) {
        var vm = this;

        vm.feedback = entity;

        vm.feedback.feedbackLines.sort(function(a, b){
            return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
        });



        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }



        //文件下载
        $scope.downloadZipFile3 = function(attachment){
            var storeId = JSON.parse(localStorage.getItem('platform-user')).roleId;
            window.open("fileserves/api/download/downloadZipFile3?key="+attachment.identifier+'&name='+attachment.fileName+'&storeId='+storeId);
        };


    }
})();
