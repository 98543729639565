(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformRegionDialogController', PlatformRegionDialogController);

    PlatformRegionDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'entity', 'PlatformRegion', 'Province', 'MessageService', 'Store', 
    '$stateParams', 'LocationProvice', '$uibModal'];

    function PlatformRegionDialogController($timeout, $scope, $q, $state, entity, PlatformRegion, Province, MessageService, Store,
     $stateParams, LocationProvice, $uibModal) {
        var vm = this;

        vm.regionResult = entity;
        vm.cancel = cancel;
        vm.save = save;
        vm.provices = [];
        vm.billingRuleList = [
            {name: '按件计费', id: 'COSTBYPIECE'}
        ]


        function cancel() {
            $state.go("platform-region");
        }

        //选择使用商家
        $scope.storesCheckbox = function(data){
            vm.region.stores = [];
            data.selected = !data.selected ? true : false;
            for(var i=0; i<vm.stores.length; i++){
                if(vm.stores[i].selected){
                    vm.region.stores.push(vm.stores[i])
                }
            }
            if(vm.region.stores.length > 0){
                vm.region.base = false;
            }
        };


        $scope.storesAllCheckbox = function(){
            vm.region.base = !vm.region.base;
            if(vm.region.base){ 
                vm.region.stores= [];
            }else {
                for(var j=0; j<vm.stores.length; j++){
                    vm.stores[j].selected = false;
                }
                vm.region.stores = [];
            }
        };
        vm.stores =Store.getAllByCondition({ items: [{key: "status", op: "=", value: "Approved"}] });
        $q.all([vm.regionResult.$promise, vm.stores.$promise]).then(function () {
            if (vm.regionResult.status == 200) {
                vm.region = vm.regionResult.message;
            }else{
                MessageService.error("信息加载失败");
                return;
            }

            if(vm.region.id){
                if(vm.region.base){
                }else {
                    for(var i=0; i<vm.region.stores.length; i++){
                        for(var j=0; j<vm.stores.length; j++){
                            if(vm.region.stores[i].id === vm.stores[j].id){
                                vm.stores[j].selected = true;
                            }
                        }
                    }
                }
            }
            LocationProvice.other4Region({regionId:vm.region.id},function(data){
                if (data.status == 200) {
                    vm.provices =data.message;
                }
            });
           
            deliveryAreaInfo();
        });

        $scope.address = function () {
            $uibModal.open({
                templateUrl: 'app/entities/platform-region/address-modal.html',
                controller: 'AddressModal1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return {
                            deliveryAreas: vm.region.platformDeliveryAreas,
                            provices: vm.provices
                        }
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    vm.region.platformDeliveryAreas = backData;
                    deliveryAreaInfo()
                }
            });
        }
        function deliveryAreaInfo(){
            vm.platformDeliveryAreas=[];
            vm.region.platformDeliveryAreas.forEach(function (item) {
                var isProvince = false;
                for(var i=0; i<vm.platformDeliveryAreas.length; i++){
                    if(vm.platformDeliveryAreas[i].id == item.provinceId){
                        isProvince = true;
                        break
                    }
                }
                if(!isProvince){
                    vm.platformDeliveryAreas.push({
                        id: item.provinceId,
                        name: item.provinceName,
                        citys:[]
                    })
                }
            })
            vm.region.platformDeliveryAreas.forEach(function (item) {
                vm.platformDeliveryAreas.forEach(function (deliveryArea) {
                    if(deliveryArea.id == item.provinceId){
                        var isCity = false;
                        for(var i=0; i<deliveryArea.citys.length; i++){
                            if(deliveryArea.citys[i].id == item.cityId){
                                isCity = true;
                                break
                            }
                        }
                        if(!isCity){
                            deliveryArea.citys.push({
                                id: item.cityId,
                                name: item.cityName,
                                districts:[]
                            })
                        }
                    }
                })
            })
            vm.region.platformDeliveryAreas.forEach(function (item) {
                vm.platformDeliveryAreas.forEach(function (deliveryArea) {
                    if(deliveryArea.id == item.provinceId){
                        deliveryArea.citys.forEach(function (city) {
                            if(city.id == item.cityId){
                                var isDistrict = false;
                                for(var i=0; i<city.districts.length; i++){
                                    if(city.districts[i].id == item.districtId){
                                        isDistrict = true;
                                        break
                                    }
                                }
                                if(!isDistrict){
                                    city.districts.push({
                                        id: item.districtId,
                                        name: item.districtName
                                    })
                                }
                            }
                        })
                    }
                })
            })
            console.log(vm.platformDeliveryAreas)
        }

        $scope.validate = function (data) {
            var regular = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
            var integer = /^[1-9]\d*$/;
            if (data.name == null || data.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            if (data.seq == null || data.seq == "") {
                MessageService.error("请录入优先级");
                return false;
            }
            if (data.billingRule == null || data.billingRule == ""&&data.billingRule!=0) {
                MessageService.error("请选择计费规则");
                return false;
            }else {
                if (!integer.test(data.pieceQuantity)) {
                    MessageService.error("计费方式的件数必须大于0的正整数");
                    return false;
                }
                if (!regular.test(data.pieceFreight)) {
                    MessageService.error("计费方式的邮费不能为负数");
                    return false;
                }
                if (!integer.test(data.pieceAddQuantity)) {
                    MessageService.error("计费方式的增加件数必须大于0的正整数");
                    return false;
                }
                if (!regular.test(data.pieceAddFreight)) {
                    MessageService.error("计费方式的增加运费不能为负数");
                    return false;
                }
                if(data.fullReduceAble && !data.fullReduceQuantity){
                    MessageService.error("请输入满免条件");
                    return false;
                }
            }
            if (data.platformDeliveryAreas == null || data.platformDeliveryAreas.length == 0) {
                MessageService.error("请勾选区域");
                return false;
            }
            return true;
        };


        function save() {
            if (!$scope.validate(vm.region)) {
                return;
            }
            vm.isSaving = true;
            if (vm.region.id !== null && vm.region.id != 0) {
                PlatformRegion.update(vm.region, onSaveSuccess, onSaveError);
            } else {
                PlatformRegion.save(vm.region, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            $state.go("platform-region");
            MessageService.success("信息更新成功");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
