(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ExpressDialogController', ExpressDialogController);

    ExpressDialogController.$inject = ['$q', '$state', '$scope', 'MessageService', 'entity', 'ExpressLocation', '$uibModalInstance', 'LocationProvice', 'LocationCity', 'LocationDistrict'];

    function ExpressDialogController($q, $state, $scope, MessageService, entity, ExpressLocation, $uibModalInstance, LocationProvice, LocationCity, LocationDistrict) {

        var vm = this;
        vm.clear = clear;
        vm.save = save;

        vm.express = entity;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        // 关闭弹出
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.toPostData = function() {
            var postData = {};
            postData.id = vm.express.id;
            postData.consignor = vm.express.consignor;
            postData.phone = vm.express.phone;
            postData.province = vm.province == null ? '' : vm.province.name;
            postData.city = vm.city == null ? '' : vm.city.name;
            postData.district = vm.district == null ? '' : vm.district.name;
            postData.address = vm.express.address;
            postData.postCode = vm.express.postCode;
            // postData.expressName = vm.express.expressName;
            // postData.expressPassword = vm.express.expressPassword;
            return postData;
        }

        $scope.validate = function(data) {
            if (data.consignor == null || data.consignor == "") {
                MessageService.error("请输入寄件人名称");
                return false;
            };

            if (data.phone == null || data.phone == "") {
                MessageService.error("请输入寄件人联系方式");
                return false;
            };

            // if(!(/^(0|86|17951)?(13[0-9]|15[012356789]|17[3678]|18[0-9]|14[57]|166|198|199)[0-9]{8}$/.test(data.phone))
            //     &&!(/0\d{2}-\d{7,8}/.test(data.phone))){
            //      MessageService.error("联系方式码非法");
            //     return false;
            // }

            if (data.province == null || data.province == "") {
                MessageService.error("请输入寄件人省份");
                return false;
            };

            if (data.city == null || data.city == '') {
                MessageService.error('请输入寄件人城市');
                return false;
            }

            if (data.district == null || data.district == "") {
                MessageService.error("请输入寄件人区县");
                return false;
            };

            if (data.address == null || data.address == "") {
                MessageService.error("请输入寄件人地址");
                return false;
            };
            return true;
        }

        function save() {
            var data = $scope.toPostData();
            if (!$scope.validate(data)) {
                return;
            };
            vm.isSaving = true;
            if (vm.express.id != null) {
                ExpressLocation.update(data, onSaveSuccess, onSaveError);
            } else {
                ExpressLocation.save(data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            MessageService.success("操作成功");
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            MessageService.error("操作失败");
            vm.isSaving = false;
        }

        loadData();
        function loadData() {
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == vm.express.province) {
                        vm.province =  p;
                        break;
                    }
                }
                if (!!vm.express.id) {
                    vm.districts = [];
                    LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                        vm.citys = data;
                        for (var i = vm.citys.length - 1; i >= 0; i--) {
                            var c = vm.citys[i];
                            if (c.name == vm.express.city) {
                                vm.city = c;
                                break;
                            }
                        }
                        if(vm.city != null ){
                            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                                vm.districts = data;
                                for (var i = vm.districts.length - 1; i >= 0; i--) {
                                    var di = vm.districts[i];
                                    if (di.name == vm.express.district) {
                                        vm.district = di;
                                    }
                                }
                            });
                        }
                    });
                } 
            });
            
        }

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }
    }
})();
