(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PageMySuffixSave2Controller', PageMySuffixSave2Controller);
    PageMySuffixSave2Controller.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService', '$stateParams', 'ProductXmlFileServes',
    'ThemeClassify', 'Store', 'xmlId', 'PlatformWechatSetting', 'pageIndex','Qinius','$q',"UidService", '$uibModal'];

    function PageMySuffixSave2Controller( $scope, Category, $uibModalInstance, MessageService, $stateParams, ProductXmlFileServes,
        ThemeClassify, Store, xmlId, PlatformWechatSetting, pageIndex,Qinius,$q,UidService, $uibModal) {
        //编辑第三方的模板 弹出窗口

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.id = null;
        vm.themeClassifyList = ThemeClassify.getAllByCondition({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: "XML"}]});
        vm.stores = Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]});
        vm.platformSetting = PlatformWechatSetting.byPlatFrom();
        vm.modelData = [];
        vm.domains = Qinius.getDomain();
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        $q.all([vm.domains.$promise]).then(function () {
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        });

        if(xmlId){
            vm.id = xmlId;
            ProductXmlFileServes.get({id: vm.id}, function (resp) {
                vm.productXml = resp;
                if(!vm.productXml.themeClassifies){
                    vm.productXml.themeClassifies = [];
                }
                if (resp.document) {
                    vm.productXml.replace = resp.document.replace;
                }
                if(vm.productXml.modelType == 'THREE' && vm.productXml.modelData){
                    vm.modelData = vm.productXml.modelData;
                    if(!vm.productXml.modelBlendent){
                        vm.productXml.modelBlendent = [];
                    }
                }
                vm.page = [];
                for(var i=0; i<vm.productXml.document.pages.page.length; i++){
                    vm.page.push({
                        index:i,
                        name: '第'+i+'页'
                    })
                }
            });
        }

        vm.categories = Category.getAllByCondition({items: []});

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.clearGltfInfo = function(xml){
            xml.modelType = 'TWO';
            xml.modelPath = null;
            xml.modelData = null;
            xml.modelBlendent = [];
            vm.modelData = [];

        }

        //配色管理
        $scope.modelBlendentAdd = function () {
            if(!vm.productXml.modelBlendent){
                vm.productXml.modelBlendent = [];
            }
            vm.productXml.modelBlendent.push({
                name:'',
                uuid:getUuid(16, 16),
                cancelled: false,
                modelData:angular.copy(vm.modelData)
            })
            console.log(vm.productXml.modelBlendent)
        }
        $scope.modelBlendentSet = function (data) {
            vm.modelData.forEach(function (modelData2){
                data.modelData.forEach(function (modelData1){
                    if(modelData1.materialName == modelData2.materialName){
                        modelData1.page = modelData2.page;
                        if(modelData1.page>0 || modelData1.page === 0){
                            modelData1.color = null;
                        }
                    }
                })
            })
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/blendent-modal.html',
                controller: 'BlendentModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            modelData: data.modelData,
                            modelPath: vm.productXml.modelPath
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    data.modelData = res;
                }
            });
        }
        $scope.removeModelBlendent = function (index) {
            vm.productXml.modelBlendent.splice(index, 1)
        }
        $scope.customStoreChange = function(){
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i];
                if (s.id == vm.productXml.customStoreId) {
                    vm.productXml.customStoreName = s.name;
                    break;
                }
            }
        }

        $scope.validate = function () {
            if (vm.productXml.categoryId == null || vm.productXml.categoryId == "") {
                MessageService.error("请选择分类");
                return false;
            }

            if (vm.productXml.name == null || vm.productXml.name == "") {
                MessageService.error("请输入作品名称");
                return false;
            }
            if (!vm.productXml.themeClassifies || vm.productXml.themeClassifies.length == 0) {
                MessageService.error("请选择模板分类");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            var _data = angular.copy(vm.productXml);
            _data.document.replace = _data.replace;
            _data.modelData = vm.modelData;
            _data.modelType = _data.modelPath ? 'THREE' : 'TWO';
            ProductXmlFileServes.update(_data, function(data){
                MessageService.success("保存成功");
                $uibModalInstance.close(data);
            },function(){
                MessageService.error("保存失败");
            });
        }

        $scope.save2 = function(){
            if (!$scope.validate()) {
                return;
            }
            var _data = angular.copy(vm.productXml);
            _data.document.replace = _data.replace;
            _data.modelData = vm.modelData;
            _data.modelType = _data.modelPath ? 'THREE' : 'TWO';
            ProductXmlFileServes.update(_data,function(data){
                var url = vm.platformSetting.productXmlUrl + '/#/page-my-suffix/'+data.identifier+'/'+vm.platformSetting.productXmlKey+'/'+data.id+'/'+_data.document.replace+'/'+pageIndex;
                if(_data.modelType=='THREE'){
                    url+='/'+_data.modelType+'/'+encodeURIComponent(JSON.stringify(_data.modelData))+'?modelPath='+encodeURIComponent(_data.modelPath) + '&xmlName=' + data.name
                }else{
                    url+='?xmlName='+data.name
                }
                window.location.href = url;
            },function(){
                MessageService.error("保存失败");
            });
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        $scope.typeChage = function(){
            if (vm.productXml.type == 'Currency') {
                vm.productXml.customStoreId = null;
                vm.productXml.customStoreName = null;
            }
        }

        //上传3D模型
        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "model-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.productXml.modelPath = $scope.domain + data.key;
                    gltfInfo(vm.productXml.modelPath)
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        };

        function gltfInfo(path) {
            // 加载glTF格式的模型
            vm.modelData = [];
            vm.materialMap = [];
            var loader = new THREE.GLTFLoader();
            if (THREE.DRACOLoader) {
                var dracoLoader = new THREE.DRACOLoader();
                if (dracoLoader) {
                    dracoLoader.setDecoderPath("lib/three/draco/");
                    loader.setDRACOLoader( dracoLoader );
                }
            }
            vm.modelLoad = true;
            loader.load(path, function (gltf) {
                gltf.scene.traverse(function(child){
                    if (child.isMesh) {
                        traverseMaterials(child, function(node,i){
                            if(vm.materialMap.indexOf(node.name) == -1){
                                var _modelData = {
                                    materialName:node.name,
                                    page:''
                                };
                                vm.modelData.push(_modelData);
                                vm.materialMap.push(node.name);
                            }
                        });
                    }
                });
                vm.modelLoad = false;
                $scope.$apply();
            }, function (xhr) {
                vm.schedule = (xhr.loaded / xhr.total * 100);
            }, function (error) {
                MessageService.error("3D模型上传失败，请稍后重试！")
                vm.modelLoad = false;
            })
        }

        function traverseMaterials(object, callback) {
            object.traverse(function(node){
                if (!node.isMesh) return;
                var materials = Array.isArray(node.material)
                    ? node.material
                    : [node.material];
                materials.forEach(callback);
            });
        }
    }

})();
