(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformRegionController', PlatformRegionController);

    PlatformRegionController.$inject = ['$timeout', '$scope', '$q', '$state',   'PlatformRegion', 'MessageService', 'pagingParams', 'paginationConstants',
    '$stateParams', '$uibModal'];

    function PlatformRegionController($timeout, $scope, $q, $state,   PlatformRegion, MessageService, pagingParams, paginationConstants, 
     $stateParams, $uibModal) {
        var vm = this;
        
         vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.loadPage = loadPage;
        function loadAll (){
            var  items=[];
            if (!!vm.searchQuery) {
                items.push({key:"name", op:"like", value:vm.searchQuery})
            }
            vm.regions = PlatformRegion.byCondition({items:items});
         }
        loadAll();

        $scope.delete = function(id) {
            if (id == null) {
                return;
            }
            MessageService.confirm("确认要删除该信息？", function(){
                PlatformRegion.delete({
                    id: id
                }, function(data){
                    loadAll();
                    MessageService.success("操作完成");
                }, function(error){
                    MessageService.error("操作失败");
                });
            })
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }
        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;

            vm.statue = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                statue:vm.statue
            });
        }
     
    }
})();
