(function () {
    'use strict';
    angular
        .module('platformApp')
        .factory('PicManagements', PicManagements);

    PicManagements.$inject = ['$resource'];

    function PicManagements($resource) {
        var resourceUrl = 'manager/' + 'api/pic-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'save': {method: 'POST'},
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            }
        });
    }
})();
