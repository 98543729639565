(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LeagueApplyController', LeagueApplyController);

    LeagueApplyController.$inject = ['$state', 'StoreApplies', 'ParseLinks',  'paginationConstants', 'pagingParams', 'MessageService','$scope', '$stateParams'];

    function LeagueApplyController($state, StoreApplies, ParseLinks,   paginationConstants, pagingParams, MessageService, $scope, $stateParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.applyType1 = applyType1;
        vm.status = status;
        $scope.batchArr = [];
        vm.applyType = "";
        vm.applyTypes = [
            {id: '', name: '全部'},
            {id: 'JOIN', name: '加入'},
            {id: 'SIGNOUT', name: '解绑'}
        ];

        loadAll();

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.productPurchases,function (data,i) {
                if($scope.selectAll){
                    data.select = true;
                    $scope.batchArr.push(data.id);
                    $scope.selectOne = true;
                }else{
                    data.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (data) {
            data.select = !data.select;
            // 选中
            if(data.select){
                $scope.batchArr.push(data.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(data.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.productPurchases,function (item,i) {
                if(!item.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.productPurchases.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }
        function loadAll () {
            var items = [{key: "parentStoreId", op: "=", value: $stateParams.storeId}];
            if(vm.searchQuery){
                items.push({key: "storeName", op: "like", value: vm.searchQuery})
            }
            if(vm.applyType){
                items.push({key: "applyType", op: "=", value: vm.applyType})
            }
            StoreApplies.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productPurchases = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            loadAll()
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }
        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        };

        $scope.approve = function (data) {
            StoreApplies.approve({id: data.id}, function (res) {
                loadAll()
                MessageService.success("通过成功");
            })
        }
        $scope.reject = function (data) {
            StoreApplies.reject({id: data.id}, function (res) {
                loadAll()
                MessageService.success("拒绝成功");
            })
        }

        $scope.batchApprove = function () {
            if($scope.batchArr.length == 0){
                MessageService.error("请选择申请的商户");
                return
            }
            for(var i=0; i<vm.productPurchases.length; i++){
                if($scope.batchArr.indexOf(vm.productPurchases[i].id) != '-1'){
                    if(vm.productPurchases[i].status != 'Created'){
                        MessageService.error("已通过或者已拒绝的申请不可再次操作");
                        return false
                    }
                }
            }
            MessageService.confirm("确定批量通过所有申请？",function(){
                StoreApplies.batchApprove({ids: $scope.batchArr},function () {
                    MessageService.success("通过成功");
                    loadAll();
                },function () {
                    MessageService.error("通过失败");
                })
            },function(){},true);
        }
        $scope.batchReject = function () {
            if($scope.batchArr.length == 0){
                MessageService.error("请选择申请的商户");
                return
            }
            for(var i=0; i<vm.productPurchases.length; i++){
                if($scope.batchArr.indexOf(vm.productPurchases[i].id) != '-1'){
                    if(vm.productPurchases[i].status != 'Created'){
                        MessageService.error("已通过或者已拒绝的申请不可再次操作");
                        return false
                    }
                }
            }

            MessageService.confirm("确定批量拒绝所有申请？",function(){
                StoreApplies.batchReject({ids: $scope.batchArr},function () {
                    MessageService.success("拒绝成功");
                    loadAll();
                },function () {
                    MessageService.error("拒绝失败");
                })
            },function(){},true);
        }
        function applyType1(target) {
            switch (target) {
                case "JOIN":
                    return "加入";
                case "SIGNOUT":
                    return "解绑";
                default:
                    return "";
            }
        }

        function status(target) {
            switch (target) {
                case "Created":
                    return "新建";
                case "Approving":
                    return "已提交";
                case "Approved":
                    return "已通过";
                case "Completed":
                    return "已完成";
                case "Rejected":
                    return "已拒绝";
                default:
                    return "";
            }
        }
    }
})();
