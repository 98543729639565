(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreController2', StoreController2);

    StoreController2.$inject = ['$state', 'AgentManagement', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', '$scope', 
    '$localStorage', 'RechargeLevel', '$q', '$stateParams'];

    function StoreController2($state, AgentManagement, ParseLinks, paginationConstants, pagingParams, MessageService, $scope,
     $localStorage, RechargeLevel, $q , $stateParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.leveId = $stateParams.leveId;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;  
        vm.user = $localStorage.user;
        if (vm.user == null && vm.user.id == null) {
            MessageService.error("获取当前用户信息失败，请重新登录")
            return;
        }
        vm.leves = RechargeLevel.getAllByCondition({});

        $q.all([vm.leves.$promise, ]).then(function(){ 
             if (vm.leves != null && vm.leves.length> 0 ) {
                vm.leves.sort(function(a, b){
                    return Number(a.leve) - Number(b.leve);
                })
             }
        }) ; 

        loadAll();

        $scope.storeManagement = function(id){ 
            sessionStorage.setItem("lmenu", "a_agent_management");
            $state.go("store-detail2", {storeId:id}); 
            function test(){
              $state.reload();
            }
            setTimeout(test,800);
        }

        function loadAll() {
            var items = [{key:"userId", op:"=", value:vm.user.id}]
            if ( vm.leveId != null) {
                items.push({ key: "store.level.id",  op: "=", value: vm.leveId});
            }
            if (pagingParams.search) {
                items.push({ key: "store.name",  op: "like", value: pagingParams.search });
                 
            } 
            AgentManagement.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.agents = data;
                vm.page = pagingParams.page;
            } 

            function onError(error) {
                MessageService.error("商户获取失败");
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;
 
    }
})();