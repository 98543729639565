(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('category', {
                parent: 'entity',
                url: '/category?page&sort&search',
                data: {
                    authorities: ["ROLE_PLATFROM_CATEGORY"],
                    pageTitle: 'global.menu.entities.commodityClassification'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/category/categories.html',
                        controller: 'CategoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('category');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('category-detail', {
                parent: 'category',
                url: '/category/{id}',
                data: {
                    authorities: ["ROLE_PLATFROM_CATEGORY"]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/category/category-detail.html',
                        controller: 'CategoryDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('category');
                                return $translate.refresh();
                            }],
                            entity: ['Category', function(Category) {
                                return Category.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('category', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('category');
                    });
                }]
            })
            .state('category-detail.edit', {
                parent: 'category-detail',
                url: '/detail/edit',
                data: {
                    authorities: ["ROLE_PLATFROM_CATEGORY"]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/category/category-dialog.html',
                        controller: 'CategoryDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Category', function(Category) {
                                return Category.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('category.new', {
                parent: 'category',
                url: '/new',
                data: {
                    authorities: ["ROLE_PLATFROM_CATEGORY"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/category/category-dialog.html',
                        controller: 'CategoryDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function() {
                        return {
                            name: null,
                            number: null,
                            cancelled: null,
                            id: null,
                            usable:null
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('category');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('category.edit', {
                parent: 'category',
                url: '/{id}/edit',
                data: {
                    authorities: ["ROLE_PLATFROM_CATEGORY"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/category/category-dialog.html',
                        controller: 'CategoryDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('category');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['Category','$stateParams', function(Category,$stateParams) {
                        return Category.get({id : $stateParams.id}).$promise;
                    }]
                }
            });

    }

})();
