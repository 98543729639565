(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('SupplierItemSelectController', SupplierItemSelectController);

    SupplierItemSelectController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', 'MessageService', 'Product', '$uibModalInstance'];

    function SupplierItemSelectController($timeout, $scope, $state, $q, entity, MessageService, Product, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.selector = "new"; // or old
        vm.switchToNew = switchToNew;
        vm.switchToOld = switchToOld;
        vm.onRootChange = onRootChange;
        vm.onCategoryChange = onCategoryChange;
        vm.onProductChange = onProductChange;
        vm.onSkuChange = onSkuChange;
        vm.searchSku = searchSku;
        vm.page = 1;
        vm.pageSize = 100;

        // for new select
        vm.rootCategories = [];
        vm.rootCategoryId = null;
        vm.categories = [];
        vm.categoryId = null;
        vm.products = [];
        vm.product = null;
        vm.productId = null;
        vm.productKey = '';
        vm.skuSpecs = [];
        vm.skuKey = '';
        vm.skuLoading = false;
        vm.skuId = null;
        vm.sku = null;

        // for old select
        vm.supplierProductsTree = [];
        vm.suplierProductsSearchQuery = '';
        vm.suplierProductsApex = [];


        init();
        function init() {
            if (vm.selector == 'new') {
                loadRootCategories();
            } else {
                loadCategoryAndProductDetails();
            }
        }

        function switchToOld() {
            vm.selector = 'old';
            if (!vm.supplierProductsTree || vm.supplierProductsTree.length == 0) {
                loadCategoryAndProductDetails();
            }
        }
        function switchToNew() {
            vm.selector = 'new';
            if (!vm.rootCategories || vm.rootCategories.length == 0) {
                loadRootCategories();
            }
        }

        /******************* for new selector *************************/
        function loadRootCategories() {
            Product.getCategories({}, function (res) {
                if (res.state == '200' || res.status == '200') {
                    vm.rootCategories = res.data || [];
                } else {
                    MessageService.error(res.message || '获取一级分类数据失败');
                }
            }, function (res) {
                MessageService.error(res.data.message || '获取一级分类数据失败');
            });
        }

        function onRootChange() {
            vm.categories = [];
            vm.categoryId = null;
            onCategoryChange();
            if (vm.rootCategoryId) {
                getCategories();
            }
        }

        function  getCategories() {
            Product.getCategories({categoryId: vm.rootCategoryId}, function (res) {
                if (res.state == '200' || res.status == '200') {
                    vm.categories = res.data || [];
                } else {
                    MessageService.error(res.message || '获取二级分类数据失败');
                }
            }, function(res) {
                MessageService.error(res.data.message || '获取二级分类数据失败');
            });
        }

        function onCategoryChange() {
            
            vm.products = [];
            vm.productId = null;
            vm.product = null;
            onProductChange();
            if (vm.categoryId) {
                getProducts();
            }
        }

        function getProducts() {
            Product.getProducts({categoryId: vm.categoryId, keyword: vm.productKey}, function(res) {
                if (res.state == '200' || res.status == '200') {
                    vm.products = res.data || [];
                } else {
                    MessageService.error(res.message || '获取商品列表失败');
                }
            }, function (res) {
                MessageService.error(res.data.message || '获取商品列表失败');
            });
        }

        function onProductChange() {
            vm.skuSpecs = [];
            vm.skuId = null
            onSkuChange();
            if (vm.productId) {
                for (var i = 0; i < vm.products.length; i++) {
                    if (vm.productId == vm.products[i].productId) {
                        vm.product = vm.products[i];
                    }
                }
                getSkuSpecs();
            } else {
                vm.product = null;
            }
        }

        function getSkuSpecs() {
            vm.skuLoading = true;
            Product.getSkuSpecs({productId: vm.productId, keyword: vm.skuKey}, function (res) {
                if (res.state == '200' || res.status == '200') {
                    vm.skuSpecs = res.data || [];
                } else {
                    MessageService.error(res.message || '获取商品SKU失败');
                }
                vm.skuLoading = false;
            }, function (res) {
                vm.skuLoading = false;
                MessageService.error(res.data.message || '获取商品SKU失败');
            });
        }

        function searchSku() {
            if (vm.productId) {
                vm.skuId = null;
                getSkuSpecs();
            }
        }

        function onSkuChange(sku) {
            if (sku) {
                vm.skuId = sku.specId;
                vm.skuNumber = sku.skuNumber;
                vm.sku = sku;
            } else {
                vm.skuId = null;
                vm.sku = null;
                vm.skuNumber = null;
            }
            resetPage();
        }

        function resetPage() {
            vm.page = 1;
        }

        $scope.getLoadCount = function() {
            var load_count = vm.page * vm.pageSize;
            return Math.min(load_count, $scope.getTotalCount());
        }
        $scope.getTotalCount = function() {
            return vm.skuSpecs.length;
        }

        $scope.canLoadMore = function() {
            var total = $scope.getTotalCount();
            var load_count = vm.page * vm.pageSize;
            return load_count < total;
        }
        $scope.loadPageMore = function() {
            var total = $scope.getTotalCount();
            if (total > vm.page * vm.pageSize) {
                vm.page++;
            }
            $timeout(function() {
                $scope.$apply();
            }, 100);
        }


        /******************* for old selector *************************/

        function loadCategoryAndProductDetails() {
            Product.getCategoryAndProductDetails({},function (res) {
                if(res.status==200 ||res.state==200  ){
                    //左侧导航栏   Tree -- > categories --> products
                    vm.supplierProductsTree = res.categories;
                    var data = res.categories;
                    vm.suplierProductsApex = []; //右侧表格
                    angular.forEach(data,function (item) {
                        angular.forEach(item.categories,function (ca) {
                            angular.forEach(ca.products,function (pro,i) {
                                vm.suplierProductsApex.push(pro);
                            })
                        })
                    })
                    vm.originSupplerProductsApex = angular.copy(vm.suplierProductsApex);
                }
            }, function () {

            })
        }


        $scope.suplierProductsConfirm = function () {
            vm.suplierProducts = false;
            for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                var left = vm.suplierProductsApex[i];
                for (var j = left.specs.length - 1; j >= 0; j--) {
                    var product = left.specs[j];
                    if (product.selected) {
                        product.selected = false;
                        vm.currentItemValueLine.supNumber = product.skuNumber;
                        return;
                    }
                }
            }
        }

        $scope.suplierProductsSearch = function () {
            if (!!vm.originSupplerProductsApex && !!vm.suplierProductsSearchQuery) {
                vm.suplierProductsApex = angular.copy(vm.originSupplerProductsApex);
                for(var i = 0 ; i< vm.suplierProductsApex.length; i++){
                    var apex = vm.suplierProductsApex[i];
                    if (apex.specs) {
                        apex.specs =  apex.specs.filter(function(p) {
                            if (p.specName.indexOf(vm.suplierProductsSearchQuery)>=0 || p.skuNumber.indexOf(vm.suplierProductsSearchQuery)>=0) {
                                return p;
                            }
                        });
                    }
                }
            }
            if (!vm.suplierProductsSearchQuery) {
                vm.suplierProductsApex = angular.copy(vm.originSupplerProductsApex );
            }
        }

        $scope.selectApex = function (apex) {
            for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                var ap = vm.suplierProductsApex[i];
                if (ap.productName == apex.productName) {
                    ap.hide = false;
                } else {
                    ap.hide = true;
                }

            }
        }

        $scope.showTree = function (tree) {
            tree.show = !tree.show;
        }

        $scope.showLeft = function (left) {
            left.show = !left.show;
        }

        $scope.productChooseOne = function (p) {
            if (!p.selected) {
                for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                    var apex = vm.suplierProductsApex[i];
                    for (var j = apex.specs.length - 1; j >= 0; j--) {
                        var product = apex.specs[j];
                        if (product.skuNumber != p.skuNumber) {
                            product.selected = false;
                        }
                    }
                }
            }
            p.selected = !p.selected;
        }

        function clear(){
            $uibModalInstance.dismiss();
        }

        function save() {
            if (vm.selector == 'new') {
                if (!vm.skuNumber) {
                    MessageService.error("请选择SKU");
                    return;
                }
                $uibModalInstance.close(vm.skuNumber);
                return;
            } else {
                for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                    var left = vm.suplierProductsApex[i];
                    for (var j = left.specs.length - 1; j >= 0; j--) {
                        var product = left.specs[j];
                        if (product.selected) {
                            $uibModalInstance.close(product.skuNumber);
                            return;
                        }
                    }
                }
                $uibModalInstance.close();
                return;
            }
        }
    }
})();