(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformALiSettingDeleteController',PlatformALiSettingDeleteController);

    PlatformALiSettingDeleteController.$inject = ['$uibModalInstance', 'entity', 'PlatformALiSetting'];

    function PlatformALiSettingDeleteController($uibModalInstance, entity, PlatformALiSetting) {
        var vm = this;

        vm.platformALiSetting = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PlatformALiSetting.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
