(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LogoSettingController', LogoSettingController);

    LogoSettingController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'MessageService', 'Store', 'PlatformWechatSetting', 'CustomerWallets',
    'Qinius', '$timeout', 'UidService', '$state', '$uibModal'];

    function LogoSettingController($scope, $q, $rootScope, $stateParams, MessageService, Store, PlatformWechatSetting, CustomerWallets,
     Qinius, $timeout, UidService, $state, $uibModal) {
        var vm = this;
        vm.save= save;
        vm.saveClean = saveClean;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.back = back;
        vm.backToClean = backToClean;
        vm.viewCleanLogs = viewCleanLogs;
        vm.platformSetting = PlatformWechatSetting.byPlatFrom();
        $q.all([vm.domains.$promise, vm.platformSetting.$promise, vm.tokens.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        $scope.changImg = function(type){
            vm.changType = type;
        } 

        window.updateImgFile = function ($event) {
            $scope.$digest();
            $timeout(function () {
                var file = $event.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "logo-"+ UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        if (vm.changType == 'loginLogo') { //登录页LOGO
                            vm.platformSetting.loginLogo = data.key;
                        } else if (vm.changType == 'menuLogo') {  //菜单顶部LOGO
                            vm.platformSetting.menuLogo = data.key;
                        }
                        vm.changType = null;
                    },
                    error: function (request) {
                        vm.changType = null;
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        function save(){
            PlatformWechatSetting.update(vm.platformSetting, onSaveSuccess, onSaveError);
        }
        function saveClean(){
            PlatformWechatSetting.update(vm.platformSetting, onSaveCleanSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $state.go('logo-setting');
            MessageService.success("保存成功");
        }
        function onSaveCleanSuccess (result) {
            $state.go('clean-setting');
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
        }

        function back () {
            $state.go('logo-setting');
        }
        function backToClean() {
            $state.go('clean-setting');
        }
        function viewCleanLogs() {
            $uibModal.open({
                templateUrl: 'app/entities/logo-setting/clean-history.html',
                controller: 'CleanHistoryController',
                controllerAs: 'vm',
                backdrop: 'static'
            });
        }
    }
})();
