(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('InvoiceDetailController', InvoiceDetailController);

    InvoiceDetailController.$inject = ['$q', '$state','$scope',  'entity', 'MessageService', 'Invoice', '$stateParams'];

    function InvoiceDetailController($q, $state, $scope, entity, MessageService, Invoice, $stateParams) {

        var vm = this;
        vm.save = save;
        vm.clear = clear;
        vm.invoice = entity; 

        
        $q.all([ vm.invoice.$promise]).then(function(){
             
        });

        
        function clear () {
            $state.go("pay-order2")
        }

         $scope.validate = function() {
            if (vm.invoice.delivery == null) {
                MessageService.error("请录入快递公司");
                return false;
            };
             
            if (vm.invoice.deliveryNumber == null || vm.invoice.deliveryNumber == "") {
                MessageService.error("请录入快递编号");
                return false;
            };
            return true;
         }

        function save() {
            if (!$scope.validate()) {
                return;
            };

            vm.isSaving = true;
            Invoice.complete({id:vm.invoice.id, delivery:vm.invoice.delivery, deliveryNumber: vm.invoice.deliveryNumber}, function(result){
                MessageService.success("操作成功");
                $state.go("invoice");
            }, function(){
                vm.isSaving = false;
                MessageService.error("操作失败");
            })
        }
    }
})();
