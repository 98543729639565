(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('TaskbillFormsController', TaskbillFormsController);

    TaskbillFormsController.$inject = ['$state', '$scope', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', 'TaskbillForms'];

    function TaskbillFormsController($state, $scope, ParseLinks, MessageService, paginationConstants, pagingParams, TaskbillForms) {

        var vm = this;

        vm.forms = [];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        loadAll();

        function loadAll() {
            var items = [];
            items.push({key: "storeId", op: "=", value: "null"});
            if (pagingParams.search) {
                items.push({key: "name", op: "like", value: pagingParams.search});
            }
            TaskbillForms.findPage({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.forms = data || [];
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                storeId:vm.storeId
            });
        }

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                TaskbillForms.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

    }
 })();