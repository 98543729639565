(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LicenseKeyDetailController', LicenseKeyDetailController);

    LicenseKeyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'LicenseKey', 'Store'];

    function LicenseKeyDetailController($scope, $rootScope, $stateParams, previousState, entity, LicenseKey, Store) {
        var vm = this;

        vm.licenseKey = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('platformApp:licenseKeyUpdate', function(event, result) {
            vm.licenseKey = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
