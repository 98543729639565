(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product-xml', {
            parent: 'entity',
            url: '/product-xml?page&sort&search',
            data: {
                authorities: ["ROLE_PLATFROM_PRODUCTXML"],
                pageTitle: 'global.menu.entities.productXml'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-xml/product-xmls.html',
                    controller: 'ProductXmlController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };0
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productXml');
                    $translatePartialLoader.addPart('page');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('reprintType');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-xml-simple', {
                parent: 'product-xml',
                url: '/{id}/product-xml-simple',
                data: {
                    authorities: ["ROLE_PLATFROM_PRODUCTXML"]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/product-xml/product-xml-simple.html',
                        controller: 'ProductXmlSimpleController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('productXml');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            entity: ['ProductXmls', function(ProductXmls) {
                                return ProductXmls.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                     }).result.then(function() {
                        $state.go('product-xml', null, { reload: 'product-xml' });
                    }, function() {
                        $state.go('product-xml', null, { reload: 'product-xml' });
                    });
                }]
            })
         ;
    }

})();
