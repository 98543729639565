(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('PasswordMarketingController', PasswordMarketingController);

    PasswordMarketingController.$inject = ['$state', 'PasswordMarketing', 'ParseLinks', '$q', 'MarketingStore', 'entity', '$scope', 'MessageService'];

    function PasswordMarketingController($state, PasswordMarketing, ParseLinks, $q, MarketingStore, entity, $scope, MessageService) {

        var vm = this;
        vm.passwordMarketing = entity;
        vm.marketingStores = vm.passwordMarketing.marketingStores;


        $scope.typeChange = function(type){
            $state.go('password-marketing',{type:type},{reload:true});
            // PasswordMarketing.getOne({type:type}, function(data){
            //     vm.passwordMarketing = data;
            //     if (vm.passwordMarketing.type== null) {
            //         vm.passwordMarketing.type = type;
            //     }   
            //     vm.marketingStores = vm.passwordMarketing.marketingStores;
            // }, function(){
            //     MessageService.error("数据加载失败");
            // })
        }

    }
})();
