(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreThirdDetailController', StoreThirdDetailController);

    StoreThirdDetailController.$inject = ['$scope', '$q', '$state', '$rootScope', '$stateParams', 'entity', 'Store', 'PlatformWechatSetting'];

    function StoreThirdDetailController($scope, $q, $state, $rootScope, $stateParams, entity, Store, PlatformWechatSetting) {
        var vm = this;

        vm.abutment = entity;
        vm.clear2 = clear2;
        vm.platformWechatSetting =  PlatformWechatSetting.byPlatFrom();
        vm.store = Store.getStore({id:$stateParams.storeId});
        function clear2 (){
            $state.go("store2");
        }
        vm.platforms = [{key:"人人店", value:"rrd"}];


        var unsubscribe = $rootScope.$on('platformApp:storeUpdate', function(event, result) {
            vm.store = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
