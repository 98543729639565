(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('HomeClearController', HomeClearController);

    HomeClearController.$inject = ['$scope', '$state', 'MessageService'];

    function HomeClearController($scope, $state, MessageService) {
        var vm = this;

    }
})();
