(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('store-product', {
                parent: 'store-detail2',
                url: '/product?page&sort&search',
                data: {
                    authorities: ['ROLE_PLATFROM_PRODUCT'],
                    pageTitle: 'global.menu.entities.productManagement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-product/products.html',
                        controller: 'StoreProductController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id, desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }]
                }
            })

            .state('store-product-detail', {
                parent: 'store-detail2',
                url: '/product/{id}',
                data: {
                    authorities: ['ROLE_PLATFROM_PRODUCT'],
                    pageTitle: 'platformApp.product.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-product/product-detail.html',
                        controller: 'StoreProductDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return Product.get({id: $stateParams.id}).$promise;
                    }]
                }
            })

            // .state('store-product.new', {
            //     parent: 'store-detail2',
            //     url: '/new',
            //     data: {
            //         authorities: ['ROLE_PLATFROM_PRODUCT'],
            //         pageTitle: 'platformApp.product.home.createOrEditLabel'
            //     },
            //     views: {
            //         'content@': {
            //             templateUrl: 'app/entities/store-product/product-dialog.html',
            //             controller: 'ProductDialogController',
            //             controllerAs: 'vm'
            //         }
            //     },
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('product');
            //             $translatePartialLoader.addPart('global');
            //             $translatePartialLoader.addPart('productType');
            //             return $translate.refresh();
            //         }],
            //         entity: function() {
            //             return {
            //                 name: null,
            //                 sku: null,
            //                 cancelled: null,
            //                 price: null,
            //                 jsonFile: null,
            //                 saleable: true,
            //                 pTicket: false,
            //                 id: null,
            //                 prototype: "Original"
            //             };
            //         }
            //     }
            // })
            .state('store-product.edit', {
                parent: 'store-detail2',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_PLATFROM_PRODUCT'],
                    pageTitle: 'platformApp.product.home.createOrEditLabel'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-product/product-dialog.html',
                        controller: 'StoreProductDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return Product.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            ;
    }

})();
