(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('mapDepotCleanupAgreement', {
                parent: 'entity',
                url: '/mapDepotCleanupAgreement',
                data: {
                    authorities: ["ROLE_PLATFROM_SETTING"],
                    pageTitle: 'global.menu.entities.MapDepotCleanupAgreement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mapDepotCleanupAgreement/mapDepotCleanupAgreement.html',
                        controller: 'MapDepotCleanupAgreementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
            .state('mapDepotCleanupAgreement-edit', {
                parent: 'entity',
                url: '/mapDepotCleanupAgreement-edit',
                data: {
                    authorities: ["ROLE_PLATFROM_SETTING"],
                    pageTitle: 'global.menu.entities.MapDepotCleanupAgreement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mapDepotCleanupAgreement/mapDepotCleanupAgreement-edit.html',
                        controller: 'MapDepotCleanupAgreementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return null;
                    }]
                }
            });
    }

})();
