(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PasswordMarketingDialogController', PasswordMarketingDialogController);

    PasswordMarketingDialogController.$inject = ['$timeout', '$scope', '$q', '$uibModalInstance', 'entity', 'PasswordMarketing', 'Store', '$stateParams'];

    function PasswordMarketingDialogController($timeout, $scope, $q, $uibModalInstance, entity, PasswordMarketing, Store, $stateParams) {
        var vm = this;

        vm.passwordMarketing = entity;
        vm.clear = clear;
        vm.save = save;
        vm.stores = Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]});
        $scope.selectAll = false;

        $q.all([vm.stores.$promise]).then(function() {
            if (vm.passwordMarketing.marketingStores && vm.passwordMarketing.marketingStores.length) {
                var t = 0;
                angular.forEach(vm.stores, function(store, index) {
                    angular.forEach(vm.passwordMarketing.marketingStores, function(s, i) {
                        if (store.id == s.storeId) {
                            store.lineId = s.id;
                            store.select = true;
                            t++;
                            if (t == vm.stores.length) {
                                $scope.selectAll = true;
                            }
                        }
                    })
                })
            }
        });

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            var selArr = [];
            angular.forEach(vm.stores, function(store, index) {
                var arr = {
                    storeId: store.id,
                    storeName: store.name,
                    added: false,
                    deleted: false,
                    id: store.id
                }
                if (store.select) {
                    if (!store.lineId) {
                        arr.added = true
                    }
                    selArr.push(arr);
                } else if(store.lineId) {
                    arr.deleted = true
                    selArr.push(arr);
                }
            })
            console.log(selArr);
            vm.passwordMarketing.marketingStores = selArr;
            vm.passwordMarketing.type = $stateParams.type;
            vm.isSaving = true;
            PasswordMarketing.update(vm.passwordMarketing, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:passwordMarketingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        $scope.chooseAll = function() {
            $scope.selectAll = !$scope.selectAll;
            angular.forEach(vm.stores, function(store, i) {
                if ($scope.selectAll) {
                    store.select = true;
                } else {
                    store.select = false;
                }
            })
        }

        $scope.chooseOne = function(store) {
            store.select = !store.select;

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.stores, function(store, i) {
                if (!store.select) {
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if (i == vm.stores.length - 1 && flag) {
                    $scope.selectAll = true;
                }
            })
        }

    }
})();