(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BaseBannerController', BaseBannerController);

    BaseBannerController.$inject = ['BaseBanner', 'MessageService', '$q', 'Qinius', '$scope', 'pagingParams', 'paginationConstants', 'ParseLinks'];

    function BaseBannerController(BaseBanner, MessageService, $q, Qinius, $scope, pagingParams, paginationConstants, ParseLinks) {

        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.domains = Qinius.getDomain();
        vm.baseBanners = [];

        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        $scope.delete = function(baseBanner){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                BaseBanner.delete({id: baseBanner.id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        loadAll();

         function loadAll () {  
            BaseBanner.byCondition({
                items:[],
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.banners = data; 
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("轮播图获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                storeId:vm.storeId,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }


    }
})();
