(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreUserDialogController', StoreUserDialogController);

    StoreUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', '$q', 'entity', 'StoreUser', 'Role', 'MessageService',
     'Store', 'AgentManagement', 'ListService'];

    function StoreUserDialogController ($timeout, $scope, $stateParams, $state, $q, entity, StoreUser, Role, MessageService,
     Store, AgentManagement, ListService) {
        var vm = this;

        vm.storeUser = entity;
        vm.clear = clear;
        vm.save = save;
        vm.storeDivShow = false;
        vm.roles = Role.getAllByCondition({items:[{key:"type",op:"=", value:"PLATFORM"}]});
        vm.stores = Store.getStoreForAgent({});
        vm.agents = AgentManagement.getAllByCondition({items:[{key:"userId",op:"=", value: $stateParams.id}]})
        $q.all([vm.storeUser.$promise, vm.roles.$promise, vm.stores.$promise, vm.agents.$promise]).then(function() {
            for (var i = vm.storeUser.authorities.length - 1; i >= 0; i--) {
               var au =  vm.storeUser.authorities[i];
               if (au == "ROLE_PLATFROM_AGENT") {
                    vm.storeDivShow = true;
                    break;
               }
            }
            if (vm.agents!= null && vm.agents.length>0) {
                for (var i = vm.agents.length - 1; i >= 0; i--) {
                    var a = vm.agents[i];
                    vm.stores.push(a.store);
                }
            }
            for (var i = 0; i < vm.agents.length; i++) {
                var au =  vm.agents[i];
                var result = ListService.inList(au.store, vm.stores, ["id"]);
                if (result) {
                    result.edited = true;
                    result.selected = true;
                }
            }
        }) ;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.roleChange = function(){
            var role = null;
            for (var i =  vm.roles.length - 1; i >= 0; i--) {
                var r=  vm.roles[i];
                if (r.id == vm.storeUser.roleId) {
                    role = r;
                    break;
                }
            }
            if (role && role.authorities) {
                for (var i = role.authorities.length - 1; i >= 0; i--) {
                    var r = role.authorities[i];
                    if (r.code =='ROLE_PLATFROM_AGENT'){
                        vm.storeDivShow = true;
                        return;
                    }
                }
            }
            vm.storeDivShow = false;
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i];
                if (s.edited) {
                    s.deleted =true;
                }
                s.added = false;
                s.selected = false;
            }
        }



        $scope.storeSelected = function(store){
            store.selected = !store.selected;
            if (store.selected && !store.edited) {
                store.added = true;
            }
            if (!store.selected && store.edited) {
                store.deleted = true;
            }
            if (store.selected && store.edited) {
                store.deleted = false;
            }
            if (!store.selected && !store.edited) {
                store.added = false;
            }
        };

        function clear () {
            $state.go('store-user', null, { reload: 'store-user' });
        }

        $scope.validate = function(){
            if(vm.storeUser.name == null || vm.storeUser.name==""){
                MessageService.error("请输入用户名称");
                return false;
            }
            if (vm.storeUser.roleId == null || vm.storeUser.roleId=="") {
                MessageService.error("请选择角色");
                return false;
            };
            return true;
        };
        function save () {
            if(!$scope.validate()){
                return;
            }

            if (vm.storeUser.id !== null) {
                console.log('update');
                 vm.isSaving = true;
                StoreUser.update(vm.storeUser, onSaveSuccess, onSaveError);
            } else {
                StoreUser.checkUser({items:[{key:"name",op:"=",value:vm.storeUser.name}]},function(data){
                    if (data.length >0) {
                        MessageService.error("该用户名已经存在，请选择其他用户名");
                    }else{
                        console.log('add');
                        vm.isSaving = true;
                        vm.storeUser.langKey = "zh-cn";
                        vm.storeUser.password = "123456";
                        StoreUser.register(vm.storeUser, onSaveSuccess, onSaveError);
                    }
                },function(){
                    MessageService.error("校验用户名时出错");
                });
            }
        }


        function onSaveSuccess (result) {
            //保存代运营信息
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i];
                if (s.added) {
                    vm.agents.push({userId:result.id, store: s, added:true});
                }else if(s.deleted){
                    for (var j = vm.agents.length - 1; j >= 0; j--) {
                        var a = vm.agents[j];
                        if (a.store.id == s.id) {
                            a.deleted = true;
                        }
                    }
                }
            }
            AgentManagement.updateAgents(vm.agents,function(data){
                 MessageService.success("保存成功");
            },function(){
                 MessageService.error("代运营信息保存失败");
            });
            $scope.$emit('platformApp:storeUserUpdate', result);
            $state.go('store-user', null, { reload: 'store-user' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }


    }
})();
