(function() {
    'use strict';

    angular.module('platformApp')
        .controller('AddressModal1Controller', AddressModal1Controller);

    AddressModal1Controller.$inject = ['$scope', '$uibModalInstance', 'MessageService', 'entity', '$q'];

    function AddressModal1Controller ($scope, $uibModalInstance, MessageService, entity, $q) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        console.log(entity)
        vm.provices = entity.provices;
        vm.deliveryAreas = entity.deliveryAreas;
        vm.proviceId = null;
        vm.citieId = null;
        vm.districtId = null;
        vm.districts = [];
        vm.cities = [];
        vm.provinceAll = false;
        vm.citieAll = false;
        vm.districtAll = false;
        $q.all([vm.provices.$promise, vm.deliveryAreas.$promise]).then(function () {
            vm.deliveryAreas.forEach(function (deliveryArea) {
                vm.provices.forEach(function (provice) {
                    provice.cities.forEach(function (citie) {
                        citie.districts.forEach(function (district) {
                            if(district.id == deliveryArea.districtId){
                                district.selected = true;
                            }
                        })
                    })
                })
            });
            addressInfo();
        });

        $scope.proviceClick = function ($event, item) {
            vm.proviceId = item.id;
            vm.citieId = null;
            vm.districtId = null;
            vm.districts = [];
            vm.cities = item.cities;
            citieAllInfo();
            districtAllInfo();
            fn($event)
        }
        $scope.citieClick = function ($event, item) {
            vm.citieId = item.id;
            vm.districtId = null;
            vm.districts = item.districts;
            districtAllInfo();
            fn($event)
        }
        $scope.districtClick = function ($event, item) {
            vm.districtId = item.id;
            fn($event)
        }

        $scope.provinceAllClick = function ($event) {
            if(!vm.provices || vm.provices.length == 0){
                return
            }
            vm.provinceAll = !vm.provinceAll;
            vm.citieAll = vm.provinceAll;
            vm.districtAll = vm.provinceAll;
            vm.provices.forEach(function (provice) {
                provice.selected = vm.provinceAll;
                provice.cities.forEach(function (citie) {
                    citie.selected = vm.provinceAll;
                    citie.districts.forEach(function (district) {
                        district.selected = vm.provinceAll;
                    })
                })
            })
        }
        $scope.proviceSelectClick = function($event, item){
            item.selected = !item.selected;
            item.cities.forEach(function (citie) {
                citie.selected = item.selected;
                citie.districts.forEach(function (district) {
                    district.selected = item.selected;
                })
            });
            addressInfo();
            fn($event)
        }

        $scope.citieAllClick = function ($event) {
            if(!vm.cities || vm.cities.length == 0){
                return
            }
            vm.citieAll = !vm.citieAll;
            vm.districtAll = vm.citieAll;
            vm.cities.forEach(function (citie) {
                citie.selected = vm.citieAll;
                citie.districts.forEach(function (district) {
                    district.selected = vm.citieAll;
                })
            });
            addressInfo()
        }
        $scope.citieSelectClick = function($event, item){
            item.selected = !item.selected;
            item.districts.forEach(function (district) {
                district.selected = item.selected;
            })
            addressInfo();
            fn($event)
        }


        $scope.districtAllClick = function ($event) {
            if(!vm.districts || vm.districts.length == 0){
                return
            }
            vm.districtAll = !vm.districtAll;
            vm.districts.forEach(function (district) {
                district.selected = vm.districtAll;
            });
            addressInfo()
        }
        $scope.districtSelectClick = function ($event, item) {
            item.selected = !item.selected;
            addressInfo();
            fn($event)
        }


        function addressInfo() {
            vm.provices.forEach(function (provice) {
                provice.selected = false;
                provice.cities.forEach(function (citie) {
                    citie.selected = false;
                    citie.districts.forEach(function (district) {
                        if(district.selected){
                            citie.selected = true;
                        }
                    })
                    if(citie.selected){
                        provice.selected = true;
                    }
                })
            })
            proviceAllInfo();
            citieAllInfo();
            districtAllInfo();
        }
        function proviceAllInfo(){
            vm.provinceAll = true;
            for(var i=0; i<vm.provices.length; i++){
                if(!vm.provices[i].selected){
                    vm.provinceAll = false;
                    break
                }
            }
        }
        function citieAllInfo(){
            if(vm.cities.length > 0){
                vm.citieAll = true;
                for(var i=0; i<vm.cities.length; i++){
                    if(!vm.cities[i].selected){
                        vm.citieAll = false;
                        break
                    }
                }
            }else{
                vm.citieAll = false;
            }
        }
        function districtAllInfo(){
            if(vm.districts.length > 0){
                vm.districtAll = true;
                for(var i=0; i<vm.districts.length; i++){
                    if(!vm.districts[i].selected){
                        vm.districtAll = false;
                        break
                    }
                }
            }else{
                vm.districtAll = false;
            }
        }

        //取消
        function cancel() {
            $uibModalInstance.close();
        }
        //保存
        function save() {
            var _data = [];
            vm.provices.forEach(function (provice) {
                provice.cities.forEach(function (citie) {
                    citie.districts.forEach(function (district) {
                        if(district.selected){
                            _data.push({
                                provinceId: provice.id,
                                provinceName: provice.name,
                                cityId: citie.id,
                                cityName: citie.name,
                                districtId: district.id,
                                districtName: district.name,
                            })
                        }
                    })
                })
            })
            $uibModalInstance.close(_data);
        }
        function fn($event) {
            var $event = $event || window.event;
            if ($event && $event.preventDefault) {
                $event.preventDefault();
            } else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }
    }
})();
