(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('NumberSetController', NumberSetController);

    NumberSetController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MessageService', 'Store'];

    function NumberSetController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MessageService, Store) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.data = {
            id: entity,
            number: ''
        };

        function save () {
            var reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,8}$/);
            if(!reg.test(vm.data.number)){
                MessageService.error("编号只能输入6-8位的字母加数字");
                return
            }
            Store.checkStoreNumber(vm.data,function (res) {
                vm.isSaving = true;
                Store.setStoreNumber(vm.data,onSaveSuccess,onSaveError)
            },function (error) {

            })
        }

        function onSaveSuccess () {
            MessageService.success("编号设置成功");
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("编号设置失败")
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
