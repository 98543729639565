(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MessageNotificationController', MessageNotificationController);

    MessageNotificationController.$inject = ['$state', 'MessageNotification', 'ParseLinks', 'Principal', 'MessageService', 'paginationConstants', 'pagingParams', '$scope'];

    function MessageNotificationController($state, MessageNotification, ParseLinks, Principal, MessageService, paginationConstants, pagingParams, $scope) {

       var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.time = null;
        
        loadAll();

        $scope.synchronizationMessage = function(item){
            MessageService.confirm("确认将消息同步给相应商家么",function(){
                MessageNotification.synchronizationMessage({id: item.id},function () {
                    loadAll();
                    MessageService.success('同步成功');
                });
            },function(){})
        }

        $scope.delete = function(item){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                MessageNotification.delete({id: item.id},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true)
        };
 
        function loadAll () {
            var items = [{key:"type", op:"=",value:"Platform"},{key:"protoId", op:"=",value:"null"}];
            if (pagingParams.search) {
                items.push({key:"content",op:"like",value:pagingParams.search});
            }  
            MessageNotification.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.messageNotifications = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
