
(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MusicController', MusicController);

    MusicController.$inject = ['$scope', '$state','$stateParams','Principal', 'MessageService', 'ParseLinks', 'paginationConstants', 'pagingParams', 'Music'];

    function MusicController($scope, $state,$stateParams,Principal, MessageService, ParseLinks, paginationConstants, pagingParams, Music) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.loadPage = loadPage;
        vm.changeStatue = changeStatue;
        vm.delete = del;
        vm.jump = jump;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.statue = pagingParams.statue;
        vm.deleteByBatch = deleteByBatch;

        vm.idss = [];
        loadAll();
        function loadAll () {
            var items=[];
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search});
                Music.getAll({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Music.getAll({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = ["id,desc"];
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.musics = data;
                console.log(data);
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("列表数据获取失败");
            }
        };

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }
        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;

            vm.statue = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
        function changeStatue (status){
            vm.statue = status;
            loadAll();
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                statue:vm.statue
            });
        }
        function del (id) {
            Music.del({
                id: id
            }, function(data){
                loadAll();
                MessageService.success("操作完成");
            }, function(error){
                MessageService.error("操作失败");
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $scope.batchArr = [];
        $scope.selectOne = false;
        $scope.selectAll = false;
        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.musics, function (mu, i) {
                if($scope.selectAll){
                    mu.select = true;
                    $scope.batchArr.push(mu.id);
                    $scope.selectOne = true;
                }else{
                    mu.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (mu) {
            mu.select = !mu.select;
            // 选中
            if(mu.select){
                $scope.batchArr.push(mu.id);
            }else{
                // 未选中
                angular.forEach($scope.batchArr,function (arr,i) {
                    if(arr.id==mu.id){
                        $scope.batchArr.splice(i,1)
                    }
                })
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.musics, function (mu,i) {
                if(!mu.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.musics.length-1 && flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        function deleteByBatch() {
            console.log($scope.batchArr.join(","));
            Music.dels({
                ids: $scope.batchArr.join(",")
            }, function(data){
                loadAll();
                MessageService.success("操作完成");
            }, function(error){
                MessageService.error("操作失败");
            });
        }
    }
})();
