(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayBatchDetail3Controller', PayBatchDetail3Controller);

    PayBatchDetail3Controller.$inject = ['$scope', '$q', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'PayBatch', 'PayCode', 'MessageService', 'History'];

    function PayBatchDetail3Controller($scope, $q, $rootScope, $stateParams, $uibModalInstance, entity, PayBatch, PayCode, MessageService, History) {
        var vm = this;

        vm.payBatch = entity;

        vm.clear = clear;

        vm.payBatchId = $stateParams.id;
        if (vm.payBatchId !=null ) {
            vm.historys = History.getAllByCondition({items:[{key:"classId", op:"=", value: vm.payBatchId},{key:"className", op:'=', value:"PayBatch"}],sort:["createdDate,desc"]});
        }

        $q.all([vm.payBatch.$promise]).then(function(){
             PayCode.payCodeDataByBatch({batch: vm.payBatch.number},function(data){
                vm.payCodeData = data;
            },function(){})
        })

        var unsubscribe = $rootScope.$on('platformApp:payBatchUpdate', function(event, result) {
            vm.payBatch = result;
        });

        $scope.submit = function (id) {
            MessageService.confirm("确认提交该信息", function () {
                PayBatch.submit({id: id}, function (data) {
                    vm.payBatch = data;
                });
            }, function () {
            });
        }


        $scope.approve = function (id) {
            MessageService.confirm("确认通过该请求", function () {
                PayBatch.approve({id: id}, function (data) {
                    vm.payBatch = data;
                });
            }, function () {
            });
        }


        $scope.reject = function (id) {
            MessageService.confirm("确认拒绝该请求", function () {
                PayBatch.reject({id: id}, function (data) {
                    vm.payBatch = data;
                });
            }, function () {
            });
        }




        function clear () {
            $uibModalInstance.close(vm.payBatch);
        }
        $scope.$on('$destroy', unsubscribe);
    }
})();
