(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CheckDeliveryDetailController', CheckDeliveryDetailController);

    CheckDeliveryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CheckDelivery'];

    function CheckDeliveryDetailController($scope, $rootScope, $stateParams, previousState, entity, CheckDelivery) {
        var vm = this;

        vm.checkDelivery = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('platformApp:checkDeliveryUpdate', function(event, result) {
            vm.checkDelivery = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
