(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FeedbackWwwDisposeController', FeedbackWwwDisposeController);

    FeedbackWwwDisposeController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity','MessageService', 'VisitorTickets'];

    function FeedbackWwwDisposeController($scope, $rootScope, $stateParams, $uibModalInstance, entity,MessageService, VisitorTickets) {
        var vm = this;
        vm.cancel = cancel;
        vm.id = entity.id;
        vm.remark = "";
        function cancel () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.subRemark = function () {
            if(!vm.remark) return MessageService.error("备注不能为空");
            VisitorTickets.treat({
                id:vm.id,
                remark:vm.remark
            },function (res){
                console.log(res);
                $uibModalInstance.close();
            })
        }
    }
})();
