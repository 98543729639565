(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('logo-setting', {
                parent: 'entity',
                url: '/logo-setting',
                data: {
                    authorities: ["ROLE_PLATFROM_LOGO_SETTING"],
                    pageTitle: 'global.menu.entities.systemSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/logo-setting/logo-setting.html',
                        controller: 'LogoSettingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
        .state('logo-setting-edit', {
                parent: 'entity',
                url: '/logo-setting-edit',
                data: {
                    authorities: ["ROLE_PLATFROM_LOGO_SETTING"],
                    pageTitle: 'global.menu.entities.systemSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/logo-setting/logo-setting-dialog.html',
                        controller: 'LogoSettingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
        .state('clean-setting', {
                parent: 'entity',
                url: '/clean-setting',
                data: {
                    authorities: ["ROLE_PLATFROM_LOGO_SETTING"],
                    pageTitle: 'global.menu.entities.cleanSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/logo-setting/clean-setting.html',
                        controller: 'LogoSettingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
        .state('clean-setting-edit', {
                parent: 'entity',
                url: '/clean-setting-edit',
                data: {
                    authorities: ["ROLE_PLATFROM_LOGO_SETTING"],
                    pageTitle: 'global.menu.entities.cleanSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/logo-setting/clean-setting-dialog.html',
                        controller: 'LogoSettingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
        .state('logo-setting-page', {
            parent: 'logo-setting',
            url: '/logo-setting/{type}',
            data: {
                authorities: ["ROLE_PLATFROM_LOGO_SETTING"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/logo-setting/logo-setting-page.html',
                    controller: 'LogoSettingPageController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return $stateParams.type;
                        }
                    }
                }).result.then(function() {
                    $state.go('logo-setting', null, { reload: 'logo-setting' });
                }, function() {
                    $state.go('logo-setting');
                });
            }]
        })
        .state('calendar-setting', {
                parent: 'entity',
                url: '/calendar-setting',
                data: {
                    authorities: ["ROLE_PLATFROM_LOGO_SETTING"],
                    pageTitle: 'global.menu.entities.systemSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/logo-setting/calendar-setting.html',
                        controller: 'CalendarSettingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        return $translate.refresh();
                    }],
                    entity: ['Product' , '$stateParams' , function(Product, $stateParams) {
                        return null;
                    }]
                }
            });
    }

})();
