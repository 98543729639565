(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ActivityQueueController', ActivityQueueController);

    ActivityQueueController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'StoreApplies'];

    function ActivityQueueController ($timeout, $scope, $stateParams, $q, entity, Product, Store, $state, MessageService, $uibModalInstance, StoreApplies) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.products = angular.copy(entity);
        $scope.chooseOne = function (item) {
            if(item.justifyQueue != 'ACTIVITY'){
                item.justifyQueue = 'ACTIVITY';
            }else{
                item.justifyQueue = 'DEFAULT';
            }
        }
        //确定
        function save() {
            $uibModalInstance.close(vm.products);
        }

        //返回
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
