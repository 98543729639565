(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreWechatSettingDialogController', StoreWechatSettingDialogController);

    StoreWechatSettingDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'StoreWechatSetting', 'MessageService', '$state'];

    function StoreWechatSettingDialogController ($timeout, $scope, $stateParams, entity, StoreWechatSetting, MessageService, $state) {
        var vm = this; 
        vm.storeWechatSetting = entity;   
        vm.save = save;
        vm.cancel = cancel;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        }); 

        $scope.validate = function () {
            if (vm.storeWechatSetting.weChatKey == null || vm.storeWechatSetting.weChatKey =="") {
                MessageService.error("请录入商户key");
                return false;
            }
            if (vm.storeWechatSetting.weChatMchid == null || vm.storeWechatSetting.weChatMchid == "") {
                MessageService.error("请录入商户id");
                return false;
            } 
            return true;
        }

        function save () {
            // if (!$scope.validate()) {
            //     return ;
            // }
            vm.isSaving = true; 
            StoreWechatSetting.update(vm.storeWechatSetting, onSaveSuccess, onSaveError); 
        }

        function cancel (){
            $state.go("store-wechat-setting");
        }

        function onSaveSuccess (result) {
            $state.go("store-wechat-setting",{},{reload:true});
            MessageService.success("保存成功");
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.success("保存失败");
            vm.isSaving = false;
        } 
    }
})();
