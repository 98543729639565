(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('File', File)
        .factory('FileService', FileService)
        .factory('FileService2', FileService2)
        .factory('ProductXmlFileServes', ProductXmlFileServes)
        .factory('Product', Product)
        .factory('Convert', Convert)
        .factory('Convert1', Convert1)
        .factory('ItemValue', ItemValue) ;

    Product.$inject = ['$resource', 'DateUtils'];
    ProductXmlFileServes.$inject = ['$resource'];
    File.$inject = ['$resource'];

    FileService.$inject = ['$resource'];
    FileService2.$inject = ['$resource'];
    Convert.$inject = ['$resource'];
    Convert1.$inject = ['$resource'];
    ItemValue.$inject = ['$resource'];

    function ItemValue($resource) {
        var resourceUrl = 'manager/' + 'api/item-values/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

     
    function ProductXmlFileServes ($resource) {
        var resourceUrl =  'fileserves/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "updateCountTip": {
                method:"POST",
                params:{
                    path:"updateCountTip"
                }
            },
            'batchUpdateCountTip': {
                method: 'GET',
                params: {
                    path: 'batchUpdateCountTip'
                }
            },
            'updatePageSize': {
                method: 'GET',
                params: {
                    path: 'updatePageSize'
                }
            },
            
            "updateImageArtProductXml": {
                method:"POST",
                params:{
                    path:"updateImageArtProductXml"
                }
            },
            'update': { method:'PUT' }
        });
    }


    function FileService ($resource) {
        var resourceUrl =  'service/' + 'api/:path/:id';

        return $resource(resourceUrl, {}, {
            'downloadZipFile': {
                method: 'GET',
                params: {
                    path: 'downloadZipFile'
                }
            }
        });
    }

    function FileService2 ($resource) {
        var resourceUrl =  'fileserves/' + 'api/download/:path/:id';
        return $resource(resourceUrl, {}, {
            'downloadZipFileCheck': {
                method: 'GET',
                params: {
                    path: 'downloadZipFileCheck'
                }
            },
            'downloadZipFile3': {
                method: 'GET',
                params: {
                    path: 'downloadZipFile3'
                }
            },
        });
    }

    function File ($resource) {
        var resourceUrl =  'manager/' + 'api/:path/:id';

        return $resource(resourceUrl, {}, {
            'remove': {
                method: 'GET',
                params: {
                    path: 'remove'
                }
            },
            'download': {
                method: 'GET',
                params: {
                    path: 'download'
                }
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'getFile'
                }
            },
            'getFontName':{
                method:'GET',
                params:{
                    path:'getFontNames'
                }
            },
            'uploadFont':{
                method:'POST',
                params:{
                    path:'uploadFont'
                },
                isArray: true

            }
        });
    }

    function Product ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/products/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            "deleteBatch":{
                method:"GET",
                params:{
                    path:"deleteBatch",
                }
            },
            "getCategoryAndProductDetails":{
                method:"GET",
                params:{
                    path:"getCategoryAndProductDetails",
                }
            },
            "getSupplierProducts":{
                method:"GET",
                params:{
                    path:"getSupplierProducts",
                }
            },
            "updateByProtoProduct":{
                method:"GET",
                params:{
                    path:"updateByProtoProduct",
                }
            },
            "shareToStore":{
                method:"GET",
                params:{
                    path:"shareToStore",
                }
            },
            "updateSaleable":{
                method:"GET",
                params:{
                    path:"updateSaleable",
                }
            },
            "updateSaleableByCategory":{
                method:"GET",
                params:{
                    path:"updateSaleableByCategory",
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }

            },
            'update': { method:'PUT' },
            "updateSaleableBatchByCategory":{
                method:"GET",
                params:{
                    path:"updateSaleableBatchByCategory",
                }
            },
            'getAllByCategory': {
                method: 'GET',
                params: {
                    path: 'getAllByCategory'
                },
                isArray: true
            },
            'pullOffShelvesByOriginal': {
                method: 'GET',
                params: {
                    path: 'pullOffShelvesByOriginal'
                }
            },
            'setQueue': {
                method: 'POST',
                params: {
                    path: 'setQueue'
                }
            },
            'getCategories': {
                method: 'GET',
                params: {
                    path: 'getCategories'
                }
            },
            'getProducts': {
                method: 'GET',
                params: {
                    path: 'getProducts'
                }
            },
            'getSkuSpecs': {
                method: 'GET',
                params: {
                    path: 'getSkuSpecs'
                }
            }
        });
    }

    function Convert1 ($resource) {
        var resourceUrl =  'justify/' + 'api/:path/:storeId';

        return $resource(resourceUrl, {}, {
            'documentToImg': {
                method: 'POST',
                params: {
                    path: 'documentToImg'
                },
                isArray: true
            },
        });
    }

    function Convert ($resource) {
        var resourceUrl =  'fileserves/' + 'api/convert/:path/:storeId';

        return $resource(resourceUrl, {}, {
            "xmlToDocument":{
                method:"GET",
                params:{
                    path:"xmlToDocument"
                }
            },
            "imageFormat":{
                method:"GET",
                params:{
                    path:"imageFormat"
                }
            },
            "imgFilter":{
                method: 'GET',
                params: {
                    path: "filter"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

})();
