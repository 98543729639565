(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductOrderController', ProductOrderController);

    ProductOrderController.$inject = ['$state', 'ProductOrder', 'ParseLinks',  'paginationConstants', 'pagingParams', 'MessageService'
    ,'$scope', '$stateParams'];

    function ProductOrderController($state, ProductOrder, ParseLinks,   paginationConstants, pagingParams, MessageService,
        $scope, $stateParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.purchaseType = purchaseType;
        vm.status = status;

        loadAll();

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                ProductOrder.delete({id: id},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true)
        };
        function loadAll () {
            if (vm.searchQuery) {
                ProductOrder.byCondition2({
                    items: [{key:"number",op:"like",value:vm.searchQuery},{key:"storeId",op:"=",value:$stateParams.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ProductOrder.byCondition2({
                    items: [{key:"storeId",op:"=",value:$stateParams.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (!vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productPurchases = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        $scope.edit = function(purchase){
            if(purchase.purchaseType == 'COMMODITYPURCHASE'){
                $state.go('product-order-buy-edit', {id: purchase.id})
            }
            if(purchase.purchaseType == 'GIFTPURCHASE'){
                $state.go('product-order-buy1-edit', {id: purchase.id})
            }
        }
        $scope.look = function(purchase){
            if(purchase.purchaseType == 'COMMODITYPURCHASE'){
                $state.go('product-order-buy-look', {id: purchase.id, type: 'look'})
            }
            if(purchase.purchaseType == 'GIFTPURCHASE'){
                $state.go('product-order-buy1-look', {id: purchase.id, type: 'look'})
            }
        }
        $scope.submit = function(purchase){
            MessageService.confirm("确认要提交产品订购么？", function  () {
                ProductOrder.submit({id:purchase.id},function(){
                    loadAll();
                    MessageService.success("提交成功");
                },function(){
                    MessageService.error("提交失败");
                })
            },function(){})
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.loadAll();
        }
        function purchaseType(target) {
            switch (target) {
                case "GIFTPURCHASE":
                    return "礼包订购";
                case "COMMODITYPURCHASE":
                    return "商品订购";
                default:
                    return "";
            }
        }

        function status(target) {
            switch (target) {
                case "Create":
                    return "新建";
                case "Approving":
                    return "已提交";
                case "Approved":
                    return "已通过";
                case "Completed":
                    return "已完成";
                case "Rejected":
                    return "已拒绝";
                default:
                    return "";
            }
        }
    }
})();
