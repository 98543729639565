(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayCodeDialogController', PayCodeDialogController);

    PayCodeDialogController.$inject = ['$scope', '$q', 'PayCode', 'MessageService', '$stateParams', 'Product', 'PayBatch', 'DateUtils','$state'];

    function PayCodeDialogController ($scope, $q, PayCode, MessageService, $stateParams, Product, PayBatch, DateUtils, $state) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;

        vm.paybatchs = PayBatch.getAllByCondition({
            items:[{key:"used", op:"=", value:"false"},
                {key: "storeId", op: "=", value: $stateParams.storeId},
                {key:"status", op:"=", value:'Approved'},
                {key:"payBatchType", op:"=", value:'PAYCODE'}
            ]});

        $q.all([vm.paybatchs.$promise]).then(function(){
            angular.forEach(vm.paybatchs,function (batch) {
                if(batch.productName){
                    batch.productNameList = batch.productName.split(";");
                }
                if(batch.productSku){
                    batch.productSkuList = batch.productSku.split(";");
                }
            })
        })

        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;

            if(flag){
                flag = false;
                $(".paybatch-proName"+index).addClass('text-decoration-underline');
                $(".paybatch-tip"+index).show().css({left:e.clientX || 0,top:e.clientY || 0});
            }

        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        function save() {
            if(!$scope.batchArr.length){
                MessageService.error("请至少选择一个批次号");
                return
            }
            MessageService.confirm("确定要生成支付码？",function(){
                vm.isSaving = true;
                PayCode.createByPayBatches({payBatchIds: $scope.batchArr},function (data) {
                    vm.isSaving = false;
                    if(data.status==500){
                        MessageService.error(data.message);
                        return
                    }
                    $state.go('store-payCode', null, {reload: 'store-payCode'});
                    MessageService.success('添加成功');
                });
            },function(){},true);
        }

        function clear() {
            $state.go('store-payCode', null, {reload: 'store-payCode'});
        }


        $scope.batchArr = [];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.paybatchs,function (paybatch,i) {
                if($scope.selectAll){
                    paybatch.select = true;
                    $scope.batchArr.push(paybatch.id);
                    $scope.selectOne = true;
                }else{
                    paybatch.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (paybatch) {
            paybatch.select = !paybatch.select;
            // 选中
            if(paybatch.select){
                $scope.batchArr.push(paybatch.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(paybatch.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.paybatchs,function (paybatch,i) {
                if(!paybatch.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.paybatchs.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }

        }
    }
})();
