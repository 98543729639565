
(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CustomerController', CustomerController);

    CustomerController.$inject = ['$scope', '$state', '$stateParams', 'Principal', 'MessageService', 'ParseLinks', 'paginationConstants', 'pagingParams', 'Customer','$uibModal'];

    function CustomerController($scope, $state, $stateParams, Principal, MessageService, ParseLinks, paginationConstants, pagingParams, Customer, $uibModal) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.loadPage = loadPage;
        vm.changeStatue = changeStatue;
        vm.jump = jump;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.statue = pagingParams.statue;

        vm.idss = [];
        loadAll();

        $scope.getCustomerSource = function(customerSource){
            //  wechat,mini,web, aliMini,byteDance
            switch(customerSource) {
                case "wechat":
                    return "H5";
                case "mini":
                    return "微信小程序";
                case "web":
                    return "web端";
                case "aliMini":
                    return "支付宝小程序";
                case "byteDance":
                    return "字节小程序";
                default:
                    return "";
            }
        }
        
        function loadAll () {
            var items=[];
            if (pagingParams.search) {
                items.push({key:"name,phone",op:"like",value:pagingParams.search});
                Customer.getAll({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Customer.getAll({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = ["id,desc"];
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customers = data;
                console.log(data);
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("列表数据获取失败");
            }
        };

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }
        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;

            vm.statue = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
        function changeStatue (status){
            vm.statue = status;
            loadAll();
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                statue:vm.statue
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $scope.detail = function(customer){
            $uibModal.open({
                templateUrl: 'app/entities/customer/customers-detail.html',
                controller: 'CustomersDetail',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return customer;
                    }]
                }
            }).result.then(function () {

            });
        }

        $scope.inactive = function(customer){
            MessageService.confirm("确定拉黑当前用户？",function(){
                Customer.setInactive({id: customer.id,inactive:true}, function (res) {
                    loadAll();
                    MessageService.success("拉黑成功");
                }, function () {
                    MessageService.error("拉黑失败");
                })
            },function(){},true);
        }
        $scope.recover = function(customer){
            MessageService.confirm("确定恢复当前用户？",function(){
                Customer.setInactive({id: customer.id,inactive:false}, function (res) {
                    loadAll();
                    MessageService.success("恢复成功");
                }, function () {
                    MessageService.error("恢复失败");
                })
            },function(){},true);
        }
    }
})();
