(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CommodityPriceDetailController', CommodityPriceDetailController);

    CommodityPriceDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams',  'entity', 'CommodityPrice', 'Store', '$uibModal'];

    function CommodityPriceDetailController($scope, $state, $rootScope, $stateParams, entity, CommodityPrice, Store, $uibModal) {
        var vm = this;

        vm.commodityPrice = entity;
        vm.clear = clear;
        vm.stores = [];
        vm.storesAll = {name: '全部', selected: false};
        vm.isStores = true;
        Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]}, function (res) {
            for(var i=0; i<res.length; i++){
                if(!res[i].cancelled){
                    vm.stores.push(res[i])
                }
            }if(vm.commodityPrice.base){
                vm.storesAll.selected = true;
                vm.isStores = false;
            }else {
                for(var i=0; i<vm.commodityPrice.stores.length; i++){
                    for(var j=0; j<vm.stores.length; j++){
                        if(vm.commodityPrice.stores[i].id === vm.stores[j].id){
                            vm.stores[j].selected = true;
                        }
                    }
                }
            }
        })

        $scope.lookPricing = function (data, index) {
            $uibModal.open({
                templateUrl: 'app/entities/commodity-price/modal/pricing-look.html',
                controller: 'PricingController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'look',
                            data: data
                        };
                    }]
                }
            });
        }
        function clear(){
            $state.go("commodity-price");
        }

        var unsubscribe = $rootScope.$on('platformApp:commodityPriceUpdate', function(event, result) {
            vm.commodityPrice = result;
        });
        $scope.$on('$destroy', unsubscribe);
        vm.priceRuleStatus = priceRuleStatus;
        function priceRuleStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "Standard":
                    return "标准定价";
                case "Ladder":
                    return "阶梯定价";
                case "Grads":
                    return "梯度定价";
                default:
                    return "";
            }
        }
    }
})();
