(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('platform-a-li-setting', {
            parent: 'entity',
            url: '/platform-a-li-setting',
            data: {
                authorities: ['ROLE_PLATFROM_SETTING'],
                pageTitle: 'platformApp.platformALiSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/platform-a-li-setting/platform-a-li-setting-detail.html',
                    controller: 'PlatformALiSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('platformALiSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PlatformALiSetting', function($stateParams, PlatformALiSetting) {
                    return PlatformALiSetting.getOne().$promise;
                }],
                previousState: ["$state", function($state) {
                    var currentStateData = {
                         name: $state.current.name || 'platform-a-li-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })


        .state('platform-a-li-setting-edit', {
            parent: 'entity',
            url: '/platform-a-li-setting-edit',
            data: {
                authorities: ['ROLE_PLATFROM_SETTING']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/platform-a-li-setting/platform-a-li-setting-dialog.html',
                    controller: 'PlatformALiSettingDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('platformALiSetting');
                    return $translate.refresh();
                }],
                entity: ['PlatformALiSetting', '$stateParams', function(PlatformALiSetting, $stateParams) {
                    return PlatformALiSetting.getOne().$promise;
                }]
            }
        })
         ;
    }

})();
