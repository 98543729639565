(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('SalesOrderExportDialogController', SalesOrderExportDialogController);

    SalesOrderExportDialogController.$inject = ['$timeout', '$http', '$scope', '$stateParams',  '$state', '$uibModalInstance', 'entity',
    'Product', 'Store', 'MessageService', 'Category', 'DateUtils'];

    function SalesOrderExportDialogController ($timeout, $http, $scope, $stateParams, $state, $uibModalInstance, entity,
     Product, Store, MessageService, Category, DateUtils) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.openCalendar = openCalendar;
        vm.dateformat = dateformat;
        vm.export = exportData;

        vm.clear = clear;
        vm.categorys = Category.getAllByCondition({items:[]});
        vm.stores = Store.getAllByCondition({items:[{key:"activated",op:"=", value:"true"}]});
        vm.products = Product.getAllByCondition2({items: [{key:"prototype",op:"=",value:"Original"}]});

        vm.sourceTypes = [{
            name:"WECHAT",
            value:"公众号"
        },{
            name:"MINI",
            value:"微信小程序"
        },{
            name:"WEB",
            value:"网页端"
        }];

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        function exportData(){
            if (!vm.endTime || !vm.startTime) {
                MessageService.error("请录入下单时间");
                return;
            }
            if ((vm.endTime -vm.startTime)/24/3600/1000>31) {
                MessageService.error("下单时间间距不能超过31天");
                return;
            }
            var json_str = getItems();
            $http({
                url: 'service/api/sales-orders/downloadFile?items=' + JSON.stringify(json_str),
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/vnd.ms-excel"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "订单详情" + DateUtils.convertLocalDateToServer(Date.now()) + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
            // window.open("service/api/sales-orders/downloadFile?items=" + JSON.stringify(json_str));
        }

        function getItems() {
            var items=[];
            if (vm.endTime !=null) {
                items.push({key: "createdDate", op: "<=", value: new Date(Date.parse(vm.endTime) + 16 * 60 * 60 * 1000 - 1000)});
            };
            if (vm.startTime !=null) {
                items.push({key: "createdDate", op: ">=", value: new Date(Date.parse(vm.startTime) -8 * 60 * 60 * 1000)});
            };
            //商品分类
            if (vm.categoryId !=null) {
                items.push({key:"orderLines.orderArticle.categoryId",op:"=",value:vm.categoryId});
            };
            //商品名称
            if (vm.productName !=null) {
                items.push({key:"orderLines.orderArticle.productName",op:"=",value:vm.productName});
            };
            //商户名
            if (vm.storeId !=null) {
                items.push({key:"storeId",op:"=",value:vm.storeId});
            };
            //订单来源
            if (vm.sourceType !=null) {
                items.push({key:"sourceType",op:"=",value:vm.sourceType});
            };
            if (vm.status !=null && vm.status !="") {
                items.push({key:"status",op:"=",value:vm.status});
            };

            return items;
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }




    }
})();
