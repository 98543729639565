(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RoleDialogController', RoleDialogController);

    RoleDialogController.$inject = ['$timeout', '$q', '$scope', '$stateParams', '$state', 'entity', 'Role',
     'Authority', 'MessageService', 'ListService' ];

    function RoleDialogController ($timeout, $q, $scope, $stateParams, $state, entity, Role,
     Authority, MessageService, ListService ) {
        var vm = this;

        vm.role = entity;
        vm.clear = clear;
        vm.save = save;
        vm.authorities = Authority.getAllByCondition({items:[{key:"jhiType",op:"=",value:"PLATFORM"}]});
        vm.authorities_2 = [];
        vm.mods = [];
        $q.all([vm.role.$promise, vm.authorities.$promise]).then(function(){
            vm.role.type = "PLATFORM";
            $scope.groupBy();
            console.log(vm.mods)
            //编辑
            if (vm.role.id) {
                for (var i = 0; i < vm.role.authorities.length; i++) {
                    var au =  vm.role.authorities[i];
                    var result = ListService.inList(au, vm.mods, ["module"]);
                    if (result) {
                       for (var j = result.authorities.length - 1; j >= 0; j--) {
                            var authority = result.authorities[j];
                            if(authority.id == au.id){
                                authority.selected = true;
                                break;
                            }
                        }
                    }
                }

                for (var i = vm.mods.length - 1; i >= 0; i--) {
                    var mod = vm.mods[i];
                    for (var j = mod.authorities.length - 1; j >= 0; j--) {
                        var au = mod.authorities[j];
                        if (!au.selected) {
                            mod.unAllSelect = true;
                            break;
                        }
                    }
                    if (mod.unAllSelect) {
                        mod.selected = false;
                    }else {
                        mod.selected = true;
                    }
                }
            };

        });

        $scope.chooseAuthorty = function(mod, au){
            au.selected = !au.selected;
            if (!au.selected) {
                mod.selected = false;
            }
            for (var i = mod.authorities.length - 1; i >= 0; i--) {
                var auth = mod.authorities[i];
                if (!auth.selected) {
                    mod.selected = false;
                    return;
                }
            }
            mod.selected = true;
        }

        $scope.chooseMod = function(mod){
            if (!mod) {
                return;
            }
            mod.selected = !mod.selected;
            for (var i = mod.authorities.length - 1; i >= 0; i--) {
                var au = mod.authorities[i];
                au.selected = mod.selected;
            }
        }


        $scope.groupBy = function(){
            vm.mods = [];
            if(vm.authorities == null || vm.authorities.length == 0 || vm.role.type == null || vm.role.type == ""){
                return null;
            }
            for (var i = vm.authorities.length - 1; i >= 0; i--) {
                var authority = vm.authorities[i];
                if (authority.jhiType != vm.role.type) {
                    continue;
                }
                var e = ListService.inList(authority,vm.mods ,["module"]);
                if(!e){
                    var r = {module:authority.module,authorities:[]};
                    vm.mods.push(r);
                }

                for (var j = vm.mods.length - 1; j >= 0; j--) {
                    var mod = vm.mods[j];
                    if(authority.module == mod.module){
                        authority.selected = false;
                        mod.authorities.push(authority);
                        break;
                    }
                }
            }
        };

        $scope.authoritySelected = function(authority){
            authority.selected = !authority.selected;

        };


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('role', null, {reload: 'role'});
        }

        $scope.toPost = function (){
            var postData  ={};
            postData.authorities = [];
            postData.id = vm.role.id;
            postData.name = vm.role.name;
            postData.type = vm.role.type;
            postData.number = vm.role.number;
            postData.cancelled = vm.role.cancelled;
            for (var i = vm.mods.length - 1; i >= 0; i--) {
                var mod = vm.mods[i];
                for (var j = mod.authorities.length - 1; j >= 0; j--) {
                    var au = mod.authorities[j];
                    if (au.selected) {
                        postData.authorities.push(au);
                    }
                }
            }
            return postData;
        };

        $scope.validate = function(){
            if(vm.role.name == null || vm.role.name == ""){
                MessageService.error("请输入名称");
                return false;
            }
            if(vm.role.type == null || vm.role.type == ""){
                MessageService.error("请输入类型");
                return false;
            }
            return true;
        };


        function save () {
            if(!$scope.validate()){
                return
            }
            var data = $scope.toPost();
            vm.isSaving = true;
            if (vm.role.id !== null) {
                Role.update(data, onSaveSuccess, onSaveError);
            } else {
                Role.save(data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:roleUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
            $state.go("role");
            $timeout(function (){
                window.location.reload();
            },50);
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }


    }
})();
