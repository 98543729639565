(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('TaskbillForms', TaskbillForms);

    TaskbillForms.$inject = ['$resource'];

    function TaskbillForms ($resource) {
        var resourceUrl =  'manager/' + 'api/taskBillForms/:path/:id';
        return $resource(resourceUrl, {}, {
            "fields":{
                method:"GET",
                params:{
                    path:"fields"
                },
                isArray:true
            },
            "create":{
                method:"POST",
                params:{
                    path: "create"
                }
            },
            "update":{
                method:"POST",
                params: {
                    path: "update"
                }
            },
            "delete":{
                method:"DELETE"
            },
            "get":{
                method:"GET"
            },
            "findAll":{
                method:"POST",
                params: {
                    path: "findAll"
                },
                isArray: true
            },
            "findPage": {
                method: "POST",
                params: {
                    path: "findPage"
                },
                isArray: true
            }
        });
    }
})();
