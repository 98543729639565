(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory("Province",Province)
        .factory('Region', Region);

    Region.$inject = ['$resource'];

    Province.$inject = ['$resource'];

    function Province ($resource) {
        var resourceUrl =  'manager/' + 'api/provinces/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                isArray: true

            },
            'getAll': {
                method: 'GET',
                params: {
                    path: "all"
                },
                isArray: true
            },
            'other': {
                method: 'GET',
                params: {
                    path: 'other'
                },
                isArray: true
            }
        });
    }

    function Region ($resource) {
        var resourceUrl =  'manager/' + 'api/regions/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "getAllByCondition":{
                method:"POST",
                isArray: true,
                params:{
                    path:"getAllByCondition"
                }
            },
            "createByAgent":{
                method:"POST",
                params:{
                    path:"createByAgent"
                }
            },
            "byCondition":{
                method:"POST",
                isArray: true,
                params:{
                    path:"byCondition"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            'delBatch': {
                method:'DELETE',
                params:{
                    path:"delBatch"
                }
            }
        });
    }
})();
