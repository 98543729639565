(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LogisticsCompanyController', LogisticsCompanyController);

    LogisticsCompanyController.$inject = ['$state', '$scope', 'LogisticsCompany', 'Region','$q', 'Province','ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService'];

    function LogisticsCompanyController($state, $scope, LogisticsCompany, Region,$q,Province,ParseLinks, paginationConstants, pagingParams, MessageService) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.back = back;
        vm.save = save;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;


        $scope.showOrNot = 'expressList';
        vm.tempArr =[];
        $scope.selectOne = false;
        $scope.selectAll = false;

        loadAll();
        $scope.chooseAll =function(){
            $scope.selectAll = !$scope.selectAll;
            angular.forEach(vm.logisticsCompanies,function(item){
                item.select = $scope.selectAll;
            });
            vm.tempArr = vm.logisticsCompanies.filter(function(item){
                return item.select == true;
            });
        };

        $scope.chooseOne = function(data){
            data.select = !data.select;
            $scope.selectAll = vm.logisticsCompanies.every(function(item){
                return item.select;
            });
            vm.tempArr = vm.logisticsCompanies.filter(function(item){
                return item.select == true;
            });
        };

        //new express
        $scope.newExpress = function(){
            $scope.showOrNot ='expressNew';
            $scope.expressView =false;
            vm.logisticsCompany={}
        };

        //view express
        $scope.expressViews = function(id){
            $scope.showOrNot ='expressNew';
            $scope.expressView =true;
            vm.logisticsCompany =LogisticsCompany.get({id : id});
        };

        //edit express
        $scope.expressEdit = function(id){
            $scope.showOrNot ='expressNew';
            $scope.expressView =false;
            vm.logisticsCompany =LogisticsCompany.get({id : id});
        };

        $scope.validate = function(){
            if (vm.logisticsCompany.name == '' || vm.logisticsCompany.name == null) {
                MessageService.error("名称不能为空");
                return false;
            };
            if (vm.logisticsCompany.code == '' || vm.logisticsCompany.code == null) {
                MessageService.error("编码不能为空");
                return false;
            };
            // if (vm.logisticsCompany.customerNumber == '' || vm.logisticsCompany.customerNumber == null) {
            //     MessageService.error("客户号不能为空");
            //     return false;
            // };
            // if (vm.logisticsCompany.customerPassword == '' ||vm.logisticsCompany.customerPassword == null) {
            //     MessageService.error("客户密码不能为空");
            //     return false;
            // };
            return true;
        };
        //save express
        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.logisticsCompany.id !== null) {
                LogisticsCompany.update(vm.logisticsCompany, onSaveSuccess1, onSaveError1);
            } else {
                LogisticsCompany.save(vm.logisticsCompany, onSaveSuccess1, onSaveError1);
            }
        }

        function onSaveSuccess1 (result) {
            $scope.$emit('backApp:logisticsCompanyUpdate', result);
            vm.isSaving = false;
            MessageService.success('操作成功');
            $scope.showOrNot ='expressList';
            loadAll();
        }

        function onSaveError1 () {
            vm.isSaving = false;
            MessageService.error('操作失败');
        }

        function back(){
            $scope.showOrNot ='expressList';
            loadAll();
        };


        //delete express 批量
        $scope.deleteExpress = function(){
            var ids = vm.tempArr.map(function(item){return item.id}).join(',');
            MessageService.confirm("确认要批量删除该快递公司?", function(){
                LogisticsCompany.delBatch({ids:ids}, function(data){
                    MessageService.success("删除成功");
                    loadAll();
                },function(){
                    MessageService.error("删除失败");
                })
            }, function(){})
        };

        $scope.delete = function(id){
            MessageService.confirm("确认要删除该快递公司?", function(){
                LogisticsCompany.delete({id:id}, function(data){
                    MessageService.success("删除成功");
                    loadAll();
                },function(){
                    MessageService.error("删除失败");
                })
            }, function(){})
        };

        function loadAll () {
            if (pagingParams.search) {
                LogisticsCompany.byCondition({
                    items:[{key:"name",op:"like",value:pagingParams.search}, {key: "storeId", op: "=", value: "null"}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                LogisticsCompany.byCondition({
                    items: [{key: "storeId", op: "=", value: "null"}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.logisticsCompanies = data;
                console.log(data);
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("物流公司获取失败");
            }
        }



        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }
    }
})();
