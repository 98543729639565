(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreWechatSettingPageController', StoreWechatSettingPageController);

    StoreWechatSettingPageController.$inject = ['$scope', '$state','$stateParams', '$q' ,'$rootScope', 'MessageService', 'entity', 'StoreWechatSetting', 'Store',
     'PlatformWechatSetting', '$uibModalInstance'];

    function StoreWechatSettingPageController($scope, $state,$stateParams, $q, $rootScope, MessageService, entity, StoreWechatSetting, Store, PlatformWechatSetting, $uibModalInstance) {
        var vm = this;
        vm.cancel = cancel;
        vm.data = entity;
        vm.menu = {};
        vm.setting = PlatformWechatSetting.byPlatFrom();
        vm.store = Store.getCurrent();
        $q.all([vm.data.$promise, vm.setting.$promise, vm.store.$promise]).then(function(){
            if (!vm.data || vm.data.errmsg) {
                MessageService.error("微信菜单获取失败");
            }else{
                if (!vm.setting.storeNotifyUrl) {
                    MessageService.error("请联系平台运营方，设置公众号链接");
                }
                if (!vm.store.id) {
                    MessageService.error("获取商户信息失败");
                }
                vm.storeId = vm.store.id;
                vm.urls=[
                    {key:"主页", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/index"},
                    {key:"个人中心", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/customer"},
                    {key:"作品", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/article"},
                    {key:"订单列表", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/sales-order/All"},
                    {key:"商家优惠券", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/coupon/newCoupon"}];
                if (!vm.data.selfmenu_info || !vm.data.selfmenu_info.button) {
                    vm.menu.button = [];
                }else{
                    vm.menu.button = vm.data.selfmenu_info.button;
                }
            }
        });

        vm.firstButtons =JSON.parse(localStorage.getItem('flag'));

        //StoreWechatSetting.getMenu({},function(data){
        //    console.log(data);
        //    angular.forEach(data.selfmenu_info.button,function(item,index){
        //        if(item.sub_button == null){
        //            data.selfmenu_info.button[index].sub_button = [];
        //        }else{
        //            if(item.sub_button.list == undefined || item.sub_button.list == '' || item.sub_button.list == null){
        //                data.selfmenu_info.button[index].sub_button = [];
        //            }else{
        //                data.selfmenu_info.button[index].sub_button=item.sub_button.list;
        //            }
        //        }
        //    });
        //    vm.firstButtons = data.selfmenu_info.button;
        //    console.log(vm.firstButtons);
        //});

        function cancel () {
            $uibModalInstance.dismiss('cancel');
        }

        //点击一级菜单
        $scope.firstMenu =  function(data,index){
            $scope.selectedMenu = data;
        };
    }
})();
