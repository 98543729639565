(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RechargeLevelController', RechargeLevelController);

    RechargeLevelController.$inject = ['$state', 'RechargeLevel', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', '$scope'];

    function RechargeLevelController($state, RechargeLevel, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, $scope) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.jump = jump;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.clear = clear;

        loadAll();
        function loadAll () {
            if (pagingParams.search) {
                RechargeLevel.byCondition({
                    items: [{key:"name",op:"like",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                RechargeLevel.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.rechargeLevels = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;

            vm.statue = null;
            vm.transition();
        }

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                RechargeLevel.delete({id: id},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true)
        };
    }
})();
