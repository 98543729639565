(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformAbutmentEditController', PlatformAbutmentEditController);

    PlatformAbutmentEditController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 
    'PlatformAbutment', 'MessageService', 'Store'];

    function PlatformAbutmentEditController($timeout, $scope, $stateParams, $uibModalInstance, entity, 
        PlatformAbutment, MessageService, Store) {
        var vm = this;

        vm.abutment = entity;
        if (vm.abutment != null) {
            vm.storeId = parseInt(vm.abutment.storeId); //returns 1234
        }
        vm.clear = clear;
        vm.save = save;

        getStores();
        function getStores() {
            Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]}, function(data){
                vm.stores = data;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.abutment.enabled = true;
            vm.abutment.storeId = vm.storeId + "";
            PlatformAbutment.save(vm.abutment, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:afterSaleProblemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('操作成功');
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error('操作失败');
        }
    }
})();