(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('ThreePreviewController', ThreePreviewController);

    ThreePreviewController.$inject = ['$scope', '$stateParams', 'Qinius', '$http', '$q', 'ImageService', 'PlatformWechatSetting', 'Music', 'Store', 'MessageService','$state', 
    '$uibModalInstance','threeTemplate','threeProductXmlData','$compile','$timeout','Convert1', 'ThreeEnv'];

    function ThreePreviewController($scope, $stateParams, Qinius, $http, $q, ImageService, PlatformWechatSetting, Music, Store, MessageService,$state,
     $uibModalInstance, threeTemplate, threeProductXmlData, $compile, $timeout, Convert1, ThreeEnv) {
        var vm = this;
        vm.template = threeTemplate;
        vm.productXml = threeProductXmlData;
        $scope.domains = Qinius.getDomain();
        vm.loading = true;
        vm.error = false;
        vm.data = "";
        vm.clear = clear;
        $q.all([$scope.domains.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            Convert1.documentToImg(vm.template, function (res) {
                vm.modelData = vm.productXml.modelData;
                vm.modelPath = vm.productXml.modelPath;
                for(var i=0; i<vm.modelData.length; i++){
                    for(var j=0; j<res.length; j++){
                        if(vm.modelData[i].page === res[j].seq){
                            vm.modelData[i].img = $scope.domain + res[j].key;
                            console.log(vm.modelData[i].img)
                        }
                    }
                }
                init();
                animate();
                vm.viewWidth = $(".threeView-body").width();
                vm.viewHeight = $(".threeView-body").height();
            }, function (error) {
                loaddingError(error.message);
            })
        });

        function clear () {
            $uibModalInstance.dismiss();
        }
        function loaddingError(message) {
            vm.loading = false;
            vm.error = true;
            vm.errorText = message;
        }
        function loaddingSuccess() {
            vm.loading = false;
            vm.error = false;
        }

        var scene, camera, renderer, controls, guiControls;
        // var stats = initStats();

        /* 场景 */
        function initScene() {
            scene = new THREE.Scene();
            // var materialColor = new THREE.MeshBasicMaterial({
            //     map:THREE.ImageUtils.loadTexture("http://photoavatar-pic-dev.deltazo.com/product-b9d71fbb-0caa-4d5f-ca23-71a1dd41aa74?imageMogr2/auto-orient"),
            //     depthTest: false
            // });
            // materialColor.map.wrapS;
            // materialColor.map.wrapT;
            // console.log(materialColor);
            // var bgPlane = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), materialColor);
            // bgPlane.position.z = -100;
            // bgPlane.scale.set(window.innerWidth * 2, window.innerHeight * 2, 1);
            // scene.add(bgPlane);
        }

        /* 相机 */
        function initCamera() {
            // camera = new THREE.PerspectiveCamera(50, vm.viewWidth / vm.viewHeight, 0.1, 1000);
            // camera.position.x = 80;
            // camera.position.y = 80;
            // camera.position.z = 50;
            // // camera.position.set(80, 80, 30);
            // camera.lookAt(new THREE.Vector3(0, 10, 0));

            camera = new THREE.PerspectiveCamera(50, clientWidth / clientHeight, 0.1, 1000);
            var l1 = new THREE.AmbientLight(0xffffff, 0.3);
            l1.name = 'a-light';
            camera.add(l1);
            var l2 = new THREE.DirectionalLight(0xffffff, 0.8 * Math.PI );
            l2.position.set(6, 0, 6); // ~60º
            l2.name = 'm-light';
            l2.castShadow = true;
            camera.add(l2);
            var l3 = new THREE.HemisphereLight(0xffffff, 0x000000, 1);
            l3.name = 'h-light';
            camera.add(l3);
            scene.add(camera);

        }

        /* 渲染器 */
        function initRender() {
            rrenderer = new THREE.WebGLRenderer({
                antialias: true, 
                alpha:true,
                precision: 'highp',
                preserveDrawingBuffer: true
            });
            renderer.setClearColor(new THREE.Color(0xf3f3f3),0.0);
            renderer.setSize(vm.viewWidth, vm.viewHeight);
            renderer.shadowMap.enabled = true;
            renderer.physicallyCorrectLights = true;
            renderer.outputEncoding = THREE.sRGBEncoding;
            renderer.minFilter = THREE.LinearFilter;
            renderer.gammaOutput = true;
            renderer.setPixelRatio( window.devicePixelRatio );

            $timeout(function () {
                document.getElementById("threeView1").appendChild(renderer.domElement);
                onWindowResize()
                initContent();
                loaddingSuccess();
                $scope.$apply();
            })
        }

        /* 灯光 */
        function initLight() {
            scene.add(new THREE.AmbientLight(0xdddddd));
            var directionalLight = new THREE.DirectionalLight(0xdddddd, 0.1);
            directionalLight.position.set(-4, 8, 4);
            scene.add(directionalLight);
        }

        /* 控制器 */
        function initControls() {
            controls = new THREE.OrbitControls(camera, renderer.domElement);
            /* 属性参数默认 */
        }

        /* 调试插件 */
        function initGui() {
            guiControls = new function () {};
            var controls = new dat.GUI({width: 200});
        }

        /* 场景中的内容 */
        function initContent() {
            // 加载 glTF 格式的模型
            var loader = new THREE.GLTFLoader();
            if (THREE.DRACOLoader) {
                var dracoLoader = new THREE.DRACOLoader();
                if (dracoLoader) {
                    dracoLoader.setDecoderPath("lib/three/draco/");
                    loader.setDRACOLoader( dracoLoader );
                }
            }
            loader.load(vm.modelPath, function (gltf) {
                var metalness = checkGltfMetalness(gltf);
                var gltfScene = gltf.scene || gltf.scenes[0];
                var clips = gltf.animations || [];
                gltfScene.updateMatrixWorld();
                var box = new THREE.Box3().setFromObject(gltfScene);
                var size = box.getSize(new THREE.Vector3()).length();
                var center = box.getCenter(new THREE.Vector3());
                // controls.reset();
                gltfScene.position.x += gltfScene.position.x - center.x;
                gltfScene.position.y += gltfScene.position.y - center.y;
                gltfScene.position.z += gltfScene.position.z - center.z;

                // 重新设置相机参数
                controls.maxDistance = size * 10;
                camera.near = size / 80;
                camera.far = size * 80;
                camera.updateProjectionMatrix();

                camera.position.copy(center);
                camera.position.x += size / 1.0;
                camera.position.y += size / 1.0;
                camera.position.z += size / 1.0;
                camera.lookAt(center);

                gltf.scene.traverse(function(child){
                    if (child.isMesh) {
                        child.material.depthWrite = !child.material.transparent;
                        var materials = Array.isArray(child.material) ? child.material : [child.material];
                        materials.forEach(function (node) {
                            for(var i=0; i<vm.modelData.length; i++){
                                if(vm.modelData[i].materialName === node.name && vm.modelData[i].img){
                                    var textureLoader = new THREE.TextureLoader();
                                    var map = textureLoader.load(vm.modelData[i].img);
                                    map.flipY = false;

                                    if (node.map) {
                                        // copy current map repeat and offset to the new map
                                        // this is for gltfpack (meshoptimizer) compressed models
                                        // Also, in the model, you need to embed a texture image so that we can retrieve it here.
                                        map.repeat.copy(node.map.repeat);
                                        map.offset.copy(node.map.offset);
                                    }

                                    // copy texture encoding from existing one to the loaded texture
                                    map.encoding = node.map ? node.map.encoding : THREE.sRGBEncoding;
                                    
                                    node.map = map;
                                    node.needsUpdate = true;
                                }
                            }
                        });
                    }
                })
                scene.add(gltf.scene);
                if (metalness > 0) {
                    console.log("metalness=" + metalness);
                    updateEnvironment();
                }
                // 判断当前的gltf问价中是否有动画
                if (clips.length !== 0) {
                    var mixer = new THREE.AnimationMixer(gltfScene);
                    mixer.clipAction(gltf.animations[0]).play();
                }
            }, function (xhr) {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            }, function (error) {
                loaddingError();
            })

        }

        function traverseMaterials(object, callback) {
            object.traverse(function(node){
                if (!node.isMesh) return;
                var materials = Array.isArray(node.material)
                    ? node.material
                    : [node.material];
                materials.forEach(callback);
            });
        }

        function getWebGLErrorMessage() {
            var element = document.createElement('div');
            element.id = 'webgl-error-message';
            element.style.fontFamily = 'monospace';
            element.style.fontSize = '13px';
            element.style.fontWeight = 'normal';
            element.style.textAlign = 'center';
            element.style.background = '#fff';
            element.style.color = '#000';
            element.style.padding = '1.5em';
            element.style.width = '400px';
            element.style.margin = '5em auto 0';
            if (!this.webgl) {
                element.innerHTML = window.WebGLRenderingContext ? [
                    'Your graphics card does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color:#000">WebGL</a>.<br />',
                    'Find out how to get it <a href="http://get.webgl.org/" style="color:#000">here</a>.'
                ].join('\n') : [
                    'Your browser does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color:#000">WebGL</a>.<br/>',
                    'Find out how to get it <a href="http://get.webgl.org/" style="color:#000">here</a>.'
                ].join('\n');
            }
            return element;
        };

        /* 性能插件 */
        function initStats() {
            // var stats = new Stats();
            // // document.body.appendChild(stats.domElement);
            // $timeout(function () {
            //     document.getElementById("threeView1").appendChild(stats.domElement);
            // })
            // return stats;
        }

        /* 窗口变动触发 */
        function onWindowResize() {
            var width = $(".threeView-body").width();
            var height = $(".threeView-body").height();
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
        }

        /* 数据更新 */
        // function update() {
        //     stats.update();
        // }
        /* 初始化 */
        function init() {
            initScene();
            initCamera();
            initRender();
            initLight();
            initControls();
            // initGui();
            /* 监听事件 */
            window.addEventListener('resize', onWindowResize, false);
        }

        /* 循环渲染 */
        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
            // update();
        }

        function checkGltfMetalness(gltf) {
            var metalness = 0;
            if (gltf && gltf.scene) {
                metalness = parseGroupMetalness(gltf.scene);
            }
            return metalness;
        }

        function parseGroupMetalness(group) {
            var metalness = 0;
            if (!group.children) {
                return metalness;
            }
            for (var i = 0; i < group.children.length; i++) {
                var child = group.children[i];
                if (child.type == 'Mesh') {
                    if (child.material && child.material.metalness > metalness) {
                        metalness = child.material.metalness;
                    }
                }
                else if (child.type == 'Group') {
                    var val = parseGroupMetalness(child);
                    if (val > metalness) {
                        metalness = val;
                    }
                }
            }
            return metalness;
        }

        function updateEnvironment () {
            ThreeEnv.getEnvMap(renderer).then(
                function (data) {
                    if (data && data.envMap) {
                        scene.environment = data.envMap;
                    }
                }
            );
        }
    }
})();
