(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$q', '$scope', 'Principal', 'LoginService', '$state', '$timeout', 'Auth', '$rootScope', 'MessageService', '$localStorage', 'PlatformWechatSetting', 'Qinius'];

    function HomeController($q, $scope, Principal, LoginService, $state, $timeout, Auth, $rootScope, MessageService, $localStorage, PlatformWechatSetting, Qinius) {
        var vm = this;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        vm.domains = Qinius.getDomain();
        vm.platformSetting = PlatformWechatSetting.byPlatFrom();
        $q.all([vm.domains.$promise, vm.platformSetting.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            console.log($scope.domain);
            console.log(vm.platformSetting);
        })

        $timeout(function () {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function login(event) {
            if (vm.username == null || vm.username == '' || vm.password == null || vm.password == '') {
                MessageService.error("请输入用户名和密码");
                return;
            }
            event.preventDefault();

            // 由于异步处理方法太多，在不改变方法的同时再次调用登录方法
            a_login(0);
            a_login(1);
        }

        function a_login(num) {
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;

                $state.go('home-clear');
                // $(".wrap").css({margin: '48px 10px 10px 230px'});
                delete $localStorage.menu1;
                delete $localStorage.menu2;
                delete $localStorage.pageName;

                $rootScope.$broadcast('authenticationSuccess');
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (err) {
                if (num == 1) {
                    MessageService.error("用户名或者密码错误");
                }
                vm.authenticationError = true;
            });
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }
    }
})();
