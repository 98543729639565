(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('DiyEditorSettingController', DiyEditorSettingController);

    DiyEditorSettingController.$inject = ['$scope', '$q', '$state', 'DiyEditorSetting', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', 'Principal', '$uibModal'];

    function DiyEditorSettingController ($scope, $q, $state, DiyEditorSetting, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, Principal, $uibModal) {
        var vm = this;
        // Principal.hasAuthority('ROLE_STORE_DIANPU').then(function (data) {
        //     if (!data) {
        //         MessageService.error("不好意思，你不具有访问权限！");
        //         $state.go("home");
        //     }
        // });

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.tabFlag =1;
        vm.isSort = false;

        vm.jump = jump

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        $scope.delete = function(item){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                DiyEditorSetting.delete({id: item.id},function () {
                    loadAll();
                });
            },function(){},true);
        };

        function loadAll () {
            if (pagingParams.search) {
                DiyEditorSetting.byCondition1({
                    items: [{key:"theme",op:"=",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                DiyEditorSetting.byCondition1({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = data.content;
                vm.queryCount = vm.totalItems;
                vm.items = data.content;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("编辑器获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("entity.action.deleteConfirm",function(){
                DialogConfig.deleteBatch({ids: $scope.batchArr},function () {
                    MessageService.success("删除成功");
                    $scope.selectOne = false;
                    $scope.selectAll = false;
                    loadAll();
                },function () {
                    MessageService.error("删除失败");
                })
            },function(){},true);

        }


        $scope.createEditor = function () {
            $uibModal.open({
                templateUrl: 'app/entities/diyEditorSetting/diy-editor-setting-create.html',
                controller: 'DiyEditorSettingCreateController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'add'
                        };
                    }]
                }
            }).result.then(function(item) {
                if(item){
                    loadAll()
                }
            });
        }
    }
})();
