(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductXmlSimpleController', ProductXmlSimpleController);

    ProductXmlSimpleController.$inject = ['$timeout', '$rootScope', '$scope', '$state', '$uibModalInstance', '$q', 'entity', 'MessageService', 'ProductXmls', 'Qinius',
    'UidService', 'Convert'];

    function ProductXmlSimpleController($timeout, $rootScope, $scope, $state, $uibModalInstance, $q, entity, MessageService, ProductXmls, Qinius,
        UidService, Convert) {
        var vm = this;
        vm.productXml = entity;
        vm.clear = clear;
        vm.showPicCheck = showPicCheck;

        function showPicCheck(show){
            vm.productXml.showPic = show;
        }

        $scope.load = function() {
            if (vm.productXml.documentPics == null) {
                vm.productXml.documentPics = [];
            }
            Convert.xmlToDocument({
                identifier: vm.productXml.identifier
            }, function(res) {
                if (res && res.documentpics && res.documentpics.documentpic) {
                    for (var i = res.documentpics.documentpic.length - 1; i >= 0; i--) {
                        var breakBoolean = false;
                        var pic = res.documentpics.documentpic[i];
                        if (pic.resource == null || !pic.resource.identifier) {
                            continue;
                        }
                        for (var j = vm.productXml.documentPics.length - 1; j >= 0; j--) {
                            var orPic = vm.productXml.documentPics[j];
                            if (orPic.deleted || orPic.cancelled) {
                                continue;
                            }
                            if (orPic.seq == pic.seq && orPic.identifier == pic.resource.identifier) {
                                breakBoolean = true;
                                break;
                            }
                        }
                        if (!breakBoolean) {
                            vm.productXml.documentPics.push({
                                seq: pic.seq,
                                identifier: pic.resource.identifier,
                                provider: "Qiniu",
                                added: true
                            });
                        }
                    }
                    vm.productXml.documentPics.sort(function(a, b) {
                        return a.seq - b.seq;
                    });
                    MessageService.info("重载成功");
                }
            });
        }

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.productXml.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.productXml.documentPics.sort(function(a, b){
                return a.seq- b.seq;
            })
        });

        $scope.deletePic = function(pic){
            if (pic == null) {
                return;
            }
            if (pic.added) {
                var inde =vm.productXml.documentPics.indexOf(pic);
                if (inde>=0) {
                    vm.productXml.documentPics.splice(inde, 1);
                }
            }else{
                pic.deleted = true;
            }
        };

        vm.token = "";
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });

        function qiniuUpload(files) {
            var result = [];
            for (var i = files.length - 1; i >= 0; i--) {
                var file = files[i];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "file" + file.name + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: true,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        result.push({ key:data.key})
                        if (result.length == files.length) {
                            result.sort(function(a, b){
                                return a.key.localeCompare(b.key);
                            })
                            if (vm.productXml.documentPics == null) {
                                vm.productXml.documentPics = [];
                            }
                            for (var j = 0 ; j< result.length ; j++) {
                                var seq = vm.productXml.documentPics.length+1;
                                var r = result[j];
                                vm.productXml.documentPics.push({
                                    name:r.key,
                                    seq:seq,
                                    identifier:$scope.domain + r.key,
                                    provider:"Qiniu",
                                    added: true
                                });
                            }
                            $scope.$apply();
                        }




                    },
                    error: function (request) {
                        MessageService.error("图片上传失败");
                    }
                });
            }

        }

        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files !=null && files.length >0) {
                qiniuUpload(files);
                // for (var i = 0; i< files.length  ; i++) {
                //     var f = files[i];

                // }
            }
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('editorApp:productXmlUpdate', function(event, result) {
            vm.productXml = result;
        });

        $scope.$on('$destroy', unsubscribe);

        $scope.save = function(){
            ProductXmls.showPic(vm.productXml, function(data){
                $scope.$emit('editorApp:productXmlUpdate', data);
                $uibModalInstance.close(data);
                MessageService.success("保存成功");
            },function(){
                MessageService.error("保存失败");
            })
        }
    }
})();
