(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('AfterSaleProblem', AfterSaleProblem);

    AfterSaleProblem.$inject = ['$resource', 'DateUtils'];

    function AfterSaleProblem($resource, DateUtils) {
        var resourceUrl = 'service/' + 'api/afterSaleProblem/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },

            "delete": {
                method: "DELETE"
            },
            "enableOrNot": {
                method:"GET",
                params:{
                    path: "enableOrNot"
                }
            },
            'get': {
                method: 'GET',
            },
            'update': {
                method: 'PUT',
            },
            'save': {
                method: 'POST',
            },
            'getSubordinate': {
                method: 'GET',
                params: {
                    path: 'getSubordinate'
                },
                isArray: true
            },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            }
        });
    }
})();
