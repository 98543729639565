
(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('OrderSettingController', OrderSettingController);

    OrderSettingController.$inject = ['$scope', '$state','$stateParams', 'MessageService', 'OrderSetting'];

    function OrderSettingController($scope, $state,$stateParams, MessageService, OrderSetting) {
        var vm = this;

        vm.enabledChange = enabledChange;
        vm.save = save;

        vm.enabled = true;
        vm.timeNum = 1;
        function enabledChange(num) {
            if (num == 1) {
                vm.enabled = true;
            } else {
                vm.enabled = false;
            }
        }

        loadOrderSetting();
        function loadOrderSetting () {
            OrderSetting.get({platformFlag: '1'}, function(data) {
                vm.orderSetting = data;
                vm.enabled = vm.orderSetting.enabled;
                vm.timeNum = vm.orderSetting.duration;
            })
        };

        function save() {
            vm.orderSetting.duration = vm.timeNum;
            vm.orderSetting.enabled = vm.enabled;
            vm.orderSetting.platformFlag = true;
            vm.orderSetting.storeId = 0;

            console.log(vm.orderSetting);
            OrderSetting.update(vm.orderSetting, function(data){
                loadOrderSetting();
                MessageService.success("操作成功");
            });
        }

    }
})();
