(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreThirdDialogController', StoreThirdDialogController);

    StoreThirdDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'PlatformAbutment'];

    function StoreThirdDialogController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams, PlatformAbutment) {
        var vm = this;

        vm.abutment = entity;
        //代运营 保存
        vm.save2 = save2;
        vm.clear2 = clear2;
        vm.platforms = [{key:"人人店", value:"rrd"}];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        vm.store = Store.getStore({id:$stateParams.storeId});
        $q.all([vm.store.$promise]).then(function(){

        }) ;

        function clear2 (){
            $state.go("store-third-list", {storeId:vm.store.id});
        }


        function save2 () {
            vm.isSaving = true;
            vm.abutment.storeId = vm.store.id;
            PlatformAbutment.save(vm.abutment , onSaveSuccess2, onSaveError);
        }

        function onSaveSuccess2 (result) {
            $scope.$emit('platformApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");

            $state.go("store-third-list", {storeId: vm.store.id});
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }
    }
})();
