(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FeedbackDealController',FeedbackDealController);

    FeedbackDealController.$inject = ['$timeout', '$q', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Feedback', 'MessageService', 'UidService'];

    function FeedbackDealController ($timeout, $q, $scope, $stateParams, $uibModalInstance, entity, Feedback, MessageService, UidService) {
        var vm = this;
        vm.feedback = entity;
        vm.feedback.feedbackLines.sort(function(a, b){
            return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
        });

        vm.clear = clear;
        vm.save = save;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function(){
            if(vm.feedback.reply == null || vm.feedback.reply ==""){
                MessageService.error("请输入答复");
                return false;
            }

            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            vm.objLine={};
            vm.objLine.description = vm.feedback.reply;
            vm.objLine.type = "Reply";
            vm.objLine.feedbackId = vm.feedback.id;
            Feedback.treated(vm.objLine, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess (result) {
            $scope.$emit('platformApp:feedbackUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        //文件下载
        $scope.downloadZipFile3 = function(attachment){
            var storeId = JSON.parse(localStorage.getItem('platform-user')).roleId;
            window.open("fileserves/api/download/downloadZipFile3?key="+attachment.identifier+'&name='+attachment.fileName+'&storeId='+storeId);
        };
    }
})();









