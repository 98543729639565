(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreUserDetailController', StoreUserDetailController);

    StoreUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StoreUser', '$uibModalInstance'];

    function StoreUserDetailController($scope, $rootScope, $stateParams, entity, StoreUser, $uibModalInstance) {
        var vm = this;

        vm.storeUser = entity;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        var unsubscribe = $rootScope.$on('backApp:storeUserUpdate', function(event, result) {
            vm.storeUser = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
