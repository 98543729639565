(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('MessagePrice', MessagePrice);

    MessagePrice.$inject = ['$resource'];

    function MessagePrice ($resource) {
        var resourceUrl =  'manager/' + 'api/message-prices/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
