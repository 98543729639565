(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('SalesOrderDeliveryController',SalesOrderDeliveryController);

    SalesOrderDeliveryController.$inject = ['$uibModalInstance', '$scope', 'entity', 'SalesOrder', 'MessageService','LogisticsCompany', 'DateUtils'];

    function SalesOrderDeliveryController($uibModalInstance, $scope, entity, SalesOrder, MessageService, LogisticsCompany, DateUtils) {
        var vm = this;
        vm.salesOrder = entity.data;
        vm.clear = clear;
        vm.confirmDelivery = confirmDelivery;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.logisticsCompanies = LogisticsCompany.getAllByCondition({
           items:[{ key: "storeId", op: "=", value: "null"}] });

        $scope.validata=function(){
            if (vm.deliveryCompany == null || vm.deliveryCompany =="") {
                MessageService.error("请录入快递公司");
                return false;
            };
            if (vm.deliveryNumber == null || vm.deliveryNumber == "") {
                MessageService.error("请录入快递单号");
                return false;
            };
            return true;
        }

        function confirmDelivery (id) {
            if (!$scope.validata()) {
                return false;
            };

            var data = {
                id:vm.salesOrder.id,
                code:vm.deliveryCompany.code,
                comps:vm.deliveryCompany.name,
                deliveryNumber:vm.deliveryNumber
            }
            SalesOrder.updateByHandlerExpress(data, function (res) {
                if(res.status==200){
                    $uibModalInstance.close(true);
                }else{
                    MessageService.error(res.message)
                }

            });
        }
    }
})();
