/**
 * Created by rae on 3/26/2018.
 */
window.angular.module('image.service', []).
factory('ImageService', ['$location', '$http', '$timeout', '$rootScope', '$state',
    function ($location, $http, $timeout, $rootScope, $state) {

        /**
         * png,jpg等图片格式转base64
         * @param url
         * @param callback
         * @param outputFormat
         */
        this.convertImgToBase64 = function (url, callback, outputFormat) {
            var canvas = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'),
                img = new Image;
            img.crossOrigin = 'Anonymous';
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = function () {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.imageSmoothingEnabled = true;
                ctx.mozImageSmoothingEnabled = true;
                ctx.webkitImageSmoothingEnabled = true;
                ctx.msImageSmoothingEnabled = true;
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL(outputFormat || 'image/png');
                // callback.call(this, dataURL);
                callback.call(this, dataURLtoBlobs(dataURL));
                canvas = null;
            };
            img.src = url;
        };

        /**
         * base64转Blob
         * @param dataurl
         * @returns {*}
         */
        this.dataURLtoBlob=function(dataurl) {
            return dataURLtoBlobs(dataurl);
        };
        function dataURLtoBlobs(dataurl) {
            var arr = dataurl.split(','),
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            var createObjectURL = function (blob) {
                return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
            };
            var fileURL = URL.createObjectURL(new Blob([u8arr], {type: 'image/*'}));
            return fileURL;
        }

        return this;

    }]);
