(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('message-price', {
            parent: 'entity',
            url: '/message-price',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'platformApp.messagePrice.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/message-price/message-prices.html',
                    controller: 'MessagePriceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('messagePrice');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('message-price-detail', {
            parent: 'message-price',
            url: '/message-price/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'platformApp.messagePrice.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/message-price/message-price-detail.html',
                    controller: 'MessagePriceDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('messagePrice');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MessagePrice', function($stateParams, MessagePrice) {
                    return MessagePrice.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'message-price',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('message-price-detail.edit', {
            parent: 'message-price-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/message-price/message-price-dialog.html',
                    controller: 'MessagePriceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['MessagePrice', function(MessagePrice) {
                            return MessagePrice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('message-price.new', {
            parent: 'message-price',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/message-price/message-price-dialog.html',
                    controller: 'MessagePriceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                number: null,
                                cancelled: null,
                                available: null,
                                base: null,
                                seq: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('message-price', null, { reload: 'message-price' });
                }, function() {
                    $state.go('message-price');
                });
            }]
        })
        .state('message-price.edit', {
            parent: 'message-price',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/message-price/message-price-dialog.html',
                    controller: 'MessagePriceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['MessagePrice', function(MessagePrice) {
                            return MessagePrice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('message-price', null, { reload: 'message-price' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('message-price.delete', {
            parent: 'message-price',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/message-price/message-price-delete-dialog.html',
                    controller: 'MessagePriceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['MessagePrice', function(MessagePrice) {
                            return MessagePrice.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('message-price', null, { reload: 'message-price' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
