(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CheckDeliveryDataDetailController', CheckDeliveryDataDetailController);

    CheckDeliveryDataDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CheckDeliveryData', 
    '$state', 'Qinius', '$q'];

    function CheckDeliveryDataDetailController($scope, $rootScope, $stateParams, previousState, entity, CheckDeliveryData,
     $state, Qinius, $q) {
        var vm = this;

        vm.checkDeliveryData = entity;
        vm.clear = clear;
        vm.previousState = previousState.name;

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.checkDeliveryData.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if (vm.checkDeliveryData && vm.checkDeliveryData.pictures) {
                 vm.picturesStr = vm.checkDeliveryData.pictures.split(",");
            }else {
                vm.picturesStr = [];
            }
            vm.pictures = [];
            for (var i = vm.picturesStr.length - 1; i >= 0; i--) {
                var p= vm.picturesStr[i];
                vm.pictures.push({key:i, value:p});
            }
        });

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image").removeClass('hide1');
            $(".modal-content").addClass('opacity-zero');
            $(".origin-image img").attr('src', pic);
        }

        $scope.backTo = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        };

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        }


        function clear(){
            $state.go("check-delivery-data");
        }

        var unsubscribe = $rootScope.$on('platformApp:checkDeliveryDataUpdate', function(event, result) {
            vm.checkDeliveryData = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
