(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('InvoiceController', InvoiceController);

    InvoiceController.$inject = ['$state','$scope', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Invoice'];

    function InvoiceController($state, $scope, ParseLinks, paginationConstants, pagingParams, MessageService, Invoice) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.del = del;
        vm.changeStatus = changeStatus;

        loadAll();
        function loadAll () {
            var items=[{key:"invoiceState",op:"in",value:"INVOICE_COMMIT;INVOICE_AUDITED"}];
            if (pagingParams.search) {
                items.push({key:"title",op:"like",value:pagingParams.search});
            } 
            Invoice.byCondition2({
                items: items,
                page: pagingParams.page - 1, 
                size: vm.itemsPerPage, 
                sort: sort()
            }, onSuccess, onError);
           
        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(datas, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.invoices = datas;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            MessageService.error("数据获取失败");
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function del (id) {
            console.log(id);
            Invoice.del({id: id}, function(){
                $state.go('invoice', null, {reload: 'invoice'});
                MessageService.success("删除成功");
            }, function (error) {
                MessageService.error("删除失败");
            });
        }

        function changeStatus (id) {
            Invoice.get({id: id}, function(data) {
                console.log(data);
                if (data.payOrder.invoiced) {
                    MessageService.error("订单已经执行过开票操作");
                    return;
                } else {
                    Invoice.changeStatus({id: id, type: 0}, function (){
                        MessageService.success("操作成功");
                        $state.go('invoice', null, {reload: 'invoice'});
                    }, function (error) {
                        MessageService.error("操作失败");
                    })
                }
            })
        }
    }
})();
