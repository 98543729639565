(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('HotWords', HotWords);

        HotWords.$inject = ['$resource'];

    function HotWords($resource) {
        var resourceUrl = 'manager/' + 'api/hot-words/:path/:id';
        return $resource(resourceUrl, {}, {
            'add': {
                method: 'POST',
            },

            'platform': {
                method: 'POST',
                isArray: true,
                params: {
                    path: 'platform'
                },
            },
            'enabled': {
                method: 'GET',
                params: {
                    path: 'enabled',
                }
            },
            'delete': {
                method: 'DELETE',
            }
        })
    }
})();