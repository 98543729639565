(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CleanHistoryController', CleanHistoryController);

    CleanHistoryController.$inject = ['$q', '$timeout', '$scope', '$stateParams', '$state', '$uibModalInstance', 'MessageService', 'History', 'paginationConstants', 'ParseLinks'];

    function CleanHistoryController($q, $timeout, $scope, $stateParams, $state, $uibModalInstance, MessageService, History, paginationConstants, ParseLinks) {
        var vm = this;
        vm.histories = [];
        vm.page = 1;
        vm.size = paginationConstants.itemsPerPage;
        vm.close = close;
        vm.transition = transition;
        vm.jump = jump;


        init();
        function init() {
            queryHistoryPage();
        }

        function queryHistoryPage() {
            var items=[
                {key: 'classId', op: '=', value: '1'},
                {key: 'className', op: '=', value: 'JustifyCleanScheduler'}
            ];
            History.pageByCondition({
                items: items,
                page: vm.page - 1,
                size: vm.size,
                sort: ["id,desc"]
            }, function(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.histories = res || []
            }, function(res) {
                MessageService.error("获取数据失败");
            });
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            queryHistoryPage();
        }

        function transition() {
            queryHistoryPage();
        }

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
