(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('frame-config', {
            parent: 'entity',
            url: '/frame-config?page&sort&search&themeClassifyId',
            data: {
                authorities: ["ROLE_FRAME_CONFIG"], 
                pageTitle: 'global.menu.entities.frameConfig'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/frame-config/frame-config.html',
                    controller: 'FrameConfigController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: false
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                         themeClassifyId: $stateParams.themeClassifyId,
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('clipArt');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('frame-config-approve', {
            parent: 'frame-config',
            url: '/approve/{id}',
            data: {
                authorities: ["ROLE_FRAME_CONFIG"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/frame-config/modal/frame-config-approve.html',
                    controller: 'FrameConfigApproveController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['FrameConfig'  , function(FrameConfig) {
                            return FrameConfig.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('frame-config', null, { reload: 'frame-config' });
                }, function() {
                    $state.go('frame-config');
                });
            }]
        })
        .state('frame-config-edit', {
            parent: 'frame-config',
            url: '/edit/{id}',
            data: {
                authorities: ["ROLE_FRAME_CONFIG"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/frame-config/modal/frame-config-approve.html',
                    controller: 'FrameConfigApproveController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['FrameConfig'  , function(FrameConfig) {
                            return FrameConfig.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('frame-config', null, { reload: 'frame-config' });
                }, function() {
                    $state.go('frame-config');
                });
            }]
        });
    }

})();
