(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CommodityAddController', CommodityAddController);

    CommodityAddController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Category', 'PaginationUtil'];

    function CommodityAddController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, MessageService, $uibModalInstance, Category, PaginationUtil) {
        var vm = this;
        vm.productOn = entity.data;
        vm.type = entity.type;
        vm.save = save;
        vm.cancel = cancel;
        vm.classifyList = [{name:'全部', id: ''}];
        vm.classifyData = {name:'全部', id: ''};
        vm.predicate = $stateParams.sort;
        vm.reverse = PaginationUtil.parseAscending($stateParams.sort);
        vm.keyword = "";
        // if(vm.type == 'edit'){
        //     vm.productOn
        // }

        //获取分类
        Category.getAllByCondition({}, function (res) {
            for(var i=0; i<res.length; i++){
                if(!res[i].cancelled){
                    vm.classifyList.push(res[i]);
                }
            }
        });

        //获取商品
        productInit()
        function productInit(){
            var items = [{key: "prototype", op: "=", value: 'Original'},
                {key: "saleable", op: "=", value: "true"},
                {key: "cancelled", op: "=", value: "false"},
                ];
            if(vm.classifyData.id){
                items.push({key:"category.id",op:"=",value: vm.classifyData.id})
            }
            if(vm.keyword){
                items.push({key:"name",op:"like",value: vm.keyword})
            }
            Product.getAllByCondition2({
                items:items
            },function(data){
                angular.forEach(vm.productOn, function(item) {
                    angular.forEach(data, function(product) {
                        if (item.productId==product.id) {
                            product.disable=true
                        }
                    })
                })
                vm.products = data;
            })
        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        //点击分类
        $scope.classifyCb = function (data) {
            vm.classifyData = data;
            productInit()
        }

        //点击商品
        $scope.productCb = function (data) {
            vm.productOn = {
                productId: data.id,
                productName: data.name
            };
        }
        $scope.select = function () {
            productInit()
        }

        //确定
        function save() {
            $uibModalInstance.close(vm.productOn);
        }
        //取消
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
