(function(){
	'use strict';
	angular
	.module('platformApp')
	.factory('UidService',UidService);

	UidService.$inject = [];
	
	function UidService(){
		var service={
			get:get
		}
		return service;
	}
	function get(){
		var d = new Date().getTime();
		var uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
		});
		return uid;
	}

})();