(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

        .state('store-payCode2', {
            parent: 'store-detail2',
            url: '/pay-code2?page&sort&search',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'platformApp.payCode.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-code/pay-codes2.html',
                    controller: 'PayCodeController2',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payCode');
                    $translatePartialLoader.addPart('payCodeType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('store-payCode', {
            parent: 'store-detail2',
            url: '/pay-code?page&sort&search',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'platformApp.payCode.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-code/pay-codes.html',
                    controller: 'PayCodeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payCode');
                    $translatePartialLoader.addPart('payCodeType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pay-code', {
            parent: 'entity',
            url: '/pay-code',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'platformApp.payCode.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-code/pay-codes.html',
                    controller: 'PayCodeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payCode');
                    $translatePartialLoader.addPart('payCodeType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pay-code2', {
            parent: 'entity',
            url: '/pay-code2',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'platformApp.payCode.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-code/pay-codes2.html',
                    controller: 'PayCodeController2',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payCode');
                    $translatePartialLoader.addPart('payCodeType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pay-code-detail', {
            parent: 'store-payCode',
            url: '/pay-code/{code}',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-code/pay-code-detail.html',
                    controller: 'PayCodeDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PayCode', function(PayCode) {
                            return PayCode.get({code : $stateParams.code}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('store-payCode.new', {
            parent: 'store-payCode',
            url: '/new',
            data: {
                authorities: ["ROLE_PLATFROM_AGENT"],
                pageTitle: 'platformApp.payCode.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-code/pay-code-dialog.html',
                    controller: 'PayCodeDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payBatch');
                    $translatePartialLoader.addPart('payCode');
                    $translatePartialLoader.addPart('payCodeType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pay-code.import', {
            parent: 'store-payCode',
            url: '/import',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-code/pay-code-dialog2.html',
                    controller: 'PayCodeDialogImportantController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                number: null,
                                code: null,
                                used: null,
                                cancelled: null,
                                usedTime: null,
                                usedUserId: null,
                                usedUserName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('store-payCode', null, { reload: 'store-payCode' });
                }, function() {
                    $state.go('store-payCode');
                });
            }]
        })

        .state('pay-code.import-cancelled', {
            parent: 'store-payCode',
            url: '/importCancelled',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-code/pay-code-dialog6.html',
                    controller: 'PayCodeDialogImportantController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                number: null,
                                code: null,
                                used: null,
                                cancelled: null,
                                usedTime: null,
                                usedUserId: null,
                                usedUserName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('store-payCode', null, { reload: 'store-payCode' });
                }, function() {
                    $state.go('store-payCode');
                });
            }]
        })

        .state('pay-code.export', {
            parent: 'store-payCode',
            url: '/export',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-code/pay-code-dialog3.html',
                    controller: 'PayCodeDialogImportantController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                number: null,
                                code: null,
                                used: null,
                                cancelled: null,
                                usedTime: null,
                                usedUserId: null,
                                usedUserName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('store-payCode', null, { reload: 'store-payCode' });
                }, function() {
                    $state.go('store-payCode');
                });
            }]
        })
        .state('pay-code.activated', {
            parent: 'store-payCode',
            url: '/activate',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-code/pay-code-dialog5.html',
                    controller: 'PayCodeDialog3Controller',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                number: null,
                                code: null,
                                used: null,
                                cancelled: null,
                                usedTime: null,
                                usedUserId: null,
                                usedUserName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('store-payCode', null, { reload: 'store-payCode' });
                }, function() {
                    $state.go('store-payCode');
                });
            }]
        })
        .state('pay-code.fast', {
            parent: 'store-payCode',
            url: '/fast',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-code/pay-code-dialog4.html',
                    controller: 'PayCodeDialog2Controller',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                number: null,
                                code: null,
                                used: null,
                                cancelled: null,
                                usedTime: null,
                                usedUserId: null,
                                usedUserName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('store-payCode', null, { reload: 'store-payCode' });
                }, function() {
                    $state.go('store-payCode');
                });
            }]
        })
        ;
    }

})();
