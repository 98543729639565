(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MessageNotificationDetailController', MessageNotificationDetailController);

    MessageNotificationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MessageNotification', '$state'];

    function MessageNotificationDetailController($scope, $rootScope, $stateParams, previousState, entity, MessageNotification, $state) {
        var vm = this;

        vm.messageNotification = entity;
        vm.previousState = previousState.name;
        vm.clear = clear;

        var unsubscribe = $rootScope.$on('platformApp:messageNotificationUpdate', function(event, result) {
            vm.messageNotification = result;
        });

        function clear () {
            $state.go("message-notification", {reload:true})
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
