(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('SalesOrder', SalesOrder);

    SalesOrder.$inject = ['$resource', 'DateUtils'];

    function SalesOrder ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/sales-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'getData3': {
                method: 'GET',
                params: {
                    path: 'getData3'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'getData4': {
                method: 'GET',
                params: {
                    path: 'getData4'
                }
            }
        });
    }
})();
