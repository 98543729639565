(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreProductController', StoreProductController);

    StoreProductController.$inject = ['$scope', '$q', '$state', 'Product', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService',
    'Qinius', '$stateParams', 'Store'];

    function StoreProductController($scope, $q, $state, Product, ParseLinks, paginationConstants, pagingParams, MessageService,
        Qinius, $stateParams, Store) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        $scope.selectAll = false;
        vm.store = Store.getStore({id:$stateParams.storeId});

        $scope.batchArr = [];
        $scope.selectOne = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.products,function (product,i) {
                if($scope.selectAll){
                    product.select = true;
                    $scope.batchArr.push(product.id);
                    $scope.selectOne = true;
                }else{
                    $scope.batchArr = [];
                    product.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (product) {
            product.select = !product.select;
            // 选中
            if(product.select){
                $scope.batchArr.push(product.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(product.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.products,function (product,i) {
                if(!product.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.products.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("确认要删除选中的商品？",function(){
                Product.deleteBatch({ids:$scope.batchArr},function(data){
                    MessageService.success("删除成功");
                    loadAll();
                },function(){
                MessageService.error("删除失败");
                })
            },function(){});
        }


        vm.domains = Qinius.getDomain();

        $q.all([vm.domains.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        $scope.delete = function (id) {
            MessageService.confirm("entity.action.deleteConfirm", function () {
                Product.delete({id: id}, function () {
                    loadAll();
                });
            }, function () {
            }, true);
        }


        function loadAll() {
            var items = [{key: "prototype", op: "=", value: "Store"},{key:"storeId", op:"=", value:$stateParams.storeId}];
            if (pagingParams.search) {
                items.push({key: "name", op: "like", value: pagingParams.search});
                Product.byCondition2({
                    items: items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Product.byCondition2({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    items:items
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("产品获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                storeId:$stateParams.storeId,
                status:vm.status
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image").removeClass('hide1');
            $(".modal-content").addClass('opacity-zero');
            $(".origin-image img").attr('src', pic);
        }

        $scope.backTo = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        };

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStart = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOn, true);
            document.addEventListener("mouseup", moveImgEnd, true);
        }

        //move事件
        function moveImgOn(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEnd() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOn, true);
            document.removeEventListener("mouseup", moveImgEnd, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
    }
})();
