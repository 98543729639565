(function () {
'use strict';

angular
    .module('platformApp')
    .controller('StoreProductPromotionDetailController', StoreProductPromotionDetailController);

StoreProductPromotionDetailController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', '$uibModalInstance',
    'MessageService', '$stateParams'
];

function StoreProductPromotionDetailController($timeout, $scope, $state, $q, entity, $uibModalInstance,
    MessageService, $stateParams) {
    var vm = this;
    vm.item = entity;
    vm.clear = clear;
    vm.storeId = $stateParams.storeId;
    if (!vm.storeId) {
        return;
    }

    $timeout(function() {
        angular.element('.form-group:eq(1)>input').focus();
    });
 
    
    function clear() {
        $uibModalInstance.dismiss('cancel');
    }
}
})();