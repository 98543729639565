(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RoleDetailController', RoleDetailController);

    RoleDetailController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'entity', 'Role', 'Authority', 'ListService','$uibModalInstance'];

    function RoleDetailController($scope, $q, $rootScope, $stateParams, entity, Role, Authority, ListService,$uibModalInstance) {
        var vm = this;

        vm.role = entity;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        vm.role_ = [];

        $q.all([vm.role.$promise]).then(function(){
            if(vm.role.authorities && vm.role.authorities.length>0){
                $scope.groupBy(vm.role.authorities);
            }
        });

         $scope.groupBy = function(list){
            if(list == null){
                return null;
            }
            for (var i = list.length - 1; i >= 0; i--) {
                var element = list[i];
                var e = ListService.inList(element,vm.role_,["module"]);
                if(!e){
                    var r = {module:element.module,authorities:[]};
                    vm.role_.push(r);
                }

                for (var j = vm.role_.length - 1; j >= 0; j--) {
                    var mod = vm.role_[j];
                    if(element.module == mod.module){
                        mod.authorities.push(element);
                        break;
                    }
                }

            }
        }

        var unsubscribe = $rootScope.$on('platformApp:roleUpdate', function(event, result) {
            vm.role = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
