;(function ($, window, undefined) {

    'use strict';

    // global
    var $window = $(window),
        Modernizr = window.Modernizr;

    // https://gist.github.com/edankwan/4389601
    Modernizr.addTest('csstransformspreserve3d', function () {
        var prop = Modernizr.prefixed('transformStyle');
        var val = 'preserve-3d';
        var computedStyle;
        if (!prop) return false;

        prop = prop.replace(/([A-Z])/g, function (str, m1) {
            return '-' + m1.toLowerCase();
        }).replace(/^ms-/, '-ms-');


        Modernizr.testStyles('#modernizr{' + prop + ':' + val + ';}', function (el, rule) {
            computedStyle = window.getComputedStyle ? getComputedStyle(el, null).getPropertyValue(prop) : '';
        });

        return (computedStyle === val);
    });

    var $event = $.event,
        $special,
        resizeTimeout;

    $special = $event.special.debouncedresize = {
        setup: function () {
            $(this).on("resize", $special.handler);
        },
        teardown: function () {
            $(this).off("resize", $special.handler);
        },
        handler: function (event, execAsap) {
            // Save the context
            var context = this,
                args = arguments,
                dispatch = function () {
                    // set correct event type
                    event.type = "debouncedresize";
                    $event.dispatch.apply(context, args);
                };

            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }

            execAsap ?
                dispatch() :
                resizeTimeout = setTimeout(dispatch, $special.threshold);
        },
        threshold: 150
    };

    $.BookBlock = function (options, element) {
        this.$el = $(element);
        this._init(options);
    };

    // the options
    $.BookBlock.defaults = {
        // vertical or horizontal flip
        orientation: 'vertical',
        // ltr (left to right) or rtl (right to left)
        direction: 'ltr',
        // speed for the flip transition in ms
        speed: 800,
        // easing for the flip transition
        easing: 'ease-in-out',
        // if set to true, both the flipping page and the sides will have an overlay to simulate shadows
        shadows: true,
        // opacity value for the "shadow" on both sides (when the flipping page is over it)
        // value : 0.1 - 1
        shadowSides: 0.2,
        // opacity value for the "shadow" on the flipping page (while it is flipping)
        // value : 0.1 - 1
        shadowFlip: 0.1,
        // if we should show the first item after reaching the end
        circular: false,
        // if we want to specify a selector that triggers the next() function. example: ´#bb-nav-next´
        nextEl: '',
        // if we want to specify a selector that triggers the prev() function
        prevEl: '',
        // autoplay. If true it overwrites the circular option to true
        autoplay: false,
        // time (ms) between page switch, if autoplay is true
        interval: 3000,
        // callback after the flip transition
        // old is the index of the previous item
        // page is the current item´s index
        // isLimit is true if the current page is the last one (or the first one)
        onEndFlip: function (old, page, isLimit) {
            return false;
        },
        // callback before the flip transition
        // page is the current item´s index
        onBeforeFlip: function (page) {
            return false;
        }
    };

    $.BookBlock.prototype = {
        _init: function (options) {
            var bindingtype = localStorage.getItem('bindingtype');
            // options
            this.options = $.extend(true, {}, $.BookBlock.defaults, options);
            // orientation class
            if(bindingtype=='Top'){
                this.$el.addClass('bb-horizontal');
            }else {
                this.$el.addClass('bb-vertical');
            }
            // items
            this.$items = this.$el.children('.bb-item').hide();
            //当前item
            this.$nowItems = this.$el.children('.bb-item:eq(0)');
            this.$firstItem = this.$el.children('.bb-item:eq(0)');
            this.$secItem = this.$el.children('.bb-item:eq(1)');
            // total items
            this.itemsCount = $(".bb-item").length;
            // current item´s index
            this.current = 0;
            // previous item´s index
            this.previous = -1;
            // show first item
            this.$current = this.$items.eq(this.current).show();
            // console.log(this.$current);
            // get width of this.$el
            // this will be necessary to create the flipping layout
            this.elWidth = this.$el.width();
            var transEndEventNames = {
                'WebkitTransition': 'webkitTransitionEnd',
                'MozTransition': 'transitionend',
                'OTransition': 'oTransitionEnd',
                'msTransition': 'MSTransitionEnd',
                'transition': 'transitionend'
            };
            this.transEndEventName = transEndEventNames[Modernizr.prefixed('transition')] + '.bookblock';

            // support css 3d transforms && css transitions && Modernizr.csstransformspreserve3d
            this.support = Modernizr.csstransitions && Modernizr.csstransforms3d && Modernizr.csstransformspreserve3d;
            // initialize/bind some events
            this._initEvents();
            // start slideshow
            if (this.options.autoplay) {
                this.options.circular = true;
                this._startSlideshow();
            }
        },
        _initEvents: function () {
            var self = this;

            if (this.options.nextEl !== '') {
                $(this.options.nextEl).on('click.bookblock touchstart.bookblock', function () {
                    self._action('next');
                    return false;
                });
            }

            if (this.options.prevEl !== '') {
                $(this.options.prevEl).on('click.bookblock touchstart.bookblock', function () {
                    self._action('prev');
                    return false;
                });
            }

            $window.on('debouncedresize', function () {
                // update width value
                self.elWidth = self.$el.width();
            });

        },
        _action: function (dir, page) {
            this._stopSlideshow();
            this._navigate(dir, page);
        },
        _navigate: function (dir, page) {
            if (this.isAnimating) {
                return false;
            }

            // callback trigger
            this.options.onBeforeFlip(this.current);

            this.isAnimating = true;
            // update current value
            this.$current = this.$items.eq(this.current);
            if (page !== undefined) {
                this.current = page;
            }
            else if (dir === 'next' && this.options.direction === 'ltr' || dir === 'prev' && this.options.direction === 'rtl') {

                if (!this.options.circular && this.current === this.itemsCount - 1) {
                    this.end = true;
                }
                else {
                    this.previous = this.current;
                    this.current = this.current < this.itemsCount - 1 ? this.current + 1 : 0;
                }
            }
            else if (dir === 'prev' && this.options.direction === 'ltr' || dir === 'next' && this.options.direction === 'rtl') {
                if (!this.options.circular && this.current === 0) {
                    this.end = true;
                }
                else {
                    this.previous = this.current;
                    this.current = this.current > 0 ? this.current - 1 : this.itemsCount - 1;
                }
            }

            this.$nextItem = this.$items.eq(this.current);

            if (!this.support) {
                this._layoutNoSupport(dir);
            } else {
                this._layout(dir);
            }

        },
        _layoutNoSupport: function (dir) {
            this.$items.hide();
            this.$nextItem.show();
            this.end = false;
            this.isAnimating = false;
            var isLimit = dir === 'next' && this.current === this.itemsCount - 1 || dir === 'prev' && this.current === 0;
            // callback trigger
            this.options.onEndFlip(this.previous, this.current, isLimit);
        },
        // creates the necessary layout for the 3d structure
        _layout: function (dir) {
            this.$nowItems = this.$items.eq(this.current + 1);
            var self = this,
                // basic structure: 1 element for the left side.
                $s_left = this._addSide('left', dir),
                // 1 element for the flipping/middle page
                $s_middle = this._addSide('middle', dir),
                // 1 element for the right side
                $s_right = this._addSide('right', dir),
                // overlays
                $o_left = $s_left.find('div.bb-overlay'),
                $o_middle_f = $s_middle.find('div.bb-flipoverlay:first'),
                $o_middle_b = $s_middle.find('div.bb-flipoverlay:last'),
                $o_right = $s_right.find('div.bb-overlay'),
                speed = this.end ? 400 : this.options.speed;

            // console.log(this);
            // console.log(dir === 'next' && self.current === self.itemsCount - 1 || dir === 'prev' && self.current === 0);
            this.$items.hide();
            if (dir == 'next' && self.current !== self.itemsCount - 1) {
                // console.log(1);
                self.$current.show();
            } else if ((dir == 'next' && self.current === self.itemsCount - 1)) {        //最后一页
                // self.$current.hide();
                self.$nextItem.show();
            } else if (dir == 'prev' && self.current > 0) {
                // console.log(3);
                self.$nowItems.show();
            } else if (dir == 'prev' && self.current === 0) {         //第一页
                // console.log(4);
                self.$nowItems.show();
            }


            localStorage.setItem('pageI',self.current);
            this.$el.prepend($s_left, $s_middle, $s_right);
            var isPull = true;
            var bindingtype = localStorage.getItem('bindingtype');
            if(bindingtype=='Left2'){
                isPull = false;
                bindingtype = 'Left';
            }
            if(isPull&&$(".bb-item").length>=2){
                $(".bb-item:eq(1)").removeClass('bb-white').find('.slide1').addClass('opacity-zero');
            }

            if(this.current == 0&&dir=='prev'||(this.current == 1&&dir=='next')&& bindingtype== 'Top'){
                if(isPull){
                    $(".bb-item:eq(1)").addClass('opacity-zero');
                }

            }else{
                $(".bb-item:eq(1)").removeClass('opacity-zero');
            }
            $s_middle.css({
                transitionDuration: speed + 'ms',
                transitionTimingFunction: this.options.easing
            }).on(this.transEndEventName, function (event) {
                if ($(event.target).hasClass('bb-page')) {
                    self.$el.children('.bb-page').remove();
                    if($(".bb-item").length>=2&&bindingtype=='Top'){
                        $(".bb-item:eq(1)").addClass('bb-white').removeClass('opacity-zero').find('.slide1').removeClass('opacity-zero');
                    }else if($(".bb-item").length>=2&&bindingtype=='Left'){
                        $(".bb-item:eq(1)").addClass('bb-white').removeClass('opacity-zero').find('.slide1').removeClass('opacity-zero');
                    }

                    self.$nextItem.show();
                    if (dir == 'prev') {
                        self.$nowItems.hide();
                    }
                    if (dir == 'next' && self.current !== self.itemsCount - 1) {
                        self.$current.hide();
                    }
                    self.end = false;
                    self.isAnimating = false;
                    var isLimit = dir === 'next' && self.current === self.itemsCount - 1 || dir === 'prev' && self.current === 0;
                    // callback trigger
                    self.options.onEndFlip(self.previous, self.current, isLimit);
                }
            });

            if (dir === 'prev') {
                $s_middle.addClass('bb-flip-initial');
            }

            // overlays
            if (this.options.shadows && !this.end) {

                var o_left_style = (dir === 'next') ? {
                            '-webkit-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-moz-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-o-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            transition: 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms'
                        } : {
                            '-webkit-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-moz-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-o-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-ms-filter': 'alpha(opacity='+this.options.shadowSides*100+')',
                            filter:'alpha(opacity='+this.options.shadowSides*100+')',
                            transition: 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            opacity: this.options.shadowSides
                        },
                    o_middle_f_style = (dir === 'next') ? {
                            '-webkit-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-moz-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-o-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            transition: 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear'
                        } : {
                            '-webkit-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-moz-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-o-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-ms-filter': 'alpha(opacity='+this.options.shadowFlip*100+')',
                            filter:'alpha(opacity='+this.options.shadowFlip*100+')',
                            transition: 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            opacity: this.options.shadowFlip
                        },
                    o_middle_b_style = (dir === 'next') ? {
                            '-webkit-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-moz-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-o-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-ms-filter': 'alpha(opacity='+this.options.shadowFlip*100+')',
                            filter:'alpha(opacity='+this.options.shadowFlip*100+')',
                            transition: 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            opacity: this.options.shadowFlip
                        } : {
                            '-webkit-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-moz-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-o-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            transition: 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear'
                        },
                    o_right_style = (dir === 'next') ? {
                            '-webkit-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-moz-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-o-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            '-ms-filter': 'alpha(opacity='+this.options.shadowSides*100+')',
                            filter:'alpha(opacity='+this.options.shadowSides*100+')',
                            transition: 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear',
                            opacity: this.options.shadowSides
                        } : {
                            '-webkit-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-moz-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            '-o-transition': 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms',
                            transition: 'opacity ' + this.options.speed / 2 + 'ms ' + 'linear' + ' ' + this.options.speed / 2 + 'ms'
                        };

                $o_middle_f.css(o_middle_f_style);
                $o_middle_b.css(o_middle_b_style);
                $o_left.css(o_left_style);
                $o_right.css(o_right_style);

            }

            setTimeout(function () {
                // first && last pages lift slightly up when we can't go further
                $s_middle.addClass(self.end ? 'bb-flip-' + dir + '-end' : 'bb-flip-' + dir);

                // overlays
                if (self.options.shadows && !self.end) {

                    $o_middle_f.css({
                        opacity: dir === 'next' ? self.options.shadowFlip : 0,
                        '-ms-filter': 'alpha(opacity='+dir === 'next' ? self.options.shadowFlip*100 : 0+')',
                        filter: 'alpha(opacity='+dir === 'next' ? self.options.shadowFlip*100 : 0+')'
                    });

                    $o_middle_b.css({
                        opacity: dir === 'next' ? 0 : self.options.shadowFlip,
                        '-ms-filter': 'alpha(opacity='+dir === 'next' ? 0 : self.options.shadowFlip*100+')',
                        filter: 'alpha(opacity='+dir === 'next' ? 0 : self.options.shadowFlip*100+')'
                    });

                    $o_left.css({
                        opacity: dir === 'next' ? self.options.shadowSides : 0,
                        '-ms-filter': 'alpha(opacity='+dir === 'next' ? self.options.shadowSides*100 : 0+')',
                        filter: 'alpha(opacity='+dir === 'next' ? self.options.shadowSides*100 : 0+')'
                    });

                    $o_right.css({
                        opacity: dir === 'next' ? 0 : self.options.shadowSides,
                        '-ms-filter': 'alpha(opacity='+dir === 'next' ? 0 : self.options.shadowSides*100+')',
                        filter: 'alpha(opacity='+dir === 'next' ? 0 : self.options.shadowSides*100+')'
                    });

                }
            }, 25);
        },
        // adds the necessary sides (bb-page) to the layout
        _addSide: function (side, dir) {
            var $side;
            var pageL = '', pageR = '', pageF = '';
            var mar = 0,isPull = true;
            var bindingtype = localStorage.getItem('bindingtype');
            if(bindingtype=='Left2'){
                isPull = false;
                bindingtype = 'Left';
            }
            var firstPage = !isPull&&(dir == 'next' && this.current == 1||dir == 'prev' && this.current == 0)
            if(firstPage){
                mar = 0;
                pageL = this.$current.html();
                pageF = this.$current.html();
            } else if (dir == 'next' && this.current == 1) {
                mar = this.$firstItem.width();
                pageL = this.$firstItem.html();
                // pageF = bindingtype == 'Top' ? "" : $(".show-flag").html();
            } else if (dir == 'next' && this.current > 1) {
                mar = 0;
                pageL = this.$current.html();
                pageF = this.$current.html();
            } else if (dir == 'prev' && this.current == 0) {
                mar = this.$firstItem.width();
                // pageR = bindingtype == 'Top' ? "" : $(".show-flag").html();
            } else if (dir == 'prev' && this.current != 0) {
                mar = 0;
                pageR = this.$nextItem.html();
            }
            if (!((dir == 'next' && this.current == this.itemsCount - 1) || (dir == 'prev' && this.current == this.itemsCount - 2))) {
                // $(".bb-content").css({background: '#fff!important'});
            }

            // console.log(dir);
            // console.log(this);
            // console.log(this.current);
            if (bindingtype == 'Top') {
                mar = 0;
            }

            if(this.current == 0&&dir=='prev'&&bindingtype == 'Top'){
                $(".bb-item").addClass('lar-noShadow');
            }else{
                $(".bb-item").removeClass('lar-noShadow');
            }
            if(dir == 'next' && this.current == this.itemsCount - 1&&bindingtype == 'Top'){
                // console.log("aaaaa");
                $(".bb-item").addClass('uad-noShadow');
            }else {
                $(".bb-item").removeClass('uad-noShadow');
            }
            switch (side) {
                case 'left':
                    /*
                     <div class="bb-page" style="z-index:102;">
                     <div class="bb-back">
                     <div class="bb-outer">
                     <div class="bb-content">
                     <div class="bb-inner">
                     dir==='next' ? [content of current page] : [content of next page]
                     </div>
                     </div>
                     <div class="bb-overlay"></div>
                     </div>
                     </div>
                     </div>
                     */
                    if (!isPull&&(this.current == 1&&dir=='next'||this.current == 0&&dir=='prev')) {
                        $side = $('<div class="bb-page no-events"><div class="bb-back"><div class="bb-outer"><div class="bb-content"><div class="bb-inner">'
                            + ( dir === 'prev' ? this.$nextItem.html() : this.$current.html() )+ '</div></div><div class="bb-overlay"></div></div></div></div>').css({'z-index': 997});

                    } else if (isPull&&(this.current == 1&&dir=='next'||this.current == 0&&dir=='prev')) {
                        $side = $('<div class="bb-page no-events opacity-zero"><div class="bb-back"><div class="bb-outer"><div class="bb-content"><div class="bb-inner">'
                            + ( dir === 'prev' ? this.$nextItem.html() : this.$current.html() )+ '</div></div><div class="bb-overlay"></div></div></div></div>').css({'z-index': 997});
                    } else {
                        $side = $('<div class="bb-page no-events"><div class="bb-back"><div class="bb-outer"><div class="bb-content"><div class="bb-inner">'
                            + ( dir === 'next' ? pageF : pageR )
                            + '</div></div><div class="bb-overlay"></div></div></div></div>').css({'z-index': 997});
                    }

                    break;
                case 'middle':
                    /*
                     <div class="bb-page" style="z-index:103;">
                     <div class="bb-front">
                     <div class="bb-outer">
                     <div class="bb-content">
                     <div class="bb-inner">
                     dir==='next' ? [content of current page] : [content of next page]
                     </div>
                     </div>
                     <div class="bb-flipoverlay"></div>
                     </div>
                     </div>
                     <div class="bb-back">
                     <div class="bb-outer">
                     <div class="bb-content">
                     <div class="bb-inner">
                     dir==='next' ? [content of next page] : [content of current page]
                     </div>
                     </div>
                     <div class="bb-flipoverlay"></div>
                     </div>
                     </div>
                     </div>
                     */
                    $side = $('<div class="bb-page"><div class="bb-front"><div class="bb-outer"><div class="bb-content"><div class="bb-inner" style="margin-left:' + mar + 'px">'
                        + (dir === 'next' ? pageL : this.$nextItem.html())
                        + '</div></div><div class="bb-flipoverlay"></div></div></div><div class="bb-back"><div class="bb-outer"><div class="bb-content" style="width:'
                        + this.elWidth + 'px"><div class="bb-inner">' + ( dir === 'next' ? this.$nextItem.html() : this.$current.html() )
                        + '</div></div><div class="bb-flipoverlay"></div></div></div></div>').css({'z-index': 998});
                    break;
                case 'right':
                    /*
                     <div class="bb-page" style="z-index:101;">
                     <div class="bb-front">
                     <div class="bb-outer">
                     <div class="bb-content">
                     <div class="bb-inner">
                     dir==='next' ? [content of next page] : [content of current page]
                     </div>
                     </div>
                     <div class="bb-overlay"></div>
                     </div>
                     </div>
                     </div>
                     */
                    if (this.current == this.$items.length-1||(this.previous == this.$items.length-1&&dir=='prev')){
                        $side = $('<div class="bb-page opacity-zero"><div class="bb-front"><div class="bb-outer"><div class="bb-content"><div class="bb-inner">'
                            + ( dir === 'next' ? this.$nextItem.html() : this.$current.html() )
                            + '</div></div><div class="bb-overlay"></div></div></div></div>').css({'z-index': 996});
                    }else {
                        $side = $('<div class="bb-page"><div class="bb-front"><div class="bb-outer"><div class="bb-content"><div class="bb-inner">'
                            + ( dir === 'next' ? this.$nextItem.html() : this.$current.html() )
                            + '</div></div><div class="bb-overlay"></div></div></div></div>').css({'z-index': 996});
                    }
                    break;
            }

            return $side;
        },
        _startSlideshow: function () {
            var self = this;
            this.slideshow = setTimeout(function () {
                self._navigate('next');
                if (self.options.autoplay) {
                    self._startSlideshow();
                }
            }, this.options.interval);
        },
        _stopSlideshow: function () {
            if (this.options.autoplay) {
                clearTimeout(this.slideshow);
                this.options.autoplay = false;
            }
        },
        // public method: flips next
        next: function () {
            this._action(this.options.direction === 'ltr' ? 'next' : 'prev');
        },
        // public method: flips back
        prev: function () {
            this._action(this.options.direction === 'ltr' ? 'prev' : 'next');
        },
        // public method: goes to a specific page
        jump: function (page) {

            page -= 1;

            if (page === this.current || page >= this.itemsCount || page < 0) {
                return false;
            }

            var dir;
            if (this.options.direction === 'ltr') {
                dir = page > this.current ? 'next' : 'prev';
            }
            else {
                dir = page > this.current ? 'prev' : 'next';
            }
            this._action(dir, page);

        },
        // public method: goes to the last page
        last: function () {
            this.jump(this.itemsCount);
        },
        // public method: goes to the first page
        first: function () {
            this.jump(1);
        },
        // public method: check if isAnimating is true
        isActive: function () {
            return this.isAnimating;
        },
        // public method: dynamically adds new elements
        // call this method after inserting new "bb-item" elements inside the BookBlock
        update: function () {
            var $currentItem = this.$items.eq(this.current);
            this.$items = this.$el.children('.bb-item');
            this.itemsCount = this.$items.length;
            this.current = $currentItem.index();
        },
        destroy: function () {
            if (this.options.autoplay) {
                this._stopSlideshow();
            }
            this.$el.removeClass('bb-' + this.options.orientation);
            this.$items.show();

            if (this.options.nextEl !== '') {
                $(this.options.nextEl).off('.bookblock');
            }

            if (this.options.prevEl !== '') {
                $(this.options.prevEl).off('.bookblock');
            }

            $window.off('debouncedresize');
        }
    };

    var logError = function (message) {
        if (window.console) {
            window.console.error(message);
        }
    };

    $.fn.bookblock = function (options) {
        if (typeof options === 'string') {
            var args = Array.prototype.slice.call(arguments, 1);
            this.each(function () {
                var instance = $.data(this, 'bookblock');
                if (!instance) {
                    logError("cannot call methods on bookblock prior to initialization; " +
                        "attempted to call method '" + options + "'");
                    return;
                }
                if (!$.isFunction(instance[options]) || options.charAt(0) === "_") {
                    logError("no such method '" + options + "' for bookblock instance");
                    return;
                }
                instance[options].apply(instance, args);
            });
        }
        else {
            this.each(function () {
                var instance = $.data(this, 'bookblock');
                if (instance) {
                    instance._init();
                }
                else {
                    instance = $.data(this, 'bookblock', new $.BookBlock(options, this));
                }
            });
        }
        return this;
    };

})(jQuery, window);
