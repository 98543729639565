(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FrameConfigApproveController', FrameConfigApproveController);

    FrameConfigApproveController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'MessageService', 
    'ThemeClassify', 'FrameConfig'];

    function FrameConfigApproveController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, MessageService,
     ThemeClassify, FrameConfig) {
        var vm = this;

        vm.textXml = entity;
        vm.clear = clear;
        vm.save = save;

        vm.name = "审核";
        if ($scope.toState.name == 'frame-config-approve') {
            vm.name = "审核";
        }else{
            vm.name = "编辑";
        }
        ThemeClassify.getAllByCondition({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: 'BORDER'}]}, function (res) {
            if(res.status && res.status != 200){
                MessageService.error(res.message);
            }else {
                vm.themeClassifies = res;
            }
        });
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise,vm.textXml.$promise]).then(function(){
            vm.textXml = vm.textXml.message;
             $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        })



        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.validate = function(){
            if(vm.textXml.themeClassifyId == null || vm.textXml.themeClassifyId==""){
                MessageService.error("请选择分类");
                return false;
            }
            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if ($scope.toState.name == 'frame-config-approve') {
                FrameConfig.approve({id:vm.textXml.id, name:vm.textXml.name, themeClassifyId: vm.textXml.themeClassifyId, seq:vm.textXml.seq},function(res){
                    MessageService.success("审核通过");
                    $uibModalInstance.close();
                    vm.isSaving = false;
                },function(){
                    MessageService.error("通过失败");
                    vm.isSaving = false;
                })
            }else{
                FrameConfig.update(vm.textXml,function(res){
                    MessageService.success("保存成功");
                    $uibModalInstance.close();
                    vm.isSaving = false;
                },function(){
                    MessageService.error("保存失败");
                    vm.isSaving = false;
                })
            }
            
        };
    }
})();
