(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory("AgentManagement", AgentManagement)
        .factory('StoreUser', StoreUser);

    StoreUser.$inject = ['$resource'];
    AgentManagement.$inject = ['$resource'];

    function AgentManagement ($resource) {
        var resourceUrl =  'manager/' + 'api/agent-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'updateAgents': {
                method: 'POST',
                params: {
                    path: 'updateAgents'
                },
            }
        });
    }


    function StoreUser ($resource) {
        var resourceUrl =  'security/' + 'api/platform-users/:path/:id';

        return $resource(resourceUrl, {}, {
            "register":{
                method:'POST',
                params:{
                    path:"register"
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "resetPassword":{
                method:"GET",
                params:{
                    path:"resetPassword"
                }
            },
            'checkUser': {
                method:"POST",
                params:{
                    path:"checkUser"
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            }
        });
    }
})();
