(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('MessageNotification', MessageNotification);

    MessageNotification.$inject = ['$resource', 'DateUtils'];

    function MessageNotification ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/message-notifications/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'shareMessage': {
                method: 'GEt',
                params: {
                    path: 'shareMessage'
                },
            },
            'synchronizationMessage': {
                method: 'GEt',
                params: {
                    path: 'synchronizationMessage'
                },
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.validate = DateUtils.convertLocalDateFromServer(data.validate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validate = DateUtils.convertLocalDateToServer(copy.validate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validate = DateUtils.convertLocalDateToServer(copy.validate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
