(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayCodeController', PayCodeController);

    PayCodeController.$inject = ['$state', 'PayCode', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', '$scope', '$stateParams', '$uibModal'];

    function PayCodeController($state, PayCode, ParseLinks, paginationConstants, pagingParams, MessageService, $scope, $stateParams, $uibModal) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.tempArr = [];
        vm.storeId = $stateParams.storeId;
        if (!vm.storeId) {
            return;
        }

        loadAll();
        $scope.delete = function(code){
            MessageService.comment("确认作废该支付码么？",function(msg){
                 PayCode.delete({code: code, remark:msg},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true);
        };
        $scope.remark = function(data){
            $uibModal.open({
                templateUrl: 'app/entities/pay-code/remark-set.html',
                controller: 'RemarkSetController',
                controllerAs: 'vm',
                backdrop: 'static',
                // size: 'sm',
                resolve: {
                    entity: [function() {
                        return data;
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    loadAll()
                }
            });
        };

        $scope.allSelected = function(){
            if (vm.allSelected) {
                for (var i = vm.payCodes.length - 1; i >= 0; i--) {
                    var code = vm.payCodes[i];
                    code.selected = true;
                }
            }else{
                for (var i = vm.payCodes.length - 1; i >= 0; i--) {
                    var code = vm.payCodes[i];
                    code.selected = false;
                }
            }
            vm.tempArr =vm.payCodes.filter(function(item){
                return item.selected == true;
            });
        };

        //批量作废
        $scope.batchDelete = function(){
            if (vm.payCodes==null) {
                return;
            }
            var ids = [];
            for (var i = vm.payCodes.length - 1; i >= 0; i--) {
                var code = vm.payCodes[i];
                if (code.selected) {
                    ids.push(code.id);
                }
            }
            if (ids.length == 0) {
                return;
            }
            MessageService.comment("确认作废选择的支付码么？",function(msg){
                PayCode.batchDelete({ids: ids, remark:msg},function () {
                loadAll();
                vm.allSelected = false;
                MessageService.success('作废成功');
            });
            },function(){},true);

        };



        $scope.payCodeSelected = function(){
            if (vm.allSelected) {
                vm.allSelected = false;
                //return;
            }else{
                vm.tempArr =vm.payCodes.filter(function(item){
                    return item.selected == true;
                });
                for (var i = vm.payCodes.length - 1; i >= 0; i--) {
                    var code = vm.payCodes[i];
                    if (!code.selected) {
                        return;
                    }
                }
                vm.allSelected = true;
            }
        };

        function loadAll () {
            var items = [{key:"storeId", op:"=", value:vm.storeId}];
            if (pagingParams.search) {
                items.push({key:"storeName,productName,batch,supplier,code,number",op:"like",value:pagingParams.search});
            }
            if (pagingParams.search) {
                PayCode.byCondition2({
                    items: items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                PayCode.byCondition2({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.allPagesCount = Math.ceil(vm.totalItems/20);
                vm.queryCount = vm.totalItems;
                vm.payCodes = data;
                angular.forEach(vm.payCodes,function (code) {
                    if(code.productName){
                        code.productNameList = code.productName.split(";");
                    }
                    if(code.productSku){
                        code.productSkuList = code.productSku.split(";");
                    }
                })
                console.log(vm.payCodes)
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据加载失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                storeId:vm.storeId,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;

            if(flag){
                flag = false;
                $(".paybatch-proName"+index).addClass('text-decoration-underline');
                $(".paybatch-tip"+index).show().css({left:e.clientX || 0,top:e.clientY || 0});
            }

        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
    }
})();
