(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('sales-order', {
                parent: 'entity',
                url: '/sales-order?page&sort&search&status&startTime&endTime&sourceType&storeId&deleted&composition&noComposition&prototype&payRefundStatus&number&phone&deliveryNumber&code&giftCode&statusData&transactionId&recipientsPhone&deliveryType&deliveryCode&recipients',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER'],
                    pageTitle: 'backApp.salesOrder.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-orders.html',
                        controller: 'SalesOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            status: $stateParams.status,
                            startTime: $stateParams.startTime,
                            endTime:$stateParams.endTime,
                            sourceType:$stateParams.sourceType,
                            storeId:$stateParams.storeId,
                            deleted:$stateParams.deleted,
                            composition:$stateParams.composition,
                            noComposition:$stateParams.noComposition,
                            prototype:$stateParams.prototype,
                            payRefundStatus:$stateParams.payRefundStatus,
                            number:$stateParams.number,
                            recipients:$stateParams.recipients,
                            phone:$stateParams.phone,
                            deliveryNumber:$stateParams.deliveryNumber,
                            code:$stateParams.code,
                            giftCode:$stateParams.giftCode,
                            statusData:$stateParams.statusData,
                            transactionId:$stateParams.transactionId,
                            recipientsPhone:$stateParams.recipientsPhone,
                            deliveryType:$stateParams.deliveryType,
                            deliveryCode:$stateParams.deliveryCode
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-order-data-import', {
                parent: 'entity',
                url: '/sales-order-data-import',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER'],
                    pageTitle: 'backApp.salesOrder.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-order-data-import.html',
                        controller: 'SalesOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            status: $stateParams.status,
                            time: $stateParams.time
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-order-list', {
                parent: 'entity',
                url: '/sales-order-list/{storeId}?page&sort&search&status&startTime&endTime&sourceType&deleted&composition&noComposition&prototype&payRefundStatus&number&phone&deliveryNumber&code&giftCode&statusData&transactionId&recipientsPhone&deliveryType&deliveryCode&recipients',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER'],
                    pageTitle: 'backApp.salesOrder.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-orders.html',
                        controller: 'SalesOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    storeId: null,
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            status: $stateParams.status,
                            startTime: $stateParams.startTime,
                            endTime:$stateParams.endTime,
                            sourceType:$stateParams.sourceType,
                            deleted:$stateParams.deleted,
                            composition:$stateParams.composition,
                            noComposition:$stateParams.noComposition,
                            prototype:$stateParams.prototype,
                            payRefundStatus:$stateParams.payRefundStatus,
                            number:$stateParams.number,
                            recipients:$stateParams.recipients,
                            phone:$stateParams.phone,
                            deliveryNumber:$stateParams.deliveryNumber,
                            code:$stateParams.code,
                            giftCode:$stateParams.giftCode,
                            statusData:$stateParams.statusData,
                            transactionId:$stateParams.transactionId,
                            recipientsPhone:$stateParams.recipientsPhone,
                            deliveryType:$stateParams.deliveryType,
                            deliveryCode:$stateParams.deliveryCode
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-order-data-export', {
                parent: 'sales-order',
                url: '/sales-order-data-export',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-data-export.html',
                        controller: 'SalesOrderExportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }] ,
                            entity: ['SalesOrder', function(SalesOrder) {
                                return {};
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })
            .state('sales-order-data-import.import', {
                parent: 'sales-order-data-import',
                url: '/sales-order-data-import-import',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-data-import3.html',
                        controller: 'SalesOrderImportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }] ,
                            entity: ['SalesOrder', function(SalesOrder) {
                                return {};
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order-data-import', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order-data-import');
                    });
                }]
            })
            .state('sales-order-data-import.import2', {
                parent: 'sales-order-data-import',
                url: '/sales-order-data-import-import2',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-data-import2.html',
                        controller: 'SalesOrderImportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }] ,
                            entity: ['SalesOrder', function(SalesOrder) {
                                return {};
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order-data-import', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order-data-import');
                    });
                }]
            })

            .state('sales-order-export', {
                parent: 'sales-order',
                url: '/export',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-export-dialog.html',
                        controller: 'SalesOrderExportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return  {};
                            }
                        }
                    }).result.then(function() {
                        $state.go('sales-order', null, { reload: 'sales-order' });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })

            .state('sales-order-detail', {
                parent: 'sales-order',
                url: '/sales-order/{id}',
                data: {
                    // authorities: ['ROLE_SALES_ORDER']
                    authorities: ['ROLE_PLATFROM_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-detail.html',
                        controller: 'SalesOrderDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('orderStatus');
                                return $translate.refresh();
                            }],
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })

            .state('sales-order-express-history', {
                parent: 'sales-order-detail',
                url: '/history/{id}',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-delivery-history.html',
                        controller: 'SalesOrderDeliveryHistoryController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('orderStatus');
                                return $translate.refresh();
                            }],
                            entity: ['ExpressHistory', function(ExpressHistory) {
                                return ExpressHistory.byCondition({items:[{key:"orderId",op:"=",value:$stateParams.id}]}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })


            .state('sales-order.edit', {
                parent: 'sales-order',
                url: '/sales-order/{id}/edit/{status}',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-order-dialog.html',
                        controller: 'SalesOrderDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['SalesOrder', '$stateParams',
                        function(SalesOrder, $stateParams) {
                            return SalesOrder.get({id : $stateParams.id}).$promise;
                        }
                    ]
                }
            })


            .state('sales-order-delivery', {
                parent: 'sales-order',
                url: '/{id}/delivery',
                data: {
                    authorities: ['ROLE_PLATFROM_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-delivery-dialog.html',
                        controller: 'SalesOrderDeliveryController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', null, {
                            reload: 'sales-order'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })

            .state('pic-view-article', {
                parent: 'sales-order',
                url: '/picView/{fileName}',
                data: {
                    authorities: ['ROLE_PLATFROM_PRODUCT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-pic-view.html',
                        controller: 'SalesOrderPicViewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProductXmlFileServes', '$stateParams', function(ProductXmlFileServes, $stateParams) {
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', null, {
                            reload: 'sales-order'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
