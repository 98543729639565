(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('paycode-analysis', {
            parent: 'entity',
            url: '/paycode-analysis',
            data: {
                authorities: ["ROLE_PLATFROM_STATISTICS"],
                pageTitle: 'global.menu.entities.paycodeAnalysis'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/paycode-analysis/paycode-analysis.html',
                    controller: 'PaycodeAnalysisController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
