(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CommandBatchDialogController', CommandBatchDialogController);

    CommandBatchDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'CommandBatch', 'Product', 'Brand', 'MessageService'];

    function CommandBatchDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, CommandBatch, Product, Brand, MessageService) {
        var vm = this;

        vm.commandBatch = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        console.log($stateParams)
        vm.storeId = $stateParams.storeId;
        vm.commandBatch.storeId = vm.storeId;
        vm.products = Product.byCondition2({items:[{key: "storeId", op: "=", value: vm.storeId}]});
        vm.brands = Brand.getAllByCondition({items:[{key: "enabled", op: "=", value: "true"},{key: "storeId", op: "=", value: vm.storeId}]});

        $q.all([vm.commandBatch.$promise, vm.products.$promise, vm.brands.$promise]).then(function() {

        }).then(function() {
        });


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function(){
            if (vm.commandBatch.number == null || vm.commandBatch.number =="") {
                MessageService.error("请录入批次号");
                return false;
            }

            if (vm.commandBatch.type == null || vm.commandBatch.type =="") {
                MessageService.error("请选择类型");
                return false;
            }


            if (vm.commandBatch.productId == null || vm.commandBatch.productId =="") {
                MessageService.error("请选择商品");
                return false;
            }


            if (vm.commandBatch.brandId == null || vm.commandBatch.brandId =="") {
                MessageService.error("请选择品牌商");
                return false;
            }
            if (vm.commandBatch.dateEnable) {
                if (vm.commandBatch.startDate == null || vm.commandBatch.startDate =="") {
                    MessageService.error("请选择开始日期");
                    return false;
                }

                if (vm.commandBatch.endDate < new Date()) {
                    MessageService.error("结束日期不能早于当前日期");
                    return false;
                }
                if (vm.commandBatch.endDate < vm.commandBatch.startDate) {
                    MessageService.error("结束日期不能早于开始日期");
                    return false;
                }
                if (vm.commandBatch.endDate == null || vm.commandBatch.endDate =="") {
                    MessageService.error("请选择结束日期");
                    return false;
                }
            }

            return true;
        }

        function save () {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            if (vm.commandBatch.id !== null) {
                CommandBatch.update(vm.commandBatch, onSaveSuccess, onSaveError);
            } else {
                CommandBatch.createByAgent(vm.commandBatch, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:commandBatchUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
