(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LogoSettingPageController', LogoSettingPageController);

    LogoSettingPageController.$inject = ['$q', '$timeout', '$scope', '$stateParams', '$state', '$uibModalInstance', 'entity', 'UidService', 'MessageService', 'Qinius', 'PlatformWechatSetting'];

    function LogoSettingPageController($q, $timeout, $scope, $stateParams, $state, $uibModalInstance, entity, UidService, MessageService, Qinius, PlatformWechatSetting) {
        var vm = this;

        vm.type = entity;
        vm.logisticsCompany = entity;
        vm.clear = clear;

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.platformSetting = PlatformWechatSetting.byPlatFrom();
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.platformSetting.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        window.updateImgFile = function ($event) {
            $scope.$digest();
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "logo-"+ UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        if (vm.type == 1) { //登录页LOGO
                            vm.platformSetting.loginLogo = data.key;
                        } else if (vm.type == 2) {  //菜单顶部LOGO
                            vm.platformSetting.menuLogo = data.key;
                        }
                        PlatformWechatSetting.update(vm.platformSetting, onSaveSuccess, onSaveError);
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
