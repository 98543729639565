(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ClipArtDialogController', ClipArtDialogController);

    ClipArtDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'ClipArt', 'MessageService',
    'Upload', 'UidService', 'ThemeClassify', 'Convert'];

    function ClipArtDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, ClipArt, MessageService,
        Upload, UidService, ThemeClassify, Convert) {
        var vm = this;

        vm.clipArt = entity;
        vm.clear = clear;
        vm.save = save;
        vm.token = "";
        vm.attachments =[];
        vm.imgs =[];
        vm.keys =[];
        vm.themeClassifies = ThemeClassify.getAllByCondition({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: 'CLIPART'}]});
        vm.domains = Qinius.getDomain();
        $q.all([vm.themeClassifies.$promise,vm.domains.$promise,vm.clipArt.$promise]).then(function(){
             $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.validate = function(){

            if(vm.clipArt.themeClassifyId == null || vm.clipArt.themeClassifyId==""){
                MessageService.error("请选择分类");
                return false;
            }
            // if(!vm.clipArt.seq){
            //     MessageService.error("请输入排序");
            //     return false;
            // }
            if(vm.clipArt.id == null && vm.attachments.length ==0){
                MessageService.error("请上传素材");
                return false;
            }
            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.clipArt.type == null || vm.clipArt.type == "" ) {
                vm.clipArt.type = "CLIPART";
            }
            vm.clipArt.provider = "qiniu";
            vm.clipArt.keys = vm.keys;
            if (vm.clipArt.id != null) {
               ClipArt.update(vm.clipArt, onSaveSuccess, onSaveError);
            } else {
                ClipArt.batchCreate(vm.clipArt, onSaveSuccess, onSaveError);
            }
        };

        function onSaveSuccess (result) {
            MessageService.success("素材保存成功");
            $scope.$emit('editorApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        function onSaveError () {
            MessageService.error("素材保存失败");
            vm.isSaving = false;
        };

        //模板背景图更改
        window.updateImgFile = function ($event) {
            $scope.$digest();
            $timeout(function () {
                for(var i=0;i<$event.target.files.length;i++){
                    var file = $event.target.files[i];
                    vm.imgs.push(file.name);
                    fileGet(file);
                }
                function fileGet(file) {
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);

                    formData.append('key', "clipArt-" + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            // Convert.imageFormat({key:data.key},function(value){
                            //     vm.attachments.push({identifier:data.key,fileName:vm.imgs[i]});
                            //     vm.keys.push(data.key);
                            // },function(data){

                            // }) 
                            vm.attachments.push({identifier:data.key,fileName:vm.imgs[i]});
                            vm.keys.push(data.key);
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                   
                }
            });
        };
        //remove背景图
        $scope.removeFile = function(file,index) {
            vm.attachments.splice(index,1);
            vm.keys.splice(index,1);
        };
    }
})();
