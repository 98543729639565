(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('SelfApplicationDetailController', SelfApplicationDetailController);

    SelfApplicationDetailController.$inject = ['$scope', '$stateParams', '$timeout', 'entity', 'SelfApplication', '$uibModalInstance'];

    function SelfApplicationDetailController($scope, $stateParams, $timeout, entity, SelfApplication, $uibModalInstance) {
        var vm = this;

        vm.selfApplication = entity;
        vm.clear = clear;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
