(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('Logistics', Logistics);

    Logistics.$inject = ['$resource', 'DateUtils'];

    function Logistics($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/logistics/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'save': {
                method: 'POST',
                params: {
                    path: 'save'
                }
            }
        });
    }
})();
