(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ThemeController', ThemeController);

    ThemeController.$inject = ['$scope', '$state', 'MessageService','$q','PlatformWechatSetting'];

    function ThemeController ($scope, $state, MessageService,$q,PlatformWechatSetting) {
        var vm = this;
        vm.save = save;
        vm.platformWechatSetting = PlatformWechatSetting.byPlatFrom();
        vm.selectStyle ='';

        $q.all([vm.platformWechatSetting.$promise]).then(function(){
            if(vm.platformWechatSetting.skin == '' || vm.platformWechatSetting.skin == null || vm.platformWechatSetting.skin == undefined){
                vm.selectStyle = 'one';
            }else{
                vm.selectStyle =vm.platformWechatSetting.skin;
            }
        });

        $scope.themeSelectd = function(str){
            vm.selectStyle = str;
        };
        function save () {
            PlatformWechatSetting.updateSkin({
                skin:vm.selectStyle
            }, onSuccess, onError);
        }
        function onSuccess () {
            vm.isSaving = false;
            MessageService.success('保存成功！');
        }

        function onError () {
            vm.isSaving = false;
            MessageService.success('保存失败！')
        }

        $scope.preview =function(data){
            localStorage.setItem('flag',data);
            $state.go('theme-detail')
        };


    }
})();
