(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('platform-region', {
            parent: 'entity',
            url: '/platform-region?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'platformApp.platformRegion.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/platform-region/platform-regions.html',
                    controller: 'PlatformRegionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('platformRegion');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('platform-region-detail', {
            parent: 'platform-region',
            url: '/{id}/detail',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/platform-region/platform-region-detail.html',
                    controller: 'PlatformRegionDetailController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('platformRegion');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['PlatformRegion', function(PlatformRegion) {
                    return PlatformRegion.get({id : $stateParams.id}).$promise;
                }]
            }
        })

        .state('platform-region-edit', {
            parent: 'platform-region',
            url: '/{id}/edit',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/platform-region/platform-region-dialog.html',
                    controller: 'PlatformRegionDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('platformRegion');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['PlatformRegion', '$stateParams' , function(PlatformRegion, $stateParams) {
                    return PlatformRegion.get({id : $stateParams.id}).$promise;
                }]
            }
        })

        .state('platform-region-new', {
            parent: 'platform-region',
            url: '/new',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/platform-region/platform-region-dialog.html',
                    controller: 'PlatformRegionDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('platformRegion');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        status:200, 
                        message: { 
                            name: null,
                            cancelled: null,
                            freight: null,
                            freightFree: null,
                            region_temp_id: null,
                            enable: null,
                            billingRule: null,
                            pieceQuantity: null,
                            pieceFreight: null,
                            pieceAddQuantity: null,
                            fullReduceAble: null,
                            fullReduceQuantity: null,
                            fullReduceQuantity: null,
                            platformDeliveryAreas:[],
                            stores:[],
                            id: null
                        }
                    }
                }
            }
        });
    }

})();
