(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('RechargeSetting2PageController', RechargeSetting2PageController);

    RechargeSetting2PageController.$inject = ['$state', 'MessageService', 'entity', 'RechargeSettingCopy'];

    function RechargeSetting2PageController($state, MessageService, entity, RechargeSettingCopy) {

        var vm = this;

        vm.clear = clear;
        vm.rechargeSettingCopy = entity;
        console.log(entity);
        vm.save = save;

        function clear() {
            $state.go("recharge-setting2");
        }

        function save() {
            console.log(vm.rechargeSettingCopy);
            RechargeSettingCopy.save(vm.rechargeSettingCopy, function(data){
                MessageService.success("操作成功");
                $state.go("recharge-setting2");
            })
        }
        
    }
})();
