(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('filter-price', {
            parent: 'entity',
            url: '/filter-price?page&sort&search',
            data: {
                authorities: ['ROLE_PLATFROM_COMMODITY_PRICE'],
                pageTitle: 'platformApp.filterPrice.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/filter-price/filter-prices.html',
                    controller: 'FilterPriceController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('filterPrice');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('filter-price.new', {
            parent: 'filter-price',
            url: '/new',
            data: {
                authorities: ["ROLE_PLATFROM_COMMODITY_PRICE"],
                pageTitle: 'platformApp.filterPrice.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/filter-price/filter-price-dialog.html',
                    controller: 'FilterPriceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('filterPrice');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: function() {
                    return {
                        name: null,
                        enable: null,
                        base: null,
                        seq: null,
                        cancelled: null,
                        id: null,
                        filterPriceLines:[{
                            filterType:'PORTRAITCARTOON',
                            filterRule:"TIME",
                            filterPriceRules:[]
                            },{
                            filterType:'IMAGESTYLECONVERT',
                            filterRule:"TIME",
                            filterPriceRules:[]
                            },{
                            filterType:'SEGMENTATION',
                            filterRule:"TIME",
                            filterPriceRules:[]
                        }]
                    };
                }
            }

        })

        .state('filter-price.edit', {
            parent: 'filter-price',
            url: '/{id}/edit',
            data: {
                authorities: ["ROLE_PLATFROM_COMMODITY_PRICE"],
                pageTitle: 'platformApp.filterPrice.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/filter-price/filter-price-dialog.html',
                    controller: 'FilterPriceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('filterPrice');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['FilterPrice', '$stateParams', function(FilterPrice, $stateParams) {
                    return FilterPrice.get({id : $stateParams.id}).$promise;
                }]
            }
        })
         ;
    }

})();
