(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayCodeDetailController', PayCodeDetailController);

    PayCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'Customer', 'entity', 'PayCode', '$q', '$uibModalInstance', 'MessageService'];

    function PayCodeDetailController($scope, $rootScope, $stateParams, Customer, entity, PayCode, $q, $uibModalInstance, MessageService) {
        var vm = this;

        vm.unbinding = unbinding;
        vm.clear = clear;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function unbinding(){
            MessageService.confirm("确认要解绑该支付码？",function(){
                PayCode.unbind({code:vm.payCode.code},function(data){
                    if (data.status == 200) {
                        MessageService.success("解绑成功");
                        $uibModalInstance.dismiss('cancel');
                    }else{
                       MessageService.error(data.message); 
                    }
                },function(){   
                    MessageService.error("解绑失败");
                })
            })
        }

        vm.payCode = entity;
        vm.customer = {};
        $q.all([vm.payCode.$promise]).then(function(){
            if (vm.payCode.usedUserId) {
                Customer.get({id:vm.payCode.usedUserId},function(data){
                    vm.customer = data;
                },function(){})
            }
        })


        var unsubscribe = $rootScope.$on('backApp:payCodeUpdate', function(event, result) {
            vm.payCode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();