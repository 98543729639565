(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductXmlBatchEditController', ProductXmlBatchEditController);
    ProductXmlBatchEditController.$inject = ['$scope', 'Category', 'UidService', '$uibModalInstance', 'MessageService', '$stateParams', 'ProductXmls',
        'ThemeClassify', 'Store', 'entity', 'PlatformWechatSetting',   '$q', '$uibModal'];

    function ProductXmlBatchEditController($scope, Category, UidService, $uibModalInstance, MessageService, $stateParams, ProductXmls,
                                         ThemeClassify, Store, entity, PlatformWechatSetting,  $q, $uibModal) {
        //编辑第三方的模板 弹出窗口

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.ids = entity;
        
        $q.all([ ]).then(function () {
        });
        ThemeClassify.getAllByCondition({
            items: [{key: "used", op: "=", value: 'true'}, {
                key: "type",
                op: "=",
                value: "XML"
            }]
        }, function (res) {
            if (res) {
                vm.themeClassifyList = res;
            }  
        });
        Store.getAllByCondition({items: [{key:"status", value:"Approved", op:"="}]}, function (res) {
            if (res) {
                vm.stores = res;
            } 
        });
        PlatformWechatSetting.get({}, function (res) {
            if (res) {
                vm.platformSetting = res;
            } 
        });

        Category.getAllByCondition({items: [{key: "usable", op: "=", value: true}]}, function (res) {
            if (res) {
                vm.categories = res;
            }
        });
        
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.customStoreChange = function(){
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i];
                if (s.id == vm.customStoreId) {
                    vm.customStoreName = s.name;
                    break;
                }
            }
        }

        $scope.typeChage = function(){
            if (vm.type == 'Currency') {
                vm.customStoreId = null;
                vm.customStoreName = null;
            }
        }

        $scope.validate = function () {
            if (vm.categoryId == null || vm.categoryId == "") {
                MessageService.error("请选择分类");
                return false;
            }
            if (vm.themeId == null || vm.themeId == "") {
                MessageService.error("请选择模板分类");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            ProductXmls.batchEdit({ids:vm.ids, categoryId:vm.categoryId, themeId:vm.themeId, 
                type:vm.type,storeId:vm.customStoreId, storeName:vm.customStoreName, } , function (res) {
                if (res) {
                    MessageService.success("保存成功");
                    $uibModalInstance.close(res.data);
                } 
            }, function () {
                MessageService.error("保存失败");
            });
        }

        

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
 
    }

})();
