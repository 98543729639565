(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ExpressPriceDialogController', ExpressPriceDialogController);

    ExpressPriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'ExpressPrice', 'Store', '$state', 'MessageService', '$uibModal'
    ];

    function ExpressPriceDialogController($timeout, $scope, $stateParams, $q, entity, ExpressPrice, Store, $state, MessageService, $uibModal) {
        var vm = this;
        vm.expressPrice = entity;
        vm.stores = [];
        vm.storesAll = { name: '全部', selected: false };
        vm.isStores = true;
        vm.save = save;
        vm.clear = clear;

        Store.getAllByCondition({
            items: [{ key: "status", op: "=", value: "Approved" }]
        }, function(res) {
            for (var i = 0; i < res.length; i++) {
                if (!res[i].cancelled && res[i].authorityType) {
                    if (res[i].authorityType.indexOf("PRODUCT") != -1) {
                        vm.stores.push(res[i])
                    }
                }
            }
            if (entity.id) {
                if (vm.expressPrice.base) {
                    vm.storesAll.selected = true;
                    vm.isStores = false;
                } else {
                    for (var i = 0; i < vm.expressPrice.stores.length; i++) {
                        for (var j = 0; j < vm.stores.length; j++) {
                            if (vm.expressPrice.stores[i].id === vm.stores[j].id) {
                                vm.stores[j].selected = true;
                            }
                        }
                    }
                }
            }
        })


        //选择使用商家
        $scope.storesCheckbox = function(data) {
            vm.expressPrice.stores = [];
            data.selected = !data.selected ? true : false;
            for (var i = 0; i < vm.stores.length; i++) {
                if (vm.stores[i].selected) {
                    vm.expressPrice.stores.push(vm.stores[i])
                }
            }
            if (vm.expressPrice.stores.length > 0) {
                vm.expressPrice.base = false;
            }
        };
        $scope.storesAllCheckbox = function() {
            vm.storesAll.selected = !vm.storesAll.selected ? true : false;
            if (!vm.storesAll.selected) {
                vm.isStores = true;
                vm.expressPrice.base = false;
            } else {
                for (var j = 0; j < vm.stores.length; j++) {
                    vm.stores[j].selected = false;
                }
                vm.isStores = false;
                vm.expressPrice.stores = [];
                vm.expressPrice.base = true;
            }
        };

        //添加价格
        $scope.addPriceLines = function() {
            var _obj = {
                startQuantity: '',
                endQuantity: '',
                price: ''
            }
            vm.expressPrice.expressPriceLines.push(_obj)
        }

        //新增定价
        $scope.addPricing = function(data, index) {
            $uibModal.open({
                templateUrl: 'app/entities/flow-price/modal/pricing.html',
                controller: 'Pricing1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            data: data
                        };
                    }]
                }
            }).result.then(function(item) {
                if (item) {
                    vm.expressPrice.commodityPrices[index] = item;
                }
            });
        }

        function save() {
            if (!vm.expressPrice.name) {
                MessageService.error("名称不能为空！");
                return
            }
            if (!vm.expressPrice.base) {
                if (vm.expressPrice.stores.length == 0) {
                    MessageService.error("适用商家不能为空！");
                    return
                }
            }
            if (!vm.expressPrice.seq) {
                MessageService.error("优先级不能为空！");
                return
            }
            if (vm.expressPrice.expressPriceLines) {
                for (var i = vm.expressPrice.expressPriceLines.length - 1; i >= 0; i--) {
                    var price = vm.expressPrice.expressPriceLines[i];
                    if (!price.cancelled) {
                        if (price.endQuantity <= price.startQuantity) {
                            MessageService.error("结束数量不能小于或者等于开始数量");
                            return false
                        }
                        if (!price.price) {
                            MessageService.error("请输入价格");
                            return false
                        }
                    }
                }
            }
            if (!vm.expressPrice.id) {
                ExpressPrice.save(vm.expressPrice, function(res) {
                    $state.go("express-price");
                    MessageService.success("保存成功");
                })
            } else {
                ExpressPrice.update(vm.expressPrice, function(res) {
                    $state.go("express-price");
                    MessageService.success("编辑成功");
                })
            }
        }

        function clear() {
            $state.go("express-price");
        }

        vm.pricingStrategy = [
            { name: '标准定价', id: 'Standard' },
            { name: '梯度定价', id: 'Grads' },
        ];

        vm.priceRuleStatus = priceRuleStatus;

        function priceRuleStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "Standard":
                    return "标准定价";
                case "Ladder":
                    return "阶梯定价";
                case "Grads":
                    return "梯度定价";
                default:
                    return "";
            }
        }
    }
})();
