(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('CheckDelivery', CheckDelivery);

    CheckDelivery.$inject = ['$resource'];

    function CheckDelivery ($resource) {
        var resourceUrl =  'service/' + 'api/check-deliveries/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
        });
    }
})();
