(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformRegionDetailController', PlatformRegionDetailController);

    PlatformRegionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Region', 'Province', '$uibModalInstance'];

    function PlatformRegionDetailController($scope, $rootScope, $stateParams, entity, Region, Province, $uibModalInstance) {
        var vm = this;

        vm.region = entity;
        vm.area = {
            easternRegion: {
                selected: false,
                region: []
            },
            southernRegion: {
                selected: false,
                region: []
            },
            centerRegion: {
                selected: false,
                region: []
            },
            northRegion: {
                selected: false,
                region: []
            },
            northWestRegion: {
                selected: false,
                region: []
            },
            southWestRegion: {
                selected: false,
                region: []
            },
            northEastRegion: {
                selected: false,
                region: []
            }
        };
        vm.clear = clear;

        getArea(vm.region.provinces);
        function getArea(provincese) {
            angular.forEach(provincese, function (provinces) {
                provinces.selected = false;
                switch (provinces.area) {
                    case "华东区":
                        vm.area.easternRegion.region.push(provinces);
                        break;
                    case "华南区":
                        vm.area.southernRegion.region.push(provinces);
                        break;
                    case "华中区":
                        vm.area.centerRegion.region.push(provinces);
                        break;
                    case "华北区":
                        vm.area.northRegion.region.push(provinces);
                        break;
                    case "西北区":
                        vm.area.northWestRegion.region.push(provinces);
                        break;
                    case "西南区":
                        vm.area.southWestRegion.region.push(provinces);
                        break;
                    case "东北区":
                        vm.area.northEastRegion.region.push(provinces);
                        break;
                }
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('platformApp:regionUpdate', function(event, result) {
            vm.region = result;
            // console.log(vm.region);
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
