(function () {
    'use strict';
    angular
        .module('platformApp')
        .factory('PasswordMarketing', PasswordMarketing)
        .factory('MarketingStore', MarketingStore);

    PasswordMarketing.$inject = ['$resource'];
    MarketingStore.$inject = ['$resource'];

    function PasswordMarketing($resource) {
        var resourceUrl = 'manager/' + 'api/password-marketings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getOne': {
                method: "GET",
                params: {
                    path: "getOne"
                }
            }
        });
    }

    function MarketingStore($resource) {
        var resourceUrl = 'manager/' + 'api/marketing-stores/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getAll': {
                method: "GET",
                params: {
                    path: "getAllMarketingStores"
                }, isArray: true
            }
        });
    }
})();
