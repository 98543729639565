(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product-order', {
            parent: 'store-detail2',
            url: '/product-order?page&sort&search',
            data: {
                authorities: ["ROLE_PLATFROM_AGENT"],
                pageTitle: 'platformApp.productPurchase.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-order/product-order.html',
                    controller: 'ProductOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                     $translatePartialLoader.addPart('purchaseStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-order-new', {
            parent: 'product-order',
            url: '/new',
            params:{itemValues: null},
            data: {
                authorities: ["ROLE_PLATFROM_AGENT"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-order/product-order-dialog.html',
                    controller: 'ProductOrderDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                    $translatePartialLoader.addPart('purchaseStatus');
                    return $translate.refresh();
                }],
                entity: function() {
                    return {
                        quantity: null,
                        status: null,
                        id: null
                    };
                }
            }
        })
        .state('product-order-buy', {
            parent: 'product-order',
            url: '/orderBuy',
            data: {
                authorities: ["ROLE_PLATFROM_AGENT"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-order/product-order-buy.html',
                    controller: 'ProductOrderBuyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                    $translatePartialLoader.addPart('purchaseStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', function($stateParams) {
                    return
                }]
            }
        })
        .state('product-order-buy-edit', {
            parent: 'product-order',
            url: '/orderBuy/edit/{id}',
            data: {
                authorities: ["ROLE_PLATFROM_AGENT"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-order/product-order-buy.html',
                    controller: 'ProductOrderBuyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                    $translatePartialLoader.addPart('purchaseStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ProductOrder', function($stateParams, ProductOrder) {
                    return ProductOrder.get({id : $stateParams.id}).$promise;
                }]
            }
        })
            .state('product-order-buy-look', {
                parent: 'product-order',
                url: '/orderBuy/look/{id}/{type}',
                data: {
                    authorities: ["ROLE_PLATFROM_AGENT"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-order/product-order-buy.html',
                        controller: 'ProductOrderBuyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductOrder', function($stateParams, ProductOrder) {
                        return ProductOrder.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('product-order-buy1', {
                parent: 'product-order',
                url: '/orderBuy1',
                data: {
                    authorities: ["ROLE_PLATFROM_AGENT"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-order/product-order-buy1.html',
                        controller: 'ProductOrderBuy1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function($stateParams) {
                        return
                    }]
                }
            })
            .state('product-order-buy1-edit', {
                parent: 'product-order',
                url: '/orderBuy1/edit/{id}',
                data: {
                    authorities: ["ROLE_PLATFROM_AGENT"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-order/product-order-buy1.html',
                        controller: 'ProductOrderBuy1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductOrder', function($stateParams, ProductOrder) {
                        return ProductOrder.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('product-order-buy1-look', {
                parent: 'product-order',
                url: '/orderBuy1/look/{id}/{type}',
                data: {
                    authorities: ["ROLE_PLATFROM_AGENT"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-order/product-order-buy1.html',
                        controller: 'ProductOrderBuy1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductOrder', function($stateParams, ProductOrder) {
                        return ProductOrder.get({id : $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();
