(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('StoreWechatSetting', StoreWechatSetting)
        .factory('PlatformWechatSetting', PlatformWechatSetting)
        .factory("StoreSmallAppWechatSetting", StoreSmallAppWechatSetting)
        .factory('WechatAuth', WechatAuth)
        .factory('WechatAuth2', WechatAuth2);

    StoreSmallAppWechatSetting.$inject = ['$resource'];

    StoreWechatSetting.$inject = ['$resource', 'DateUtils'];

    PlatformWechatSetting.$inject = ['$resource'];

    WechatAuth.$inject = ['$resource'];

    WechatAuth2.$inject = ['$resource'];

    function WechatAuth ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'wx/:path';

        return $resource(resourceUrl, {}, {
            "auth_notification":{
                method:"POST",
                params:{
                    path:"auth_notification"
                }
            },
            "pre_auth_code":{
                method:"POST",
                params:{
                    path:"pre_auth_code"
                }
            },
            "auth_notify":{
                method:"GET",
                params:{
                    path:"auth_notify"
                }
             },
            "platformAuth_notify":{
                method:"GET",
                params:{
                    path:"platformAuth_notify"
                }
             }
        });
    }

    function WechatAuth2 ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'wx/small-app/:path';

        return $resource(resourceUrl, {}, {

            "commit":{
                method:"GET",
                params:{
                    path:"commit"
                }
            },
            "undocodeaudit":{
                method:"GET",
                params:{
                    path:"undocodeaudit"
                }
             },
            "submit_audit":{
                method:"GET",
                params:{
                    path:"submit_audit"
                }
             },
            "release":{
                method:"GET",
                params:{
                    path:"release"
                }
             },
            "get_latest_auditstatus":{
                method:"GET",
                params:{
                    path:"get_latest_auditstatus"
                }
             },
             "getTemplateList":{
                method:"GET",
                params:{
                    path:"getTemplateList"
                }
             },
            "getDomain":{
                method:"GET",
                params:{
                    path:"getDomain"
                }
            },
            "setPrivacySetting":{
                method:"POST",
                params:{
                    path:"setPrivacySetting"
                }
            },
            "getPrivacySetting":{
                method:"GET",
                params:{
                    path:"getPrivacySetting"
                }
            }
        });
    }

    function PlatformWechatSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/platform-wechat-settings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',

            },
            'update': { method:'PUT' }
        });
    }

    function StoreWechatSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-wechat-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expiresTime = DateUtils.convertDateTimeFromServer(data.expiresTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'createMenu':{
                method:'POST',
                params:{
                    path:"createMenu"
                }
            },
            'getMenu': {
                method: 'GET',
                params: {
                    path: "getMenu"
                }
            },
            "getEntityByStoreId":{
                method:"GET",
                params:{
                    path:"getEntityByStoreId"
                }
            },
            "getPublicWechatSetting":{
                method:"GET",
                params:{
                    path:"getPublicWechatSetting"
                }
            },
            "getByStoreId":{
                method:"GET",
                params:{
                    path:"getByStoreId"
                }
            },
            "getPrivateWechatSetting":{
                method:"GET",
                params:{
                    path:"getPrivateWechatSetting"
                }
            }
        });
    }
    function StoreSmallAppWechatSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-small-app-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expiresTime = DateUtils.convertDateTimeFromServer(data.expiresTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            "getByStoreId":{
                method:"GET",
                params:{
                    path:"getByStoreId"
                }
            },
            "getEntityByStoreId":{
                method:"GET",
                params:{
                    path:"getEntityByStoreId"
                }
            }
        });
    }
})();
