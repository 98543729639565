(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreFreeSpaceController', StoreFreeSpaceController);

    StoreFreeSpaceController.$inject = ['$scope', 'entity', 'StoreStorage', '$uibModalInstance', 'MessageService'];

    function StoreFreeSpaceController($scope, entity, StoreStorage, $uibModalInstance, MessageService) {
        var vm = this;

        vm.storeStorage = entity;

        vm.storeStorage.initSize = vm.storeStorage.initSize / 1000 / 1000/1000/1000;

        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            StoreStorage.updateInitSize({
                storeId: vm.storeStorage.storeId,
                size: vm.storeStorage.initSize * 1000 * 1000*1000*1000
            }, function(data) {
                $uibModalInstance.close(data);
                MessageService.success("保存成功");
            }, function() {
                MessageService.error("保存失败");
            })
        }
    }
})();
