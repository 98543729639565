(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PropertyDialogController', PropertyDialogController);

    PropertyDialogController.$inject = ['$timeout', '$scope', '$state', 'entity', 'Property', 'MessageService'];

    function PropertyDialogController ($timeout, $scope, $state, entity, Property, MessageService ) {
        var vm = this;

        vm.property = entity;
        if(!vm.property.id){
            vm.property.propertyType = 'Default'
        }

        vm.clear = clear;
        vm.save = save;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('property', null, { reload: 'property' });
        }

        $scope.validate = function(){
            if(vm.property.name == null || vm.property.name==""){
                MessageService.error("请输入规格名称");
                return false;
            }

            return true;
        };

        $scope.addValue = function(){
            if(!$scope.validate()){
                return;
            }
            var value = {id:null,value:null,cancelled:false,added:true}
            if (vm.property.propertyValues == null) {
                vm.property.propertyValues = [];
            }
            vm.property.propertyValues.push(value);
        };

        $scope.deleteValue = function(value){
            if(value == null || vm.property.propertyValues == null || vm.property.propertyValues.length == 0){
                return;
            }
            if(value.id==null){
                var inde1 = vm.property.propertyValues.indexOf(value);
                vm.property.propertyValues.splice(inde1,1);
            }else{
                value.cancelled = true;
            }
        };


        function save() {
            var isValueNull = false
            for(var i=0;i<vm.property.propertyValues.length;i++){
                var v = vm.property.propertyValues[i];
                if(v.value==null||v.value==''){
                    MessageService.error("请输入规格列表名称");
                    isValueNull = true
                    break
                }
            }
            if(isValueNull){
                return false;
            }
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.property.id !== null) {
                Property.update(vm.property, onSaveSuccess, onSaveError);
            } else {
                Property.save(vm.property, onSaveSuccess, onSaveError);
            }
        };

        function onSaveSuccess (result) {
            $scope.$emit('backApp:propertyUpdate', result);
            $state.go('property', null, { reload: 'property' });
            vm.isSaving = false;
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }


        window.addPropertys=function(event){
            var e = event || window.event;
            if(e && e.keyCode==13){ // enter 键
                var value = {id:null,value:e.target.value,cancelled:false,added:true};
                if (vm.property.propertyValues == null) {
                    vm.property.propertyValues = [];
                }
                vm.property.propertyValues.push(value);
                $("#field_value").val('');
            }
        };

    }
})();
