(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FlowPriceDialogController', FlowPriceDialogController);

    FlowPriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product',
    'Store', '$state', 'MessageService', '$uibModal', 'Tariffs', "ListService"];

    function FlowPriceDialogController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product,
        Store, $state, MessageService, $uibModal, Tariffs, ListService) {
        var vm = this;
        vm.commodityPrice = entity;
        // vm.products = Product.getAllByCondition2({items:[{key: "prototype", op: "=", value: "Original"}]});
        vm.stores = [];
        vm.storesAll = {name: '全部', selected: false};
        vm.isStores = true;
        vm.save = save;
        vm.clear = clear;

        if(!vm.commodityPrice.id){
            vm.commodityPrice = {
                name: '',
                available: false,
                stores: [],
                seq: '',
                commodityPrices: [
                    {priceRule:'Grads',editorType:'BOOK_SENIOR',editorTypeName:'高级编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'BOOK_JUNIOR',editorTypeName:'简易编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'PICTURE',editorTypeName:'冲印编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'POSTER',editorTypeName:'万物编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'NEW_EDITOR',editorTypeName:'相册编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'SIMPLEST',editorTypeName:'极简编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'DIY_EDITOR',editorTypeName:'DIY编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'FORM_EDITOR',editorTypeName:'表单编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'CALENDAR_EDITOR',editorTypeName:'动态台历编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                ],
                base: false,
                tariffType:'Editor'
            }
        }else{
            if (vm.commodityPrice.commodityPrices && vm.commodityPrice.commodityPrices.length<9) {
                var commodityPrices = [
                    {priceRule:'Grads',editorType:'BOOK_SENIOR',editorTypeName:'高级编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'BOOK_JUNIOR',editorTypeName:'简易编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'PICTURE',editorTypeName:'冲印编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'POSTER',editorTypeName:'万物编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'NEW_EDITOR',editorTypeName:'相册编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'SIMPLEST',editorTypeName:'极简编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'DIY_EDITOR',editorTypeName:'DIY编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'FORM_EDITOR',editorTypeName:'表单编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                    {priceRule:'Grads',editorType:'CALENDAR_EDITOR',editorTypeName:'动态台历编辑器',remark:'',priceLines:[],products:[],itemValueLines:[]},
                ];
                for (var i = commodityPrices.length - 1; i >= 0; i--) {
                    var com = commodityPrices[i];
                    var result =  ListService.inList(com, vm.commodityPrice.commodityPrices, ["editorType"]);
                    if (result == null) {
                        vm.commodityPrice.commodityPrices.push(com);
                    }
                }
            }
        }

        Store.getAllByCondition({
            items: [{key: "status", op: "=", value: "Approved"}]
        }, function (res) {
            for(var i=0; i<res.length; i++){
                if(!res[i].cancelled){
                    vm.stores.push(res[i])
                }
            }
            if(entity.id){
                if(vm.commodityPrice.base){
                    vm.storesAll.selected = true;
                    vm.isStores = false;
                }else {
                    for(var i=0; i<vm.commodityPrice.stores.length; i++){
                        for(var j=0; j<vm.stores.length; j++){
                            if(vm.commodityPrice.stores[i].id === vm.stores[j].id){
                                vm.stores[j].selected = true;
                            }
                        }
                    }
                }
            }
        })

        //选择使用商家
        $scope.storesCheckbox = function(data){
            vm.commodityPrice.stores = [];
            data.selected = !data.selected ? true : false;
            for(var i=0; i<vm.stores.length; i++){
                if(vm.stores[i].selected){
                    vm.commodityPrice.stores.push(vm.stores[i])
                }
            }
            if(vm.commodityPrice.stores.length > 0){
                vm.commodityPrice.base = false;
            }
        };
        $scope.storesAllCheckbox = function(){
            vm.storesAll.selected = !vm.storesAll.selected ? true : false;
            if(!vm.storesAll.selected){
                vm.isStores = true;
                vm.commodityPrice.base = false;
            }else {
                for(var j=0; j<vm.stores.length; j++){
                    vm.stores[j].selected = false;
                }
                vm.isStores = false;
                vm.commodityPrice.stores = [];
                vm.commodityPrice.base = true;
            }
        };
        //新增定价
        $scope.addPricing = function (data, index) {
            $uibModal.open({
                templateUrl: 'app/entities/flow-price/modal/pricing.html',
                controller: 'Pricing1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            data: data
                        };
                    }]
                }
            }).result.then(function (item) {
                if(item){
                    vm.commodityPrice.commodityPrices[index] = item;
                }
            });
        }
        function save () {
            if(!vm.commodityPrice.name){
                MessageService.error("价目表名称不能为空！");
                return
            }
            if(!vm.commodityPrice.base){
                if(vm.commodityPrice.stores.length == 0){
                    MessageService.error("适用商家不能为空！");
                    return
                }
            }
            if(!vm.commodityPrice.seq){
                MessageService.error("优先级不能为空！");
                return
            }
            var _commodityPrices = [];
            for(var i=0; i<vm.commodityPrice.commodityPrices.length; i++){
                if(!vm.commodityPrice.commodityPrices[i].cancelled){
                    _commodityPrices.push(vm.commodityPrice.commodityPrices[i])
                }
            }
            if(_commodityPrices.length == 0){
                MessageService.error("价目信息不能为空！");
                return
            }

            if(!vm.commodityPrice.id){
                Tariffs.save(vm.commodityPrice, function (res) {
                    $state.go("flow-price");
                    MessageService.success("保存成功");
                })
            }else {
                Tariffs.update(vm.commodityPrice, function (res) {
                    $state.go("flow-price");
                    MessageService.success("编辑成功");
                })
            }
        }
        function clear(){
            $state.go("flow-price");
        }

        vm.priceRuleStatus = priceRuleStatus;
        function priceRuleStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "Standard":
                    return "标准定价";
                case "Ladder":
                    return "阶梯定价";
                case "Grads":
                    return "梯度定价";
                default:
                    return "";
            }
        }

        vm.editorTypeStatus = editorTypeStatus;
        function editorTypeStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "BOOK_SENIOR":
                    return "高级编辑器";
                case "BOOK_JUNIOR":
                    return "简易编辑器";
                case "PICTURE":
                    return "冲印编辑器";
                case "POSTER":
                    return "万物编辑器";
                case "NEW_EDITOR":
                    return "相册编辑器";
                case "SIMPLEST":
                    return "极简编辑器";
                case "DIY_EDITOR":
                    return "DIY编辑器";
                case "FORM_EDITOR":
                    return "表单编辑器";
                case "CALENDAR_EDITOR":
                    return "动态台历编辑器";
                default:
                    return "";
            }
        }

    }
})();
