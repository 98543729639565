(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreThirdController', StoreThirdController);

    StoreThirdController.$inject = ['$scope', '$q', '$state', '$rootScope', '$stateParams', 'entity', 'Store',
    'PlatformAbutment', 'MessageService', 'PlatformWechatSetting'];

    function StoreThirdController($scope, $q, $state, $rootScope, $stateParams, entity, Store,
     PlatformAbutment, MessageService, PlatformWechatSetting) {
        var vm = this;

        vm.abutment = entity;
        vm.tabDiv = tabDiv;
        vm.store = Store.getStore({id:$stateParams.storeId});
        vm.platformWechatSetting = PlatformWechatSetting.byPlatFrom();
        vm.abutments = PlatformAbutment.getAll({items:[{key:"storeId", op:"=", value:$stateParams.storeId}]});
         vm.tabFlag = 1;
        function tabDiv(index){
            vm.tabFlag = index;
        }

        $scope.delete =function(abutment){
            MessageService.confirm("确认要删除该数据", function(){
                PlatformAbutment.del({id:abutment.id},function(data){
                    MessageService.success("删除成功");
                    vm.abutments = PlatformAbutment.getAll({items:[]});
                },function(){
                    MessageService.error("删除失败");
                })
            }, function(){

            })
        }
    }
})();
