(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PictureSettingController', PictureSettingController);

    PictureSettingController.$inject = ['$scope', '$state', 'PicManagements', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', '$uibModal'];

    function PictureSettingController ($scope, $state, PicManagements, ParseLinks, paginationConstants, pagingParams, MessageService, $uibModal) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        loadAll();

        function loadAll () {
            PicManagements.byCondition({
                items: [],
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.properties = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("属性获取失败");
            }
        }

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                PicManagements.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true);
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0  ? 1 : vm.jumpText;
            loadPage(vm.page);
        }


        //创建
        $scope.uploadAdd = function () {
            $uibModal.open({
                templateUrl: 'app/entities/picture-setting/modal/picture-setting-modal.html',
                controller: 'PictureSettingModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            data: {}
                        };
                    }]
                }
            }).result.then(function (item) {
                if(item){
                    loadAll();
                }
            });
        }
        //编辑
        $scope.uploadEdit = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/picture-setting/modal/picture-setting-modal.html',
                controller: 'PictureSettingModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            data: data
                        };
                    }]
                }
            }).result.then(function (item) {
                if(item){
                    loadAll();
                }
            });
        }

        $scope.pageSiteInit = function (item) {
            switch(item) {
                case 'login':
                    return '登录页';
                case 'register':
                    return '注册页';
                default:
                    return ''
            }
        }
    }
})();
