(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('Music', Music);

    Music.$inject = ['$resource', 'DateUtils'];

    function Music($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/music/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'allByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'update': {
                method: 'GET',
                params: {
                    path: 'update'
                }
            },
            'save': {
                method: 'POST'
            },
            'get': {
                method: 'GET'
            },
            'del': {
                method: 'DELETE'
            },
            'dels': {
                method: 'DELETE',
                params: {
                    path: 'delBatch'
                }
            }
        });
    }
})();