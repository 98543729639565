(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('LogisticsCompanyDialogController', LogisticsCompanyDialogController);

    LogisticsCompanyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LogisticsCompany', 'MessageService'];

    function LogisticsCompanyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LogisticsCompany, MessageService) {
        var vm = this;

        vm.logisticsCompany = entity;
        console.log(entity);
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.logisticsCompany.id !== null) {
                LogisticsCompany.update(vm.logisticsCompany, onSaveSuccess, onSaveError);
            } else {
                LogisticsCompany.save(vm.logisticsCompany, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:logisticsCompanyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('操作成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error('操作失败');
        }


    }
})();
