(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('GroupBuyMarketingController', GroupBuyMarketingController);

    GroupBuyMarketingController.$inject = ['$state', 'PasswordMarketing', 'ParseLinks', '$q', 'MarketingStore', 'entity'];

    function GroupBuyMarketingController($state, PasswordMarketing, ParseLinks, $q, MarketingStore, entity) {

        var vm = this;
        vm.passwordMarketing = entity;
        vm.marketingStores = vm.passwordMarketing.marketingStores;

    }
})();
