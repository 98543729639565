(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreDetailController', StoreDetailController)
        .filter('sizeTransition', function () {
            return function(size){
                if (!size)
                    return 0 + "B";
                var num = 1000.00;
                if (size < num)
                    return size.toFixed(2) + "B";
                if (size < Math.pow(num, 2))
                    return (size / num).toFixed(2) + "KB"; //kb
                if (size < Math.pow(num, 3))
                    return (size / Math.pow(num, 2)).toFixed(2) + "MB"; //M
                if (size < Math.pow(num, 4))
                    return (size / Math.pow(num, 3)).toFixed(2) + "GB"; //G
                return (size / Math.pow(num, 4)).toFixed(2) + "TB"; //T
            }
        });

    StoreDetailController.$inject = ['$scope', '$q', '$state', '$rootScope', '$stateParams', 'entity', 'Store', 'Qinius', 'OrderSetting', 'StoreWechatSetting',
    'StoreSmallAppWechatSetting', 'Product', 'StoreStorage'];

    function StoreDetailController($scope, $q, $state, $rootScope, $stateParams, entity, Store, Qinius, OrderSetting, StoreWechatSetting,
        StoreSmallAppWechatSetting, Product, StoreStorage) {
        var vm = this;

        vm.store = entity;
        vm.clear = clear;
        vm.clear2 = clear2;
        vm.getQualityName = getQualityName;
        //通过标识商户授权类型 查找对应信息
        vm.wechat = false;
        vm.mini = false;
        vm.wechatSetting = {};
        vm.miniSetting = {};
        function clear () {
            $state.go("store");
        };

        function clear2 (){
            $state.go("store2");
        }

        function getQualityName(val) {
            switch (val) {
                case 'LOW':
                    return '中';
                case 'MEDIUM':
                    return '高';
                case 'HIGH':
                    return '超清';
                 default:
                    return '';
             }
         }


        vm.types = [{key:"公众号",value:"WECHAT",selected:false}, {key:"微信小程序",value:"MINI",selected:false}, {key:"网页端",value:"WEB",selected:false}, {key: "支付宝", value: "ALI", selected: false}, {key: "字节跳动", value: "BYTEDANCE", selected: false}, {key: "京东小程序", value: "JD", selected: false}]
        vm.navigateRules = [
            {key: "h5", value: "h5", selected: false},
            {key: "小程序", value: "mini", selected: false}
        ]
        if ($stateParams.storeId) {
            vm.storeOrderSetting =OrderSetting.getStoreOrderSetting({storeId:$stateParams.storeId});
        }
        vm.domains = Qinius.getDomain();

        $q.all([vm.domains.$promise , vm.store.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';

            StoreStorage.byStoreId({storeId:vm.store.id}, function(data){
                vm.storeStorage = data;
                vm.initSize = vm.storeStorage.initSize;//TB
                vm.buySize = vm.storeStorage.buySize;//TB
            }, function(data){
            })

            if (vm.store.storeType2) {
                for (var i = vm.types.length - 1; i >= 0; i--) {
                    var t = vm.types[i];
                    if(vm.store.storeType2.indexOf(t.value)>=0){
                        t.selected = true;
                        if (t.key== "公众号") {
                            vm.wechat = true;
                        }
                        if (t.key== "小程序") {
                            vm.mini = true;
                        }
                    }
                }
            }
            if (vm.store.navigateRule) {
                for (var i = vm.navigateRules.length - 1; i >= 0; i--) {
                    var t = vm.navigateRules[i];
                    if(vm.store.navigateRule.indexOf(t.value)>=0){
                        t.selected = true;
                    }
                }
            }
            // if (vm.wechat) {
            //     StoreWechatSetting.getByStoreId({storeId:vm.store.id}, function(data){
            //         if (data) {
            //            vm.wechatSetting = data;
            //         }
            //     }, function(data){

            //     })
            // }
            // if (vm.mini) {
            //     StoreSmallAppWechatSetting.getEntityByStoreId({storeId:vm.store.id}, function(data){
            //         if (data) {
            //            vm.miniSetting = data;
            //         }
            //     }, function(data){

            //     })
            // }
            Product.getAllByCondition2({
                items: [
                    {key: "prototype", op: "=", value: 'Store'},
                    {key: "cancelled", op: "=", value: 'false'},
                    {key: "storeId", op: "=", value: vm.store.id}],
                sort: ["id,desc"]
            }, function (res) {
                vm.products = res;
            }, function (error) {

            });

        })

        var unsubscribe = $rootScope.$on('platformApp:storeUpdate', function(event, result) {
            vm.store = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
