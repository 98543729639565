(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CategoryDetailController', CategoryDetailController);

    CategoryDetailController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'entity', 'Category','$uibModalInstance', 'Qinius'];

    function CategoryDetailController($scope, $q, $rootScope, $stateParams, entity, Category, $uibModalInstance, Qinius) {
        var vm = this;

        vm.category = entity;
        vm.clear = clear;
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('backApp:categoryUpdate', function(event, result) {
            vm.category = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
