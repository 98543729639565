(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('feedback', {
                parent: 'entity',
                url: '/feedback',
                data: {
                    authorities: ['ROLE_FEEDBACK'],
                    pageTitle: 'global.menu.entities.feedbackManagement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/feedback/feedback.html',
                        controller: 'FeedbackController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    status:null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            status: $stateParams.status
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('feedback');
                        return $translate.refresh();
                    }]
                }
            })
            .state('feedback-www',{
                parent: 'entity',
                url: '/feedback-www',
                data: {
                    authorities: ['ROLE_VISITOR_TICKET'],
                    pageTitle: 'global.menu.entities.feedbackManagement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/feedback/feedback-www.html',
                        controller: 'FeedbackWwwController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    status:null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            status: $stateParams.status
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('feedback');
                        return $translate.refresh();
                    }]
                }
            })
            .state('feedback-detail', {
                parent: 'feedback',
                url: '/feedback/{id}',
                data: {
                    authorities: ['ROLE_FEEDBACK']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/feedback/feedback-detail.html',
                        controller: 'FeedbackDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('feedback');
                                return $translate.refresh();
                            }],
                            entity: ['Feedback', function(Feedback) {
                                return Feedback.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('feedback', {}, {
                            reload: 'feedback'
                        });
                    }, function() {
                        $state.go('feedback');
                    });
                }]
            })
            .state('feedback-deal', {
                parent: 'feedback',
                url: '/{id}/deal',
                data: {
                    authorities: ['ROLE_FEEDBACK']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/feedback/feedback-deal-dialog.html',
                        controller: 'FeedbackDealController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('feedback');
                                return $translate.refresh();
                            }],
                            entity: ['Feedback', function(Feedback) {
                                return Feedback.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('feedback', {}, {
                            reload: 'feedback'
                        });
                    }, function() {
                        $state.go('feedback');
                    });
                }]
            })
    }

})();
