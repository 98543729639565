(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('HomePageController', HomePageController);

    HomePageController.$inject = ['$q', '$scope', '$state', 'MessageService', 'SalesOrder', 'ProductPurchase', 'PayCode', 'AfterSale'];

    function HomePageController($q, $scope, $state, MessageService, SalesOrder, ProductPurchase, PayCode, AfterSale) {
        var vm = this;
        vm.lmenuClick = lmenuClick;

        vm.navs=[
            {name:'平台设置', url:'platform-wechat-setting', img:'platform-set.svg', lmenu:"a_platform-wechat-setting"},
            {name:'商户管理', url:'store', img:'store-user-manage.svg', lmenu:"a_merchant"},
            {name:'订购管理', url:'product-purchase', img:'order-manage.svg', lmenu:"a_merchant"},
            {name:'充值记录', url:'pay-order', img:'pay-record.svg', lmenu:"a_merchant"},
            {name:'订单列表', url:'sales-order', img:'order-list.svg', lmenu:"a_salesManagement"},
            {name:'商品管理', url:'product', img:'product-manage.svg', lmenu:"a_product"},
            {name:'数据统计', url:'statisticalSales', img:'data-total.svg', lmenu:"a_data"},
            {name:'售后管理', url:'after-sale', img:'after-manage.svg', lmenu:"a_afterSale"},
        ];

        function lmenuClick(a_id) {
            vm.lmenu = a_id;
            sessionStorage.setItem("lmenu", a_id);
            function test(){
              $state.reload();
            }
            setTimeout(test,800);
        }

        //receivingNum: null, uncheckedNum: null, producingNum: null, paidNum: 11
        vm.orders = SalesOrder.getHomeNum2();
        vm.ppNumResource = ProductPurchase.getYeasterDayNum();
        vm.payCodes = PayCode.getAllByTimeSlot3();
        vm.soState = SalesOrder.getAllNumByState();
        vm.afterSaleNums = AfterSale.getAllNumByToday();
        $q.all([vm.orders.$promise, vm.ppNumResource.$promise, vm.payCodes.$promise, vm.soState.$promise, vm.afterSaleNums.$promise]).then(function(){
            vm.soNum = vm.orders.length;
            vm.turnoverNum = 0;
            for (var i = vm.orders.length - 1; i >= 0; i--) {
               vm.turnoverNum = vm.turnoverNum+ vm.orders[i].payAmount;
            }
            vm.ppNUm = vm.ppNumResource.object;
            vm.cadAllNum = vm.payCodes.allNum;
            vm.cadActivateNum = vm.payCodes.activateNum;

            // 订单动态
            vm.uncheckedNum = vm.soState.uncheckedNum == null ? 0 : vm.soState.uncheckedNum;
            vm.receivingNum = vm.soState.receivingNum == null ? 0 : vm.soState.receivingNum;
            vm.producingNum = vm.soState.producingNum == null ? 0 : vm.soState.producingNum;
            vm.paidNum = vm.soState.paidNum == null ? 0 : vm.soState.paidNum;
            vm.combinationErrorNum = vm.soState.combinationErrorNum == null ? 0 : vm.soState.combinationErrorNum;
            

            // 售后数据  receivingNum: 1, todayNum: 0
            vm.afReceivingNum = vm.afterSaleNums.receivingNum == null ? 0 : vm.afterSaleNums.receivingNum;
            vm.afTodayNum = vm.afterSaleNums.todayNum == null ? 0 : vm.afterSaleNums.todayNum;
        });
    }
})();
