(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('StorageSpace', StorageSpace)
        .factory('StorageSpace2', StorageSpace2)
        .factory('StorageSpace3', StorageSpace3);

    StorageSpace.$inject = ['$resource'];
    StorageSpace2.$inject = ['$resource'];
    StorageSpace3.$inject = ['$resource'];

    function StorageSpace($resource) {
        var resourceUrl = 'manager/' + 'api/store-storages/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true,
            },
            'getUsedSizeStoreId': {
                method: 'GET',
                params: {
                    path: 'getUsedSizeStoreId'
                },
                isArray: true,
            },
        });
    }

    function StorageSpace2($resource) {
        var resourceUrl = 'manager/' + 'api/customer-storages/:path/:id';

        return $resource(resourceUrl, {}, {
            'getUsedSizeStoreId': {
                method: 'GET',
                params: {
                    path: 'getUsedSizeStoreId'
                },
            },
            'fixUsedSize': {
                method: 'GET',
                params: {
                    path: 'fixUsedSize'
                },
            },
        });
    }
    function StorageSpace3($resource) {
        var resourceUrl = 'manager/' + 'api/store-storage-snapshots/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true,
            },
        });
    }
})();