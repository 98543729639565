(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('store-payBatch2', {
                parent: 'store-detail2',
                url: '/pay-batch2?page&sort&search',
                data: {
                    authorities: ['ROLE_BATCHNUMBER'],
                    pageTitle: 'platformApp.payBatch.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pay-batch/pay-batches2.html',
                        controller: 'PayBatch2Controller',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payBatch');
                        $translatePartialLoader.addPart('payCode');
                        $translatePartialLoader.addPart('payCodeType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('store-payBatch', {
                parent: 'store-detail2',
                url: '/pay-batch?page&sort&search',
                data: {
                    authorities: ['ROLE_BATCHNUMBER'],
                    pageTitle: 'platformApp.payBatch.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pay-batch/pay-batches.html',
                        controller: 'PayBatchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payBatch');
                        $translatePartialLoader.addPart('payCode');
                        $translatePartialLoader.addPart('payCodeType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('store-payBatch.new', {
                parent: 'store-payBatch',
                url: '/new',
                data: {
                    authorities: ['ROLE_BATCHNUMBER'],
                    pageTitle: 'platformApp.payBatch.home.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-dialog.html',
                        controller: 'PayBatchDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    number: null,
                                    storeId: null,
                                    storeName: null,
                                    productId: null,
                                    productName: null,
                                    activated: null,
                                    supplier: null,
                                    periodOfValidity: null,
                                    used: null,
                                    cancelled: null,
                                    range: null,
                                    scalar: null,
                                    startNumber: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('store-payBatch', null, {
                            reload: 'store-payBatch'
                        });
                    }, function() {
                        $state.go('store-payBatch');
                    });
                }]
            })
            .state('store-payBatch2.new', {
                parent: 'store-payBatch2',
                url: '/new',
                data: {
                    authorities: ['ROLE_BATCHNUMBER'],
                    pageTitle: 'platformApp.payBatch.home.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-dialog.html',
                        controller: 'PayBatchDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    number: null,
                                    storeId: null,
                                    storeName: null,
                                    productId: null,
                                    productName: null,
                                    activated: null,
                                    supplier: null,
                                    periodOfValidity: null,
                                    used: null,
                                    cancelled: null,
                                    range: null,
                                    scalar: null,
                                    startNumber: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('store-payBatch2', null, {
                            reload: 'store-payBatch2'
                        });
                    }, function() {
                        $state.go('store-payBatch2');
                    });
                }]
            })
            .state('pay-batch', {
                parent: 'entity',
                url: '/pay-batch?page&sort&search',
                data: {
                    authorities: ['ROLE_BATCHNUMBER'],
                    pageTitle: 'platformApp.payBatch.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pay-batch/pay-batches.html',
                        controller: 'PayBatchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payBatch');
                        $translatePartialLoader.addPart('payCode');
                        $translatePartialLoader.addPart('payCodeType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
             
            .state('store-payBatch2.detail', {
                parent: 'store-payBatch2',
                url: '/{id}/detail2',
                data: {
                    authorities: ['ROLE_BATCHNUMBER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-detail.html',
                        controller: 'PayBatchDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('payBatch');
                                $translatePartialLoader.addPart('payCode');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('payCodeType');
                                return $translate.refresh();
                            }],
                            entity: ['PayBatch', function(PayBatch) {
                                return PayBatch.get({
                                    id: $stateParams.id
                                }).$promise;
                            }],
                        }
                    }).result.then(function() {
                        $state.go('store-payBatch2', null, {
                            reload: 'store-payBatch2'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })

            .state('store-payBatch.detail', {
                parent: 'store-payBatch',
                url: '/{id}/detail',
                data: {
                    authorities: ['ROLE_BATCHNUMBER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-detail.html',
                        controller: 'PayBatchDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('payBatch');
                                $translatePartialLoader.addPart('payCode');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('payCodeType');
                                return $translate.refresh();
                            }],
                            entity: ['PayBatch', function(PayBatch) {
                                return PayBatch.get({
                                    id: $stateParams.id
                                }).$promise;
                            }],
                        }
                    }).result.then(function() {
                        $state.go('store-payBatch', null, {
                            reload: 'store-payBatch'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })

            .state('pay-batch.new', {
                parent: 'pay-batch',
                url: '/new',
                data: {
                    authorities: ['ROLE_BATCHNUMBER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-dialog.html',
                        controller: 'PayBatchDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    number: null,
                                    storeId: null,
                                    storeName: null,
                                    productId: null,
                                    productName: null,
                                    activated: null,
                                    supplier: null,
                                    periodOfValidity: null,
                                    used: null,
                                    cancelled: null,
                                    range: null,
                                    scalar: null,
                                    startNumber: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('pay-batch', null, {
                            reload: 'pay-batch'
                        });
                    }, function() {
                        $state.go('pay-batch');
                    });
                }]
            })
            .state('store-payBatch.edit', {
                parent: 'store-payBatch',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_BATCHNUMBER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-dialog.html',
                        controller: 'PayBatchDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PayBatch', function(PayBatch) {
                                return PayBatch.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('store-payBatch', null, {
                            reload: 'store-payBatch'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('store-payBatch2.edit2', {
                parent: 'store-payBatch2',
                url: '/{id}/edit2',
                data: {
                    authorities: ['ROLE_BATCHNUMBER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-dialog2.html',
                        controller: 'PayBatchDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PayBatch', function(PayBatch) {
                                return PayBatch.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('store-payBatch2', null, {
                            reload: 'store-payBatch2'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('pay-batch3', {
                parent: 'entity',
                url: '/pay-batch3?page&sort&search',
                data: {
                    authorities: ['ROLE_BATCHNUMBER'],
                    pageTitle: 'platformApp.payBatch.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pay-batch/pay-batches3.html',
                        controller: 'PayBatch3Controller',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payBatch');
                        $translatePartialLoader.addPart('payCode');
                        $translatePartialLoader.addPart('payCodeType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pay-batch3.detail', {
                parent: 'pay-batch3',
                url: '/{id}/detail3',
                data: {
                    authorities: ['ROLE_BATCHNUMBER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-detail3.html',
                        controller: 'PayBatchDetail3Controller',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('payBatch');
                                $translatePartialLoader.addPart('payCode');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('payCodeType');
                                return $translate.refresh();
                            }],
                            entity: ['PayBatch', function(PayBatch) {
                                return PayBatch.get({
                                    id: $stateParams.id
                                }).$promise;
                            }],
                        }
                    }).result.then(function() {
                        $state.go('pay-batch3', null, {
                            reload: 'pay-batch3'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
