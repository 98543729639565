(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('StatisticalSales', StatisticalSales);

    StatisticalSales.$inject = ['$resource'];

    function StatisticalSales ($resource) {
        var resourceUrl =  'manager/' + 'api/statisticalSales/:path/:id';

        return $resource(resourceUrl, {}, {
            // ----------销售分析使用----end-------
            "yesterdayDate": {
                method: "GET",
                params: {
                    path: "yesterdayDate"
                }
            }
        });
    }
})();
