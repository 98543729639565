(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('BannerController', BannerController);

    BannerController.$inject = ['$scope', '$q', '$state', 'Banner', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', '$stateParams',
    'Store', '$http'];

    function BannerController ($scope, $q, $state, Banner, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius,  $stateParams,
        Store, $http) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.storeId = $stateParams.storeId;
        $scope.selectAll = false;

        vm.jump = jump
        vm.store = Store.getStore({id:vm.storeId});
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.store.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        $scope.delete = function(banner){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Banner.delete({id: banner.id},function () {
                    loadAll();
                });
            },function(){},true);
        }


        function loadAll () {
            var items = [{key:"storeId",op:"=",value: vm.store.id}];
            if (pagingParams.search) {
                items.push({key:"number",op:"=",value:pagingParams.search});
                Banner.byCondition2({
                    items: items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Banner.byCondition2({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.banners = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("轮播图获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                storeId:vm.storeId,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.banners,function (banner,i) {
                if($scope.selectAll){
                    banner.select = true;
                    $scope.batchArr.push(banner.id);
                    $scope.selectOne = true;
                }else{
                    $scope.batchArr = [];
                    banner.select = false;
                    $scope.selectOne = false;
                }
            })
        }

       $scope.chooseOne = function (banner) {
            banner.select = !banner.select;
            // 选中
            if(banner.select){
                $scope.batchArr.push(banner.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(banner.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.banners,function (banner,i) {
                if(!banner.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.banners.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("确认要删除选中的轮播页？",function(){
                Banner.deleteBatch({ids:$scope.batchArr},function(data){
                    MessageService.success("删除成功");
                    loadAll();
                },function(){
                MessageService.error("删除失败");
                })
            },function(){});
        }
    }
})();
