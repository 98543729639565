(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayBatchDetailController', PayBatchDetailController);

    PayBatchDetailController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'PayBatch', 'PayCode', 'MessageService', 'History',
        'ProductPurchase'];

    function PayBatchDetailController($scope, $q, $rootScope, $stateParams, $uibModalInstance, entity, PayBatch, PayCode, MessageService, History,
                                      ProductPurchase) {
        var vm = this;

        vm.payBatch = entity;

        vm.clear = clear;

        vm.payBatchId = $stateParams.id;
        vm.purchases = ProductPurchase.getAllByCondition2({items: [{key: "status", op: "=", value: "Completed"},{key: "storeId", op: "=", value: $stateParams.storeId}]})||[];
        if (vm.payBatchId !=null ) {
            vm.historys = History.getAllByCondition({items:[{key:"classId", op:"=", value: vm.payBatchId},{key:"className", op:'=', value:"PayBatch"}],sort:["createdDate,desc"]});
        }

        $q.all([vm.payBatch.$promise, vm.purchases.$promise]).then(function(){
            PayCode.payCodeDataByBatch({batch: vm.payBatch.number},function(data){
                vm.payCodeData = data;
            },function(){})

            vm.productPurchaseLines = [];
            console.log(vm.payBatch)
            for (var i = vm.purchases.length - 1; i >= 0; i--) {
                var p = vm.purchases[i];
                if (p.id == vm.payBatch.productPurchaseId) {
                    vm.payBatch.productPurchaseNumber = p.number;
                    vm.productPurchaseLines = angular.copy(p.productPurchaseLines)
                    formatting();
                    for (var i = vm.products.length - 1; i >= 0; i--) {
                        var p = vm.products[i]
                        if (p.productId == vm.payBatch.productId) {
                            for (var j = p.itemValueLines.length - 1; j >= 0; j--) {
                                var l = p.itemValueLines[j];
                                if (l.itemValueLineId == vm.payBatch.itemValueLineId) {
                                    vm.payBatch.itemValueLineName = l.sku;
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    break;
                }
            }
        })


        function formatting() {
            vm.products = []
            for(var i=0; i<vm.productPurchaseLines.length; i++){
                var _itemValueLineProductId = vm.productPurchaseLines[i].itemValueLineProductId;
                var _itemValueLineProductName = vm.productPurchaseLines[i].itemValueLineProductName;
                var _product = {
                    productId: _itemValueLineProductId,
                    productName: _itemValueLineProductName,
                    itemValueLines:  []
                }
                var _isResult = vm.products.some(function (item, index) {
                    if(item.productId==_itemValueLineProductId){
                        vm.products[index].itemValueLines.push(vm.productPurchaseLines[i])
                        return true
                    }
                })
                if(!_isResult){
                    _product.itemValueLines.push(vm.productPurchaseLines[i])
                    vm.products.push(_product)
                }
            }
            console.log(vm.products)
        }

        var unsubscribe = $rootScope.$on('platformApp:payBatchUpdate', function(event, result) {
            vm.payBatch = result;
        });

        $scope.submit = function (id) {
            MessageService.confirm("确认提交该信息", function () {
                PayBatch.submit({id: id}, function (data) {
                    vm.payBatch = data;
                });
            }, function () {
            });
        }


        $scope.approve = function (id) {
            MessageService.confirm("确认通过该请求", function () {
                PayBatch.approve({id: id}, function (data) {
                    vm.payBatch = data;
                });
            }, function () {
            });
        }


        $scope.reject = function (id) {
            MessageService.confirm("确认拒绝该请求", function () {
                PayBatch.reject({id: id}, function (data) {
                    vm.payBatch = data;
                });
            }, function () {
            });
        }




        function clear () {
            $uibModalInstance.close(vm.payBatch);
        }
        $scope.$on('$destroy', unsubscribe);
    }
})();
