(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreProductDetailController', StoreProductDetailController);

    StoreProductDetailController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', '$state', 'entity', 'Qinius', 'MessageService', '$uibModal'];

    function StoreProductDetailController($scope, $q, $rootScope, $stateParams, $state, entity, Qinius, MessageService, $uibModal) {
        var vm = this;

        vm.product = entity;
        $scope.colspanLen = 3;
        vm.clear = clear;
        vm.copyUrl = copyUrl;

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.product.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if (vm.product.objectProperties !=null) {
                vm.product.defaultObjectProperties = [];
                vm.product.expandedObjectProperties = [];
                if (vm.product.itemValues && vm.product.itemValues[0] && vm.product.itemValues[0].itemValueLines 
                && vm.product.itemValues[0].itemValueLines[0]){
                    vm.itemValueObjectValues = vm.product.itemValues[0].itemValueLines[0].itemValueObjectValues;
                    $scope.colspanLen = vm.itemValueObjectValues? vm.itemValueObjectValues.length +3 : 3;
                }
                for (var i = vm.product.objectProperties.length - 1; i >= 0; i--) {
                    var ob = vm.product.objectProperties[i];
                    if (ob.propertyType == "Default") {
                        vm.product.defaultObjectProperties.push(ob);
                    }else if (ob.propertyType == "Expanded") {
                        vm.product.expandedObjectProperties.push(ob);
                    }
                }
            }
        })

       $scope.updatePromotion = function (line) {
            createModal('app/entities/store-product/product-promotion-detail.html', 'StoreProductPromotionDetailController', line).result.then(
                function (data) {
                    line = data
                });
        }


        var createModal = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:item,
                }
            });
        };


        function copyUrl() {
            document.getElementById("productUrlSpan").focus();
            document.getElementById("productUrlSpan").select();
            var tag = document.execCommand("Copy"); //执行浏览器复制命令
            if (tag) {
                MessageService.success("复制成功");
            } else {
                MessageService.error("复制失败，请Ctrl+C复制。");
            };
        }

        function clear () {
            $state.go("store-product")
        }

        $scope.getItemValueStr = function(itemValue){
            var valueStr="";
            if (itemValue == null || itemValue.propertyValues == null) {
                return valueStr;
            };
            for (var i = 0; i< itemValue.propertyValues.length  ; i++) {
                var propertyValue = itemValue.propertyValues[i];
                valueStr = valueStr+" "+propertyValue.value;
            };
            return valueStr;
        }

        var unsubscribe = $rootScope.$on('platformApp:productUpdate', function(event, result) {
            vm.product = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".listDetail").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".listDetail").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStartDialog = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOnDialog, true);
            document.addEventListener("mouseup", moveImgEndDialog, true);
        }

        //move事件
        function moveImgOnDialog(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEndDialog() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOnDialog, true);
            document.removeEventListener("mouseup", moveImgEndDialog, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
    }
})();
