(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('FrameConfig', FrameConfig);

    FrameConfig.$inject = ['$resource'];

    function FrameConfig ($resource) {
        var resourceUrl =  'manager/' + 'api/frame-configs/:path/:id';
        return $resource(resourceUrl, {}, {
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete': {
                method: 'DELETE'
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            "approve":{
                method:"GET",
                params:{
                    path:"approve"
                }
            },
            "reject":{
                method:"GET",
                params:{
                    path:"reject"
                },
            },
            "receive":{
                method:"POST",
                params:{
                    path:"receive"
                },
            }
        });
    }
})();
