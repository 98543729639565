(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CommodityPriceDialogController', CommodityPriceDialogController);

    CommodityPriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product',
    'Store', '$state', 'MessageService', '$uibModal', 'Tariffs'];

    function CommodityPriceDialogController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product,
        Store, $state, MessageService, $uibModal, Tariffs) {
        var vm = this;
        vm.commodityPrice = entity;
        // vm.products = Product.getAllByCondition2({items:[{key: "prototype", op: "=", value: "Original"}]});
        vm.stores = [];
        vm.storesAll = {name: '全部', selected: false};
        vm.isStores = true;
        vm.save = save;
        vm.clear = clear;

        if(!entity.id){
            vm.commodityPrice = {
                name: '',
                available: false,
                stores: [],
                seq: '',
                commodityPrices: [],
                base: false,
                tariffType:'Product'
            }
        }

        Store.getAllByCondition({
            items: [{key: "status", op: "=", value: "Approved"}]
        }, function (res) {
            for(var i=0; i<res.length; i++){
                if(!res[i].cancelled){
                    vm.stores.push(res[i])
                }
            }
            if(entity.id){
                if(vm.commodityPrice.base){
                    vm.storesAll.selected = true;
                    vm.isStores = false;
                }else {
                    for(var i=0; i<vm.commodityPrice.stores.length; i++){
                        for(var j=0; j<vm.stores.length; j++){
                            if(vm.commodityPrice.stores[i].id === vm.stores[j].id){
                                vm.stores[j].selected = true;
                            }
                        }
                    }
                }
            }
        })

        //选择使用商家
        $scope.storesCheckbox = function(data){
            vm.commodityPrice.stores = [];
            data.selected = !data.selected ? true : false;
            for(var i=0; i<vm.stores.length; i++){
                if(vm.stores[i].selected){
                    vm.commodityPrice.stores.push(vm.stores[i])
                }
            }
            if(vm.commodityPrice.stores.length > 0){
                vm.commodityPrice.base = false;
            }
        };
        
        $scope.storesAllCheckbox = function(){
            vm.storesAll.selected = !vm.storesAll.selected ? true : false;
            if(!vm.storesAll.selected){
                vm.isStores = true;
                vm.commodityPrice.base = false;
            }else {
                for(var j=0; j<vm.stores.length; j++){
                    vm.stores[j].selected = false;
                }
                vm.isStores = false;
                vm.commodityPrice.stores = [];
                vm.commodityPrice.base = true;
            }
        };


        //新建商品
        $scope.addPriceLines = function () {
            $uibModal.open({
                templateUrl: 'app/entities/commodity-price/modal/commodity-add.html',
                controller: 'CommodityAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        console.log(vm.commodityPrice.commodityPrices)
                        return {
                            type: 'add',
                            data: vm.commodityPrice.commodityPrices
                        };
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    vm.commodityPrice.commodityPrices.push(backData)
                }
            });
        }
        //编辑商品
        // $scope.editPrice = function (data, index) {
        //     $uibModal.open({
        //         templateUrl: 'app/entities/commodity-price/modal/commodity-add.html',
        //         controller: 'CommodityAddController',
        //         controllerAs: 'vm',
        //         backdrop: 'static',
        //         resolve: {
        //             entity: [function() {
        //                 return {
        //                     type: 'edit',
        //                     data: data
        //                 };
        //             }]
        //         }
        //     }).result.then(function (backData) {
        //         if(backData){
        //             vm.commodityPrice.commodityPrices[index] = backData;
        //         }
        //     });
        // }
        //删除商品
        $scope.deletePricing = function(data,index){
            MessageService.confirm("确定删除当前商品信息吗？",function(){
                if(!data.id){
                    vm.commodityPrice.commodityPrices.splice(index, 1);
                }{
                    data.deleted = true;
                    data.cancelled = true;
                }
            },function(){},true);
        }
        //新增定价
        $scope.addPricing = function (data, index) {
            $uibModal.open({
                templateUrl: 'app/entities/commodity-price/modal/pricing.html',
                controller: 'PricingController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'add',
                            data: data
                        };
                    }]
                }
            }).result.then(function (item) {
                if(item){
                    var isSell = true;
                    for(var i=0; i<vm.commodityPrice.commodityPrices.length; i++){
                        var _product = vm.commodityPrice.commodityPrices[i];
                        if(!_product.cancelled){
                            if(_product.productId == item.productId && _product.hasOwnProperty("itemValueLines")){
                                for(var j=0; j<_product.itemValueLines.length; j++){
                                    for(var k=0; k<item.itemValueLines.length; k++){
                                        if(item.itemValueLines[k].id == _product.itemValueLines[j].id){
                                            isSell = false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if(isSell){
                        vm.commodityPrice.commodityPrices[index] = item;
                    }else {
                        MessageService.error("当前商品的可销售商品定价已经存在，不可重复添加！");
                    }
                }
            });
        }
        //编辑定价
        $scope.editPricing = function (data, index) {
            $uibModal.open({
                templateUrl: 'app/entities/commodity-price/modal/pricing.html',
                controller: 'PricingController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'edit',
                            data: data
                        };
                    }]
                }
            }).result.then(function (item) {
                if(item){
                    var isSell = true;
                    for(var i=0; i<vm.commodityPrice.commodityPrices.length; i++){
                        var _product = vm.commodityPrice.commodityPrices[i];
                        if(_product.productId == item.productId && _product.hasOwnProperty("itemValueLines") && i != index && !_product.cancelled){
                            for(var j=0; j<_product.itemValueLines.length; j++){
                                for(var k=0; k<item.itemValueLines.length; k++){
                                    if(item.itemValueLines[k].id == _product.itemValueLines[j].id){
                                        isSell = false;
                                    }
                                }
                            }
                        }
                    }
                    if(isSell){
                        vm.commodityPrice.commodityPrices[index] = item;
                    }else {
                        MessageService.error("当前商品的可销售商品定价已经存在，不可重复添加！");
                    }
                }
            });
        }
        function save () {
            if(!vm.commodityPrice.name){
                MessageService.error("商品名称不能为空！");
                return
            }
            if(!vm.commodityPrice.base){
                if(vm.commodityPrice.stores.length == 0){
                    MessageService.error("适用商家不能为空！");
                    return
                }
            }
            if(!vm.commodityPrice.seq){
                MessageService.error("优先级不能为空！");
                return
            }
            var _commodityPrices = [];
            for(var i=0; i<vm.commodityPrice.commodityPrices.length; i++){
                if(!vm.commodityPrice.commodityPrices[i].cancelled){
                    if(!vm.commodityPrice.commodityPrices[i].hasOwnProperty("itemValueLines")){
                        MessageService.error("商品信息定价不能为空！");
                        return false
                    }
                    _commodityPrices.push(vm.commodityPrice.commodityPrices[i])
                }
            }
            if(_commodityPrices.length == 0){
                MessageService.error("商品信息不能为空！");
                return
            }

            if(!vm.commodityPrice.id){
                Tariffs.save(vm.commodityPrice, function (res) {
                    $state.go("commodity-price");
                    MessageService.success("保存成功");
                })
            }else {
                Tariffs.update(vm.commodityPrice, function (res) {
                    $state.go("commodity-price");
                    MessageService.success("编辑成功");
                })
            }
        }
        function clear(){
            $state.go("commodity-price");
        }

        vm.priceRuleStatus = priceRuleStatus;
        function priceRuleStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "Standard":
                    return "标准定价";
                case "Ladder":
                    return "阶梯定价";
                case "Grads":
                    return "梯度定价";
                default:
                    return "";
            }
        }

    }
})();
