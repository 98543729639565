(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('SalesOrderDetailController', SalesOrderDetailController);

    SalesOrderDetailController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'SalesOrder', 'Qinius',
     'History', '$state', 'MessageService' ,'ExpressHistory', 'Convert','ProductXmls','$uibModal', 'PlatformWechatSetting', '$localStorage', 'Customer'];

    function SalesOrderDetailController($scope, $q, $rootScope, $stateParams, $uibModalInstance, entity, SalesOrder, Qinius,
     History, $state, MessageService, ExpressHistory, Convert, ProductXmls,$uibModal, PlatformWechatSetting, $localStorage, Customer) {
        var vm = this;

        vm.salesOrder = entity;
        vm.clear = clear;

        vm.orderId = $stateParams.id;
        if (vm.orderId !=null ) {
            vm.historys = History.getAllByCondition({items:[{key:"classId", op:"=", value: vm.orderId},{key:"className", op:'=', value:"SalesOrder"}],sort:["createdDate,asc"]});
            vm.recombinationRecord = History.getAllByCondition({items:[{key:"classId", op:"=", value: vm.orderId},{key:"className", op:'=', value:"ReJustify"}],sort:["createdDate,asc"]});
        }

        vm.histories = [];
        vm.histories = ExpressHistory.byCondition({items:[{key:"orderId",op:"=",value:$stateParams.id}]})
        // vm.histories = ExpressHistory.byOrderId({orderId:$stateParams.id})

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise,vm.histories.$promise, vm.historys.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('backApp:salesOrderUpdate', function(event, result) {
            vm.salesOrder = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.editorArticle = function(line){
            console.log(line)
            PlatformWechatSetting.get({}, function (res) {
                if(!res.editorNotifyUrl){
                    MessageService.error("请设置editorNotifyUrl");
                    return;
                }
                Customer.getToken({id: line.userId}, function (res1) {
                    if(res1.status == 200) {
                        if (line.orderArticle.editorType == "BOOK_JUNIOR" || line.orderArticle.editorType == "BOOK_SENIOR" || line.orderArticle.editorType == "NEW_EDITOR" || line.orderArticle.editorType == "CALENDAR_EDITOR") {
                            window.open(res.editorNotifyUrl + '/#/' + line.storeId + '/page-my-suffix-orderArticle/' + line.orderArticle.id + '?source=platform&authorization=' + res1.message)
                        } else if (line.orderArticle.editorType == "POSTER" || line.orderArticle.editorType == "SIMPLEST" || line.orderArticle.editorType == "DIY_EDITOR" || line.orderArticle.editorType == "FORM_EDITOR") {
                            window.open(res.editorNotifyUrl + '/#/' + line.storeId + '/' + line.orderArticle.id + '/orderArticleEditorAllEdit?source=platform&authorization=' + res1.message)
                        } else if (line.orderArticle.editorType == "PICTURE") {
                            window.open(res.editorNotifyUrl + '/#/' + line.storeId + '/' + line.orderArticle.id + '/orderArticlePhotoWebEdit?source=platform&authorization=' + res1.message)
                        }
                    }else{
                        MessageService.error(res1.message || "获取token失败，请稍后再试！");
                    }
                })
            })
        };
        $scope.goToView = function (line, orderArticle) {
            PlatformWechatSetting.get({}, function (res) {
                if(!res.editorNotifyUrl){
                    MessageService.error("请设置editorNotifyUrl");
                    return;
                }
                Customer.getToken({id: line.userId}, function (res1) {
                    if(res1.status == 200) {
                        window.open(res.editorNotifyUrl + '/#/' + line.storeId + '/webView2/' + orderArticle.id + '?source=external&authorization=' + res1.message);
                    }else{
                        MessageService.error(res1.message || "获取token失败，请稍后再试！");
                    }
                })
            })
            return;
            if(orderArticle.productType == 'StandardPrint' ||  orderArticle.productType == 'Standard'
            || vm.salesOrder.sourceType == 'SUBSTITUTION' ){
                MessageService.error("对不起，当前客户端不支持此类作品的操作");
                return;
            }

            ProductXmls.get({id:orderArticle.productXmlId}, function (productXml) {
                if(productXml.modelType && productXml.modelType === 'THREE'){
                    if(!productXml.modelPath){
                        MessageService.error("暂无3D模型");
                        return
                    }
                    Convert.xmlToDocument({
                        identifier: orderArticle.fileName,
                        provider: 'qiniu'
                    }, function (res) {
                        $uibModal.open({
                            templateUrl: "app/entities/sales-order/three-preview.html",
                            controller: "ThreePreviewController",
                            backdrop: 'static',
                            keyboard: true,
                            windowClass: "jh-modal-md",
                            controllerAs: 'vm',
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart('page');
                                    return $translate.refresh();
                                }],
                                threeTemplate:res,
                                threeProductXmlData:productXml,
                            }
                        });
                    });
                }else {
                    $(".listBGC").hide();
                    $(".modal-content").hide();
                    $state.go('product-view-article', {fileName: orderArticle.fileName, itemValueId:orderArticle.itemValueId}, {reload: true});
                }
            })
        };
        $scope.showHistory = function () {
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/sales-order-delivery-history.html',
                controller: 'SalesOrderDeliveryHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }],
                    entity: ['ExpressHistory', function(ExpressHistory) {
                        return ExpressHistory.byCondition({items:[{key:"orderId",op:"=",value:vm.salesOrder.id}]}).$promise;
                    }]
                }
            }).result.then(function() {

            }, function() {

            });
        }
        $scope.downFile = function(line){
            if (!line.filePath) {
                MessageService.error("作品文件不存在，下载失败");
                return;
            }
            window.open(line.filePath);
        }

        $scope.goToViewPic = function (orderArticle) {
            if(orderArticle.productType == 'StandardPrint' ||  orderArticle.productType == 'Standard'
            || vm.salesOrder.sourceType == 'SUBSTITUTION' ){
                MessageService.error("对不起，当前客户端不支持此类作品的操作");
                return;
            }

            $(".listBGC").hide();
            $(".modal-content").hide();
            $state.go('pic-view-article', {fileName: orderArticle.fileName, id:vm.orderId}, {reload: true});
        };

        $scope.previewWork = function (item) {
            if(!item.orderArticleId){
                MessageService.error("没有作品");
                return;
            }
            PlatformWechatSetting.get({}, function (res) {
                if(!res.editorNotifyUrl){
                    MessageService.error("请设置editorNotifyUrl");
                    return;
                }
                Customer.getToken({id: line.userId}, function (res1) {
                    if(res1.status == 200) {
                        window.open(res.editorNotifyUrl + '/#/' + vm.salesOrder.storeId + '/webView2/' + item.orderArticleId + '?source=external&authorization=' + res1.message);
                    }else{
                        MessageService.error(res1.message || "获取token失败，请稍后再试！");
                    }
                })
            })
        }
    }
})();
