
(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MusicDetailController', MusicDetailController);

    MusicDetailController.$inject = ['$q', '$timeout', '$scope', '$state', 'MessageService', 'entity', '$uibModalInstance', 'Music', 'UidService', 'Qinius', 'MultimediaGroup'];

    function MusicDetailController($q, $timeout, $scope, $state, MessageService, entity, $uibModalInstance, Music, UidService, Qinius, MultimediaGroup) {
        var vm = this;

        vm.music = entity;
        vm.isSaving = false;

        vm.clear = clear;
        vm.fileChange = fileChange;
        vm.save = save;

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.multimediaGroups = MultimediaGroup.allByCondition({items: []});

        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.multimediaGroups.$promise]).then(function () {
            vm.propertiesBk = angular.copy(vm.properties);
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';

            console.log(vm.music.groupId);
            vm.group = vm.music.groupId;

            var srcStr = $scope.domain + vm.music.identify;
            $('#audioDIV').html("<embed src='"+ srcStr +"' class='col-md-12 col-sm-12 col-xs-12'></embed>");
        }).then(function () {
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function fileChange() {
            // console.log(vm.file);
        }

        window.updateFile = function ($event) {

            $timeout(function () {
                var file = $event.target.files[0];
                if (vm.group == null || vm.group == undefined) {
                    MessageService.error("请选择音乐分组");
                    vm.file = null;
                    return;
                }
                if (file == null || file == undefined) {
                    vm.music.size = "";
                    vm.music.type = "";
                    return;
                }
                vm.music.size = (file.size / 1024).toFixed(2);
                vm.music.type = file.type;
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "music-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.music.identify = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        function save () {
            vm.isSaving = true;
            vm.music.groupId = vm.group;
            Music.save(vm.music, function (result) {
                $uibModalInstance.close(result);
                MessageService.success("操作完成");
            }, function (){
                vm.isSaving = false;
                MessageService.error("操作失败");
            });
        }
    }
})();
