(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('PackageVersion', PackageVersion);

    PackageVersion.$inject = ['$resource', 'DateUtils'];

    function PackageVersion($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/package-versions/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "updateType": {
                method: "GET",
                params: {
                    path: "updateType"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'batchCreate':{
                method: 'GET',
                params: {
                    path: 'batchCreate'
                }
            },
            'delete': {
                method: 'DELETE'
            },
        });
    }
})();
