(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store', {
            parent: 'entity',
            url: '/store/{status}?page&sort&search',
            data: {
                authorities: ['ROLE_PLATFROM_STORE'],
                pageTitle: 'global.menu.entities.merchantSetting'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/stores.html',
                    controller: 'StoreController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('store2', {
            parent: 'entity',
            url: '/store2?page&sort&search',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'global.menu.entities.agentManagement'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/stores2.html',
                    controller: 'StoreController2',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('store3', {
            parent: 'entity',
            url: '/store3/{leveId}',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'platformApp.store.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/stores2.html',
                    controller: 'StoreController2',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('store-detail', {
            parent: 'store',
            url: '/{id}',
            data: {
                authorities: ['ROLE_PLATFROM_STORE']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-detail.html',
                    controller: 'StoreDetailController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Store', '$stateParams',
                    function(Store, $stateParams) {
                        return Store.getStore({id : $stateParams.id}).$promise;
                    }
                ]
            }
        })
        .state('store-detail2', {
            parent: 'store2',
            url: '/{storeId}',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'global.menu.entities.merchantSetting'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-detail2.html',
                    controller: 'StoreDetailController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Store', '$stateParams',
                    function(Store, $stateParams) {
                        return Store.getStore({id : $stateParams.storeId}).$promise;
                    }
                ]
            }
        })

        .state('store-approve', {
            parent: 'store',
            url: '/{id}/approve',
            data: {
                authorities: ['ROLE_PLATFROM_STORE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/store/store-approve.html',
                    controller: 'StoreApproveController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Store', function(Store) {
                            return Store.getStore({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('store', null, { reload: 'store' });
                }, function() {
                    $state.go('store', null, { reload: 'store' });
                });
            }]
        })

        .state('store-edit', {
            parent: 'store',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_PLATFROM_STORE']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-dialog.html',
                    controller: 'StoreDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Store', '$stateParams',
                    function(Store, $stateParams) {
                        return Store.getStore({id : $stateParams.id}).$promise;
                    }
                ]
            }
        })

        .state('store-edit2', {
            parent: 'store-detail2',
            url: '/edit2/{storeId}',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-dialog2.html',
                    controller: 'StoreDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Store', '$stateParams',
                    function(Store, $stateParams) {
                        return Store.getStore({id : $stateParams.storeId}).$promise;
                    }
                ]
            }
        })

        .state('store.check', {
            parent: 'store',
            url: '/check/{id}',
            data: {
                authorities: ['ROLE_PLATFROM_STORE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/store/store-check.html',
                    controller: 'StoreCheckController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Store', function(Store) {
                            return Store.getStore({id : $stateParams.id}).$promise;

                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('store');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('storeType');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('store', {}, { reload: true });
                }, function() {
                    $state.go('store');
                });
            }]
        })
        .state('store.img', {
            parent: 'store.check',
            url: '/{url}',
            data: {
                authorities: ['ROLE_PLATFROM_STORE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/store/store-check-img.html',
                    controller: 'StoreCheckImgController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('store');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('storeType');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('store.check', {}, { reload: false });
                }, function() {
                    $state.go('store.check');
                });
            }]
        })
        //平台给商户
        .state('store.storeFreeSpace', {
            parent: 'store-detail',
            url: '/storeFreeSpace/{id}',
            data: {
                authorities: ['ROLE_PLATFROM_STORE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/store/store-free-space-dialog.html',
                    controller: 'StoreFreeSpaceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            return $translate.refresh();
                        }],
                        entity: ['StoreStorage', function(StoreStorage) {
                            return StoreStorage.byStoreId({storeId : $stateParams.id}).$promise;

                        }],
                    }
                }).result.then(function() {
                    $state.go('store-detail', {id:$stateParams.id}, {reload: true });
                }, function() {
                    $state.go('store-detail');
                });
            }]
        })
        //平台设置 商户下客户
        .state('store.freeSpace', {
            parent: 'store',
            url: '/freeSpace/{id}',
            data: {
                authorities: ['ROLE_PLATFROM_STORE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/store/free-space-dialog.html',
                    controller: 'FreeSpaceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('store');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('storeType');
                            return $translate.refresh();
                        }],
                        entity: ['Store', function(Store) {
                            return Store.getStore({id : $stateParams.id}).$promise;

                        }],
                    }
                }).result.then(function() {
                    $state.go('store', {}, { reload: true });
                }, function() {
                    $state.go('store');
                });
            }]
        });
    }

})();
