(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('giveNotice', {
            parent: 'entity',
            url: '/giveNotice?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'global.menu.entities.giveNotice'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/give-notice/give-notice.html',
                    controller: 'GiveNoticeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('giveNotice.new', {
                parent: 'giveNotice',
                url: '/new',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.giveNotice'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/give-notice/give-notice-dialog.html',
                        controller: 'GiveNoticeDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['VersionDescriptions', '$stateParams',
                        function(VersionDescriptions, $stateParams) {
                            return null;
                        }
                    ]
                }
            })
            .state('giveNotice.edit', {
                parent: 'giveNotice',
                url: '/{id}/edit',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.giveNotice'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/give-notice/give-notice-dialog.html',
                        controller: 'GiveNoticeDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['VersionDescriptions', '$stateParams',
                        function(VersionDescriptions, $stateParams) {
                            return VersionDescriptions.get({
                                id: $stateParams.id
                            }).$promise;
                        }
                    ]
                }
            });
    }

})();
