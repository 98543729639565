(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MouldDetailController', MouldDetailController);

    MouldDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Mould', 'Category', 'MouldProperty'];

    function MouldDetailController($scope, $rootScope, $stateParams, previousState, entity, Mould, Category, MouldProperty) {
        var vm = this;

        vm.mould = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('platformApp:mouldUpdate', function(event, result) {
            vm.mould = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
