(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreSmallAppSettingDetailController2', StoreSmallAppSettingDetailController2);

    StoreSmallAppSettingDetailController2.$inject = ['$scope', '$timeout', '$q' ,'$rootScope', '$stateParams', 'MessageService', 'entity', 'WechatAuth',
     'PlatformWechatSetting', 'Upload', 'UidService', 'Qinius', '$localStorage', 'Store'];

    function StoreSmallAppSettingDetailController2($scope, $timeout, $q, $rootScope, $stateParams, MessageService, entity,
        WechatAuth, PlatformWechatSetting, Upload, UidService, Qinius, $localStorage, Store) {
        var vm = this;
        vm.clear = clear;
        vm.btnNum = 1;
        vm.loading = true;
        vm.tokens = Qinius.get();
        vm.pre_auth_code = WechatAuth.pre_auth_code();
        vm.platformWechatSetting = PlatformWechatSetting.byPlatFrom();
        vm.storeSmallAppSetting = entity;
        vm.store = Store.getStore({id:$stateParams.storeId});



        $q.all([vm.platformWechatSetting.$promise, vm.storeSmallAppSetting.$promise, vm.tokens.$promise, vm.pre_auth_code.$promise, vm.store.s$promise]).then(function(){
            vm.token = vm.tokens.token;
            if (vm.pre_auth_code.status == "200") {
                vm.preAuthCode =  vm.pre_auth_code.message;
            }
            $scope.notifyUrl = encodeURIComponent(vm.platformWechatSetting.platformNotifyUrl + "/#/store2/"+$stateParams.storeId+"/platform2/auth_notify");
            vm.loading = false;
        })


        $scope.auth = function(){
            window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="+vm.platformWechatSetting.appId
                +"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+ $scope.notifyUrl;
        }

        $scope.authAgain = function(){
             window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="
            +vm.platformWechatSetting.appId+"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+$scope.notifyUrl;
        }

        getUrl();
        function getUrl () {
           vm.url = window.location.host;
        }

        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "store-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        console.log(data);
                        vm.file = "http://" + vm.url + "/fileserves/mp-verify/" + $stateParams.storeId + "/" + file.name;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.removeFile = function(file) {
            vm.file = null;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('platformApp:storeWechatSettingUpdate', function(event, result) {
            vm.storeSmallAppSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
