(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayOrderController', PayOrderController);

    PayOrderController.$inject = ['$scope', '$q', '$state', 'PayOrder', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 
    'MessageService', 'Store', 'DateUtils', '$http'];

    function PayOrderController ($scope, $q, $state, PayOrder, ParseLinks, AlertService, paginationConstants, pagingParams,
     MessageService, Store, DateUtils, $http) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        console.log(vm.reverse);
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.datePickerOpenStatus = {};
        vm.openCalendar =  openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.clear2 = function(){
            vm.datePickerOpenStatus.startTime = false;
            vm.datePickerOpenStatus.endTime = false;
            vm.endTime = null;
            vm.startTime = null;
            vm.amountEnd = null;
            vm.amountStart = null;
            vm.storeId = null;
        }

        $scope.down = function(){
            var seq = "";
            if (vm.endTime != null) {
                var endTime = DateUtils.convertLocalDateToServer(vm.endTime)
                seq += "&endTime="+endTime;
            }
            if (vm.startTime != null) {
                var startTime = DateUtils.convertLocalDateToServer(vm.startTime)
                seq += "&startTime="+ startTime;
            }
            if (vm.amountEnd != null) {
                seq += "&amountEnd="+vm.amountEnd;
            }
            if (vm.amountStart != null) {
                seq += "&amountStart="+vm.amountStart;
            }
            if (vm.storeId != null) {
                seq += "&storeId="+storeId;
            }
            seq = seq.substring(1);
            $http({
                url: 'manager/api/pay-orders/download?items=' + seq,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/vnd.ms-excel"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "充值订单" + DateUtils.convertLocalDateToServer(Date.now()) + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});

            // window.open("manager/api/pay-orders/download?"+seq);
        }

        $scope.search2 = function(){
            var items = [];
            if (vm.endTime != null) {
                items.push({"key":"createdDate", op:"<=", value:vm.endTime});
            }
            if (vm.startTime != null) {
                items.push({"key":"createdDate", op:">=", value:vm.startTime});
            }
            if (vm.amountEnd != null) {
                items.push({"key":"amount", op:"<=", value:vm.amountEnd});
            }
            if (vm.amountStart != null) {
                items.push({"key":"amount", op:">=", value:vm.amountStart});
            }
            if (vm.storeId != null) {
                items.push({"key":"storeId", op:"=", value:vm.storeId});
            }
            PayOrder.byCondition2({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
        }

        // vm.stores = Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]});

        // $q.all([vm.stores.$promise]).then(function(){ 
        //     $scope.getStoreName = function(order){
        //         if (vm.stores != null && vm.stores.length != 0 &&  order != null || order.storeId != null) {
        //             for (var i = vm.stores.length - 1; i >= 0; i--) {
        //                 var store = vm.stores[i];
        //                 if (store.id == order.storeId) {
        //                     return store.name;
        //                 }
        //             }
        //         }
        //     }
        // })
        
        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                PayOrder.byCondition2({
                    items: [{key:"id",op:"=",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                PayOrder.byCondition2({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            
        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.payOrders = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            MessageService.error("充值订单获取失败");
        }


        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
    } 
})();
