(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('secret-key', {
            parent: 'entity',
            url: '/secret-key?page&sort&search',
            data: {
                authorities: ["ROLE_PLATFORM_NEWSECRETKEY"],
                pageTitle: 'global.menu.entities.secretKey'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/secret-key/secret-key.html',
                    controller: 'SecretKeyController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('clipArt');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('secret-key.new', {
            parent: 'secret-key',
            url: '/new',
            data: {
                authorities: ["ROLE_PLATFORM_NEWSECRETKEY"],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/secret-key/secret-key-dialog.html',
                    controller: 'SecretKeyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {name: null, identifier: null, cancelled: null, provider: null, id: null,type:null};
                        }
                    }
                }).result.then(function() {
                    $state.go('secret-key', null, { reload: 'secret-key' });
                }, function() {
                    $state.go('secret-key');
                });
            }]
        })
        .state('secret-key.edit', {
            parent: 'secret-key',
            url: '/edit/{id}',
            data: {
                authorities: ["ROLE_PLATFORM_NEWSECRETKEY"],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/secret-key/secret-key-dialog.html',
                    controller: 'SecretKeyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('clipArt');
                            return $translate.refresh();
                        }],
                        entity: ['SecretKey', function(SecretKey) {
                            return SecretKey.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('secret-key', null, { reload: 'secret-key' });
                }, function() {
                    $state.go('secret-key');
                });
            }]
        });
    }

})();
