(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('statisticalStore', {
            parent: 'entity',
            url: '/statistical/store',
            data: {
                authorities: ["ROLE_PLATFROM_STATISTICS"],
                pageTitle: 'home.title'
            },
            views: {
                'content2@': {
                    templateUrl: 'app/entities/statistical/statistical-store.html',
                    controller: 'StatisticalStoreController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('statisticalSales', {
            parent: 'entity',
            url: '/statistical/sales',
            data: {
                authorities: ["ROLE_PLATFROM_STATISTICS"],
                pageTitle: 'global.menu.entities.statisticalPaycodes'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/statistical/statistical-sales.html',
                    controller: 'StatisticalSalesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    return $translate.refresh();
                }]
            }
        })
        .state('data-helper', {
        parent: 'entity',
        url: '/data-helper',
        data: {
            authorities: ["ROLE_PLATFROM_STATISTICS"],
            pageTitle: 'global.menu.entities.dataHelper'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/statistical/data-helper.html',
                controller: 'DataHelperController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                $translatePartialLoader.addPart('store');
                return $translate.refresh();
            }]
        }

        });
    }

})();
