(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreCheckImgController', StoreCheckImgController);

    StoreCheckImgController.$inject = ['$scope', '$q', '$stateParams', 'Qinius','$uibModalInstance'];

    function StoreCheckImgController($scope, $q, $stateParams, Qinius,$uibModalInstance) {
        var vm = this;

        vm.url = $stateParams.url
        vm.clear = clear;

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })


        function clear() {
            $(".store-form").show();
            $uibModalInstance.dismiss('cancel');
        }


        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            // $('body').css("overflow", "hidden");
            $(".origin-image").removeClass('hide1');
            $(".store-con").addClass('opacity-zero');
            $(".origin-image img").attr('src', pic);
        }

        $scope.backTo = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        };

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStart = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOn, true);
            document.addEventListener("mouseup", moveImgEnd, true);
        }

        //move事件
        function moveImgOn(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEnd() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOn, true);
            document.removeEventListener("mouseup", moveImgEnd, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
    }
})();
