(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FrameConfigController', FrameConfigController);

    FrameConfigController.$inject = ['$scope', '$q', '$state', 'FrameConfig', 'ParseLinks', 'paginationConstants', 'pagingParams',
     'MessageService', 'Qinius', '$uibModal', 'ThemeClassify'];

    function FrameConfigController ($scope, $q, $state, FrameConfig, ParseLinks, paginationConstants, pagingParams, 
        MessageService, Qinius, $uibModal, ThemeClassify) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.themeClassifyId = Number(pagingParams.themeClassifyId);
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.status = status;
        vm.class = ThemeClassify.getAllByCondition({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: 'BORDER'}]});
        // 获取qiniu服务器
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.class.$promise]).then(function(){
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        function loadAll () {
            var items = [{key:"storeId",op:"=",value:"null"}];
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search});
            }
            if (vm.themeClassifyId) {
                items.push({key:"themeClassify.id",op:"=",value:vm.themeClassifyId});
            }
            FrameConfig.byCondition({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.textXmls = res.message;
                    vm.page = pagingParams.page;
                }else {
                    MessageService.error(res.message);
                }
            }
            function onError(error) {
                MessageService.error("边框获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                themeClassifyId:vm.themeClassifyId
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $scope.approved = function(item){
            MessageService.confirm("确认要通过该边框", function(){
                FrameConfig.approve({id:item.id, status:"Approved", remarks:""},function(res){
                    if(res.status == 200){
                        MessageService.success("审核通过");
                        loadAll();
                    }else {
                        MessageService.error(res.message);
                    }
                },function(){
                    MessageService.error("通过失败");
                })
            })
        }
        $scope.rejected = function(item){
            MessageService.confirm("确认要拒绝该边框", function(){
                FrameConfig.reject({id:item.id, status:"Rejected", remarks:""},function(res){
                    if(res.status == 200){
                        MessageService.success("拒绝成功");
                        loadAll();
                    }else {
                        MessageService.error(res.message);
                    }
                },function(){
                    MessageService.error("拒绝失败");
                })
            })
        };
        $scope.look = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/frame-config/modal/frame-config-look.html',
                controller: 'FrameConfigLookController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            url: data.cover,
                            domain: $scope.domain
                        };
                    }]
                }
            });
        }
        $scope.delete = function(id){
            MessageService.confirm("确认删除该边框？",function(){
                FrameConfig.delete({id: id},function (res) {
                    if(res.status == 200){
                        MessageService.success("删除成功");
                        loadAll();
                    }else {
                        MessageService.error(res.message);
                    }
                });
            },function(){});
        };
        //Creating,  Created,  Approved,  Rejected,  Cancelled
        function status(item) {
            switch (item) {
                case 'Creating':
                    return '新建';
                    break;
                case 'Created':
                    return '已创建';
                    break;
                case 'Approved':
                    return '已通过';
                    break;
                case 'Rejected':
                    return '已拒绝';
                    break;
                case 'Cancelled':
                    return '已取消';
                    break;
                default:
                    return '暂无状态'
            }
        }
    }
})();
