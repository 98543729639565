(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreWechatMenuSettingDialogController', StoreWechatMenuSettingDialogController);

    StoreWechatMenuSettingDialogController.$inject = ['$scope', '$q','$stateParams', '$state','entity', 'Store','$localStorage','PlatformWechatSetting','StoreWechatSetting', 'MessageService'];

    function StoreWechatMenuSettingDialogController ($scope, $q,$stateParams, $state,entity,Store, $localStorage,PlatformWechatSetting,StoreWechatSetting, MessageService) {
        var vm = this;
        vm.data = entity;
        $scope.menuDisplay = false;
        vm.menu = {};
        vm.firstButtons = [];
        vm.setting = PlatformWechatSetting.byPlatFrom();
        vm.store = Store.getCurrent();
        vm.subMenuAdd = false;
        vm.operate = false;
        vm.previewShow = false;
        vm.editIfOrNot = '';
        vm.editFirstIfOrNot = '';
        var tempUrls =[];
        $scope.xx =undefined;
        vm.changeReverse = false;

        var flagOne = true;
        //验证一级菜单
        $scope.validateFirstName =function(name,$event){
            if(name == '' || name == undefined){
                angular.element($event.target).parents('.firstMenuName').find('.hint').text('菜单名称不能为空');
                flagOne = false;
            }else{
                var length=name.length;
                var characters=(name.match(/[^\x00-\xff]/g) ||"").length;
                var num=length-characters;
                var total=characters*2+num;
                if(total >8){
                    angular.element($event.target).parents('.firstMenuName').find('.hint').text('名称字数不能超过4个汉字或8个字母');
                    flagOne = false;
                }else{
                    angular.element($event.target).parents('.firstMenuName').find('.hint').text('');
                    flagOne = true;
                }
            }
        };
        //验证二级菜单
        var flag = true;
        $scope.validateName = function (name,index,$event){
            if(name == '' || name == undefined){
                angular.element($event.target).parents('.'+index).find('.hint').text('菜单名称不能为空');
                flag = false;
            }else {
                var length=name.length;
                var characters=(name.match(/[^\x00-\xff]/g) ||"").length;
                var num=length-characters;
                var total=characters*2+num;
                if(total >8){
                    angular.element('.hint').text('');
                    angular.element($event.target).parents('.'+index).find('.hint').text('名称字数不能超过4个汉字或8个字母');
                    flag = false;
                }else{
                    angular.element($event.target).parents('.'+index).find('.hint').text('');
                    flag = true;
                }
            }
        };
        var objFirst = {
            name:'',
            url:'',
            sub_button:[],
            type:'view',
            first:'level'
        };
        //点击一级菜单
        $scope.firstMenu =  function(data){
            angular.element('.hint').text('');
            vm.operate = true;
            $scope.selectedMenu = data;
            $scope.menuDisplay = true;
            if(data == null || data == ''){
                vm.firstButtons.push(angular.copy(objFirst));
                vm.buttonMenu = vm.firstButtons[vm.firstButtons.length-1];
                // console.log(vm.buttonMenu);
            }else{
                vm.buttonMenu = data;
                // console.log(vm.buttonMenu)
            }
            // console.log( vm.firstButtons)
        };
        var obj = {
            name:'',
            url:'',
            sub_button:[],
            type:'view',
            second:'second'
        };
        //开始添加二级菜单
        $scope.addSubmenu = function(data){
            data.sub_button.push(angular.copy(obj));
        };

        $q.all([vm.data.$promise, vm.setting.$promise, vm.store.$promise]).then(function(){
            if (!vm.data || vm.data.errmsg) {
                MessageService.error("微信菜单获取失败");
            }else{
                if (!vm.setting.storeNotifyUrl) {
                    MessageService.error("请联系平台运营方，设置公众号链接");
                }
                if (!vm.store.id) {
                    MessageService.error("获取商户信息失败");
                }
                vm.storeId = vm.store.id;
                vm.urls=[
                    {key:"主页", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/index",type:'1'},
                    {key:"个人中心", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/customer",type:'1'},
                    {key:"作品", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/article",type:'1'},
                    {key:"订单列表", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/sales-order/All",type:'1'},
                    {key:"商家优惠券", value: vm.setting.storeNotifyUrl + "/#/" + vm.storeId + "/coupon/newCoupon",type:'1'}];
                if (!vm.data.selfmenu_info || !vm.data.selfmenu_info.button) {
                    vm.menu.button = [];
                }else{
                    vm.menu.button = vm.data.selfmenu_info.button;
                }
                // console.log(vm.urls);
                angular.forEach(vm.urls,function(item,index){
                    tempUrls.push(item.value);
                });
                // console.log(tempUrls);
                loadAll();
            }
        });
        function loadAll(){
            StoreWechatSetting.getMenu({},function(data){
                if(data == '' || data == null){
                    vm.firstButtons = [];
                }else{
                    angular.forEach(data.selfmenu_info.button,function(item,index){
                        if(item.sub_button == null){
                            data.selfmenu_info.button[index].sub_button = [];
                        }else{
                            if(item.sub_button.list == undefined || item.sub_button.list == '' || item.sub_button.list == null){
                                data.selfmenu_info.button[index].sub_button = [];
                            }else{
                                data.selfmenu_info.button[index].sub_button=item.sub_button.list;
                            }
                        }
                    });
                    vm.firstButtons = data.selfmenu_info.button;
                    // console.log(vm.firstButtons);
                }
            });
        };

        $scope.saveMenu = function(){
            // console.log(vm.firstButtons);
            if(flagOne == true && flag== true){
                var arr= vm.firstButtons.filter(function(button,index){
                    return button.url == '';
                });
                if(arr.length>0){
                    MessageService.error("一级菜单地址不能为空，请编辑添加页面地址");
                }else{
                    vm.isSaving = true;
                    updateMenu(vm.firstButtons);
                }
            }else{
                MessageService.error("菜单名称不能为空,不能超过4个汉字或8个字母,请切换菜单修改");
            }
        };

        function updateMenu(buttons){
            StoreWechatSetting.createMenu({button:buttons},function(data){
                if (data.errcode == 0) {
                    $state.go("store-wechat-setting",{},{reload:true});
                    MessageService.success("保存成功");
                    vm.isSaving = false;
                }else{
                    MessageService.error("保存失败");
                    vm.isSaving = false;
                }
            },function(){
                MessageService.error("保存失败");
                vm.isSaving = false;
            });
        };

        //删除一级菜单
        $scope.delMenu = function(data){
            MessageService.confirm("确认要删除该微信菜单?", function(){
                var index= vm.firstButtons.indexOf(data);
                vm.firstButtons.splice(index,1);
                $scope.menuDisplay = false;
            }, function(){})
        };

        //删除二级菜单
        $scope.deleteMenu = function(data,index){
            MessageService.confirm("确认要删除该二级菜单?", function(){
                vm.buttonMenu.sub_button.splice(index,1);
                //当二级菜单全部删除  让一级菜单的url type恢复
                if(vm.buttonMenu.sub_button.length ==0){
                    vm.buttonMenu.type = 'view';
                    vm.buttonMenu.url = '';
                }
            }, function(){})
        };

        //编辑一级菜单
        $scope.editMenuFirst = function(data){
            vm.editFirstIfOrNot = data;
        };

        //编辑二级菜单
        $scope.editMenu =  function(data){
            // console.log(data);
            vm.editIfOrNot = data;
            if(tempUrls.indexOf(data.url) == -1){
                //   自定义菜单
                $scope.xx = $scope.linkStyles[1].Value_Data;
            }else{
                //    可供选择菜单
                $scope.xx = $scope.linkStyles[0].Value_Data;
            }
        };

        $scope.previewStage = function(){
            $state.go('show-preview');
            localStorage.setItem('flag',JSON.stringify(vm.firstButtons));
        };

        $scope.linkStyles =[
            {
                Value_Data:1,
                Value_Desc:'选择跳转页面'
            },
            {
                Value_Data:2,
                Value_Desc:'自定义链接'
            }
        ];

        // 交换数组元素
        var swapItems = function(arr, index1, index2) {
            arr[index1] = arr.splice(index2, 1, arr[index1])[0];
            return arr;
        };

        // 上移
        $scope.upRecord = function(arr, $index) {
            if($index == 0) {
                return;
            }
            swapItems(arr, $index, $index - 1);
        };

        // 下移
        $scope.downRecord = function(arr, $index) {
            if($index == arr.length -1) {
                return;
            }
            swapItems(arr, $index, $index + 1);
        };


        $scope.exchange = function(){
            vm.changeReverse = true;
        };
    }
})();
