(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('SalesOrderController', SalesOrderController);

    SalesOrderController.$inject = ['$scope', 'Principal',  '$state', 'SalesOrder', 'ParseLinks', 'paginationConstants', '$http', '$uibModal',
     'pagingParams', 'MessageService', '$stateParams', 'DateUtils', 'FileService2', '$timeout','Qinius','$q','Category', 'Product','Store'];

    function SalesOrderController ($scope, Principal, $state, SalesOrder, ParseLinks, paginationConstants, $http, $uibModal,
     pagingParams, MessageService, $stateParams, DateUtils, FileService2, $timeout,Qinius,$q,Category, Product,Store) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.deleted = pagingParams.deleted;
        vm.composition = pagingParams.composition;
        vm.noComposition = pagingParams.noComposition;
        vm.status = pagingParams.status;
        vm.startTime = pagingParams.startTime ? new Date(pagingParams.startTime):null;
        vm.endTime = pagingParams.endTime? new Date(pagingParams.endTime):null;
        vm.sourceType = pagingParams.sourceType;
        vm.prototype = pagingParams.prototype;
        vm.number = pagingParams.number;
        vm.phone = pagingParams.phone?Number(pagingParams.phone):null;
        vm.deliveryNumber = pagingParams.deliveryNumber;
        vm.code = pagingParams.code;
        vm.giftCode = pagingParams.giftCode;
        vm.transactionId = pagingParams.transactionId;
        vm.recipients = pagingParams.recipients;
        vm.recipientsPhone = pagingParams.recipientsPhone;
        vm.deliveryType = pagingParams.deliveryType;
        vm.deliveryCode = pagingParams.deliveryCode;


        vm.payRefundStatus = pagingParams.payRefundStatus;
        vm.storeId = pagingParams.storeId?Number(pagingParams.storeId):null;
        vm.statusData = pagingParams.statusData?Number(pagingParams.statusData):null;

        vm.jump = jump;
        vm.reJustify = reJustify;
        vm.reject = reject;
        vm.dateformat = dateformat;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.status = pagingParams.status;
        vm.time = pagingParams.time;

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        vm.isOpen =false;
        $scope.selectTab ='';
        vm.tempArr =[];
        $scope.selectOne = false;
        $scope.selectAll = false;
        vm.showpage = false;
        vm.domains = Qinius.getDomain();
        vm.multipartItems ={};
        if(vm.status == '' || vm.status == undefined ){
            $scope.selectTab = '';
        }else{
            $scope.selectTab = vm.status;
        }
        if (!!vm.deleted) {
            $scope.selectTab = 'Deleted';
        }
        if (!!vm.composition) {
            $scope.selectTab = 'CombinationError';
        }
        if(!!vm.payRefundStatus){
            $scope.selectTab = vm.payRefundStatus;
        }


        vm.statutsTabs = [
            { key:"", value:"全部订单", search:"" },
            { key:"Created", value:"待支付" },
            { key:"Submitted", value:"待提交"},
            { key:"Unchecked", value:"待核验"},
            { key:"CheckedError", value:"核验失败" },
            { key:"CheckIng", value:"待审核"},
            { key:"Paid", value:"待生产"},
            { key:"CombinationError", value:"组版失败"},
            { key:"OutFailed", value:"推生产失败"},
            { key:"Producing", value:"生产中"},
            { key:"ToBeDelivery", value:"待发货"},
            { key:"Receiving", value:"发货中"},
            { key:"ToTake", value:"待取件"},
            { key:"Required", value:"退款订单"},
            { key:"Approved", value:"退款完成"},
            { key:"Received", value:"已签收" },
            { key:"Completed", value:"交易完成" },
            { key:"Cancelled", value:"交易取消" },
            { key:"Deleted", value:"交易删除" }
            ]


        $scope.saveMessage = function(so){
            SalesOrder.customerServiceMessage({id:so.id, customerServiceMessage: so.customerServiceMessage}, function(data){
                MessageService.success("备注更新成功");
                vm.id = null;
                loadAll();
            } ,function(){
                MessageService.error("备注更新失败");
            })
        }

        $scope.jumpJustify = function(salesOrder){
            MessageService.confirm("确认订单进入快速组版队列？", function() {
                SalesOrder.jumpJustify({
                    id: salesOrder.id
                }, function(data) {
                     MessageService.success("执行快速组版成功");
                    loadAll();
                }, function() {
                    MessageService.error("执行快速组版失败");
                })
            }, function() {})
        }

        $scope.pushscm = function(salesOrder){
            if (salesOrder == null) {
                return;
            };
            MessageService.confirm("确认订单要推入供应链？", function() {
                SalesOrder.postForOrder({
                    id: salesOrder.id
                }, function(data) {
                    if (data.status ==200 && data.message) {
                        MessageService.success("执行推入成功");
                    }else{
                        MessageService.error("已加入生产队列，请稍后查看推入结果");
                    }
                    loadAll();
                }, function() {
                    MessageService.error("执行推入失败");
                })
            }, function() {})
        }

        // 商品品类
        vm.categorys = Category.getAllByCondition({items:[]});
        vm.stores = Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]});
        Product.getAllByCondition2({
            items: [{key:"prototype",op:"=",value:"Original"}]
        }, function(data1) {
            vm.products = data1;
        });

        //统计
        vm.statusDataList = SalesOrder.getStatusData({storeId:vm.storeId});

        vm.remark = remark;
        vm.id = null;


        function remark(so){
            if (so == null) {
                return;
            }
            vm.id = so.id;
        }


       $q.all([vm.categorys.$promise,vm.statusDataList.$promise ]).then(function(){
            loadAll();
       });

       $scope.delivery = function(data,id) {
            if (id == null) {
                return;
            };
           createModal('app/entities/sales-order/sales-order-delivery-dialog.html', 'SalesOrderDeliveryController', data).result.then(function () {
               loadAll();
           });
        }

        var createModal = function (url, controller, data) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            data: data
                        };
                    }]
                }
            });
        };

        vm.sourceTypes = [{
            name:"WECHAT",
            value:"公众号"
        },{
            name:"MINI",
            value:"微信小程序"
        },{
            name:"WEB",
            value:"网页端"
        }];

        vm.prototypes = [{
            name:"Store",
            value:"平台"
        },{
            name:"StoreSelf",
            value:"自运营"
        }];
        vm.deliveryTypes = [{
            name:"Delivery",
            value:"普通快递"
        },{
            name:"Delivery_Quick",
            value:"加急快递"
        },{
            name:"Self",
            value:"线下自提"
        }];

        $scope.chooseAll =function(){
            $scope.selectAll = !$scope.selectAll;
            angular.forEach(vm.salesOrders,function(item){
                item.select = $scope.selectAll;
            });
            vm.tempArr = vm.salesOrders.filter(function(item){
                return item.select == true;
            });
        };

        $scope.chooseOne = function(data){
            data.select = !data.select;
            $scope.selectAll = vm.salesOrders.every(function(item){
                return item.select;
            });
            vm.tempArr = vm.salesOrders.filter(function(item){
                return item.select == true;
            });

        };

        $scope.rejectBatch = function(){
            var ids = vm.tempArr.map(function(item){return item.id});
            createModal2('app/entities/sales-order/support-reject-dialog.html', 'SupportRejectOrderController').result.then(function (info) {
                SalesOrder.rejects({ids: ids, reason:info}, function(data) {
                    loadAll();
                    MessageService.success("订单驳回成功");
                },function(){
                    MessageService.error("订单驳回失败");
                })
           });
        };

        $scope.shrink = function(){
            vm.isOpen = !vm.isOpen;
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        var checkNum = 0;
        var timer = $timeout(function(){
            if (checkNum != 0) {
                checkNum = 0;
            }
        },3000);


        $scope.byStatus =function(status){
            if(status == '' || status == undefined){
                $scope.selectTab = '';
            }else {
                $scope.selectTab = status;
            }
            if (status == "CombinationError") {
                $scope.getComposition();
                return;
            }

            if (status == "Deleted") {
                $scope.getDeleted();
                return;
            }

            if (status == "Required") {
                $scope.getRefund();
                return;
            }
            if (status == "Approved") {
                $scope.getRefundApproved();
                return;
            }

            if(status === 'Paid'){
                vm.noComposition = 'noComposition';
            }else {
                vm.noComposition = '';
            }
            vm.deleted = "";
            vm.composition = '';
            vm.payRefundStatus = "";
            vm.status = status;
            search();
        };
        $scope.getDeleted =function(){
            $scope.selectTab = 'Deleted';
            vm.status = "";
            vm.composition = '';
            vm.noComposition = '';
            vm.payRefundStatus = "";
            vm.deleted = 'Deleted';
            search();
        };

        $scope.getComposition =function(){
            $scope.selectTab = 'CombinationError';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.payRefundStatus = "";
            vm.composition = 'CombinationError';
            search();
        };


        $scope.getRefund =function(){
            $scope.selectTab = 'Required';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.composition = '';
            vm.payRefundStatus = 'Required';
            search();
        };

        $scope.getRefundApproved = function(){
            $scope.selectTab = 'Approved';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.composition = '';
            vm.payRefundStatus = 'Approved';
            search();
        };

        $scope.screens = function(){
            search(vm.currentSearch);
        };

        $scope.changeStatusData = function(attr){
            vm.statusData = attr;
            $scope.selectTab = '';
            vm.status = null;
            vm.startTime = null;
            vm.endTime = null;
            vm.sourceType = null;
            vm.prototype = null;
            vm.recipients = null;
            vm.number = null;
            vm.phone = null;
            vm.deliveryNumber = null;
            vm.code = null;
            vm.giftCode = null;
            vm.transactionId = null;
            vm.recipientsPhone = null;
            vm.page = 1;
            vm.currentSearch = null;
            vm.searchQuery = null;
            vm.deleted = "";
            vm.composition = '';
            vm.payRefundStatus = "";
            vm.noComposition = '';
            loadAll()
        }

        $scope.resetInfo = function(){
            vm.startTime = null;
            vm.endTime = null;
            vm.statusData = null;
            // vm.storeId =null;
            vm.recipients = null;
            vm.sourceType = null;
            vm.prototype = null;
            vm.number = null;
            vm.phone = null;
            vm.deliveryNumber = null;
            vm.code = null;
            vm.giftCode = null;
            vm.transactionId = null;
            vm.recipientsPhone = null;
            vm.page = 1;
            vm.deliveryType = null;
            vm.deliveryCode = null;
            search();
        };

        $scope.checkOrderLineState = function(order){
            var flag = false;
            if (order.status != 'Paid') {
                return flag;
            }
            if (order.orderLines.length > 0) {
                for (var i = order.orderLines.length - 1; i >= 0; i--) {
                    var line = order.orderLines[i];
                    if (line != null && line.productType != 'StandardPrint'  &&
                        line.productType != 'Standard' && (line.status == 'CombinationError' || line.status == 'New' || order.status == "Paid" )){
                        return true;
                    }
                }
            }
            return flag;
        };

        function getItems() {
            var items=[];
            if (vm.endTime !=null) {
                items.push({key: "createdDate", op: "<=", value: new Date(Date.parse(vm.endTime) + 16 * 60 * 60 * 1000 - 1000)});
            };
            if (vm.startTime !=null) {
                items.push({key: "createdDate", op: ">=", value: new Date(Date.parse(vm.startTime) -8 * 60 * 60 * 1000)});
            };
            //商户名
            if (vm.storeId !=null) {
                items.push({key:"storeId",op:"=",value:vm.storeId});
            };
            //订单来源
            if (vm.sourceType !=null) {
                items.push({key:"sourceType",op:"=",value:vm.sourceType});
            };
            //平台/自运营
            if (vm.prototype !=null&&vm.prototype !="All") {
                items.push({key:"orderLines.orderArticle.prototype",op:"=",value:vm.prototype});
            };
            if (vm.status !=null) {
                if(vm.status == 'ToTake'){
                    items.push({key:"deliveryType",op:"=",value:'Self'});
                    items.push({key:"deliveryStatus",op:"!=",value:'Claimed'});
                }else{
                    items.push({key:"status",op:"=",value:vm.status});
                }
            }
            if (vm.deleted =='Deleted') {
                items.push({key:"cancelled",op:"=",value:"true"});
            }
            if (vm.composition =='CombinationError') {
               items.push({key: "orderLines.status", op: "=", value: "CombinationError"});
                items.push({key:"status",op:"=",value:"Paid"});
            }
            // if (vm.noComposition =='noComposition') {
            //     items.push({key: "orderLines.status", op: "!=", value: "CombinationError"});
            // }
            if (vm.payRefundStatus) {
                items.push({key:"payRefundStatus",op:"=",value:vm.payRefundStatus});
            }
            //客户电话
            if (vm.phone !=null) {
                items.push({key:"locations.customerPhone",op:"like",value:vm.phone});
            };
            //订单编号
            if (vm.number !=null) {
                items.push({key:"number",op:"like",value:vm.number});
            };
            //快递单号
            if (vm.deliveryNumber !=null) {
                items.push({key:"deliveryNumber",op:"like",value:vm.deliveryNumber});
            };
            //支付码礼物券
            if (vm.code !=null) {
                items.push({key:"orderLines.payCodes.code,productLines.payCodes.code",op:"=",value:vm.code});
            };
            //支付码礼物券
            if (vm.giftCode !=null) {
                items.push({key:"orderLines.giftVouchers.code,productLines.giftVouchers.code",op:"=",value:vm.giftCode});
            };
            //收件人
            if(vm.recipients){
                items.push({key:"locations.contact",op:"=",value:vm.recipients});
            }

            //流水单号
            if(vm.transactionId){
                items.push({key:"transactionId",op:"like",value:vm.transactionId});
            }
            //收件人电话
            if(vm.recipientsPhone){
                items.push({key:"locations.phone",op:"=",value:vm.recipientsPhone});
            }
            if (vm.status != 'ToTake' && vm.deliveryType !=null) {
                items.push({key:"deliveryType",op:"=",value:vm.deliveryType});
            };
            if (vm.deliveryCode !=null) {
                items.push({key:"deliveryCode",op:"=",value:vm.deliveryCode});
            };

            console.log(vm.prototype)
            return items;
        }

        function loadAll () {
            var items = getItems();
            if (vm.currentSearch) {
                items.push({key:"locations.contact,orderLines.orderArticle.name",op:"like",value:vm.currentSearch});
            }
            if (vm.statusData&&(!items.length||vm.storeId&&items.length==1)) {
                SalesOrder.byStatus({
                    status:vm.statusData,
                    storeId:vm.storeId,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage
                }, onSuccess, onError);
            }else if (vm.deleted == 'Deleted') {
                SalesOrder.byCondition2({
                    items:items,
                    available:false,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }else{
                SalesOrder.byCondition2({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return ["createdDate,desc"];
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.allPagesCount = Math.ceil(vm.totalItems/20);
            vm.queryCount = vm.totalItems;
            vm.salesOrders = data;
            vm.page = pagingParams.page;

            // for (var i=0;i<vm.salesOrders.length;i++){
            //     var order = vm.salesOrders[i];
            //     order.editShow = true;
            //     for (var j=0; j<order.orderLines.length;j++){
            //         var line = order.orderLines[j];
            //         if(line.orderArticle.prototype=='StoreSelf'){
            //             order.editShow = false;
            //             break
            //         }
            //     }
            // }
        }
        function onError(error) {
            MessageService.error("订单获取失败");
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status: vm.status,
                deleted: vm.deleted,
                startTime: vm.startTime ? DateUtils.convertLocalDateToServer(vm.startTime) : "",
                endTime: vm.endTime ? DateUtils.convertLocalDateToServer(vm.endTime) : "",
                sourceType: vm.sourceType,
                storeId: vm.storeId,
                composition: vm.composition,
                noComposition: vm.noComposition,
                prototype: vm.prototype,
                payRefundStatus: vm.payRefundStatus,
                number: vm.number,
                phone: vm.phone,
                deliveryNumber: vm.deliveryNumber,
                code: vm.code,
                giftCode: vm.giftCode,
                statusData: vm.statusData,
                recipients: vm.recipients,
                transactionId: vm.transactionId,
                recipientsPhone: vm.recipientsPhone,
                deliveryType: vm.deliveryType,
                deliveryCode: vm.deliveryCode
            });
        }

        function search(searchQuery) {
            // if (!searchQuery){
            //     return vm.clear();
            // }
            vm.statusData = null;
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.statusData = null;
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        function reJustify(obj) {
            SalesOrder.justify({id: obj.id}, function (data) {
                MessageService.success("订单文件组版中...");
                loadAll();
            }, function () {
                MessageService.error("订单文件组版失败");
            });
        }

        function reject(id) {
           createModal2('app/entities/sales-order/support-reject-dialog.html', 'SupportRejectOrderController').result.then(function (info) {
                SalesOrder.supportReject({id: id, reason:info}, function(data) {
                    MessageService.success("订单驳回成功");
                    loadAll();
                }, function () {
                    MessageService.error("订单驳回失败");
                });
           });
        }

        var createModal2 = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {

                    }]
                }
            });
        };

        $scope.approveRefund = function (salesorder) {
            MessageService.confirm("确认要通过该请求？", function(){
                SalesOrder.approveRefund({id: salesorder.id}, function(data) {
                    MessageService.success("退款成功");
                    loadAll();
                }, function () {
                    MessageService.error("退款失败");
                });
            })
        }

        $scope.rejectRefund = function (salesorder) {
            MessageService.confirm("确认要拒绝该请求？", function(){
                SalesOrder.rejectRefund({id: salesorder.id}, function(data) {
                    MessageService.success("拒绝退款成功");
                    loadAll();
                }, function () {
                    MessageService.error("拒绝退款成功");
                });
            })

        }

        $scope.checkOrderLinecombinationError = function(order){
            if (order.status != 'Paid') {
                return false;
            }
            if (order.orderLines.length > 0) {
                for (var i = order.orderLines.length - 1; i >= 0; i--) {
                    var line = order.orderLines[i];
                    if (line.status == 'CombinationError') {
                        return true;
                    }
                }
            }
            return false;
        }

        $scope.combinationError = function (salesOrder) {
            $http({
                url: 'justify/api/downloadErrorLog',
                method: "GET",
                params: {
                    orderNumber:salesOrder.number
                },
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function (data) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "组版失败日志_" +salesOrder.number+ ".zip";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function () {
                MessageService.error("下载失败，请稍后重试！");
            });
        }

        $scope.watchPayDetail = function (order) {
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/pay-detail.html',
                controller: 'PayDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                // size: 'lg',
                resolve: {
                    entity: [function() {
                        return SalesOrder.get({id:order.id}).$promise;
                    }]
                }
            }).result.then(function(item) {

            });
        }
        //确认核销
        $scope.deliveryCodeCheck = function (salesOrder) {
            SalesOrder.deliveryCodeCheck({id: salesOrder.id}, function (res) {
                MessageService.success("确认核销成功");
                loadAll()
            }, function (error) {
                MessageService.error("确认核销失败");
            });
        }
        //订单手动核验
        $scope.doCheck = function (salesOrder) {
            SalesOrder.doCheck({id: salesOrder.id}, function (res) {
                MessageService.info(res.message);
                loadAll();
            }, function (error) {
                MessageService.error("手动失败");
            });
        }

        // 重组并推送
        $scope.recombinationAndPush = function (data,id){
            if (id == null) {
                return;
            };
            createModal('app/entities/sales-order/sales-order-recombination-dialog.html', 'SalesOrderRecombinationController', data).result.then(function () {
                loadAll();
            });
        }
    }
})();
