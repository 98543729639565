(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformSmallAppSettingDetail3Controller', PlatformSmallAppSettingDetail3Controller);

    PlatformSmallAppSettingDetail3Controller.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState',
    'entity', 'PlatformWechatSetting', 'WechatAuth2', 'MessageService', "Store", 'Upload'];

    function PlatformSmallAppSettingDetail3Controller($scope, $rootScope, $stateParams, previousState,
        entity, PlatformWechatSetting, WechatAuth2, MessageService, Store, Upload) {
        var vm = this;
 
        $scope.storeShow = false;
        $scope.versionShow = false;

        vm.templates = entity.template_list;
        vm.stores = Store.findByHasSmallAll();
        $scope.selectStore = function(store){
            if (store == null) {
                return;
            }
            if (store.id == vm.storeId) {
                vm.storeId = null;
            }else{
                vm.storeId = store.id;
            }
        }

        window.updateFile = function ($event) {
            vm.file = $event.target.files[0];
            $(".choose-file").val(vm.file.name);
        };

        $scope.selectVersion = function(temp){
            if (temp == null) {
                return;
            }
            if (temp.template_id == vm.template_id) {
                vm.template_id = null;
                vm.user_version= null;
                vm.user_desc = null;
            }else{
                vm.template_id = temp.template_id;
                vm.user_version= temp.user_version;
                vm.user_desc = temp.user_desc;
            }
        }

        $scope.submit = function() {
            if (vm.storeId == null || vm.template_id == null) {
                MessageService.error("请选择想要提交小程序的商铺或者版本");
                return;
            }
            MessageService.confirm("确认要提交该版本代码？", function() {
                Upload.upload({
                    type: 'POST',
                    url: 'manager/wx/small-app/commit', //上传路径
                    data: {
                        storeId: vm.storeId,
                        template_id: vm.template_id,
                        user_version: vm.user_version,
                        user_desc: vm.user_desc,
                        extFile: vm.file,
                        type: vm.type
                    }
                }, function(res){
                    if (res.status && res.errcode == "0") {
                        MessageService.success("提交成功， 小程序进入审核阶段");
                    } else {
                        MessageService.error("提交失败，错误编号：" + data.errcode);
                    }
                }, function(){
                    MessageService.error("提交失败");
                });
            },function(){
            })
        }

        $scope.recall = function() {
            if (vm.storeId == null) {
                MessageService.error("请选择想要提交小程序的商铺");
                return;
            }
            MessageService.confirm("确认要撤回正在审核的掉程序么？", function() {
                WechatAuth2.undocodeaudit({
                    storeId: vm.storeId
                }, function(data) {
                    if (data.errcode == "0") {
                        MessageService.success("撤回成功");
                    }else if (data.errcode == "-1") {
                        MessageService.error("系统错误");
                    }else if (data.errcode == "87013") {
                        MessageService.error("撤回次数达到上限（每天一次，每个月 10 次）");
                    }else  {
                        MessageService.error(data.errcode+":"+data.errmsg);
                    }
                }, function() {

                })
            }, function() {

            })
        }

        $scope.search = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要查询小程序审核状态的商铺");
                return;
            }
            WechatAuth2.get_latest_auditstatus({storeId:vm.storeId},function(data){
                if(data.errcode == "0"){
                    if (data.status == "0") {
                        MessageService.success("审核成功");
                    }
                    if (data.status == "1") {
                        MessageService.error("审核失败:"+ data.reason);
                    }
                    if (data.status == "2") {
                        MessageService.info("审核中");
                    }
                }else{
                    MessageService.error(data.errcode+":"+data.errmsg);
                }
            },function(){});
        }

        $scope.release = function(){
            if (vm.storeId == null  ) {
                MessageService.error("请选择想要发布小程序的商铺");
                return;
            }
            WechatAuth2.release({storeId:vm.storeId},function(data){
                if(data.errcode == "0"){
                    MessageService.success("发布成功");
                }
                if (data.status == "0") {
                    MessageService.success("发布成功");
                }
                if (data.status == "-1") {
                    MessageService.error("系统繁忙");
                }
                if (data.status == "85019") {
                    MessageService.error("没有审核版本");
                }
                if (data.status == "85019") {
                    MessageService.error("没有审核版本");
                }
            },function(){});
        }


        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('platformApp:platformWechatSettingUpdate', function(event, result) {
            vm.platformWechatSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.appDomains = null;
        $scope.getSmallAppDomain = function () {
            if(vm.storeId==null){
                MessageService.error("请选择想要查询的商铺");
                return
            }
            vm.appDomains = WechatAuth2.getDomain({storeId:vm.storeId});
        }
    }
})();
