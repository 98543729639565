(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductOrderDialogController', ProductOrderDialogController);

    ProductOrderDialogController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$q', 'entity', 'ProductPurchase',
    'Product', 'MessageService', 'Store', 'DateUtils', 'CommodityPrice', 'Category', 'Qinius'];

    function ProductOrderDialogController ($state, $timeout, $scope, $stateParams, $q, entity, ProductPurchase,
        Product, MessageService, Store, DateUtils, CommodityPrice, Category, Qinius) {
        var vm = this;

        console.log($stateParams)
        vm.classifyList = [{name: '全部', id: ''}];
        vm.products = [];
        vm.itemValueLines = [];
        vm.search = search;
        vm.item = [];
        vm.domains = Qinius.getDomain();
        vm.item = $stateParams.itemValues || [];

        $q.all([vm.domains.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            //获取分类
            Category.getAllByCondition({}, function (res) {
                for(var i=0; i<res.length; i++){
                    if(!res[i].cancelled){
                        vm.classifyList.push(res[i]);
                    }
                }
                productInit(vm.classifyList[0])
            })
        });


        $scope.classifyCb = function (data) {
            productInit(data)
        }

        //获取商品
        function productInit(data){
            vm.classify = data;
            vm.products = [];
            var items = [{key: "prototype", op: "=", value: 'Original'},
                {key: "saleable", op: "=", value: "true"},
                {key: "custom", op: "=", value: "false"}];
            if(data.id){
                items.push({key:"category.id",op:"=",value: data.id})
            }
            if(vm.keyword){
                items.push({key:"name",op:"like",value: vm.keyword})
            }
            Product.getAllByCondition2({
                items:items
            },function(data){
                for(var i=0; i<data.length; i++){
                    if(!data[i].cancelled){
                        for(var j=0; j<vm.item.length; j++){
                            if(vm.item[j].id == data[i].id){
                                data[i].selected = true
                            }
                        }
                        vm.products.push(data[i])
                    }
                }
                var nums = 20; //每页出现的数量
                var pages = Math.ceil(vm.products.length/nums); //得到总页数
                var thisDate = function(curr){
                    //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                    var str = [], last = curr*nums - 1;
                    last = last >= vm.products.length ? (vm.products.length-1) : last;
                    for(var i = (curr*nums - nums); i <= last; i++){
                        str.push(vm.products[i]);
                    }
                    return str;
                };

                //调用分页
                laypage({
                    cont: 'c-paging',
                    curr: vm.page, //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    jump: function(obj){
                        $timeout(function () {
                            vm.products1=thisDate(obj.curr);
                            vm.page = obj.curr;
                        })
                    }
                });
            })
        }
        //获取选中商品
        $scope.itemValueLinesCb = function (data) {
            data.selected = !data.selected ? true : false;
            if(!data.selected){
                for(var i=0; i<vm.item.length; i++){
                    if(vm.item[i].id == data.id){
                        vm.item.splice(i, 1);
                    }
                }
            }else {
                vm.item.push(data)
            }
        }

        function search() {
            productInit(vm.classify)
        }
        //商品订购
        $scope.goodsOrder = function () {
            if(vm.item.length == 0){
                MessageService.error("请选择订购的商品");
                return
            }
            var _data = {
                itemValue: vm.item,
                purchaseType: 'COMMODITYPURCHASE'
            }
            var _itemValue = JSON.stringify(_data);
            localStorage.setItem("platformItemValue",_itemValue);
            $state.go('product-order-buy');
        }
        //礼包订购
        $scope.goodsOrder1 = function () {
            if(vm.item.length == 0){
                MessageService.error("请选择订购的商品");
                return
            }
            var _data = {
                itemValue: vm.item,
                purchaseType: 'GIFTPURCHASE'
            }
            var _itemValue = JSON.stringify(_data);
            localStorage.setItem("itemValue",_itemValue);
            $state.go('product-order-buy1');
        }
    }
})();
