(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('AfterSaleProblemEditController', AfterSaleProblemEditController);

    AfterSaleProblemEditController.$inject = ['$q', '$scope', '$stateParams', 'entity', 'AfterSaleProblem', '$state','MessageService'];

    function AfterSaleProblemEditController($q, $scope, $stateParams, entity, AfterSaleProblem, $state,MessageService) {
        var vm = this;
        vm.afterSaleProblem = entity;

        vm.clear = clear;
        vm.save = save;
        vm.addRows = addRows;
        vm.delete0 = delete0;
        vm.edit0 = edit0;

        vm.afterProblemList = AfterSaleProblem.getSubordinate({id: entity.id});
        $q.all([vm.afterSaleProblem.$promise, vm.afterProblemList.$promise]).then(function(){
            console.log(vm.afterProblemList);
        })

        function save () {

            if (vm.afterSaleProblem.sort < 1) {
                MessageService.error('序号不得小于1');
                return;
            }
            for (var i = vm.afterProblemList.length - 1; i >= 0; i--) {
                var l = vm.afterProblemList[i];
                if (!l.sort) {
                    MessageService.error('序号不能为零或者空');
                    return;
                }
                if (!l.content) {
                    MessageService.error('内容不能为者空');
                    return;
                }
            }
            // vm.isSaving = true;
            AfterSaleProblem.update({
                afterSaleProblem: vm.afterSaleProblem,
                list: vm.afterProblemList
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:afterSaleProblemUpdate', result);
            $state.go('after-sale-problem', {}, { reload: true });
            vm.isSaving = false;
            MessageService.success('操作成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error('操作失败');
        }

        function clear () {
            $state.go('after-sale-problem', {}, { reload: true });
        };

        function addRows() {
            var af = {
                'cancelled': false,
                'content': null,
                'createdBy': null,
                'createdDate': null,
                'enabled': true,
                'id': null,
                'lastModifiedBy': null,
                'lastModifiedDate': null,
                'sort': null,
                added:true,
                'superiorId': vm.afterSaleProblem.id
            }

            vm.afterProblemList.push(af);
        }


        function delete0 (afterProblem) {
            if (!afterProblem.added) {
                afterProblem.deleted = true;
            } else {
                var ind = vm.afterProblemList.indexOf(afterProblem);
                if (ind >= 0) {
                    vm.afterProblemList.splice(ind, 1);
                }
            }
        }

        function edit0(index) {
            $("#in0_"+index).attr("readOnly",false);
            $("#in1_"+index).attr("readOnly",false);
        }
    }
})();
