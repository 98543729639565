(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PriceListDetailController', PriceListDetailController);

    PriceListDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PriceList', '$state', '$uibModal'];

    function PriceListDetailController($scope, $rootScope, $stateParams, previousState, entity, PriceList, $state, $uibModal) {
        var vm = this;

        vm.priceList = entity;
        vm.previousState = previousState.name;
        vm.clear = clear;
        vm.storeId = $stateParams.storeId;
        if (!vm.storeId) {
            return;
        }

        $scope.viewCondition = function (line) {
            createModal('app/entities/price-list/price-list-detail-condition.html', 'PriceListDetailConditionController', line).result.then(
                function (data) {
                    for (var i = vm.priceList.storePriceLines.length - 1; i >= 0; i--) {
                        var priceLine = vm.priceList.storePriceLines[i];
                        if (data.uuid == priceLine.uuid) {
                            priceLine = data;
                            break;
                        }
                    }
                });
        }


        var createModal = function (url, controller, line) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:line,
                }
            });
        };



        function clear(){
            $state.go("price-list", {storeId:vm.storeId})
        }

        var unsubscribe = $rootScope.$on('platformApp:priceListUpdate', function(event, result) {
            vm.priceList = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
