(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreProductDialogController', StoreProductDialogController);

    StoreProductDialogController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', 'Product',  'ListService', 'UidService',
        'MessageService', 'Qinius', 'Property','$uibModal', 'RegionTemp', '$stateParams'
    ];

    function StoreProductDialogController($timeout, $scope, $state, $q, entity, Product,  ListService, UidService,
        MessageService, Qinius, Property,$uibModal, RegionTemp, $stateParams
                                     ) {
        var vm = this;
        localStorage.setItem('Production', 'edit');
        $scope.status = localStorage.getItem('Production');
        vm.product = entity;
        vm.clear = clear;
        vm.save = save;
        $scope.colspanLen = 3;
        vm.pTicketCheck = pTicketCheck;
        vm.saleableCheck = saleableCheck;
        vm.storeId = $stateParams.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.tokens = Qinius.get();
        vm.regionTemps = RegionTemp.getAllByCondition({items:[{key:"storeId", value:vm.storeId, op:"="}]});
        vm.domains = Qinius.getDomain();

        $scope.amountChange = function (itemValue, amount) {
            if (itemValue == null || itemValue[amount] == null) {
                return;
            }
            if (!(/^-?\d+\.?\d{0,2}$/.test(itemValue[amount]))) {
                MessageService.error("金额只能输入小数点后两位数");
                itemValue[amount] = Math.round(itemValue[amount] * 100) / 100;
                return;
            }
        };

        $scope.updatePromotion = function (item) {
            createModal('app/entities/store-product/product-promotion-dialog.html', 'StoreProductPromotionDialogController', item).result.then(
                function (data) {
                    item = data
                });
        }

        $scope.itemvalueDefault =  function(itemValue){
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var value = vm.product.itemValues[i];
                if (value.id == itemValue.id) {
                    continue;
                }
                value.default = false;
            }
        }


        $scope.itemvalueLineDefault =  function(itemValue, line){
            for (var i = itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var valueLine = itemValue.itemValueLines[i];
                if (line.id == valueLine.id) {
                    continue;
                }
                valueLine.default = false;
            }
        }

        var createModal = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:item,
                }
            });
        };


        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.product.$promise, vm.regionTemps.$promise]).then(function () {
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if (vm.product.objectProperties !=null) {
                vm.product.defaultObjectProperties = [];
                vm.product.expandedObjectProperties = [];
                if (vm.product.itemValues && vm.product.itemValues[0] && vm.product.itemValues[0].itemValueLines
                && vm.product.itemValues[0].itemValueLines[0]){
                    vm.itemValueObjectValues = vm.product.itemValues[0].itemValueLines[0].itemValueObjectValues;
                    $scope.colspanLen = vm.itemValueObjectValues? vm.itemValueObjectValues.length +3 : 3;
                }
                for (var i = vm.product.objectProperties.length - 1; i >= 0; i--) {
                    var ob = vm.product.objectProperties[i];
                    if (ob.propertyType == "Default") {
                        vm.product.defaultObjectProperties.push(ob);
                    }else if (ob.propertyType == "Expanded") {
                        vm.product.expandedObjectProperties.push(ob);
                    }
                }
            }
        }).then(function () {
        });

        function saleableCheck(saleable) {
            vm.product.saleable = saleable;
        }

        function pTicketCheck(b){
            vm.product.pTicket = b;
        }

        $scope.removeFile = function (field) {
            if (field == 'coverImg') {
                vm.product.coverImg = null;
            } else if (field == 'detailImg') {
                vm.product.detailImg = null;
            }
        };

        $scope.removeFile2 = function (itemValue) {
            itemValue.coverImg = null;
        };

        //标准印品文件移除
        $scope.removeFile3 = function (line) {
            line.fileIdentifier = null;
        };

        window.xmlLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id;
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.coverImg = $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }

                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.uploadFile = function (fileWatched, uuid) {
            $timeout(function () {
                var file = fileWatched;
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //产品 coverImg更改
        window.updateImgFile1 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.coverImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        window.xmlLineProductFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id;
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.fileIdentifier = data.key;
                                        break;
                                    }
                                }
                            }

                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //产品 detailImg更改
        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.detailImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };



        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go("store-product");
        }

        $scope.validate = function () {
            if (vm.product.name == null || vm.product.name == "") {
                MessageService.error("请输入名称");
                return false;
            }


            if (vm.product.coverImg == null || vm.product.coverImg == "") {
                MessageService.error("请上传商品图片");
                return false;
            }
            if (vm.product.detailImg == null || vm.product.detailImg == "") {
                MessageService.error("请上完善商品详情");
                return false;
            }


            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var item = vm.product.itemValues[i];
                if (item.cancelled || item.deleted) {
                    continue;
                }
                if (item.price == null || item.price === "" || item.price > 1000000 || item.price < 0) {
                    MessageService.error("请输入有效价格");
                    return false;
                }

                if (vm.product.productType == "Complex" && item.coverImg == null || item.coverImg == "") {
                    MessageService.error("组合中，请选择详情图");
                    return false;
                }
            }
            return true;
        };


        function save() {
            if (!$scope.validate()) {
                return;
            }
            if (vm.product.productType == "Simple") {
                vm.product.objectProperties = [];
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var item = vm.product.itemValues[i];
                    item.objectvalues = [];
                }
            }
            vm.isSaving = true;
            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:productUpdate', result);
            MessageService.success("保存成功");
            vm.isSaving = false;
            $state.go("store-product");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".dialog-form").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".dialog-form").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStartDialog = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOnDialog, true);
            document.addEventListener("mouseup", moveImgEndDialog, true);
        }

        //move事件
        function moveImgOnDialog(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEndDialog() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOnDialog, true);
            document.removeEventListener("mouseup", moveImgEndDialog, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
    }
})();
