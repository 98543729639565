(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('price-list', {
            parent: 'store-detail2',
            url: '/price-list?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'platformApp.priceList.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/price-list/price-lists.html',
                    controller: 'PriceListController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('priceList');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('price-list-detail', {
            parent: 'price-list',
            url: '/price-list/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'platformApp.priceList.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/price-list/price-list-detail.html',
                    controller: 'PriceListDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('priceList');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PriceList', function($stateParams, PriceList) {
                    return PriceList.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'price-list',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         
        .state('price-list.new', {
                parent: 'price-list',
                url: '/new',
                data: {
                    authorities: [],
                    pageTitle: 'platformApp.priceList.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/price-list/price-list-dialog.html',
                        controller: 'PriceListDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('priceList');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            cancelled: null,
                            seq: null,
                            id: null,
                            priceLines: [],
                        };
                    }
                }

            })

        .state('price-list.edit', {
            parent: 'price-list',
            url: '/{id}/edit',
            data: {
                authorities: [],
                pageTitle: 'platformApp.priceList.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/price-list/price-list-dialog.html',
                    controller: 'PriceListDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('priceList');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PriceList', function($stateParams, PriceList) {
                    return PriceList.get({id : $stateParams.id}).$promise;
                }]
            }
        });
    }

})();
