(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('initController', initController);

    initController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$timeout', 'Auth', '$rootScope', '$sessionStorage'];

    function initController($scope, Principal, LoginService, $state, $timeout, Auth, $rootScope, $sessionStorage) {
        var vm = this; 
        $scope.settings = {};

        $scope.getSettings = function(){
            
        }
        

        if($sessionStorage.settings ==null){
            $scope.getSettings(); 
        }else{
            $scope.settings = $sessionStorage.settings;
        }


 
        
         
        
    }
})();
