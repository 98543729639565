(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PropertyDetailController', PropertyDetailController);

    PropertyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Property', '$timeout'];

    function PropertyDetailController($scope, $rootScope, $stateParams, $uibModalInstance, entity, Property, $timeout) {
        var vm = this;

        vm.property = entity;
        vm.clear = clear;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        var unsubscribe = $rootScope.$on('backApp:propertyUpdate', function(event, result) {
            vm.property = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
