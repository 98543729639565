(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('CheckDeliveryDeleteController',CheckDeliveryDeleteController);

    CheckDeliveryDeleteController.$inject = ['$uibModalInstance', 'entity', 'CheckDelivery'];

    function CheckDeliveryDeleteController($uibModalInstance, entity, CheckDelivery) {
        var vm = this;

        vm.checkDelivery = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CheckDelivery.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
