(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('store-third-list', {
                parent: 'store-detail2',
                url: '/store-third-list',
                data: {
                    authorities: ['ROLE_PLATFROM_AGENT'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-third/store-thirds.html',
                        controller: 'StoreThirdController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                }
            })
            .state('store-third-new', {
                parent: 'store-third-list',
                url: '/new',
                data: {
                    authorities: ['ROLE_PLATFROM_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-third/store-third-dialog.html',
                        controller: 'StoreThirdDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            name: null,
                            id: null,
                            platform: "RRD"
                        };
                    }

                }
            })

            .state('store-third-edit', {
                parent: 'store-detail2',
                url: '/store-third-edit/{id}',
                data: {
                    authorities: ['ROLE_PLATFROM_AGENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-third/store-third-dialog.html',
                        controller: 'StoreThirdDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['PlatformAbutment', '$stateParams', function(PlatformAbutment, $stateParams) {
                        return PlatformAbutment.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();
