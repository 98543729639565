(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('PlatformALiSetting', PlatformALiSetting)
        .factory('PlatformDouDians', PlatformDouDians);

    PlatformALiSetting.$inject = ['$resource', 'DateUtils'];
    PlatformDouDians.$inject = ['$resource', 'DateUtils'];

    function PlatformALiSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/platform-ali-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expiresTime = DateUtils.convertLocalDateFromServer(data.expiresTime);
                        data.reExpiresTime = DateUtils.convertLocalDateFromServer(data.reExpiresTime);
                    }
                    return data;
                }
            },
            'getOne': {
                method: 'GET',
                params: {
                    path: 'getOne'
                } 
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.expiresTime = DateUtils.convertLocalDateToServer(copy.expiresTime);
                    copy.reExpiresTime = DateUtils.convertLocalDateToServer(copy.reExpiresTime);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.expiresTime = DateUtils.convertLocalDateToServer(copy.expiresTime);
                    copy.reExpiresTime = DateUtils.convertLocalDateToServer(copy.reExpiresTime);
                    return angular.toJson(copy);
                }
            }
        });
    }

    function PlatformDouDians ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/platform-dou-dians/:path/:id';

        return $resource(resourceUrl, {}, {
            'save': {method: 'POST',},
            'update': { method:'PUT' },
            'getOne': {
                method: 'GET',
                params: {
                    path: 'getOne'
                }
            },
            'query': { method: 'GET', isArray: true},
        });
    }
})();
