(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformWechatSettingDetailController', PlatformWechatSettingDetailController);

    PlatformWechatSettingDetailController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PlatformWechatSetting', 
    'PlatformAbutment', 'MessageService', "SalesOrder", 'Customer'];

    function PlatformWechatSettingDetailController($state, $scope, $rootScope, $stateParams, previousState, entity, PlatformWechatSetting, 
        PlatformAbutment, MessageService, SalesOrder, Customer) {
        var vm = this;

        vm.dels = dels;
        vm.changeState2 = changeState2;
        vm.platformWechatSetting = entity;
        vm.reload = reload;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('platformApp:platformWechatSettingUpdate', function(event, result) {
            vm.platformWechatSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);


        getAllAbutments();
        function getAllAbutments() {
            vm.abuts = PlatformAbutment.getAll({});
        }

        function changeState2(stateStr) {
            vm.isSaving = true;
            if (stateStr.enabled) {
                stateStr.enabled = false;
            } else {
                stateStr.enabled = true;
            }
            PlatformAbutment.changeState({id:stateStr.id}, function(result){
                vm.isSaving = false;
                MessageService.success('操作成功');
            }, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:afterSaleProblemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('操作成功');
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error('操作失败');
        }

        $scope.fix =function(){
            if (!vm.storeId) {
                MessageService.error("操作失败,请输入商户id");
                return;
            }
            Customer.updateVersion({storeId:vm.storeId}, function(data){
                if(data.status == 200){
                    MessageService.success('操作成功,共处理'+data.message+"客户");
                }else{
                    MessageService.error('操作失败,失败原因：'+data.message);
                }
            },function(data){
               MessageService.error('操作失败,失败原因：'+data.data.message);
            }) 
        }

        $scope.fix2 =function(){
            if (!vm.storeId2) {
                MessageService.error("操作失败,请输入商户id");
                return;
            }
            MessageService.confirm("请确认主账户数据清理完毕？", function(){
                Customer.updateVersion2({storeId:vm.storeId2}, function(data){
                    if(data.status == 200){
                        MessageService.success('操作成功,共处理'+data.message+"客户");
                    }else{
                        MessageService.error('操作失败,失败原因：'+data.message);
                    }
                },function(data){
                   MessageService.error('操作失败,失败原因：'+data.data.message);
                }) 
            })
        }

        function dels (id, index) {
            PlatformAbutment.del({id:id}, function(result){
                vm.abuts.splice(index, 1);
                MessageService.success('操作成功');
            }, onSaveError);
        }

        function reload(id) {
            PlatformAbutment.reloadToken({id:id}, function(result){
                if(result == '1') {
                    MessageService.error("Token可以正常使用，不需要刷新");
                } else {
                    MessageService.error("操作失败");
                }
            })
        }
    }
})();
