(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreApproveController', StoreApproveController);

    StoreApproveController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Store', '$uibModalInstance', 'MessageService'];

    function StoreApproveController($scope, $rootScope, $stateParams, entity, Store, $uibModalInstance, MessageService) {
        var vm = this;

        vm.store = entity; 

        vm.clear = clear;
        vm.customCheck = customCheck;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            if (vm.store.custom && (vm.store.numberRule == null || vm.store.numberRule == "")) {
                MessageService.error("请录入单据前缀规则")
                return;
            }
            if (vm.store.numberRule && vm.store.numberRule.length  !=4) {
                 MessageService.error("请输入4位长度单据前缀规则")
                return;
            }
            Store.getAllByCondition({
                items: [{
                    key: "numberRule",
                    op: "=",
                    value: vm.store.numberRule
                }]
            }, function(data) {
                if ((data && data.length>1)|| (data.length == 1 && data[0].id != vm.store.id)) {
                     MessageService.error("该编号前缀已经有商家在使用，请更换前缀");
                }else{
                    Store.approve({
                        id: vm.store.id,
                        status: 'Approved',
                        numberRule: vm.store.numberRule
                    }, function(data) {
                        $scope.$emit('platformApp:storeUpdate', data);
                        $uibModalInstance.close(data);
                        MessageService.success("保存成功");
                    }, function() {
                        MessageService.error("保存失败");
                    })
                }
            }, function() {
                MessageService.error("保存失败");
            });
        }

        function customCheck (custom){
            vm.store.custom = custom;
            if (!custom) {
                vm.store.numberRule = null;
            }
        }

        var unsubscribe = $rootScope.$on('platformApp:storeUpdate', function(event, result) {
            vm.store = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
