(function(){
    'user strict';
    angular.module('platformApp')
    .directive('perm', perm);

    perm.$inject = ['Principal', '$q']
    function perm(Principal, $q) {
        var directive = {
            restrict: 'A',
            scope: false, 
            link: linkFunc
        };
        return directive;
        function linkFunc(scope, element, attrs) {
            var auth = attrs.perm; 
            if(auth.indexOf(",") <= 0){
                var result = Principal.hasAuthority(auth);
                $q.all([result.$promise]).then(function(){
                    if(!result.$$state.value){
                        element.hide();
                    }
                })
            }else{
                var authorities = auth.split(",");
                if(authorities !=null && authorities.length > 0){ 
                    var result = Principal.hasAnyAuthority(authorities);
                    if(!result){
                        element.hide();
                    }
                } else{
                    element.hide(); 
                }
            }
        }
    }
})();
