(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PictureSettingModalController', PictureSettingModalController);

    PictureSettingModalController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'PicManagements', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Qinius', 'UidService'];

    function PictureSettingModalController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, PicManagements, Store, $state, MessageService, $uibModalInstance, Qinius, UidService) {
        var vm = this;
        vm.data = entity.data || {};
        vm.save = save;
        vm.cancel = cancel;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.typeList = [
            {name:'注册页', id:'register'},
            {name:'登录页', id:'login'},
        ];

        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })
        //上传图片
        window.updateImgFile = function ($event) {
            var file = $event.target.files[0];
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "pic-"+ UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.data.img = $scope.domain + data.key;
                    $scope.$digest();
                },
                error: function (request) {
                    MessageService.error("文件上传失败");
                }
            });
        };

        $scope.removeFile = function () {
            vm.data.img = null;
        }
        //确定
        function save() {
            if(!vm.data.pageSite){
                MessageService.error("展示位置不能为空");
                return
            }
            if(!vm.data.seq){
                MessageService.error("排序不能为空");
                return
            }
            if(vm.data.id){
                PicManagements.update(vm.data,function (res) {
                    MessageService.success("编辑成功");
                    $uibModalInstance.close(true);
                }, function (error) {
                    MessageService.error("失败，请稍后重试！");
                })
            }else{
                PicManagements.save(vm.data,function (res) {
                    MessageService.success("添加成功");
                    $uibModalInstance.close(true);
                }, function (error) {
                    MessageService.error("失败，请稍后重试！");
                })
            }
        }
        //取消
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
