(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayBatchChangeApplicationController', PayBatchChangeApplicationController);

    PayBatchChangeApplicationController.$inject = ['$state', 'PayBatchChangeApplication', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','$scope','MessageService','Store'];

    function PayBatchChangeApplicationController($state, PayBatchChangeApplication, ParseLinks, AlertService, paginationConstants, pagingParams,$scope,MessageService,Store) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.time = null;
        vm.status = status;

        Store.getAllByCondition({items: [{key: "status", op: "=", value: "Approved"}]},function(data){
            vm.stores = data;
        });

        $scope.byTime = function(time){
            vm.time = time.name;
            vm.timeId = time.id;
            loadAll();
        };

        loadAll();

        function loadAll () {
            var items = [];
            if (vm.timeId != null) {
                items.push({key:"storeId",op:"=",value:vm.timeId});
            };

            PayBatchChangeApplication.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.payBatchChangeApplications = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }


        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0  ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.delete = function (id) {
            MessageService.confirm("entity.action.deleteConfirm", function () {
                PayBatchChangeApplication.delete({id: id}, function () {
                    loadAll();
                });
            }, function () {
            }, true);
        }

        $scope.approve = function(id) {
            MessageService.confirm("确认要通过该申请么？", function() {
                PayBatchChangeApplication.approve({id: id}, function(data) {
                    loadAll();
                    MessageService.success("通过成功");
                }, function() {
                    MessageService.error("通过失败")
                })
            });
        };

        $scope.reject = function (id) {
            MessageService.confirm("确认要拒绝该申请么？", function() {
                PayBatchChangeApplication.reject({id: id}, function() {
                    loadAll();
                    MessageService.success("拒绝成功");
                }, function() {
                    MessageService.error("拒绝失败")
                })
            });
        }
    }
})();
