(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MessagePriceController', MessagePriceController);

    MessagePriceController.$inject = ['MessagePrice'];

    function MessagePriceController(MessagePrice) {

        var vm = this;

        vm.messagePrices = [];

        loadAll();

        function loadAll() {
            MessagePrice.byCondition({items:[]}, function(result) {
                vm.messagePrices = result;
                vm.searchQuery = null;
            });
        }
    }
})();
