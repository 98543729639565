(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductXmlCancelArticleController', ProductXmlCancelArticleController);
    ProductXmlCancelArticleController.$inject = ['$scope','Article', '$uibModalInstance', 'MessageService','xmlId', '$q', '$uibModal'];

    function ProductXmlCancelArticleController($scope, Article, $uibModalInstance, MessageService, xmlId,  $q, $uibModal) {
        //编辑第三方的模板 弹出窗口

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.xmlId = xmlId;
        
        $q.all([ ]).then(function () {
        });
         
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            Article.invalidByProductXmlId({productXmlId:vm.xmlId}, function () {
                MessageService.success("作废成功");
                $uibModalInstance.close();
            }, function(){
                MessageService.error("作废失败");
            });
        }
 
    }

})();
