(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreCheckController', StoreCheckController);

    StoreCheckController.$inject = ['$scope', '$q', 'DateUtils', '$rootScope', '$stateParams', 'entity', 'Store', 'Qinius', 'OrderSetting',
        '$uibModalInstance','MessageService','$state'];

    function StoreCheckController($scope, $q, DateUtils, $rootScope, $stateParams, entity, Store, Qinius, OrderSetting,
                                  $uibModalInstance,MessageService,$state) {
        var vm = this;

        vm.store = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.types = [{key:"公众号",value:"WECHAT",selected:false}, {key:"小程序",value:"MINI",selected:false}, {key:"第三方",value:"THIRD",selected:false}]
        if ($stateParams.storeId) {
            vm.storeOrderSetting =OrderSetting.getStoreOrderSetting({storeId:$stateParams.storeId});
        }
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise , vm.store.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if (vm.store.storeType2) {
                for (var i = vm.types.length - 1; i >= 0; i--) {
                    var t = vm.types[i];
                    if(vm.store.storeType2.indexOf(t.value)>=0){
                        t.selected = true;
                    }
                }
            }
        })

        var unsubscribe = $rootScope.$on('platformApp:storeUpdate', function(event, result) {
            vm.store = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.activatStore = function () {
            if(vm.store.periodOfValidity==null||vm.store.periodOfValidity==''){
                MessageService.error("请输入有效期");
                return
            }
            var arr = [];
            if(vm.store.Web){
                arr.push('Web');
            }
            if(vm.store.WECHAT){
                arr.push('WECHAT');
            }
            if(vm.store.MINI){
                arr.push('MINI');
            }
            if(vm.store.ALI){
                arr.push('ALI');
            }
            if(vm.store.BYTEDANCE){
                arr.push('BYTEDANCE');
            }
            if(vm.store.JD){
                arr.push('JD');
            }
            if(!arr.length){
                MessageService.error("请至少选择一项商户类型");
                return
            }
            vm.store.storeType2 = arr.join(';');
            MessageService.confirm("确定激活该商户吗？",function(){
                Store.approve({
                    id:vm.store.id,
                    status:"Approved",
                    periodOfValidity:DateUtils.convertLocalDateToServer(vm.store.periodOfValidity),
                    storeType2:vm.store.storeType2
                },function (result) {
                    MessageService.success("商户激活成功");
                    $uibModalInstance.close(result);
                })
            },function(){},true)


        }

        $scope.refuse = function () {
            MessageService.confirm("确定拒绝该商户申请吗？",function(){
                Store.approve({
                    id:vm.store.id,
                    status:"Rejected"
                },function (result) {
                    MessageService.success("商户申请已拒绝");
                    $uibModalInstance.close(result);
                })
            },function(){},true)
        }


        $scope.goToReadImage = function (pic) {
            $(".store-form").hide()
            $state.go('store.img',{url:pic})

        }

        $scope.backTo = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        };

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStart = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOn, true);
            document.addEventListener("mouseup", moveImgEnd, true);
        }

        //move事件
        function moveImgOn(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEnd() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOn, true);
            document.removeEventListener("mouseup", moveImgEnd, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
    }
})();
