(function () {
    'use strict';

    angular
        .module('platformApp')
        .directive('fileRead', fileRead);

    fileRead.$inject = ['$rootScope', '$timeout','File'];


    function fileRead($rootScope, $timeout,File) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element) {
            element.bind("change", function (changeEvent) {
                var files = changeEvent.target.files;
                if (files) {

                    /*******modify******start*/
                    files.type = "font";
                    // scope.template.uploadFont.push(files);

                    // File.uploadFont({model:'font',file:files},function (msg) {
                    //     console.log(msg);
                    // });
                    /*******modify******end*/

                    // angular.forEach(files, function (item, index) {
                    //     (function (index) {
                    //         var reader = new FileReader();
                    //         // Closure to capture the file information.
                    //         reader.onload = (function (theFile) {
                    //             return function (e) {
                    //                 if (scope.fontList == "" || scope.fontList == null) {
                    //                     scope.fontList = [];
                    //                 }
                    //                 // Render thumbnail.
                    //                 scope.fontList.push({
                    //                     name: files[index].name.split('.')[0],
                    //                     value: files[index].name.split('.')[0]
                    //                 });
                    //                 scope.$digest();
                    //                 var sheet = document.createElement('style');
                    //                 sheet.innerHTML = "@font-face {font-family: '" + files[index].name.split('.')[0] + "'; src: url('" + e.target.result + "');}";
                    //                 $('head').append(sheet);
                    //                 if (scope.fontList.length - 3 === files.length) {
                    //                     // SweetAlert.swal('', '字体上传成功，请勿刷新页面。', 'success')
                    //                 }
                    //             };
                    //         })(files[index]);
                    //         // Read in the image file as a data URL.
                    //         reader.readAsDataURL(files[index]);
                    //     })(index)
                    // });
                }
            })
        }
    }
})();

