(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('SalesOrderImportDialogController', SalesOrderImportDialogController);

    SalesOrderImportDialogController.$inject = ['$timeout', '$scope', '$stateParams',  '$state', '$uibModalInstance', 'entity', 'CheckDelivery', 'Upload', 'MessageService'];

    function SalesOrderImportDialogController ($timeout, $scope, $stateParams, $state, $uibModalInstance, entity,  CheckDelivery, Upload, MessageService) {
        var vm = this;

        vm.clear = clear;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () { 
            $uibModalInstance.dismiss('cancel');
        }

        $scope.uploadFile = function(fileWatched) {
            vm.file = fileWatched;
            vm.fileName = fileWatched.name;
             
        };

        $scope.import1 = function(){
            Upload.upload({
                type: 'POST',
                url: 'service/api/sales-orders/importSalesOrder', //上传路径
                data: {
                    file: vm.file,
                    name:vm.fileName
                }
            }).then(function successCallback(res) {
                if(res.status && res.status == 200){
                    MessageService.success("导入成功"); 
                    $uibModalInstance.dismiss('cancel');
                }
            }, function errorCallback(response) { 
                MessageService.error("文件导入，连接超时， 请稍后刷新页面确认是否成功");
            });
        }

        $scope.import2 = function(){
            Upload.upload({
                type: 'POST',
                url: 'service/api/sales-orders/updateData', //上传路径
                data: {
                    file: vm.file,
                    name:vm.fileName
                }
            }).then(function successCallback(res) {
                if(res.status && res.status == 200){
                    $uibModalInstance.dismiss('cancel');
                    MessageService.success("导入成功"); 
                }
            }, function errorCallback(response) { 
                MessageService.error("文件导入，连接超时， 请稍后刷新页面确认是否成功");
            });
        }
    }
})();
