(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MarketingController', MarketingController);

    MarketingController.$inject = ['$scope', '$q', '$rootScope', '$state', 'PasswordMarketing', 'Store', 'ListService', '$stateParams'];

    function MarketingController($scope, $q, $rootScope, $state, PasswordMarketing, Store, ListService, $stateParams) {
        var vm = this;

        vm.clear = clear;
        vm.storeId = $stateParams.storeId;
        if (!vm.storeId) {
            return;
        }

        vm.marketingStore = PasswordMarketing.getOne({type:"Password"});
        vm.store = Store.getStore({id:vm.storeId});

        $q.all([vm.marketingStore.$promise, vm.store.$promise]).then(function(){
            var result = ListService.inList2(vm.store, vm.marketingStore.marketingStores, "id", "storeId");
            if (result) {
                vm.show = true;
            }
        })

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.marketingManagement = function(){
            sessionStorage.setItem("lmenu", "a_marketing_management");
            $state.go("password-activity");
            function test(){
              $state.reload();
            }
            setTimeout(test,800);
        }


        var unsubscribe = $rootScope.$on('platformApp:categoryUpdate', function(event, result) {
            vm.marketing = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
