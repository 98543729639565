(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FlowAnalysisController', FlowAnalysisController);

    FlowAnalysisController.$inject = ['$scope', '$state', 'ParseLinks', 'MessageService', 'SalesOrder', 'XlsxService', 'Product', 'Store', 'DateUtils','$http'];

    function FlowAnalysisController ($scope, $state, ParseLinks, MessageService, SalesOrder, XlsxService, Product, Store, DateUtils,$http) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.openCalendar =  openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.transition = transition;
        vm.search = search;
        vm.clear = clear;
        vm.downLoadFile = downLoadFile;
        vm.startDate = "";
        vm.endDate = "";
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
        //获取商户
        vm.stores = [];
        Store.getAllByCondition({items: [{key: "status", op: "=", value: "Approved"}]},function (res) {
            for(var i=0; i<res.length; i++){
                if(!res[i].cancelled && res[i].authorityType){
                    if(res[i].authorityType.indexOf("PRODUCT") != -1){
                        vm.stores.push(res[i])
                    }
                }
            }
        });
        vm.data = {
            storeId:'',
            startDate:'',
            endDate:'',
            editorType:'',
            page:0,
            pageSize:20
        };
        vm.page = 1;
        //编辑器
        vm.editorTypes = [
            {name:'简易编辑器', id:'BOOK_JUNIOR'},
            {name:'高级编辑器', id:'BOOK_SENIOR'},
            {name:'万物编辑器', id:'POSTER'},
            {name:'冲印编辑器', id:'PICTURE'},
            {name:'相册编辑器', id:'NEW_EDITOR'},
            {name:'DIY编辑器', id:'DIY_EDITOR'},         
            {name:'表单编辑器', id:'FORM_EDITOR'},     
            {name:'动态台历编辑器', id:'CALENDAR_EDITOR'},
        ]
        init();
        function init() {
            vm.tableData = [];
            vm.data.page = vm.page-1;
            vm.data.startDate = DateUtils.convertLocalDateToServer(vm.startDate);
            vm.data.endDate = DateUtils.convertLocalDateToServer(vm.endDate);
            SalesOrder.byConditionPage(vm.data,function (res) {
                if(res.status == 200){
                    vm.totalItems = res.message.count;
                    vm.tableData = res.message.productVMS;
                }
            })
        }
        function transition() {
            init();
        }
        function search() {
            vm.page = 1;
            init();
        }
        function clear() {
            vm.page = 1;
            vm.data = {
                storeId:'',
                startDate:'',
                endDate:'',
                editorType:'',
                page:0,
                pageSize:20
            };
            vm.startDate = "";
            vm.endDate = "";
            init();
        }
        function downLoadFile(){
            vm.data.page = vm.page-1;
            vm.data.startDate = DateUtils.convertLocalDateToServer(vm.data.startDate);
            vm.data.endDate = DateUtils.convertLocalDateToServer(vm.data.endDate);
            $http({
                url: 'service/api/sales-orders/byConditionPage/download',
                method: "GET",
                params: vm.data,
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "流量定价统计" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
                }).error(function (data, status, headers, config) {
            });
        }
        vm.editorTypeStatus = editorTypeStatus;
        function editorTypeStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "BOOK_SENIOR":
                    return "高级编辑器";
                case "BOOK_JUNIOR":
                    return "简易编辑器";
                case "PICTURE":
                    return "冲印编辑器";
                case "POSTER":
                    return "万物编辑器";
                case "NEW_EDITOR":
                    return "相册编辑器";
                case "FORM_EDITOR":
                    return "表单编辑器";
                default:
                    return "";
            }
        }
    }
})();
