(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FeedbackWwwDetailController', FeedbackWwwDetailController);

    FeedbackWwwDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'FontManagement'];

    function FeedbackWwwDetailController($scope, $rootScope, $stateParams, $uibModalInstance, entity, FontManagement) {
        var vm = this;
        vm.clear = clear;
        vm.data = entity;
        console.log(vm.data,'-----');

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.getStatusName = function (status) {
            if(status === "Untreated"){
                return "未处理";
            }else if(status === "Treated"){
                return "已处理";
            }
            return "";
        }
    }
})();
