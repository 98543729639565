(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('RechargeLevel', RechargeLevel)
        .factory('RechargeSettingCopy', RechargeSettingCopy);

    RechargeLevel.$inject = ['$resource'];

    RechargeSettingCopy.$inject = ['$resource'];

    function RechargeLevel ($resource) {
        var resourceUrl =  'manager/' + 'api/recharge-level/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},

            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'save': { method:'POST' },
            'delete': {
                method: 'DELETE'
            }
        });
    }

    function RechargeSettingCopy ($resource) {
        var resourceUrl =  'manager/' + 'api/recharge-setting-copy/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},

            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'save': { method:'POST' },
            'delete': {
                method: 'DELETE'
            }
        });
    }
})();
