(function () {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('page-my-suffix', {
                parent: 'entity',
                url: '/page-my-suffix',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/pagesmySuffix.html',
                        controller: 'PageMySuffixController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('page-my-suffix-edit', {
                parent: 'entity',
                url: '/page-my-suffix/{id}',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/pagesmySuffix.html',
                        controller: 'PageMySuffixController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('page-my-suffix-copy', {
                parent: 'entity',
                url: '/page-my-suffix/{id}/copy',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/pagesmySuffix.html',
                        controller: 'PageMySuffixController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    copy:true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    entity: ['ProductXmlFileServes','$stateParams', function(ProductXmlFileServes,$stateParams) {
                        return ProductXmlFileServes.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

        ;
    }

})();
