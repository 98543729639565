(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store-payment-setting', {
            parent: 'store-detail2',
            url: '/store-payment-setting',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'global.menu.entities.store-payment-setting'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-payment-setting/store-payment-setting-detail.html',
                    controller: 'StorePaymentSettingDetailController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Store', '$stateParams', function(Store, $stateParams) {
                        return Store.getStore({id: $stateParams.storeId}).$promise;
                    }
                ]
            }
        })

        .state('store-payment-setting-edit', {
            parent: 'store-detail2',
            url: '/store-payment-setting-edit',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-payment-setting/store-payment-setting-dialog.html',
                    controller: 'StorePaymentSettingDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Store', '$stateParams', function(Store, $stateParams) {
                        return Store.getStore({id : $stateParams.storeId}).$promise;
                    }
                ]
            }
        })
        ;
    }

})();
