(function () {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreProductPromotionDialogController', StoreProductPromotionDialogController);

    StoreProductPromotionDialogController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', 'Product',  'ListService', '$uibModalInstance',
        'MessageService', '$stateParams' ];

    function StoreProductPromotionDialogController($timeout, $scope, $state, $q, entity, Product,  ListService, $uibModalInstance,
        MessageService, $stateParams ) {
        var vm = this;
        vm.item = entity;
        vm.clear = clear;
        vm.save = save;
        vm.storeId = $stateParams.storeId;
        if (!vm.storeId) {
            return;
        }

        $scope.productSelected = function(p){
            p.selected = !p.selected;
        }

        vm.products = Product.getAllByCondition2({items:[{key:"storeId", op:"=", value:vm.storeId},{key:"productType", op:"=", value:"Promotion"}]});
        
        $q.all([vm.products.$promise]).then(function () {
            if (vm.item.products && vm.item.products.length>=0) {
                for (var i = vm.item.products.length - 1; i >= 0; i--) {
                    var p =  vm.item.products[i];
                    var result = ListService.inList(p, vm.products, ["id"]);
                    if (result) {
                        result.selected = true;
                    }
                }
            }
        }).then(function () {
        });
 

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save() {
            vm.item.products = [];
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var p =  vm.products[i];
                if (p.selected) {
                    vm.item.products.push(p);
                }
            }
            $uibModalInstance.close(vm.item);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
