(function() {
    'use strict';

    angular
        .module('platformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        //公有公众号
        .state('store-wechat-setting', {
            parent: 'entity',
            url: '/store-wechat-setting',
            data: {
                authorities: ['ROLE_PLATFROM_WECHAT'],
                pageTitle: 'platformApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-detail.html',
                    controller: 'StoreWechatSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreWechatSetting', function($stateParams, StoreWechatSetting) {
                    return StoreWechatSetting.getEntityByStoreId().$promise;
                }]
            }
        })

       .state('store-wechat-setting-detail', {
            parent: 'entity',
            url: '/platform/auth_notify?auth_code&expires_in',
            data: {
                authorities: ['ROLE_PLATFROM_WECHAT'],
                pageTitle: 'platformApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-detail.html',
                    controller: 'StoreWechatSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WechatAuth', function($stateParams, WechatAuth) {
                    return WechatAuth.platformAuth_notify({auth_code:$stateParams.auth_code,expires_in:$stateParams.expires_in}).$promise;
                }]
            }
        })

        .state('store-wechat-setting.edit', {
            parent: 'entity',
            url: '/store-wechat-setting/{id}/platform/edit',
            data: {
                authorities: ['ROLE_PLATFROM_WECHAT'],
                pageTitle: 'platformApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-dialog.html',
                    controller: 'StoreWechatSettingDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreWechatSetting', function($stateParams, StoreWechatSetting) {
                    return StoreWechatSetting.getEntityByStoreId().$promise;
                }]
            }
        })
        //以下 代运营
        .state('store-store-wechat-setting', {
            parent: 'store-detail2',
            url: '/store-store-wechat-setting',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'global.menu.entities.store-store-wechat-setting'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-detail2.html',
                    controller: 'StoreWechatSettingDetailController2',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreWechatSetting', function($stateParams, StoreWechatSetting) {
                    return StoreWechatSetting.getPrivateWechatSetting({storeId:$stateParams.storeId}).$promise;
                }]
            }
        })

       .state('store-store-wechat-setting-detail', {
            parent: 'store-detail2',
            url: '/platform/auth_notify?auth_code&expires_in',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'platformApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-detail2.html',
                    controller: 'StoreWechatSettingDetailController2',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WechatAuth', function($stateParams, WechatAuth) {
                    return WechatAuth.auth_notify({auth_code:$stateParams.auth_code,expires_in:$stateParams.expires_in, storeId:$stateParams.storeId}).$promise;
                }]
            }
        })
       .state('store-store-small-app-setting', {
            parent: 'store-detail2',
            url: '/store-store-small-app-setting',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'global.menu.entities.store-store-small-app-setting'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-small-app-setting-detail2.html',
                    controller: 'StoreSmallAppSettingDetailController2',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreSmallAppWechatSetting', function($stateParams, StoreSmallAppWechatSetting) {
                    return StoreSmallAppWechatSetting.getEntityByStoreId({storeId:$stateParams.storeId}).$promise;
                }]
            }
        })

       .state('store-store-small-app-setting-detail', {
            parent: 'store-detail2',
            url: '/platform2/auth_notify?auth_code&expires_in',
            data: {
                authorities: ['ROLE_PLATFROM_AGENT'],
                pageTitle: 'platformApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-small-app-setting-detail2.html',
                    controller: 'StoreSmallAppSettingDetailController2',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WechatAuth', function($stateParams, WechatAuth) {
                    return WechatAuth.auth_notify({auth_code:$stateParams.auth_code,expires_in:$stateParams.expires_in, storeId:$stateParams.storeId}).$promise;
                }]
            }
        })
        ;
    }
})();
