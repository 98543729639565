(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformWechatSettingDialogController', PlatformWechatSettingDialogController);

    PlatformWechatSettingDialogController.$inject = ['$timeout', '$scope', 'MessageService', '$state', 'entity', 'PlatformWechatSetting'];

    function PlatformWechatSettingDialogController ($timeout, $scope, MessageService, $state, entity, PlatformWechatSetting) {
        var vm = this;

        vm.platformWechatSetting = entity;

        vm.clear = clear;
        vm.save = save;
        vm.save1 = save1;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('platform-wechat-setting', null, { reload: true });
        }

        function save () {
            if (!vm.platformWechatSetting.backNotifyUrl) {
                MessageService.error("请录入back服务域名");
                return;
            };
            if (!vm.platformWechatSetting.storeNotifyUrl) {
                MessageService.error("请录入store服务域名");
                return;
            };
            if (!vm.platformWechatSetting.editorNotifyUrl) {
                MessageService.error("请录入editor服务域名");
                return;
            };
            vm.isSaving = true;

            if (vm.platformWechatSetting.id !== null) {
                PlatformWechatSetting.update(vm.platformWechatSetting, onSaveSuccess, onSaveError);
            } else {
                PlatformWechatSetting.save(vm.platformWechatSetting, onSaveSuccess, onSaveError);
            }
        }

        function save1 () {
            if (!vm.platformWechatSetting.storeInNoticePhone) {
                MessageService.error("请录入商户入驻接收人手机号");
                return;
            };
            vm.isSaving = true;
            if (vm.platformWechatSetting.id !== null) {
                PlatformWechatSetting.update(vm.platformWechatSetting, onSaveSuccess1, onSaveError);
            } else {
                PlatformWechatSetting.save(vm.platformWechatSetting, onSaveSuccess1, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:platformWechatSettingUpdate', result);
            // $uibModalInstance.close(result);
            $state.go('platform-wechat-setting', null, { reload: 'platform-wechat-setting' });
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveSuccess1 (result) {
            $scope.$emit('platformApp:platformWechatSettingUpdate', result);
            // $uibModalInstance.close(result);
            $state.go('api2', null, { reload: 'api2' });
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }


    }
})();
