(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductPurchaseDialogController', ProductPurchaseDialogController);

    ProductPurchaseDialogController.$inject = ['$scope', '$q', '$uibModalInstance', 'entity', 'ProductPurchase', 'Store', 'Product'];

    function ProductPurchaseDialogController($scope, $q, $uibModalInstance, entity, ProductPurchase, Store, Product) {
        var vm = this;
        vm.productPurchase = entity;
        vm.clear = clear;
        vm.products = Product.getAllByCondition2({items:[{key:"prototype",value:"Store", op:"="}]});
        vm.stores = Store.getAllByCondition({items: [{key:"activated" ,value: true,op:"="}]});

        $q.all([vm.stores.$promise]).then(function(){

        });

        $scope.selectChange = function(productPurchase){
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i];
                if (s.id == productPurchase.storeId) {
                    productPurchase.storeName = s.name;
                    vm.products = s.products;
                    break;
                }
            }
        };

        $scope.export = function(){
            if (vm.productPurchase.storeId == null && vm.productPurchase.productId == null){
                window.open("manager/api/product-purchases/exportDate");
            }else if (vm.productPurchase.storeId != null  && vm.productPurchase.productId == null){
                window.open("manager/api/product-purchases/exportDate?storeId="+vm.productPurchase.storeId);
            }else  if (vm.productPurchase.storeId == null  && vm.productPurchase.productId != null){
                window.open("manager/api/product-purchases/exportDate?productId="+vm.productPurchase.productId);
            }else{
                window.open("manager/api/product-purchases/exportDate?storeId="+vm.productPurchase.storeId +"&&productId="+vm.productPurchase.productId);
            }

        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
