(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductPurchaseController', ProductPurchaseController);

    ProductPurchaseController.$inject = ['$state', 'Principal','ProductPurchase', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService'
    ,'$scope', 'PayCode', 'Store'];

    function ProductPurchaseController($state, Principal,ProductPurchase, ParseLinks, paginationConstants, pagingParams, MessageService,
        $scope, PayCode, Store) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.time = null;
        vm.purchaseType = purchaseType;
        vm.status = status;
        Store.getAllByCondition({items: [{key: "status", op: "=", value: "Approved"}]},function(data){
            vm.stores = data;
        });

        loadAll();

        vm.tempArr =[];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll =function(){
            $scope.selectAll = !$scope.selectAll;
            angular.forEach(vm.productPurchases,function(item){
                item.select = $scope.selectAll;
            });
            vm.tempArr = vm.productPurchases.filter(function(item){
                return item.select == true;
            });
        };

        $scope.chooseOne = function(data){
            data.select = !data.select;
            $scope.selectAll = vm.productPurchases.every(function(item){
                return item.select;
            });
            vm.tempArr = vm.productPurchases.filter(function(item){
                return item.select == true;
            });
        };


        $scope.delete = function(item){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                ProductPurchase.delete({id: item.id},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true)
        };

        $scope.byTime = function(time){
            vm.time = time.name;
            vm.timeId = time.id;
            loadAll();
        };

        function loadAll () {
            var items = [{key:"status",op:"!=",value:"Create"}];
            if (vm.timeId != null) {
                items.push({key:"storeId",op:"=",value:vm.timeId});
            };
            if (pagingParams.search) {
                items.push({key:"product.name,storeName",op:"like",value:pagingParams.search});
                ProductPurchase.byCondition2({
                    items: items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ProductPurchase.byCondition2({
                    items: items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productPurchases = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        $scope.approve = function(purchase) {
            MessageService.confirm("确认要通过产品订购么？", function() {
                ProductPurchase.approve({id: purchase.id}, function(data) {
                    loadAll();
                    MessageService.success("通过成功");
                }, function() {
                    MessageService.error("通过失败")
                })
            });
        };

        $scope.approveBatch = function() {
            var ids = vm.tempArr.map(function(item){return item.id}).join(',');
            MessageService.confirm("确认要通过产品订购么？", function() {
                ProductPurchase.approveBatch({ids: ids}, function(data) {
                    loadAll();
                    MessageService.success("通过成功");
                }, function() {
                    MessageService.error("通过失败")
                })
            });
        };

        $scope.delBatch = function() {
            var ids = vm.tempArr.map(function(item){return item.id}).join(',');
            MessageService.confirm("确认要拒绝产品订购么？", function() {
                ProductPurchase.delBatch({ids: ids}, function(data) {
                    loadAll();
                    MessageService.success("拒绝成功");
                }, function() {
                    MessageService.error("拒绝失败")
                })
            });
        }
        $scope.refuseBatch = function () {
            var ids = vm.tempArr.map(function(item){return item.id}).join(',');
            MessageService.confirm("确认要拒绝产品订购么？", function() {
                ProductPurchase.rejectBatch({ids: ids}, function(data) {
                    loadAll();
                    MessageService.success("拒绝成功");
                }, function() {
                    MessageService.error("拒绝失败")
                })
            });
        }
        $scope.reject = function (data) {
            MessageService.confirm("确认要拒绝产品订购么？", function() {
                ProductPurchase.reject({id: data.id}, function() {
                    loadAll();
                    MessageService.success("拒绝成功");
                }, function() {
                    MessageService.error("拒绝失败")
                })
            });
        }
        $scope.accomplish = function (data) {
            ProductPurchase.complete({id: data.id}, function() {
                loadAll();
                MessageService.success("完成");
            }, function() {
                MessageService.error("操作失败，请稍后重试！")
            })
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }


        function purchaseType(target) {
            switch (target) {
                case "GIFTPURCHASE":
                    return "礼包订购";
                case "COMMODITYPURCHASE":
                    return "商品订购";
                case "EXCHANGEPURCHASE":
                    return "兑换码订购";
                default:
                    return "";
            }
        }

        function status(target) {
            switch (target) {
                case "Create":
                    return "新建";
                case "Approving":
                    return "已提交";
                case "Approved":
                    return "已通过";
                case "Completed":
                    return "已完成";
                case "Rejected":
                    return "已拒绝";
                default:
                    return "";
            }
        }
    }
})();
