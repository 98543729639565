(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('MessageTempDialogController', MessageTempDialogController);

    MessageTempDialogController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'entity', 'MessageTemp', 'MessageService', 'ListService'];

    function MessageTempDialogController($state, $scope, $rootScope, $stateParams, entity, MessageTemp, MessageService, ListService) {
        var vm = this;
        vm.save = save;

        vm.tempList = entity.message;

        $scope.getLabel = function(target){
            if (!target) {
                return;
            }
            switch(target){
                case "auditPassTemp":
                    return "商户审核通过";
                case "auditRejectTemp":
                    return "商户未通过审核";
                case "storeRegisterTemp":
                    return "商户入驻提醒审核";
                case "storeValidityTemp":
                    return "商户授权到期提醒";
                case "verifyCodeTemp":
                    return "验证码";
                case "orderMsgTemp":
                    return "订单短信通知";
                case "storeAuthTemp":
                    return "商户授权提醒";
                case "balanceTemp":
                    return "余额不足";
                case "balanceWarnTemp":
                    return "余额预警";
                case "newProductTemp":
                    return "平台商品新增通知";
                case "storeStorageTemp":
                    return "商户存储到期提醒";
                case "customerStorageTemp":
                    return "客户存储到期提醒";
                case "customerStorageTemp":
                    return "客户存储到期提醒";
                case "cleanStorageTemp":
                    return "计划清理客户存储通知";
                case "cleanedStorageTemp":
                    return "客户存储清理通知";
                case "sendCouponTemp":
                    return "发放优惠券";
                default :
                return"";
            }
        }

        if (vm.tempList == null) {
            vm.tempList = [];
        }

        var auditPassTemp = {type:"auditPassTemp"};
        var result = ListService.inList(auditPassTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(auditPassTemp);
        }

        var auditRejectTemp = {type:"auditRejectTemp"};
        var result = ListService.inList(auditRejectTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(auditRejectTemp);
        }

        var storeRegisterTemp = {type:"storeRegisterTemp"};
        var result = ListService.inList(storeRegisterTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(storeRegisterTemp);
        }

        var storeValidityTemp = {type:"storeValidityTemp"};
        var result = ListService.inList(storeValidityTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(storeValidityTemp);
        }

        var verifyCodeTemp = {type:"verifyCodeTemp"};
        var result = ListService.inList(verifyCodeTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(verifyCodeTemp);
        }

        var orderMsgTemp = {type:"orderMsgTemp"};
        var result = ListService.inList(orderMsgTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(orderMsgTemp);
        }

        var storeAuthTemp = {type:"storeAuthTemp"};
        var result = ListService.inList(storeAuthTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(storeAuthTemp);
        }

        var balanceTemp = {type:"balanceTemp"};
        var result = ListService.inList(balanceTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(balanceTemp);
        }

        var balanceWarnTemp = {type:"balanceWarnTemp"};
        var result = ListService.inList(balanceWarnTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(balanceWarnTemp);
        }

        var newProductTemp = {type:"newProductTemp"};
        var result = ListService.inList(newProductTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(newProductTemp);
        }

        var storeStorageTemp = {type:"storeStorageTemp"};
        var result = ListService.inList(storeStorageTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(storeStorageTemp);
        }

        var customerStorageTemp = {type:"customerStorageTemp"};
        var result = ListService.inList(customerStorageTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(customerStorageTemp);
        }

        var customerStorageTemp = {type:"customerStorageTemp"};
        var result = ListService.inList(customerStorageTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(customerStorageTemp);
        }

        var cleanStorageTemp = {type:"cleanStorageTemp"};
        var result = ListService.inList(cleanStorageTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(cleanStorageTemp);
        }

        var cleanedStorageTemp = {type:"cleanedStorageTemp"};
        var result = ListService.inList(cleanedStorageTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(cleanedStorageTemp);
        }

        var sendCouponTemp = {type:"sendCouponTemp"};
        var result = ListService.inList(sendCouponTemp, vm.tempList, ["type"]);
        if (result == null) {
            vm.tempList.push(sendCouponTemp);
        }


        var unsubscribe = $rootScope.$on('platformApp:platformWechatSettingUpdate', function(event, result) {
            vm.platformWechatSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);


       function save(){
            vm.saving =true;
            MessageTemp.batchUpdate(vm.tempList, onSaveSuccess, onSaveError);
       }

        function onSaveSuccess(result) {
            MessageService.success('操作成功');
             vm.saving = false;
            $state.go('message-temp', null, {reload: 'message-temp'});
        }

        function onSaveError() {
             vm.saving = false;
            MessageService.error('操作失败');
        }


    }
})();
