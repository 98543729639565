(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('HotBotAddController', HotBotAddController);

        HotBotAddController.$inject = ['$state', 'HotWords','MessageService', '$scope','$uibModalInstance'];

    function HotBotAddController($state, HotWords, MessageService, $scope,$uibModalInstance) {
        var vm =this;
        vm.clear = clear;
        vm.save = save;
        vm.HotBot = '';
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (!vm.HotBot) {
                MessageService.success('请输入关键词')
            }
            HotWords.add({name:vm.HotBot},function(res){
                if (res.status == 200) {
                    MessageService.success('添加成功')
                    $uibModalInstance.close();
                }else{
                    MessageService.success('添加失败')
                }
            })
        }
    }
})();
