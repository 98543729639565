(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ProductPurchaseDetailController', ProductPurchaseDetailController);

    ProductPurchaseDetailController.$inject = ['$scope', '$q', 'entity', 'ProductPurchase', 'Store', 'Product', '$state'];

    function ProductPurchaseDetailController($scope, $q, entity, ProductPurchase, Store, Product, $state) {
        var vm = this;
        vm.productPurchase = entity;
        console.log(vm.productPurchase)
        vm.clear = clear;
        vm.purchaseType = purchaseType;
        vm.status = status;


        $q.all([vm.productPurchase.$promise]).then(function () {
            formatting()
        })
        function formatting() {
            vm.products = []
            for(var i=0; i<vm.productPurchase.productPurchaseLines.length; i++){
                var _itemValueLineProductId = vm.productPurchase.productPurchaseLines[i].itemValueLineProductId;
                var _itemValueLineProductName = vm.productPurchase.productPurchaseLines[i].itemValueLineProductName;
                var _product = {
                    productId: _itemValueLineProductId,
                    productName: _itemValueLineProductName,
                    itemValueLines:  []
                }
                var _isResult = vm.products.some(function (item, index) {
                    if(item.productId==_itemValueLineProductId){
                        vm.products[index].itemValueLines.push(vm.productPurchase.productPurchaseLines[i])
                        return true
                    }
                })
                if(!_isResult){
                    _product.itemValueLines.push(vm.productPurchase.productPurchaseLines[i])
                    vm.products.push(_product)
                }
            }
        }
        function clear () {
            $state.go('product-purchase')
        }
        function purchaseType(target) {
            switch (target) {
                case "GIFTPURCHASE":
                    return "礼包订购";
                case "COMMODITYPURCHASE":
                    return "商品订购";
                default:
                    return "";
            }
        }

        function status(target) {
            switch (target) {
                case "Create":
                    return "新建";
                case "Approving":
                    return "已提交";
                case "Approved":
                    return "已通过";
                case "Completed":
                    return "已完成";
                default:
                    return "";
            }
        }
    }
})();
