(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ItemValueLinePackageDetailController', ItemValueLinePackageDetailController);

    ItemValueLinePackageDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'ItemValueLinePackageViewD',
        'ParseLinks', 'MessageService','pagingParams', 'OrderLines', 'paginationConstants'];

    function ItemValueLinePackageDetailController($scope, $state, $rootScope, $stateParams, previousState, ItemValueLinePackageViewD,
                                                  ParseLinks, MessageService,pagingParams, OrderLines, paginationConstants) {
        var vm = this;

        vm.uuid = $stateParams.id;
        vm.periodOfValidity = $stateParams.date;
        vm.previousState = previousState.name;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;


        vm.startTime = pagingParams.startTime ? new Date(pagingParams.startTime):null;
        vm.endTime = pagingParams.endTime? new Date(pagingParams.endTime):null;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        var unsubscribe = $rootScope.$on('platformApp:itemValueLinePackageUpdate', function(event, result) {
            vm.itemValueLinePackage = result;
        });
        $scope.$on('$destroy', unsubscribe);

        loadAll();

        function loadAll() {

            ItemValueLinePackageViewD.byCondition({
                items: [{key:"uuid",op:"=",value:vm.uuid}],
                page: 0,
                size: 20,
                sort: sort()
            }, onSuccess, onError);

            var items=[{key: "uuid", op: "=", value: vm.uuid}];
            if (pagingParams.search) {
                items.push({key: "salesOrderNumber", op: "like", value: pagingParams.search});
            }
            if (vm.endTime !=null) {
                items.push({key: "salesOrderPaidDate", op: "<=", value: new Date(Date.parse(vm.endTime) + (24 * 60 * 60 * 1000) - 1000)});
            };
            if (vm.startTime !=null) {
                items.push({key: "salesOrderPaidDate", op: ">=", value: vm.startTime});
            };

            OrderLines.getPlatformStockConsume({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess1, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.packageViews = data;
                angular.forEach(vm.packageViews,function (view) {
                    if(view.packageType=='COMMODITYPURCHASE'){
                        vm.commodityView = angular.copy(view);
                    }else{
                        vm.giftView = angular.copy(view);
                    }
                })
            }
            function onError(error) {
                MessageService.error("库存获取失败");
            }
        }

        function clear() {
            $state.go('item-value-line-package');
        }

        function onSuccess1(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.stockConsumes = data;
            vm.page = pagingParams.page;
        }
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                id: vm.uuid,
                date: vm.periodOfValidity,
                startTime: vm.startTime,
                endTime:vm.endTime,
            });
        }

        function search(searchQuery) {
            // if (!searchQuery){
            //     return vm.clear();
            // }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump () {
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        $scope.clearSearch = function () {
            vm.startTime = null;
            vm.endTime = null;
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.searchQuery = null;
            vm.transition();
        }
    }
})();
