(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('Customer', Customer);

    Customer.$inject = ['$resource', 'DateUtils'];

    function Customer($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/customers/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "updateVersion":{
                method: 'GET',
                params: {
                    path: 'updateVersion'
                }
            },
            "updateVersion2":{
                method: 'GET',
                params: {
                    path: 'updateVersion2'
                }
            },
            'allByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'getToken': {
                method: 'GET',
                params: {
                    path: 'getToken'
                }
            },
            "setInactive":{
                method:"GET",
                params:{
                    path:"setInactive"
                }
            }
        });
    }
})();
