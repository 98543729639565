(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('PlatformAbutment', PlatformAbutment)
        .factory('StoreAliSettings', StoreAliSettings)
        .factory('PlatformWechatSetting', PlatformWechatSetting)
        .factory('SystemSetting', SystemSetting)
        .factory('PlatformByteDance', PlatformByteDance);

    StoreAliSettings.$inject = ['$resource'];
    PlatformAbutment.$inject = ['$resource'];
    PlatformWechatSetting.$inject = ['$resource'];
    SystemSetting.$inject = ['$resource'];
    PlatformByteDance.$inject = ['$resource'];

    function StoreAliSettings ($resource) {
        var resourceUrl ='manager/' + 'api/store-ali-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'buildQuery': {
                method: 'GET',
                params: {
                    path: 'buildQuery'
                }
            },
            "miniVersionAuditApply":{
                method:"GET",
                params:{
                    path:"miniVersionAuditApply"
                }
            },
            'detailQuery': {
                method: 'GET',
                params: {
                    path: 'detailQuery'
                }
            },
            'miniVersionGrayOnline': {
                method: 'GET',
                params: {
                    path: 'miniVersionGrayOnline'
                }
            },
            'grayCancel': {
                method: 'GET',
                params: {
                    path: 'grayCancel'
                }
            },
            'miniVersionOnline': {
                method: 'GET',
                params: {
                    path: 'miniVersionOnline'
                }
            },
            "auditedCancel":{
                method: 'GET',
                params: {
                    path: 'auditedCancel'
                }
            }
        });
    }


    function PlatformAbutment ($resource) {
        var resourceUrl ='manager/' + 'api/platform-abutment/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition2'
                },
                isArray: true
            },

            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'PUT'
            },
            'del': {
                method: 'GET',
                params: {
                    path: 'del'
                }
            },
            'changeState': {
                method: 'GET',
                params: {
                    path: 'changeState'
                }
            },
            'reloadToken': {
                method: 'GET',
                params: {
                    path: 'reloadToken'
                }
            }
        });
    }

    function SystemSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/system-settings/:path';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "getAll": {
                method: "GET",
                params: {
                    path: "getAll"
                },
                isArray: true
            },
            "getByKey": {
                method: "GET",
                params: {
                    path: "getByKey"
                }
            },
            "getValueByKey": {
                method: "POST",
                params: {
                    path: "getValueByKey"
                },
                isArray: true
            },
            'update': { method:'PUT' },
            "editValueByKey": {
                method: "GET",
                params: {
                    path: "editValueByKey"
                },
            }
        });
    }

    function PlatformWechatSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/platform-wechat-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "updateSkin": {
                method: "GET",
                params: {
                    path: "updateSkin"
                }
            },
            "byPlatFrom": {
                method: "GET",
                params: {
                    path: "byPlatFrom"
                }
            },
            'update': { method:'PUT' },
            "byPlatFrom2": {
                method: "GET",
                params: {
                    path: "byPlatFrom"
                }
            },
            'update2': { method:'PUT' }
        });
    }

    function PlatformByteDance ($resource) {
        var resourceUrl ='manager/' + 'api/platform-byte-dances/:path/:id';

        return $resource(resourceUrl, {}, {
            'update': { method:'PUT' },
            'getOne': {
                method: 'GET',
                params: {
                    path: 'getOne'
                }
            },
            "auth_notification":{
                method:"POST",
                params:{
                    path:"auth_notification"
                }
            },
            'pre_auth_code': {
                method: 'POST',
                params: {
                    path: 'pre_auth_code'
                }
            },
            'getTemplateList': {
                method: 'GET',
                params: {
                    path: 'getTemplateList'
                }
            }
        });
    }
})();
