(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoreController', StoreController);

    StoreController.$inject = ['$state', 'Store', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService',
     '$scope', '$stateParams', '$uibModal'];

    function StoreController($state, Store, ParseLinks, paginationConstants, pagingParams, MessageService,
        $scope, $stateParams, $uibModal) {

        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.openCalendar = openCalendar;
        vm.validityPeriod = false;
        $scope.selectAll = false;

        loadAll();

        vm.status = 'All'
        if ($stateParams.status == 'Created') {
           vm.status = 'Created'
        }
        if ($stateParams.status == 'Approved') {
            vm.status = 'Approved'
        }
        if ($stateParams.status == 'Rejected') {
            vm.status = 'Rejected'
        }

        $scope.setNumber = function(p){
            $uibModal.open({
                templateUrl: 'app/entities/store/number-set.html',
                controller: 'NumberSetController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return p.id;
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    loadAll()
                }
            });

        }


        function loadAll() {
            var items = [];
            if ($stateParams.status == 'Created') {
                items.push({key:"status", op:"=", value:"Created"});
            }
            if ($stateParams.status == 'Approved') {
                items.push({key:"status", op:"=", value:"Approved"});
            }
            if ($stateParams.status == 'Rejected') {
                items.push({key:"status", op:"=", value:"Rejected"});
            }
            if (pagingParams.search) {
                items.push({key: "name",op: "like",value: pagingParams.search});
            }
            Store.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.stores = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("商户获取失败");
            }
        }

        $scope.delete = function(id) {
            MessageService.confirm("确认要删除该商户么？" , function() {
                Store.delete({
                    id: id
                }, function() {
                    loadAll();
                });
            }, function() {});
        }

        $scope.activated = function(id) {
            MessageService.confirm("确认要激活选中商户么？", function() {
                Store.activation2({
                    id: id
                }, function() {
                    loadAll();
                });
            }, function() {});
        }

        $scope.sendAuthMsg = function(id) {
            Store.sendAuthMsg({id: id}, function() { });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;

        $scope.chooseAll = function() {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.stores, function(store, i) {
                if ($scope.selectAll) {
                    store.select = true;
                    $scope.batchArr.push(store.id);
                    $scope.selectOne = true;
                } else {
                    $scope.batchArr = [];
                    store.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (store) {
            store.select = !store.select;
            // 选中
            if(store.select){
                $scope.batchArr.push(store.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(store.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.stores,function (store,i) {
                if(!store.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.stores.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function() {
            MessageService.confirm("确认要删除选中的商户？", function() {
                Store.deleteBatch({
                    ids: $scope.batchArr
                }, function(data) {
                    MessageService.success("删除成功");
                    loadAll();
                }, function() {
                    MessageService.error("删除失败");
                })
            }, function() {});
        }

        $scope.approve = function(store, status) {
            if (store == null || status == null) {
                return;
            }
            Store.approve({
                id: store.id,
                status: status
            }, function(data) {
                loadAll();
            }, function() {})
        }



        function openCalendar(store) {
            store.validityPeriod = true;
        }

        $scope.getPeriodOfValidity = function(id, periodOfValidity) {
            Store.updatePeriodOfValidity({
                id: id,
                periodOfValidity: periodOfValidity
            });
        }

        $scope.close = function(id) {
            MessageService.confirm("entity.action.deleteStoreConfirm", function() {
                Store.closeStore({
                    id: id
                }, function() {
                    loadAll();
                    MessageService.success('关闭成功');
                })
            }, function() {}, true)
        };
    }
})();
