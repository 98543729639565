(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformByteDancesController', PlatformByteDancesController);

    PlatformByteDancesController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PlatformByteDance',
    'PlatformAbutment', 'MessageService', "SalesOrder"];

    function PlatformByteDancesController($state, $scope, $rootScope, $stateParams, previousState, entity, PlatformByteDance,
        PlatformAbutment, MessageService, SalesOrder) {
        var vm = this;

        vm.dels = dels;
        vm.changeState2 = changeState2;
        vm.platformByteDance = entity;
        vm.reload = reload;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('platformApp:PlatformByteDanceUpdate', function(event, result) {
            vm.platformByteDance = result;
        });
        $scope.$on('$destroy', unsubscribe);


        getAllAbutments();
        function getAllAbutments() {
            vm.abuts = PlatformAbutment.getAll({});
        }

        function changeState2(stateStr) {
            vm.isSaving = true;
            if (stateStr.enabled) {
                stateStr.enabled = false;
            } else {
                stateStr.enabled = true;
            }
            PlatformAbutment.changeState({id:stateStr.id}, function(result){
                vm.isSaving = false;
                MessageService.success('操作成功');
            }, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:afterSaleProblemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('操作成功');
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error('操作失败');
        }

        function dels (id, index) {
            PlatformAbutment.del({id:id}, function(result){
                vm.abuts.splice(index, 1);
                MessageService.success('操作成功');
            }, onSaveError);
        }

        function reload(id) {
            PlatformAbutment.reloadToken({id:id}, function(result){
                if(result == '1') {
                    MessageService.error("Token可以正常使用，不需要刷新");
                } else {
                    MessageService.error("操作失败");
                }
            })
        }
    }
})();
