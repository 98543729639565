(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('ClipArt', ClipArt);

    ClipArt.$inject = ['$resource', 'DateUtils'];

    function ClipArt($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/clipArt/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "updateType": {
                method: "GET",
                params: {
                    path: "updateType"
                }
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'getone'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                params: {
                    path: 'update'
                },
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                params: {
                    path: 'save'
                },
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'batchCreate':{
                method: 'GET',
                params: {
                    path: 'batchCreate'
                }
            },
            'delete': {
                method: 'DELETE',
                params: {
                    path: 'delete'
                }
            }
        });
    }
})();
