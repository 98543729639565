(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PayBatch2Controller', PayBatch2Controller);

    PayBatch2Controller.$inject = ['$state', 'PayBatch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', '$stateParams'];

    function PayBatch2Controller($state, PayBatch, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, $stateParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.storeId = $stateParams.storeId;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();


        function loadAll () {
            var items = [{key:"used", op:"=", value:'true'},{key:"storeId", op:"=", value: vm.storeId}]
            if (pagingParams.search) {
                items.push({key:"storeName,productName,supplier,number",op:"like",value:pagingParams.search});
            }
            PayBatch.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.allPagesCount = Math.ceil(vm.totalItems/20);
                vm.queryCount = vm.totalItems;
                vm.payBatchs = data;
                angular.forEach(vm.payBatchs,function (batch) {
                    if(batch.productName){
                        batch.productNameList = batch.productName.split(";");
                    }
                    if(batch.productSku){
                        batch.productSkuList = batch.productSku.split(";");
                    }
                })
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据加载失败");
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                storeId:vm.storeId,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;

            if(flag){
                flag = false;
                $(".paybatch-proName"+index).addClass('text-decoration-underline');
                $(".paybatch-tip"+index).show().css({left:e.clientX || 0,top:e.clientY || 0});
            }

        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;

            if(flag){
                flag = false;
                $(".paybatch-proName"+index).addClass('text-decoration-underline');
                $(".paybatch-tip"+index).show().css({left:e.clientX || 0,top:e.clientY || 0});
            }

        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
    }
})();
