(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('SalesOrderDeliveryHistoryController',SalesOrderDeliveryHistoryController);

    SalesOrderDeliveryHistoryController.$inject = ['$uibModalInstance', '$scope', 'entity', '$q'];

    function SalesOrderDeliveryHistoryController($uibModalInstance, $scope, entity,  $q) {
        var vm = this;

        vm.histories = entity;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
