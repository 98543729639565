(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('AuthorityDetailController', AuthorityDetailController);

    // AuthorityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Authority'];
    AuthorityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Authority', '$uibModalInstance'];

    // function AuthorityDetailController($scope, $rootScope, $stateParams, previousState, entity, Authority) {
    //     var vm = this;
    //
    //     vm.authority = entity;
    //     vm.previousState = previousState.name;
    //
    //     var unsubscribe = $rootScope.$on('platformApp:authorityUpdate', function(event, result) {
    //         vm.authority = result;
    //     });
    //     $scope.$on('$destroy', unsubscribe);
    // }
    function AuthorityDetailController($scope, $rootScope, $stateParams,entity, Authority, $uibModalInstance) {
        var vm = this;

        vm.authority = entity;
        vm.clear = clear;
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('platformApp:authorityUpdate', function(event, result) {
            vm.authority = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
