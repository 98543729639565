(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StoragePriceDetailController', StoragePriceDetailController);

    StoragePriceDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'entity', 'StoragePrice', 'Store', '$uibModal', 'MessageService'];

    function StoragePriceDetailController($scope, $state, $rootScope, $stateParams, entity, StoragePrice, Store, $uibModal, MessageService) {
        var vm = this;

        vm.storagePrice = entity;
        vm.clear = clear;
        vm.stores = [];
        vm.storesAll = { name: '全部', selected: false };
        vm.isStores = true;
        Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]}, function(res) {
            for (var i = 0; i < res.length; i++) {
                if (!res[i].cancelled && res[i].authorityType) {
                    if (res[i].authorityType.indexOf("PRODUCT") != -1) {
                        vm.stores.push(res[i])
                    }
                }
            }
            if (vm.storagePrice.base) {
                vm.storesAll.selected = true;
                vm.isStores = false;
            } else {
                for (var i = 0; i < vm.storagePrice.stores.length; i++) {
                    for (var j = 0; j < vm.stores.length; j++) {
                        if (vm.storagePrice.stores[i].id === vm.stores[j].id) {
                            vm.stores[j].selected = true;
                        }
                    }
                }
            }
        })

        function clear() {
            $state.go("storage-price");
        }

        var unsubscribe = $rootScope.$on('platformApp:storagePriceUpdate', function(event, result) {
            vm.storagePrice = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
