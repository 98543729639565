(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('StorageSpaceController', StorageSpaceController);

    StorageSpaceController.$inject = ['$state', 'StorageSpace', 'StorageSpace2', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', '$scope'];

    function StorageSpaceController($state, StorageSpace, StorageSpace2, ParseLinks, MessageService, paginationConstants, pagingParams, $scope) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        $scope.selectAll = false;

        loadAll();

        function loadAll() {
            if (pagingParams.search) {
                StorageSpace.byCondition({
                    items: [{ key: "storeName", op: "like", value: pagingParams.search }],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: ["usedSize,desc"]
                }, onSuccess, onError);
            } else {
                StorageSpace.byCondition({
                    items: [],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: ["usedSize,desc"]
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.prices = res;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("查询失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;

        $scope.chooseAll = function() {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.prices, function(price, i) {
                if ($scope.selectAll) {
                    price.select = true;
                    $scope.batchArr.push(price.id);
                    $scope.selectOne = true;
                } else {
                    $scope.batchArr = [];
                    price.select = false;
                    $scope.selectOne = false;
                }
            })
        }
        $scope.getUsedSizeStore = function(id) {
            StorageSpace2.fixUsedSize({ storeId: id }, function(res) {
                if (res.status == 200) {
                    MessageService.success('操作成功');
                } else {
                    MessageService.error('操作失败');
                }
            })
        }
    }
})();