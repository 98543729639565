(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('ExpressPriceDetailController', ExpressPriceDetailController);

    ExpressPriceDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'entity', 'ExpressPrice', 'Store', '$uibModal', 'MessageService'];

    function ExpressPriceDetailController($scope, $state, $rootScope, $stateParams, entity, ExpressPrice, Store, $uibModal, MessageService) {
        var vm = this;

        vm.expressPrice = entity;
        vm.clear = clear;
        vm.stores = [];
        vm.storesAll = { name: '全部', selected: false };
        vm.isStores = true;
        Store.getAllByCondition({items:[{key:"activated" ,value: true,op:"="}]}, function(res) {
            for (var i = 0; i < res.length; i++) {
                if (!res[i].cancelled && res[i].authorityType) {
                    if (res[i].authorityType.indexOf("PRODUCT") != -1) {
                        vm.stores.push(res[i])
                    }
                }
            }
            if (vm.expressPrice.base) {
                vm.storesAll.selected = true;
                vm.isStores = false;
            } else {
                for (var i = 0; i < vm.expressPrice.stores.length; i++) {
                    for (var j = 0; j < vm.stores.length; j++) {
                        if (vm.expressPrice.stores[i].id === vm.stores[j].id) {
                            vm.stores[j].selected = true;
                        }
                    }
                }
            }
        })

        function clear() {
            $state.go("express-price");
        }

        var unsubscribe = $rootScope.$on('platformApp:expressPriceUpdate', function(event, result) {
            vm.expressPrice = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.priceRuleStatus = priceRuleStatus;

        function priceRuleStatus(target) {
            switch (target) {
                case "Standard":
                    return "标准定价";
                case "Ladder":
                    return "阶梯定价";
                case "Grads":
                    return "梯度定价";
                default:
                    return "";
            }
        }
    }
})();
