(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('VersionPackageDialogController', VersionPackageDialogController);

    VersionPackageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'PackageVersion', 'MessageService'];

    function VersionPackageDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, PackageVersion, MessageService) {
        var vm = this;
        vm.storeList = [
            {name:'公众号', value:'WECHAT'},
            {name:'微信小程序', value: 'MINI'},
            {name:'网页端', value: 'WEB'},
            {name:'支付宝小程序', value: 'ALI'},
            {name:'字节跳动小程序', value: 'BYTEDANCE'},
            {name:'京东小程序', value: 'JD'}
        ];

        vm.data = entity;
        vm.clear = clear;
        vm.save = save;
        $q.all([vm.data.$promise]).then(function(){
             console.log(vm.data)
            if(vm.data.id){
                var arr = vm.data.storePort.split(';');
                angular.forEach(arr,function (item) {
                    angular.forEach(vm.storeList,function (list) {
                        if(list.value==item){
                            list.selected = true
                        }
                    })
                })

                if(vm.data.branch){
                    vm.data.branch = "true";
                }else{
                    vm.data.branch = "false";
                }
            }
        })
        //控制只能输入小数点后2位
        window.clearNoNum = function (obj) {
            obj.value = obj.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
            obj.value = obj.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            obj.value = obj.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (obj.value.indexOf(".") < 0 && obj.value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                obj.value = parseFloat(obj.value);
            }
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.validate = function(){
            var ports = []
            angular.forEach(vm.storeList,function (s) {
                if(s.selected){
                    ports.push(s.value)
                }
            })
            vm.data.storePort = ports.join(';');
            if(vm.data.storePort == null || vm.data.storePort == ""){
                MessageService.error("请选择商户版本");
                return false;
            }
            if(vm.data.name == null || vm.data.name == ""){
                MessageService.error("请输入套餐名称");
                return false;
            }
            if(vm.data.oriPrice == null || vm.data.oriPrice==""){
                MessageService.error("请输入原价");
                return false;
            }
            if(vm.data.price == null || vm.data.price==""){
                MessageService.error("请输入现价");
                return false;
            }
            if(vm.data.validYears == null || vm.data.validYears==""){
                MessageService.error("请输入商户有效日期");
                return false;
            }
            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;

            if (vm.data.id != null) {
                PackageVersion.update(vm.data, onSaveSuccess, onSaveError);
            } else {
                PackageVersion.save(vm.data, onSaveSuccess, onSaveError);
            }
        };

        function onSaveSuccess (result) {
            MessageService.success("套餐保存成功");
            $scope.$emit('editorApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        function onSaveError () {
            MessageService.error("套餐保存失败");
            vm.isSaving = false;
        };
    }
})();
