(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FilterPriceDialogController', FilterPriceDialogController);

    FilterPriceDialogController.$inject = ['$timeout', '$scope', '$stateParams',  'entity', 'FilterPrice', 'Store', '$state', '$uibModal', "MessageService"];

    function FilterPriceDialogController ($timeout, $scope, $stateParams, entity, FilterPrice, Store, $state, $uibModal, MessageService) {
        var vm = this;

        vm.filterPrice = entity;
        vm.back = back;
        vm.save = save;
        vm.isStores = true;

        vm.stores = [];
        vm.storesAll = {name: '全部', selected: false};

        Store.getAllByCondition({
            items: [{key: "status", op: "=", value: "Approved"}]
        }, function (res) {
            for(var i=0; i<res.length; i++){
                if(!res[i].cancelled){
                    vm.stores.push(res[i]);
                }
            }
            if(entity.id){
                if(vm.filterPrice.base){
                    vm.storesAll.selected = true;
                    vm.isStores = false;
                }else {
                    for(var i=0; i<vm.filterPrice.stores.length; i++){
                        for(var j=0; j<vm.stores.length; j++){
                            if(vm.filterPrice.stores[i].id === vm.stores[j].id){
                                vm.stores[j].selected = true;
                            }
                        }
                    }
                }
            }
            if(vm.filterPrice.filterPriceLines.length == 2){
                vm.filterPrice.filterPriceLines.push(
                    {
                        filterType:'SEGMENTATION',
                        filterRule:"TIME",
                        filterPriceRules:[]
                    }
                );
            }
        });

        //新增定价
        $scope.addPricing = function (data, index) {
            $uibModal.open({
                templateUrl: 'app/entities/filter-price/modal/pricing.html',
                controller: 'FilterPricingModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            data: data
                        };
                    }]
                }
            }).result.then(function (item) {
                if(item){
                    vm.filterPrice.filterPriceLines[index] = item;
                }
            });
        }

        //选择使用商家
        $scope.storesCheckbox = function(data){
            vm.filterPrice.stores = [];
            data.selected = !data.selected ? true : false;
            for(var i=0; i<vm.stores.length; i++){
                if(vm.stores[i].selected){
                    vm.filterPrice.stores.push(vm.stores[i])
                }
            }
            if(vm.filterPrice.stores.length > 0){
                vm.filterPrice.base = false;
            }
        };
        $scope.storesAllCheckbox = function(){
            vm.storesAll.selected = !vm.storesAll.selected ? true : false;
            if(!vm.storesAll.selected){
                vm.isStores = true;
                vm.filterPrice.base = false;
            }else {
                for(var j=0; j<vm.stores.length; j++){
                    vm.stores[j].selected = false;
                }
                vm.isStores = false;
                vm.filterPrice.stores = [];
                vm.filterPrice.base = true;
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function back () {
            $state.go("filter-price");
        }

        function save () {
            if (vm.filterPrice.name == "" || vm.filterPrice.name == null) {
                MessageService.error("请输入名称");
                return;
            }
            if (vm.filterPrice.seq == "" || vm.filterPrice.seq == null) {
                MessageService.error("请输入优先级");
                return;
            }

            vm.isSaving = true;
            if (vm.filterPrice.id !== null) {
                FilterPrice.update(vm.filterPrice, onSaveSuccess, onSaveError);
            } else {
                FilterPrice.save(vm.filterPrice, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:filterPriceUpdate', result);
            $state.go("filter-price");
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.priceRule = priceRule;
        function priceRule(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "TIME":
                    return "按次收费";
                default:
                    return "";
            }
        }

        vm.editorType = editorType;
        function editorType(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "PORTRAITCARTOON":
                    return "人像动漫化";
                case "IMAGESTYLECONVERT":
                    return "图像风格转换";
                case "SEGMENTATION":
                    return "人像抠图";
                default:
                    return "";
            }
        }


    }
})();
