(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('CheckDeliveryData', CheckDeliveryData);

    CheckDeliveryData.$inject = ['$resource', 'DateUtils'];

    function CheckDeliveryData ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/check-delivery-data/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            "updateStatus":{
                method: 'GET',
                params:{
                    path:"updateStatus"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.orderTime = DateUtils.convertDateTimeFromServer(data.orderTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
