(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('FreeSpaceController', FreeSpaceController);

    FreeSpaceController.$inject = ['$scope', 'entity', 'Store', '$uibModalInstance', 'MessageService'];

    function FreeSpaceController($scope, entity, Store, $uibModalInstance, MessageService) {
        var vm = this;

        vm.store = entity;

        vm.store.freeSpace = vm.store.freeSpace / 1000 / 1000;

        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            Store.updateFreeSpace({
                id: vm.store.id,
                space: vm.store.freeSpace * 1000 * 1000
            }, function(data) {
                $uibModalInstance.close(data);
                MessageService.success("保存成功");
            }, function() {
                MessageService.error("保存失败");
            })
        }
    }
})();
