(function() {
    'use strict';

    angular
        .module('platformApp')
        .controller('PlatformByteDancesDialogController', PlatformByteDancesDialogController);

    PlatformByteDancesDialogController.$inject = ['$timeout', '$scope', 'MessageService', '$state', 'entity', 'PlatformByteDance'];

    function PlatformByteDancesDialogController ($timeout, $scope, MessageService, $state, entity, PlatformByteDance) {
        var vm = this;

        vm.platformByteDance = entity;

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('platform-byte-dances', null, { reload: true });
        }

        function save () {
            // if (!vm.platformByteDance.backNotifyUrl) {
            //     MessageService.error("请录入back服务域名");
            //     return;
            // };
            // if (!vm.platformWechatSetting.storeNotifyUrl) {
            //     MessageService.error("请录入store服务域名");
            //     return;
            // };
            // if (!vm.platformWechatSetting.editorNotifyUrl) {
            //     MessageService.error("请录入editor服务域名");
            //     return;
            // };
            vm.isSaving = true;

            if (vm.platformByteDance.id) {
                PlatformByteDance.update(vm.platformByteDance, onSaveSuccess, onSaveError);
            } else {
                PlatformByteDance.save(vm.platformByteDance, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:platformByteDanceUpdate', result);
            // $uibModalInstance.close(result);
            $state.go('platform-byte-dances', null, { reload: 'platform-byte-dances' });
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveSuccess1 (result) {
            $scope.$emit('platformApp:platformByteDanceUpdate', result);
            // $uibModalInstance.close(result);
            $state.go('api2', null, { reload: 'api2' });
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }


    }
})();
